import { throttle } from "lodash";
import * as React from "react";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { hideModal, showModal, showYesNoPrompt } from "../../../../redux/reducers/ModalReducer";
import { addWorkToLicenseRequest, clearModalSearchResults, copyExistingLicense, updateLicenseField, updateLicenseRequestWorkItem } from "../../../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IShoppingCartState } from "../../../../redux/types/IShoppingCartState";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import { DELETE_LICENSE_STATE_KEY, EMPTY_STRING_VALUE, EVENT_HANDLER_THROTTLE_TIME, WORK_MAINTENANCE_TOOLBAR } from "../../../Consts";
import { ILicenseUserSearchQuery } from "../../../types/ILicenseUserSearchQuery";
import { ILicenseUserSearchResult } from "../../../types/ILicenseUserSearchResult";
import { ITreeData } from "../../../types/ITreeData";
import { LicenseMaintenanceGeneralDataView } from "../licenseMaintenanceGeneralDataView/LicenseMaintenanceGeneralDataView";
import { LicenseMaintenanceGridsView } from "../licenseMaintenanceGridsView/LicenseMaintenanceGridsView";
import LicenseMaintenanceToolbar from "../licenseMaintenanceToolbar/LicenseMaintenanceToolbar";
import { ILicenseInputItem } from "../../../../redux/types/ILicenseInputItem";
import { ILicenseRequestItem } from "../../../../redux/types/ILicenseRequestItem";
import { ILicenseRequestWorkItem } from "../../../../redux/types/ILicenseRequestWorkItem";
import { submitLicenseToCRMThunk } from "../../../../redux/thunks/RepertoireThunks";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { ILookupInstance } from "../../../../lookup/types/ILookup";
import { CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP } from "../../../ConfigurationConsts";
import { getDataAction } from "../../../components/toolBar/ToolbarHelper";
import { SearchRequests } from "../../../services/SearchRequests";
import { ComponentsHelper } from "../../../../core/services/ComponentHelper";

export interface ILicenseMaintenancePageProps {
    licenseMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    licenseMaintenanceToolbarData?: IRepertoireComponentDataItem;
    licenseMaintenanceSaveResultData?: IRepertoireComponentDataItem;
    licenseMaintenanceGridsViewData?: IRepertoireComponentDataItem;
    searchResultTableData?: IRepertoireComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem
    shoppingCart: IShoppingCartState;
    activeTab: number;
    tabs: ITabReduxItem[];
    lookupValues: ILookupDictionary;
    licenseRequestWorkParameterFields?: ILicenseInputItem[];
    hideModal: typeof hideModal;
    showYesNoPrompt: typeof showYesNoPrompt;
    resetMessageBanner: () => void;
    addNewLicense: (lookupValues: ILookupDictionary,licenseRequestWorkParameterFields: ILicenseInputItem[], isNew?: boolean, licenseMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem) => void;
    updateLicenseField: typeof updateLicenseField;
    saveChanges: (license: IShoppingCartState, licenseRequestWorkParameterFields: ILicenseInputItem[],lookupValues: ILookupDictionary) => void;
    deleteLicense: (licenseID: number, sources: ITreeData[], activeTab: number) => void;
    sources?: ITreeData[];
    copyLicense: typeof copyExistingLicense;
    showModal: typeof showModal;
    getShoppingCartParameters: () => void;
    shoppingCartParameters: any;
    crmUsers?: ILicenseUserSearchResult[];
    searchLicenseUser?: (query: ILicenseUserSearchQuery) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    addWorkToLicenseRequest: typeof addWorkToLicenseRequest
    updateLicenseRequestWorkItem: typeof updateLicenseRequestWorkItem;
    getLicenseRequestWorkFields: (commercialUseToken: number, geographicalToken: number, sectorToken: number, userTypeToken: number, useTypeToken: string, workID: number, licenseRequestWorkID: number) => void;
    getWorksContributors: (workIds: number[]) => void;
    licenseWorksPricesImport: (licenseWorksPriceImport: any[]) => void;
    licenseCalculateWorkPrice: (shoppingCart: IShoppingCartState, selectedLicenseRequestWorks: ILicenseRequestWorkItem[], licenseRequestWorkParameterFields: ILicenseInputItem[],
        licenseRequest: ILicenseRequestItem, lookupValues: ILookupDictionary, isModified: boolean) => void;
    submitLicense: (licenseRequestID: number, clientID: string) => void;
    bookPriceCalculationsJobInProgress?: boolean;
    getWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], readonlyIndicatorsWorkFlagTypes: string[], dataActions?: IDataActionToolbar[], workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem, formats?: FormatFields[]) => void;
    workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem;
    formats?: ILookupInstance[];
    undoLicenseChanges: (licenseID: number, lookupValues: ILookupDictionary, licenseRequestWorkParameterFields: ILicenseInputItem[]) => void;
    licenseCode?: string;
    updatedScroll?: number;
    importWorksToLicenseRequest: (workNumbers: string[], workNumberTypes: string[], worksImportData: any[]) => void;
}

interface ILicenseMaintenancePageState {
    toolbarWidth: number;
    tempLicenseWorkRows: ILicenseRequestWorkItem[];
}

export class LicenseMaintenancePage extends React.PureComponent<
    ILicenseMaintenancePageProps,
    ILicenseMaintenancePageState
> {
    toolbarParentRef;
    toolbarChildRef;

    constructor(props: ILicenseMaintenancePageProps) {
        super(props);
        this.state = {
            toolbarWidth: null,
            tempLicenseWorkRows: [{
                licenseRequestWorkID: 0,
                licenseRequestID: 0,
                price: 0,
                workID: 0,
                title: [""],
                order: 0,
                workNumber: [""],
                number: 0,
                priceVAT: 0,
                priceNet: 0,
                currencyAdministrationPrice: 0,
                totalPrice: 0,
                discountPercentage: 0,
                percentageVA: 0,
                percentageVE: 0,
                percentageVAA: 0,
                percentageVAT: 0,

                ////////////////////////////////////////////////////////////////
                //Dynamic fields, form Licensing.LicenseInput
                // Applicable only for CEDRO
                // We will retrieve the fields based on a set of values selected in the Use Tab
                dePagina: "",
                a: "",
                numeroDePagina: "",
                totalPaginas: "",
                porcentaje: "",
                obraCompleta: "",
                numeroCapitulos: "",
                numeroArticulos: "",
                numeroGraficos: "",
                idioma: "",
                observaciones: "",
                nombreDelCurso: "",
                universidadInstitucion: "",
                precioEjemplar: "",
                tituloNuevaObra: "",
                soporteDelEstampado: "",
                entregaDelContenido: "",
                numeroDeReproducciones: "",
                numeroDeAccesos: "",
                numeroDeUsuarios: "",
                numeroDeDestinatarios: "",
                numeroDeAsistente: "",
                numeroDeRecortes: "",
                inicio: "",
                fin: "",
                titulo: "",
                url: "",
                totalPaginas2: "",
                anoPublicacion: "",
                numeroVolumen: "",
                autor: "",
                fechaDelPost: "",
                tituloDelPost: ""
            }]
        };
        this.toolbarParentRef = React.createRef();
        this.toolbarChildRef = React.createRef();

    }

    componentDidMount() {
        const { shoppingCart } = this.props
        this.setState({
            tempLicenseWorkRows: shoppingCart.licenseRequests[0].licenseRequestWorks
        });

        this.props.getShoppingCartParameters();
    }

    updateLicenseRequestWorkItemToLocalState = (licenseRequestWorks: ILicenseRequestWorkItem[]) => {   
        this.toolbarChildRef.current.toggleSaveButton();
        this.setState({
            tempLicenseWorkRows: licenseRequestWorks
        });
    }



    saveLicense() {
        const { saveChanges, shoppingCart, licenseRequestWorkParameterFields, lookupValues } = this.props;
        const { tempLicenseWorkRows } = this.state;
        shoppingCart.licenseRequests[0].licenseRequestWorks = tempLicenseWorkRows;
        saveChanges(shoppingCart, licenseRequestWorkParameterFields, lookupValues);
        this.toolbarChildRef.current.disableSaveButton();
    }

    deleteAction() {
        const { licenseMaintenanceToolbarData, showYesNoPrompt, hideModal, activeTab } = this.props;

        const title: string = licenseMaintenanceToolbarData.fields.find(f => f.name === DELETE_LICENSE_STATE_KEY).data;
        const props: IYesNoPromptViewModalProps = {
            yesCallback: () => {
                this.deleteLicense();
                hideModal();
            },
            noCallback: () => { hideModal(); }
        }
        showYesNoPrompt(title, props);
    }

    deleteLicense() {
        const { deleteLicense, shoppingCart, sources, activeTab } = this.props;
        deleteLicense(shoppingCart.shoppingCartID, sources, activeTab);
    }

    undoChanges = () => {
        const { shoppingCart, undoLicenseChanges, lookupValues, resetMessageBanner } = this.props;
        undoLicenseChanges(shoppingCart.shoppingCartID, lookupValues, [])
        resetMessageBanner();

    }

    submitLicense = () => {
        const { shoppingCart, submitLicense } = this.props;        
        shoppingCart.licenseRequests.forEach((licenseReq) => {
            if (ComponentsHelper.isEmpty(licenseReq.licenseCode)) {
                submitLicense(licenseReq.licenseRequestID, licenseReq.clientValue.value)
            }            
        })
        
    }

    resetMessageBanner() {
        const { resetMessageBanner } = this.props;
        resetMessageBanner();
    }

    getWorkDetails = (dataSource: string, workID: number) => {
        const { lookupValues, workMaintenanceGeneralDataViewData, tabs, activeTab } = this.props;
        const dataActionList = getDataAction(dataSource, WORK_MAINTENANCE_TOOLBAR);
        SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
            .then(formats => {
                this.props.getWorkDetails(dataSource, workID, lookupValues, tabs[activeTab].otherIndicatorWorkFlagTypes, tabs[activeTab].readonlyIndicatorWorkFlagTypes, dataActionList, workMaintenanceGeneralDataViewData, formats);
            });
    }

    render() {
        if (this.props.shoppingCart) {
            const {
                tabs,
                activeTab,
                shoppingCart,
                licenseMaintenanceGeneralDataViewData,
                hideModal,
                showYesNoPrompt,
                licenseMaintenanceToolbarData,
                licenseMaintenanceSaveResultData,
                addNewLicense,
                updateLicenseField,
                copyLicense,
                showModal,
                licenseMaintenanceGridsViewData,
                dataGridTableData,
                shoppingCartParameters,
                crmUsers,
                searchLicenseUser,
                searchResultTableData,
                updatePagination,
                updateLicenseRequestWorkItem,
                getLicenseRequestWorkFields,
                licenseRequestWorkParameterFields,
                getWorksContributors,
                licenseWorksPricesImport,
                licenseCalculateWorkPrice,
                bookPriceCalculationsJobInProgress,
                licenseCode,
                updatedScroll,
                importWorksToLicenseRequest
            } = this.props;

            const title = shoppingCart ? `${shoppingCart.shoppingCartName?.value ?? 'New shopping cart'} - ${shoppingCart.shoppingCartID == 0 ? EMPTY_STRING_VALUE : shoppingCart.shoppingCartID}` : null;

            const {
                toolbarWidth,
                tempLicenseWorkRows
            } = this.state;

            const isReadonly: boolean = tabs[activeTab].isReadonly;

            const changesMade = tabs[activeTab].changesMade;

            let { lookupValues } = this.props;
            const licenseRequest = shoppingCart.licenseRequests[0];

            const isSubmittedStatus = (licenseRequest.status?.value == "Submitted");

            this.setState({
                tempLicenseWorkRows: licenseRequest.licenseRequestWorks
            });

            return (
                <div>
                    <div><span className="title">{title}</span></div>
                    <button type="button" className="toolbar-toggle" data-toggle="collapse" data-target="#toolbarContainer">
                        <i className="icon ms-Icon ms-Icon--MoreVertical" aria-hidden="true"></i>
                    </button>
                    <div id="toolbarContainer" className="WorkMaintenanceToolbar collapse" ref={this.toolbarParentRef}>
                        <LicenseMaintenanceToolbar
                            ref={this.toolbarChildRef}
                            scroll={updatedScroll}
                            toolbarWidth={toolbarWidth}
                            changesMade={changesMade}
                            saveResultData={licenseMaintenanceSaveResultData}
                            toolbarData={licenseMaintenanceToolbarData}
                            saveChanges={this.saveLicense.bind(this)}
                            deleteLicense={this.deleteAction.bind(this)}
                            undoLicenseChanges={this.undoChanges.bind(this)}
                            addNewLicense={() => addNewLicense(lookupValues, [], true, licenseMaintenanceGeneralDataViewData)}
                            resetMessageBanner={this.resetMessageBanner.bind(this)}
                            activeTab={activeTab}
                            tabs={tabs}
                            showYesNoPrompt={showYesNoPrompt}
                            hideModal={hideModal}
                            license={shoppingCart}
                            isReadonly={isReadonly}
                            clearModalSearchResults={clearModalSearchResults}
                            isNew={tabs[activeTab].licenseMaintenanceState?.isNew}
                            copyLicense={copyLicense}
                            submitLicense={this.submitLicense.bind(this)}
                            bookPriceCalculationsJobInProgress={bookPriceCalculationsJobInProgress}
                            isStatusSubmitted={isSubmittedStatus}
                        />
                    </div>

                    <LicenseMaintenanceGeneralDataView
                        licenseMaintenanceGeneralDataViewData={licenseMaintenanceGeneralDataViewData}
                        shoppingCart={shoppingCart}
                        isReadonly={isReadonly}
                        lookupValues={lookupValues}
                        updateLicenseField={updateLicenseField}
                        showModal={showModal}
                        licenseRequest={licenseRequest}
                        licenseCode={ licenseCode }
                    />
                    <LicenseMaintenanceGridsView
                        licenseMaintenanceGridsViewData={licenseMaintenanceGridsViewData}
                        shoppingCart={shoppingCart}
                        dataGridTableData={dataGridTableData}
                        isReadonly={isReadonly}
                        lookupValues={lookupValues}
                        isNewShoppingCart={tabs[activeTab].licenseMaintenanceState?.isNew}
                        resetPagination={() => { }}
                        updatePagination={updatePagination}
                        searchResultTableData={searchResultTableData}
                        showModal={showModal}
                        shoppingCartParameters={shoppingCartParameters}
                        updateLicenseField={updateLicenseField}
                        crmUsers={crmUsers}
                        searchLicenseUser={searchLicenseUser}
                        addWorkToLicenseRequest={addWorkToLicenseRequest}
                        updateLicenseRequestWorkItem={updateLicenseRequestWorkItem}
                        getLicenseRequestWorkFields={getLicenseRequestWorkFields}
                        licenseRequestWorkParameterFields={licenseRequestWorkParameterFields}
                        licenseRequest={licenseRequest}
                        getWorksContributors={getWorksContributors}
                        licenseWorksPricesImport={licenseWorksPricesImport}
                        tabs={tabs}
                        activeTab={activeTab}
                        licenseCalculateWorkPrice={licenseCalculateWorkPrice}
                        tempLicenseWorkRows={tempLicenseWorkRows}
                        updateLicenseRequestWorkItemToLocalState={this.updateLicenseRequestWorkItemToLocalState}
                        getWorkDetails={this.getWorkDetails}
                        changesMade={changesMade}
                        importWorksToLicenseRequest={importWorksToLicenseRequest}
                    />
                </div>
            );
        }
        else
            return null;
    }
}