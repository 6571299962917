// Groups
export const WORK_MAINTENANCE_GROUP: string = "WorkMaintenance";
export const PRODUCT_MAINTENANCE_GROUP: string = "ProductMaintenance";
export const INTERESTED_PARTY_MAINTENANCE_GROUP: string = "IPMaintenance";
export const INGESTION_GROUP: string = "Ingestion";
export const USAGE_MAINTENANCE_GROUP: string = "UsageMaintenance";
export const PORTAL_CONFIGURATION_GROUP: string = "PortalConfiguration";
export const AGREEMENT_MAINTENANCE_GROUP: string = "AgreementMaintenance";

// Keys
export const CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY: string = "FieldFormat";

export const CONFIGURATION_PARAMETER_DEFAULT_SET_TYPE_GROUP_KEY: string = "DefaultSetTypeGroup";
export const CONFIGURATION_PARAMETER_DEFAULT_SET_TYPE_KEY: string = "DefaultSetType";

// Work Maintenance Keys
export const DISPLAY_CONTRIBUTORS_COLUMNS_KEY: string = "DisplayContributorsColumns";
export const FULL_TIME_DURATION_FORMAT_KEY: string = "fullTime";
export const SECONDS_DURTION_FORMAT_KEY: string = "seconds";
export const CONFIGURATION_PARAMETER_AGREEMENT_SHARE_DISPLAY_KEY: string = "AgreementShareDisplayOptions";
export const CONFIGURATION_PARAMETER_CUSTOMER_SOCIETY_CODE: string = "CustomerSocietyCode";
export const CONFIGURATION_PARAMETER_SHARE_DECIMALS_TO_DISPLAY: string = "ShareDecimalPlacesToDisplay";
export const CONFIGURATION_PARAMETER_FIELDS_EXCLUDED_FROM_COPY_WORK = 'FieldsExcludedFromCopyWork';
export const CONFIGURATION_PARAMETER_GET_UI_CONFIGURATION = 'UIConfiguration';
export const CONFIGURATION_PARAMETER_WRITER_CONTRIBUTOR_TYPES = 'WriterContributorTypes';
export const CONFIGURATION_PARAMETER_PUBLISHER_CONTRIBUTOR_TYPES = 'PublisherContributorTypes';
export const CONFIGURATION_PARAMETER_GENRE_CATEGORY_FROM_WORK_TYPE = 'GenreCategoryFromWorkType';
export const CONFIGURATION_PARAMETER_GENRE_CATEGORY_DEFAULTS_TYPES = 'GenereCategoryDefaults';
export const WORK_FLAG_KEY: string = "WorkFlag";
export const READONLY_WORK_FLAG_KEY: string = "ReadonlyWorkFlags";
export const READONLY_FLAGS_FIELD_KEY: string = "ReadonlyFlagsField";

// Product Maintenance Keys
export const CONFIGURATION_PRODUCT_MAINTENANCE_SUBMISSION_KEY: string = "Submission";
export const CONFIGURATION_PRODUCT_MAINTENANCE_ENABLE_CUESHEET_KEY: string = "EnableCommonCuesheets";

// Usage Maintenance Keys
export const CONFIGURATION_PARAMETER_USAGE_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS: string = "UseNewUIGridSearchResults";
export const CONFIGURATION_PARAMETER_WORK_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS: string = "UseNewUIWorkMaintenanceGeneralView";
export const CONFIGURATION_PARAMETER_PRODUCT_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS: string = "UseNewUIProductMaintenanceGeneralView";
export const CONFIGURATION_PARAMETER_POOL_MAINTENANCE_GENERAL_VIEW_DISPLAY_OPTIONS: string = "UseNewUIPoolMaintenanceGeneralView";
export const CONFIGURATION_PARAMETER_USAGE_MATCHING_DEFAULTS_PRODUCTS: string = "UsageMatchingSearchDefaultsProducts";
export const CONFIGURATION_PARAMETER_USAGE_MATCHING_DEFAULTS_WORKS: string = "UsageMatchingSearchDefaultsWorks";
export const CONFIGURATION_PARAMETER_USAGE_REGROUP_AND_DELETE_DISPLAY_OPTIONS: string = "EnableRegroupAndDeleteUI";

//Distribution Maintenance Keys
export const CONFIGURATION_PARAMETER_DISTRIBUTION_SETTINGS_VIEW_DISPLAY_OPTIONS: string = "JobStatusQA";

//IP Maintenance keys
export const CONFIGURATION_PARAMETER_IP_GRID_SEARCH_RESULTS_DISPLAY_OPTIONS: string = "UseNewUIGridIPISearchResults";
