import * as React from "react";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { setUsageType, searchDataChanged, setEditableFields, updateEditableFields, updateUsageGroupMatches } from "../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import SelectorView from "../../repertoire/components/selectorView/SelectorView";
import UsageSearchView from "../components/usageComponents/searchView/UsageSearchView";
import {
    FINDUSAGE_PAGE_VIEW,
    SEARCH_VIEW_USAGES,
    USAGESEARCH_VIEW,
    USAGETOWORK,
    USAGETOPRODUCT,
    SEARCH_VIEW,
    TITLE_EDITABLE_FIELDS_VIEW,
    USAGE_SEARCH_EDITABLE_FIELDS,
    EDITABLE_FIELDS,
    REPERTOIRE,
    WORK_MAINTENANCE_TOOLBAR,
    USAGERESULTTABLE_VIEW,
    ALL_ROLES,
    VIEW_DISTRIBUTIONS_ROLE,
    VIEW_POOLS_ROLE,
    CONFIGURE_USAGES_ROLE,
    VIEW_USAGES_ROLE,
    UPDATE_USAGES_ROLE,
    ADVANCED,
    SIMPLE,
    DATA_GRID_TABLE_FIELDS_EXPANDER,
    SEARCH_VIEW_USAGES_USAGE_TO_PRODUCT,
    SEARCH_VIEW_USAGES_USAGE_TO_WORK
} from "../Consts";
import { IMatchWorksSearchQuery } from "../types/usageTypes/IMatchWorksSearchQuery";
import { IMatchWorksSearchResult } from "../types/usageTypes/IMatchWorksSearchResult";
import { IUsageGroupResult, IUsageGroupsSearchResult, IUsageSearchResultRowType } from "../types/usageTypes/IUsageGroupsSearchResult";
import { IRepertoireField } from "../types/IRepertoireField";
import { IUsagesSearchQuery } from "../types/usageTypes/IUsagesSearchQuery";
import { ISourceMatchType } from '../../settings/types/ISourceMatchType';
import { Dictionary } from '../../core/types/Dictionary';
import { hideModal, showModal, showUpdateFieldsModal } from "../../redux/reducers/ModalReducer";
import { IShowFieldSettingViewModalProps } from "../components/repertoireModal/RepertoireModal";
import { getDataAction } from "../components/toolBar/ToolbarHelper";
import { IDataActionToolbar } from "../types/IDataActionToolbar";
import { FormatFields } from "../../redux/types/FormatFields";
import { UsageSearchRequests } from "../services/usageServices/UsageSearchRequests";
import { CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, PRODUCT_MAINTENANCE_GROUP, WORK_MAINTENANCE_GROUP } from "../ConfigurationConsts";
import { IMatchProductsSearchQuery } from "../types/usageTypes/IMatchProductsSearchQuery";
import { IProductSearchResult } from "../types/IProductSearchResult";
import { ITreeData } from "../types/ITreeData";
import { IDistributionType } from "../types/usageTypes/IDistibutionType";
import { IDistribution } from "../types/usageTypes/IDistribution";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { useMsal } from "@azure/msal-react";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { msalConfig } from "../../accounts/types/msalAuthConfig";
import IEditableFieldsComponentData from "../types/IEditableFieldsComponentData";
import { IUsagePoolSearchQuery } from "../types/usageTypes/IUsagePoolSearchQuery";
import { IDistributionSearchQuery } from "../../repertoire/types/usageTypes/IDistributionSearchQuery";

export interface IFindUsagePageProps {
    findUsagePageData: IRepertoireComponentDataItem;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    searchUsages: (query: IUsagesSearchQuery, modalOpen: boolean, usageType: string, lookupSources: ITreeData[], continuationToken?: string) => void;
    usageGroupsSearchResult: IUsageGroupResult[];
    matchWorkSearchResult: IMatchWorksSearchResult[];
    searchSuccessful: boolean;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    isHundredResultsPerPage?: boolean;
    currentPage?: number;
    getUsageDetails: (usageID: string, openEntityForWorflowSession?: boolean) => void;
    getDistributionDetails?: (distributionID: number, lookupValues: ILookupDictionary) => void;
    getUsagePool?: (poolId: number,matchingSources: ITreeData[]) => void;
    resetPagination: (repertoireSection: string) => void;
    setIsHundredResultsPerPage?: (isHundredResultsPerPage: boolean) => void;
    lookupValues: ILookupDictionary;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    distributions: {id:number, code: string; description: string; }[];
    pools: { id:number,code: string; description: string; }[];
    sourceMajors: { code: string; description: string; }[];
    sourceMinors: { code: string; description: string; }[];
    getDistributions?: () => void;
    getPools?: () => void;
    searchMatchWorks: (query: IMatchWorksSearchQuery, modalOpen: boolean) => void;
    expandedMatchWorkResults?: number[];
    usageExpandAll?: boolean,
    usageExpandAllResults?: () => void;
    expandMatchWorkResult: (matchWorkResults: number) => void;
    setUsageType: typeof setUsageType;
    usageType: string;
    searchDataChanged: typeof searchDataChanged;
    getSources: () => void;
    sources: Dictionary<ISourceMatchType>;
    updateEditableField: typeof updateEditableFields;
    searchEditableFields?: IRepertoireField[];
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView: IRepertoireComponentDataItem;
    hideModal?: typeof hideModal;
    dataGridTableData?: IRepertoireComponentDataItem;
    editableFields: IEditableFieldsComponentData;
    setEditableFields: typeof setEditableFields;
    searchMatchProducts: (query: IMatchProductsSearchQuery, modalOpen: boolean) => void;
    matchProductsSearchResult?: IProductSearchResult[];
    getWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], readonlyIndicatorsWorkFlagTypes: string[], dataActions?: IDataActionToolbar[], workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem, formats?: FormatFields[]) => void;
    workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem;
    getProductDetails: (dataSource: string, productID: number, productMaintenanceGeneralDataView: IRepertoireComponentDataItem, formats: FormatFields[]) => void;
    productMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    lookupSources?: ITreeData[];
    sortSearchResults: (name: string, results: any) => void;
    getDistributionTypes: () => void;
    distributionTypes: IDistributionType[];
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    continuationToken?: string;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
    updateUsageGroupMatches?: typeof updateUsageGroupMatches;
    saveUsageGroup?: (updatedUsageGroup: IUsageSearchResultRowType) => void;
    saveResultData?: IRepertoireComponentDataItem;
    expanderFieldsData?: IRepertoireField[];
    showModal?: typeof showModal;
    matchingEntityForUsageSearchCriteria?: { id: number, number: string; }
    usageMatchingDefaultsProducts?: string[];
    usageMatchingDefaultsWorks?: string[];
    matchingSources: ITreeData[];
    searchUsagePoolsByCodeDistributionPools?: (query: IUsagePoolSearchQuery) => void;
    poolCodeToSearch?: string;
    updatePoolCodeToSearch?: (value: string) => void;
    customer?: string
    usagePoolsSearchResults
    getProductMaintenanceEnableCuesheets?:()=>void;
    getProductMaintenanceCuesheetsDataSource?: () => void;
    searchDistributionByCode: (query: IDistributionSearchQuery) => void;
    distributionCodeToSearch: string;
    updateDistributionCodeToSearch: (value: string) => void;
    usageDistributionsSearchResults
}

const FindUsagePage = (props: IFindUsagePageProps) => {
    const [loaded, setLoaded] = React.useState(false)
    const { instance, accounts } = useMsal()

    React.useEffect(() => {
        getRoles();
        checkIfLoaded();

        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
        const {
            sources,
            getSources,
            getDistributionTypes,
            getProductMaintenanceEnableCuesheets,
            getProductMaintenanceCuesheetsDataSource
        } = props;
        getProductMaintenanceEnableCuesheets && getProductMaintenanceEnableCuesheets();
        getProductMaintenanceCuesheetsDataSource && getProductMaintenanceCuesheetsDataSource();
        if (!sources)
            getSources();

        getDistributionTypes();
    }, [])

    React.useEffect(() => {
        const {
            distributions,
            pools,
            getDistributions,
            getPools,
            roles
        } = props;

        checkIfLoaded();

        if (roles.length > 0 && distributions.length === 0 && (roles.includes(VIEW_DISTRIBUTIONS_ROLE) || roles.includes(ALL_ROLES))) getDistributions();
        if (roles.length > 0 && pools.length === 0 && (roles.includes(VIEW_POOLS_ROLE) || roles.includes(ALL_ROLES))) getPools();

    }, [props, loaded])

    React.useEffect(() => {
        const { getDistributions, getPools, roles } = props;
        if (roles.includes(VIEW_DISTRIBUTIONS_ROLE) || roles.includes(ALL_ROLES)) getDistributions();
        if (roles.includes(VIEW_POOLS_ROLE) || roles.includes(ALL_ROLES)) getPools();
    }, [loaded])

    const [searchType, setSearchType] = React.useState(SIMPLE);

    const checkIfLoaded = () => {
        const {
            findUsagePageData,
            searchViewData,
            usageType,
            roles,
            editableFields,
            setEditableFields
        } = props;

        let fieldsToState: IRepertoireField[] = [];
        if (findUsagePageData) {
            const searchData = { ...searchViewData }
            if (findUsagePageData.fields && findUsagePageData.fields.length > 0 && !loaded) {
                if (roles.length > 0) setLoaded(true);
                const header = findUsagePageData.fields.find(f => f.name === "PageTitle");
                if (header) {
                    document.title = header.data;
                }
            }
        }
        if (!loaded && searchViewData && searchViewData.fields && searchViewData.fields.length > 0 && (!editableFields || Object.keys(editableFields).length === 0)){
            setEditableFields(USAGESEARCH_VIEW, SEARCH_VIEW_USAGES_USAGE_TO_PRODUCT);
            setEditableFields(USAGESEARCH_VIEW, SEARCH_VIEW_USAGES_USAGE_TO_WORK);
            setEditableFields(USAGERESULTTABLE_VIEW, SEARCH_VIEW_USAGES);
        }
    }

    const onSaveEditableFields = (componentFieldName: string, key: string) => {
        const { updateComponentFieldsItems, editableFields, tabs, activeTab, usageType } = props;
        const componentInstanceForFields = componentFieldName === USAGESEARCH_VIEW ? SEARCH_VIEW_USAGES + usageType.replace(/\s+/g, '') : SEARCH_VIEW_USAGES;
        updateComponentFieldsItems(editableFields[key], componentFieldName, componentInstanceForFields, componentFieldName, tabs, activeTab);
    }

    const showFieldsModal = (componentFieldName?: string) => {
        const {
            showUpdateFieldsModal,
            editableFieldsDataView,
            dataGridTableData,
            setEditableFields,
            usageType
        } = props;
        const componentInstanceForFields = componentFieldName === USAGESEARCH_VIEW ? SEARCH_VIEW_USAGES + usageType.replace(/\s+/g, '') : SEARCH_VIEW_USAGES;
        var key;

        if(componentInstanceForFields === SEARCH_VIEW_USAGES_USAGE_TO_PRODUCT){
            key = "usageToProduct"
        }
        else if(componentInstanceForFields === SEARCH_VIEW_USAGES_USAGE_TO_WORK){
            key = "usageToWork"
        }
        else if(componentInstanceForFields === SEARCH_VIEW_USAGES){
            key = "usageResultsTable"
        }
        else{
            key = "default"
        }

        const fieldsViewData: IRepertoireComponentDataItem = { fields: editableFieldsDataView.fields.filter(x => x.componentInstance === USAGE_SEARCH_EDITABLE_FIELDS) };
        const title: string = fieldsViewData.fields.find(item => item.name === TITLE_EDITABLE_FIELDS_VIEW).data;

        const fieldSetting: IShowFieldSettingViewModalProps = {
            componentInstance: EDITABLE_FIELDS,
            componentViewData: fieldsViewData,
            dataGridViewData: dataGridTableData,
            onClickSave: () => onSaveEditableFields(componentFieldName, key),
            key: key
        }
        showUpdateFieldsModal(title, fieldSetting, key);
    }

    const onClickNumber = (id: number) => {
        const { usageType } = props;
        if (usageType === USAGETOWORK) {
            getWorkDetails(REPERTOIRE, id);
        }
        else if (usageType === USAGETOPRODUCT) {
            getProductDetails(REPERTOIRE, id);
        }
    }

    const getWorkDetails = (dataSource: string, workID: number) => {

        const { lookupValues, workMaintenanceGeneralDataViewData, tabs, activeTab } = props;
        const dataActionList = getDataAction(dataSource, WORK_MAINTENANCE_TOOLBAR);
        const formatFields = UsageSearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
            .then(formats => {
                props.getWorkDetails(dataSource, workID, lookupValues, tabs[activeTab].otherIndicatorWorkFlagTypes, tabs[activeTab].readonlyIndicatorWorkFlagTypes, dataActionList, workMaintenanceGeneralDataViewData, formats);
            });
    }

    const getProductDetails = (dataSource: string, productID: number) => {
        const { productMaintenanceGeneralDataViewData } = props;
        const formatFields = UsageSearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, PRODUCT_MAINTENANCE_GROUP)
            .then(formats => {
                props.getProductDetails(dataSource, productID, productMaintenanceGeneralDataViewData, formats);
            });
    }

    const updateUserPagination = (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => {
        const { defaultActiveAccordions, allResultsPerPage, updateUserPreferences, updatePagination } = props;
        updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, undefined, undefined, undefined, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection);
    }

    let scope =
    {
        scopes: [msalConfig.auth.scope]
    }

    const getRoles = () => {
        const { saveRoles } = props;

        instance.acquireTokenSilent({
            ...scope,
            account: accounts[0]
        }).then((response) => {
            const decodedJwtToken = ComponentsHelper.parseJwt(response.accessToken);
            const roles = decodedJwtToken.roles;
            saveRoles(roles);
        }).catch((error) => {
            saveRoles([ALL_ROLES])
        });
    }

    const expanderFields = props.dataGridTableData.fields.filter(
        (field: IRepertoireField) => field.componentInstance === DATA_GRID_TABLE_FIELDS_EXPANDER);

    if (loaded) {
        const {
            searchViewData,
            searchResultsTableData,
            searchUsages,
            usageGroupsSearchResult,
            matchWorkSearchResult,
            searchSuccessful,
            indexOfFirstResult,
            indexOfLastResult,
            resultsPerPage,
            currentPage,
            resetPagination,
            setIsHundredResultsPerPage,
            updatePagination,
            getUsageDetails,
            getDistributionDetails,
            getUsagePool,
            lookupValues,
            distributions,
            pools,
            sourceMajors,
            sourceMinors,
            setUsageType,
            searchMatchWorks,
            searchMatchProducts,
            usageType,
            searchDataChanged,
            matchProductsSearchResult,
            lookupSources,
            sortSearchResults,
            distributionTypes,
            continuationToken,
            updateUsageGroupMatches,
            saveUsageGroup,
            saveResultData,
            showModal,
            matchingEntityForUsageSearchCriteria,
            usageMatchingDefaultsProducts,
            usageMatchingDefaultsWorks,
            tabs,
            activeTab,
            isHundredResultsPerPage,
            matchingSources,
            poolCodeToSearch,
            searchUsagePoolsByCodeDistributionPools,
            updatePoolCodeToSearch,
            customer,
            usagePoolsSearchResults,
            searchDistributionByCode,
            distributionCodeToSearch,
            updateDistributionCodeToSearch,
            usageDistributionsSearchResults,
        } = props;

        let isSearchTypeHidden: boolean = false;
        let searchTypeField = null;

        let filteredSearchViewData = { fields: [] };
        if (searchViewData) {
            const filtered = searchViewData.fields.filter(f => f.componentInstance === SEARCH_VIEW_USAGES);
            searchTypeField = filtered && filtered.find(x => x.name === 'searchType');
            isSearchTypeHidden = searchTypeField && searchTypeField.hidden
            filteredSearchViewData = { fields: searchViewData.fields.filter(f => !['usageType', 'searchType'].includes(f.name)) };
        }

        let filteredLookUpSources: ITreeData[] = [];
        let useType;
        if (usageType == USAGETOWORK) {
            useType = "Usage to Work";
        }
        else if (usageType == USAGETOPRODUCT) {
            useType = "Usage to Product";
        }
        if (lookupSources) {
            filteredLookUpSources = lookupSources.filter(f => f.matchType == useType);
        }

        const getViewRoleDisabled = (): boolean => {
            const { roles } = props;
            if (roles && (roles.includes(VIEW_USAGES_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
            return true;
        }

        const getConfigureRoleDisabled = (): boolean => {
            const { roles } = props;
            if (roles && (roles.includes(CONFIGURE_USAGES_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
            return true;
        }

        return (
            <div className="findUsagePage">
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <span className="title">Find Usage Information</span>
                            <div >
                                <button className="editBtn" title="Configure data fields" style={{ paddingBottom: "4px" }} onClick={() => showFieldsModal(USAGESEARCH_VIEW)} disabled={getConfigureRoleDisabled()}>
                                    <i className="ms-Icon ms-Icon--Settings"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="row">
                        <SelectorView
                            selection={usageType}
                            setSelection={setUsageType}
                            option1={USAGETOWORK}
                            option2={USAGETOPRODUCT}
                        />
                        <SelectorView
                            selection={searchType}
                            setSelection={setSearchType}
                            option1={SIMPLE}
                            option2={ADVANCED}
                            selectorTitle={searchTypeField && searchTypeField.data}
                        />
                    </div>
                </div>

                <UsageSearchView
                    searchViewData={filteredSearchViewData}
                    searchMatchWorks={searchMatchWorks}
                    searchMatchProducts={searchMatchProducts}
                    matchProductsSearchResult={matchProductsSearchResult}
                    matchWorkSearchResult={matchWorkSearchResult}
                    searchResultsTableData={searchResultsTableData}
                    componentInstance={SEARCH_VIEW_USAGES}
                    modalProps={undefined}
                    searchUsages={searchUsages}
                    usageGroup={tabs[activeTab]?.usageMaintenanceState?.usageGroup}
                    usageGroupsSearchResult={usageGroupsSearchResult}
                    searchSuccessful={searchSuccessful}
                    indexOfFirstResult={indexOfFirstResult}
                    indexOfLastResult={indexOfLastResult}
                    resultsPerPage={resultsPerPage}
                    currentPage={currentPage}
                    resetPagination={resetPagination}
                    setIsHundredResultsPerPage={setIsHundredResultsPerPage}
                    updatePagination={updateUserPagination}
                    sourceMajors={sourceMajors}
                    sourceMinors={sourceMinors}
                    matchingSources={matchingSources}
                    distributions={distributions}
                    pools={pools}
                    lookupValues={lookupValues}
                    hideModal={undefined}
                    getUsageDetails={getUsageDetails}
                    getDistributionDetails={getDistributionDetails}
                    getUsagePool={getUsagePool}
                    expandedMatchWorkResults={undefined}
                    usageExpandAll={undefined}
                    expandMatchWorkResult={undefined}
                    usageExpandAllResults={undefined}
                    searchDataChanged={searchDataChanged}
                    usageType={usageType}
                    onClickNumber={onClickNumber}
                    lookupSources={filteredLookUpSources}
                    sortSearchResults={sortSearchResults}
                    distributionTypes={distributionTypes}
                    continuationToken={continuationToken}
                    showFieldsModal={showFieldsModal}
                    searchDisabled={getViewRoleDisabled()}
                    updateUsageGroupMatches={updateUsageGroupMatches}
                    saveUsageGroup={saveUsageGroup}
                    saveResultData={saveResultData}
                    searchType={searchType}
                    expanderFieldsData={expanderFields}
                    showModal={showModal}
                    matchingEntityForUsageSearchCriteria={matchingEntityForUsageSearchCriteria}
                    usageMatchingDefaultsWorks={usageMatchingDefaultsWorks}
                    usageMatchingDefaultsProducts={usageMatchingDefaultsProducts}
                    isHundredResultsPerPage={isHundredResultsPerPage}
                    searchUsagePoolsByCodeDistributionPools={searchUsagePoolsByCodeDistributionPools}
                    poolCodeToSearch={poolCodeToSearch}
                    updatePoolCodeToSearch={updatePoolCodeToSearch}
                    customer={customer}
                    usagePoolsSearchResults={usagePoolsSearchResults}
                    searchDistributionByCode={searchDistributionByCode }
                    distributionCodeToSearch={distributionCodeToSearch }
                    updateDistributionCodeToSearch={updateDistributionCodeToSearch }
                    usageDistributionsSearchResults={usageDistributionsSearchResults }
                />
            </div>
        );
    }
    return <div />;
}

export default FindUsagePage;
