import * as React from "react";
import { startCase } from "lodash";
import { CSVLink } from "react-csv"
import { Button, Input, InputRef, Row, Space, Table } from "antd";
import { Key, useRef, useState } from "react";
import { ColumnsType, ColumnType, FilterConfirmProps, FilterValue } from "antd/lib/table/interface";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IRepertoireField } from "../../../types/IRepertoireField";
import {
    USAGEGROUPUPDATE_ACTION_STATE_KEY, USAGERESULTTABLE_VIEW, USAGETOPRODUCT, USAGE_MATCHSTATUS_DEFINITE_MATCH, USAGE_TO_PRODUCT, USAGE_TO_WORK
} from "../../../Consts";
import { ComponentsHelper } from "../../../../core/services/ComponentHelper";
import { ITreeData } from "../../../types/ITreeData";
import IconTextButton from "../../iconTextButton/IconTextButton";
import { IUsageSearchMatchType, IUsageSearchResultRowType } from "../../../types/usageTypes/IUsageGroupsSearchResult";
import ActionButton, { SizedActionButton } from "../../actionButton/ActionButton";
import { updateUsageGroupMatches } from "../../../../redux/reducers/RepertoireReducer";
import { MessageBanner, MessageType } from "../../messageBanner/MessageBanner";
import { RenderExpandIconProps } from "rc-table/lib/interface";
import UsageSearchToolbar from "../../../usage/components/usageSearchToolbar/UsageSearchToolbar";
import { createCopyOfTableContents } from "../../../services/TableFunctions";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";

export interface IResultsTableProps {
    searchResultsTableData: IRepertoireComponentDataItem;
    tableContents: any;
    componentInstance: string;
    selectAll?: boolean;
    isSelectedAll?: boolean;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    repertoireSection: string;
    getUsageDetails: (usageID: string, openEntityForWorflowSession?: boolean) => void;
    getDistributionDetails?: (distributionID: number, lookupValues: ILookupDictionary) => void;
    getUsagePool?: (poolId: number,matchingSources: ITreeData[],isPoolMaintainence?:boolean) => void;
    usageType?: string;
    onClickNumber: (id: number) => void;
    sortSearchResults?: (name: string, results: any) => void;
    distributions?: { id : number, code: string; description: string; }[];
    pools?: { id:number,code: string; description: string; }[];
    lookupSources?: ITreeData[];
    changeData: (value: any, fieldName: string) => void;
    titleContains: string;
    distributionsFilter: string[];
    search: (continuationToken?: string) => void;
    continuationToken?: string;
    showFieldsModal?: (componentFieldName: string) => void;
    updateUsageGroupMatches?: typeof updateUsageGroupMatches;
    saveUsageGroup?: (updatedUsageGroup: IUsageSearchResultRowType) => void;
    saveResultData?: IRepertoireComponentDataItem;
    resetMessageBanner?: () => void;
    isHundredResultsPerPage?: boolean;
    setIsHundredResultsPerPage: (isHundredResultsPerPage: boolean) => void;
    lookupValues?: ILookupDictionary;
    sources?: ITreeData[];
    matchingSources?: ITreeData[];
}

type DataIndex = keyof IUsageSearchResultRowType;

export const ResultsTable: React.FC<IResultsTableProps> = (props: IResultsTableProps) => {

    const [updatedUsageGroups, setUpdatedUsageGroups] = useState<IUsageSearchResultRowType[]>([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [showMoreRows, setShowMoreRows] = useState<number[]>([]);
    const [hasChanges, setHasChanges] = useState<number[]>([]);
    const [showMoreAll, setShowMoreAll] = useState<boolean>(true);
    const searchInput = useRef<InputRef>(null);
    const [expandedRows, setExpandedRows] = useState<Key[]>([]);
    const [allExpanded, setAllExpanded] = useState<boolean>(false);
    const [currentPageNumberTable, setCurrentPageNumberTable] = useState<number>(props.currentPage);

    const [oldSorted, setOldSorter] = useState({});

    const isSortingChanged = (sorter) => {
        return JSON.stringify(sorter) !== JSON.stringify(oldSorted);
    }

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const handleChange = (pagination, filters: globalThis.Record<string, FilterValue>, sorter) => {
        if (filters.title && props.titleContains !== filters.title.toString()) {
            props.changeData(filters.title.toString(), 'title');
        }
        else if (filters.distributionCode && !props.distributionsFilter ||
            (props.distributionsFilter && props.distributionsFilter.length !== filters.distributionCode.length) ||
            (props.distributionsFilter && !props.distributionsFilter.every((v, i) => v === filters.distributionCode[i]))) {
            props.changeData(filters.distributionCode, 'distributionsFilter');
        }


        if (isSortingChanged(sorter)) {
            setCurrentPageNumberTable(1);
        } else {
         setCurrentPageNumberTable(pagination.current);
        }

        setOldSorter(sorter)
        sortTableByColumn(sorter)
    }

    const onClickExpand = (index: number) => {
        if (showMoreRows.includes(index))
            setShowMoreRows(showMoreAll => showMoreAll.filter(x => x !== index))
        else
            setShowMoreRows(showMoreAll => [...showMoreAll, index])
    }

    const onClickExpandAll = (rows: IUsageSearchResultRowType[]) => {
        let rowsToExpand = [];
        if (!allExpanded)
            rowsToExpand = rows.map(x => x.key);
        setExpandedRows(rowsToExpand);
        setAllExpanded(!allExpanded);
    }

    const setDataForRow = (match, index, fieldName) => {
        if (match[fieldName] && match[fieldName].length > 0) {
            if (showMoreAll || showMoreRows.includes(index))
                return match[fieldName].join(', ');
            else
                return match[fieldName][0]
        }
        else
            return null;
    }

    const handleMatchingEntityOnClick = (fieldName: string, match: IUsageSearchMatchType, usageGroup: IUsageSearchResultRowType) => {
        switch (fieldName) {
            case 'open':
                props.onClickNumber(match.key)
                break;
            case 'makeDefinite':
                makeDefiniteMatch(match, usageGroup);
                break;
            case 'remove':
                removeRow(match, usageGroup);
                break;
        }
    }

    const makeDefiniteMatch = (row: IUsageSearchMatchType, usageGroup: IUsageSearchResultRowType) => {
        const matches = usageGroup.matchesExpanded.filter(x => x.id === row.key);
        const updatedUsageGroup = usageGroup;
        updatedUsageGroup.matchesExpanded = matches;
        setHasChanges(hasChanges => [...hasChanges, usageGroup.key]);
        props.updateUsageGroupMatches(updatedUsageGroup);

        let updatedUsageGroupsCopy = updatedUsageGroups.filter(x => x.key !== updatedUsageGroup.key);
        updatedUsageGroup.matchStatus = USAGE_MATCHSTATUS_DEFINITE_MATCH;

        setUpdatedUsageGroups([...updatedUsageGroupsCopy, updatedUsageGroup]);
    }

    const removeRow = (row: IUsageSearchMatchType, usageGroup: IUsageSearchResultRowType) => {
        const matches = usageGroup.matchesExpanded.filter(x => x.id !== row.key)
        const updatedUsageGroup = usageGroup;
        updatedUsageGroup.matchesExpanded = matches;
        setHasChanges(hasChanges => [...hasChanges, usageGroup.key]);
        props.updateUsageGroupMatches(updatedUsageGroup);

        let updatedUsageGroupsCopy = updatedUsageGroups.filter(x => x.key !== updatedUsageGroup.key);

        setUpdatedUsageGroups([...updatedUsageGroupsCopy, updatedUsageGroup]);
    }

    const renderSaveMessageBanner = (usageGroup: IUsageSearchResultRowType) => {
        const { resetMessageBanner } = props;
        let messages: string[] = []
        if (usageGroup.saveSuccessful)
            messages.push('saveSuccessMessage')
        else
            messages = props.saveResultData.fields.filter(x => usageGroup.saveMessage.includes(x.name)).map(f => f.data);
        return (
            <MessageBanner messageType={usageGroup.saveSuccessful ? MessageType.Success : MessageType.Danger} messages={messages} resetMessageBanner={resetMessageBanner} />
        )
    }

    const onClickSave = () => {
        //setHasChanges(hasChanges => hasChanges.filter(x => x !== usageGroup.key))
        updatedUsageGroups.forEach((u) => {
            props.saveUsageGroup(u)
        });
        setHasChanges([]);
        setUpdatedUsageGroups([]);
    }

    const renderNestedTableHeader = (usageGroup: IUsageSearchResultRowType) => {
        return (
            <Row justify='space-between'>
                <Row>
                    <IconTextButton
                        text="Expand All"
                        icon="icon ms-Icon ms-Icon--DoubleChevronDown"
                        onClick={() => setShowMoreAll(!showMoreAll)}
                    />
                </Row>
                <Row><h4>Matches</h4></Row>
                <Row >
                    {usageGroup.saveSuccessful != undefined && usageGroup.saveSuccessful != null &&
                        renderSaveMessageBanner(usageGroup)
                    }
                </Row>
            </Row>
        )
    }

    const getIconForAction = (fieldName) => {
        switch (fieldName) {            
            case 'open':
                return 'OpenInNewTab'
            case 'makeDefinite':   
                return 'CircleAddition'
            case 'remove':
                return 'Delete'
        }
    }

    const getIconForExpandAll = () => {
        if (allExpanded)
            return 'ms-Icon--Hide3';
        else
            return 'ms-Icon--View';
    }

    // Nested table to display when row is expanded
    const expandedRowRender = (record: IUsageSearchResultRowType, index) => {
        const matches = record.matchesExpanded;
        if (matches && matches.length > 0) {            
            let filteredFields = props.searchResultsTableData.fields.filter(
                (field: IRepertoireField) =>
                    field.componentInstance === props.componentInstance &&
                    field.name.includes(props.usageType === 'Usage To Work' ? 'MatchingWork' : 'MatchingProduct')
            );

            let cols: ColumnsType<IUsageSearchMatchType> = [
                {
                    title: '', dataIndex: 'expand', key: 'expand', width: '1%', render: (value, record, index) => {
                        return <>
                            <div className="arrowIconDiv" onClick={() => onClickExpand(index)}>
                                <img src="assets/right-arrow.svg" title="Expand details" alt="Expand icon" className={showMoreAll || showMoreRows.includes(index) ? "arrowIconExpanded" : 'arrowIcon'}></img>
                            </div>
                        </>
                    }
                },
            ]

            cols = cols.concat(filteredFields.map(f => {
                if (f.name.includes('Action')) {               
                    return {
                        title: f.data.split('/').pop(), dataIndex: f.name, key: f.name, fixed: f.name.includes('open') ? 'right' : null, render: (value, matchRecord, index) => {
                            return <>
                                <IconTextButton
                                    text={f.data.split('/').pop()}
                                    icon={"icon ms-Icon ms-Icon--".concat(getIconForAction(f.name.split("Action")[0]))}
                                    onClick={() => handleMatchingEntityOnClick(f.name.split("Action")[0], matchRecord, record)}
                                />
                            </>
                        }
                    }
                }
                else return { title: f.data.split("/").pop(), dataIndex: f.name.split("Matching")[0], key: f.name, className: 'colMaxWidth' }
            }))

            const data: IUsageSearchMatchType[] = matches.map((m, index) => ({
                key: m.id,
                status: m.matchType,
                source: m.matchSource,
                numbers: setDataForRow(m, index, 'numbers'),
                titles: setDataForRow(m, index, 'titles'),
                performers: setDataForRow(m, index, 'performers'),
                composers: setDataForRow(m, index, 'composers')
            }))

            return <Table
                columns={cols}
                dataSource={data}
                pagination={{
                    defaultPageSize: 10,
                    position: ['bottomRight']
                }}
                scroll={{ x: 'max-content' }}
                className="nested-table"
                title={() => renderNestedTableHeader(record)} />;
        }
        else return null;
    }

    // props for the search filter pop up
    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<IUsageSearchResultRowType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined translate={undefined} onAuxClick={undefined} onAuxClickCapture={undefined} />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button type="link" size="small" onClick={() => { close(); }}>
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} translate={undefined} onAuxClick={undefined} onAuxClickCapture={undefined} />
        ),
        onFilter: (value, record) => {
            return record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase())
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#fcfa5d', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const sortTableByColumn = (sorter: any) => {
        const { componentInstance, sortSearchResults, tableContents } = props;
        let descending = sorter.order === "ascend" ? false : true;

        const tableContentsCopy = createCopyOfTableContents(tableContents);

        tableContentsCopy.sort((a: any, b: any) => {
            // set to emtpy string if null
            let newA = sorter.field == 'sourceMajor' ? a['source'] || "" : a[sorter.field] || "";
            let newB = sorter.field == 'sourceMajor' ? b['source'] || "" : b[sorter.field] || "";

            newA = newA ? newA : "";
            newB = newB ? newB : "";

            if (!descending) {
                return isNaN(newA) ? (newA > newB ? 1 : -1) : (newA - newB);
            } else {
                return isNaN(newA) ? (newB > newA ? 1 : -1) : (newB - newA);
            }
        });

        if (sortSearchResults) {
            sortSearchResults(componentInstance, tableContentsCopy);
        }
    };
    const getDistributionId=(distributionCode:string) :number=>{
        const {distributions}=props;
        var resultObj:{ id : number, code: string; description: string; }[];
        resultObj = distributions.filter(x=>x.code === distributionCode);
        return resultObj[0].id;
    }
    const getPoolId=(poolCode:string):number=>{
        const {pools}=props;
        var resultObj:{ id : number, code: string; description: string; }[];
        resultObj = pools.filter(x=>x.code === poolCode);
        return resultObj[0].id;
    }

    // generate columns for the table
    const getColumns = () => {
        const { searchResultsTableData, componentInstance, matchingSources, distributions, pools, lookupSources, usageType,lookupValues,tableContents } = props;
        let removedItemAction: IRepertoireField = undefined;
        if (searchResultsTableData && searchResultsTableData.fields) {
            let filteredFields = searchResultsTableData.fields.filter(
                (field: IRepertoireField) =>
                    field.componentInstance === componentInstance &&
                    field.name !== USAGEGROUPUPDATE_ACTION_STATE_KEY &&
                    !field.name.includes('Matching')
            );

            if ("Usage To Product" !== usageType) {
                filteredFields = filteredFields.filter(
                    (field: IRepertoireField) =>
                        field.name !== "productType" && field.name !== "id1" && field.name !== "id2"
                );
            }
            if("Usage To Work" !== usageType){
                filteredFields = filteredFields.filter(
                    (field: IRepertoireField) =>
                        field.name !== "matches"
                );
            }

            const actionFieldIndex = filteredFields.findIndex(({ name }) => name === "update_action");
            if (actionFieldIndex > -1) {
                removedItemAction = filteredFields[actionFieldIndex];
                filteredFields.splice(actionFieldIndex, 1);
            }
            let orderedFields = ComponentsHelper.orderFieldsBySpecificField(filteredFields, "order");
            if (!orderedFields && orderedFields.length > 0) {
                orderedFields = filteredFields
            }
            if (removedItemAction) {
                orderedFields.push(removedItemAction);
            }

            let columns: ColumnsType<IUsageSearchResultRowType> = orderedFields.filter(x => !x.hidden).map(field => {
                switch (field.name) {
                    case 'title':
                        return {
                            title: field.data,
                             dataIndex: field.name,
                              width: '30em',
                              sorter: (a, b) => a.title ? a.title.localeCompare(b.title) : null,
                               key: field.name,
                                ...getColumnSearchProps(field.name as keyof IUsageSearchResultRowType), 
                               render: (value, record, index) => value !== null ?
                                <div>
                                    <a onClick={()=>props.getUsageDetails(record.key.toString())}>{ value === ""?"Title not available":value}</a>
                                </div> : null
                        }
                    case 'distributionCode':
                        if (distributions != undefined && distributions.length > 0) {
                            const distributionsFilter = distributions.map(d => ({
                                id: d.id,
                                text: d.code,
                                value: d.code
                            }));
                            let distributionsSet = distributionsFilter.filter((element, index) => {
                                return distributionsFilter.indexOf(element) === index;
                            });
                            return {
                                title: field.data,
                                dataIndex: field.name,
                                key: field.name,
                                filters: [...distributionsSet],
                                filterSearch: true,
                                onFilter: (value, record) => {
                                    return record.distributionCode.includes(value.toString());
                                },
                                sorter: (a, b) => a.distributionCode != undefined ? a.distributionCode.localeCompare(b.distributionCode) : null,
                                render: (value, record, index) => value !== null ?
                                    <div>
                                        <a onClick={() => {
                                            props.getDistributionDetails(getDistributionId(value), lookupValues)
                                        }}>{value === "" ? "Distribution code not available" : value}</a>
                                    </div> : null
                            }
                        }
                            else{
                                return {
                                    title: field.data,
                                    dataIndex: field.name,
                                    key: field.name,
                                    sorter: (a, b) => a.distributionCode != undefined ? a.distributionCode.localeCompare(b.distributionCode) : null
                            }
                        }
                    case 'poolCode':
                        if(pools != undefined && pools.length > 0) {
                            return {
                                title: field.data,
                                dataIndex: field.name,
                                key: field.name,
                                filters: pools.map(p => ({text: p.code, value: p.code})),
                                filterSearch: true,
                                onFilter: (value, record) => {
                                    return record.poolCode.includes(value.toString())
                                },
                                sorter: (a, b) => a.poolCode != undefined ? a.poolCode.localeCompare(b?.poolCode) : null,
                                render: (value, record, index) => value !== null ?
                                    <div>
                                        <a onClick={() => props.getUsagePool(getPoolId(value), matchingSources, true)}>{value === "" ? "Pool code not available" : value}</a>
                                    </div> : null
                            }
                        }
                        else{
                            return {
                                title: field.data,
                                dataIndex: field.name,
                                key: field.name,
                                sorter: (a, b) => a.poolCode != undefined ? a.poolCode.localeCompare(b.poolCode) : null
                            }
                        }
                    case 'sourceMajor':
                        return {
                            title: field.data,
                            dataIndex: field.name,
                            key: field.name,
                            filters: lookupSources.map(s => ({ text: s.hierarchy.substring(7), value: s.hierarchy.substring(7) })),
                            filterSearch: true,
                            onFilter: (value, record) => {
                                return record.sourceMajor.includes(value.toString())
                            },
                            sorter: (a, b) => a.sourceMajor != undefined ? a.sourceMajor.localeCompare(b.sourceMajor) : null
                        }
                    case 'matches':
                        return {
                            title: field.data, dataIndex: field.name, key: field.name, render: (value, record, index) => value !== null ?
                                <div>
                                    <a onClick={()=>props.onClickNumber(record.matchesExpanded[0].id)}>{ value === "" ? "Title not available" : value }</a>
                                </div> : null,
                            sorter: (a, b) => a.matches != undefined ? a.matches.localeCompare(b.matches) : null
                        }
                    case 'id1':
                        return {
                            title: field.data, dataIndex: field.name, key: field.name, render: (value, record, index) => value !== null ?
                                <div>
                                    <a onClick={()=>props.onClickNumber(record.matchesExpanded[0].id)}>{ value === "" ? "Title not available" : value }</a>
                                </div> : null,
                            sorter: (a, b) => a.id1 != undefined ? a.id1.localeCompare(b.id1) : null
                        }
                    case 'id2': 
                        return { 
                            title: field.data,
                            dataIndex: field.name, 
                            key: field.name, 
                            sorter: (a, b) => a.id2 != undefined ? a.id2.localeCompare(b.id2) : null
                        }
                    case 'matchSource':
                        return {
                            title: field.data,
                            dataIndex: field.name,
                            key: field.name,
                            sorter: (a, b) => a.matchSource != undefined ? a.matchSource.localeCompare(b.matchSource) : null
                        }
                    default:
                        return { title: field.data, dataIndex: field.name, key: field.name, sorter: (a, b) => (isNaN(a[field.name]) && a[field.name] != undefined) ? a[field.name].localeCompare(b[field.name]) : a[field.name] - b[field.name] }
                }
            })
            // Insert expand Icon next to work number
            let matchStatusIndex = columns.findIndex(x => x.title === 'matchStatus');
            columns.splice(matchStatusIndex - 2, 0, Table.EXPAND_COLUMN)
            return columns;
        }
    }

    const { tableContents } = props;
    const columns = getColumns();

    const data: IUsageSearchResultRowType[] = tableContents.map(row => ({
        key: row.id,
        title: row.title,
        distributionCode: row.distributionCode,
        sourceMajor: row.source,
        weight: row.weight,
        matchStatus: row.matchStatus,
        allocationStatus: row.allocationStatus,
        productType: row.productType,
        matches: row.matches && row.matches.length > 0 && row.matches[0].numbers && row.matches[0].numbers.length > 0 ? row.matches[0].numbers[0] : null,
        id1: row.matches && row.matches.length > 0 && row.matches[0].numbers && row.matches[0].numbers.length > 0 ? row.matches[0].numbers[0] : null,
        id2: row.matches && row.matches.length > 0 && row.matches[0].numbers && row.matches[0].numbers.length > 1 ? row.matches[0].numbers[1] : null,
        poolCode: row.poolCode,
        matchesExpanded: row.matches,
        saveMessage: row.saveMessage,
        saveSuccessful: row.saveSuccessful,
        matchSource: row.matches && row.matches.length > 0 && row.matches[0].matchSource ? startCase(row.matches[0].matchSource) : null
    }));

    const renderCustomExpandIcon = (expandProps: RenderExpandIconProps<IUsageSearchResultRowType>) => {
        if (expandProps.expandable) {
            if (expandedRows.includes(expandProps.record.key))
                return <i className="icon ms-Icon ms-Icon--Hide3" title="Hide matches" aria-label="Hide matches" onClick={(e) => expandProps.onExpand(expandProps.record, e)}></i>
            else
                return <i className="icon ms-Icon ms-Icon--View" title="Show matches" aria-label="Show matches" onClick={(e) => expandProps.onExpand(expandProps.record, e)}></i>
        }
    }

    const renderHeader = () => {

        if(props.resultsPerPage == 100 && props.indexOfLastResult == 100 && props.isHundredResultsPerPage == true) {
            props.setIsHundredResultsPerPage(false);
            props.search(props.continuationToken)
        }

        return (
            <div className="">

                <button type="button" className="toolbar-toggle" data-toggle="collapse" data-target="#toolbarContainer">
                    <i className="icon ms-Icon ms-Icon--MoreVertical" aria-hidden="true"></i>
                </button>

                <div id="toolbarContainer" className="UsageSearchToolbar collapse" >
                    <UsageSearchToolbar
                        data={data}
                        getIconForExpandAll={getIconForExpandAll}
                        onClickExpandAll={onClickExpandAll}
                        onClickSave={onClickSave}
                        showFieldsModal={props.showFieldsModal}
                        hasChanges={hasChanges}
                    />

                </div>
            </div>
        )
    }

    return (
        <div className="tableContainer">
            <Table
                columns={columns}
                dataSource={data}
                pagination={{
                    defaultPageSize: props.resultsPerPage, pageSizeOptions: [10, 25, 50, 100,250, 500,1000] ,position: ['bottomRight', 'topRight'], onChange(page, pageSize) {
                        let isLastPage = page * pageSize === data.length ? true : false;
                        isLastPage = Math.ceil(data.length / pageSize) == page ? true : false;
                        const indexOfLastResult = page * props.resultsPerPage;
                        const indexOfFirstResult = indexOfLastResult - props.resultsPerPage;
                        props.updatePagination(indexOfFirstResult, indexOfLastResult, pageSize, page, 'usages')
                        if (isLastPage && props.continuationToken) {
                            props.search(props.continuationToken)
                        }
                    },
                    current: currentPageNumberTable,
                    defaultCurrent: props.currentPage,
                    className: 'pageNumberDiv',
                    total: data.length,
                    showTotal: (total) => `${total} Results`
                }}

                size={'middle'}
                expandable={{
                    expandedRowKeys: expandedRows,
                    expandedRowRender: (record, index) => expandedRowRender(record, index),
                    onExpand(expanded, record) {
                        if (expandedRows.includes(record.key)) {
                            setExpandedRows(expandedRows => expandedRows.filter(x => x !== record.key))
                        }
                        else {
                            setExpandedRows(expandedRows => [...expandedRows, record.key]);
                        }
                    },
                    expandIcon: (props: RenderExpandIconProps<IUsageSearchResultRowType>) => renderCustomExpandIcon(props),
                    rowExpandable: (record) => record.matchesExpanded && (record.matchesExpanded.length > 1 || hasChanges.includes(record.key) || record.saveMessage !== undefined)
                }}
                onChange={handleChange}
                title={renderHeader}
                className="parent-table"
                tableLayout={"fixed"}
            />
        </div>
    );
}
