import { useEffect, useState } from "react";
import { CircleFlag } from 'react-circle-flags'
import { NavItem, NavLink } from "reactstrap"

export const LANGUAGE_SETTINGS = {
    english: "en-GB",
    french: "fr"
}

const UserLanguageDropDown = () => {
    const [preferences, setPreferences] = useState(LANGUAGE_SETTINGS.english);
    const customer = localStorage.getItem('customer');

    useEffect(() => {
        const storedPreferences = localStorage.getItem('userLanguagePreferences');
        if (storedPreferences) {
            setPreferences(JSON.parse(storedPreferences));
        }
        else {
            localStorage.setItem('userLanguagePreferences', JSON.stringify(LANGUAGE_SETTINGS.english))
        }
       
    }, []);

    const savePreferences = (updatedPreferences) => {
        const storedPreferences = localStorage.getItem('userLanguagePreferences');

        if (storedPreferences !== updatedPreferences) {
            localStorage.setItem('userLanguagePreferences', JSON.stringify(updatedPreferences));
            setPreferences(updatedPreferences);
            window.location.reload();
        }
    };

    const getCountry = (c) => {
        let cust = JSON.parse(customer);

        if (c == 'en')
            return cust == 'SOCAN' ? "ca" : "ie"
        else if (c == 'fr')
            return cust == 'SOCAN' ? "ca" : "fr"
    };


    return (
        <div className="nav navbar-nav">
            
            <NavItem className="dropdown" key={'viewPreference'}>
                <NavLink className="dropdown-toggle">
                    &nbsp;&nbsp;{`Language`}
                    <b className="caret"></b>
                </NavLink>
                <ul className="dropdown-menu" >
                    <NavItem key={LANGUAGE_SETTINGS.english}>
                        <NavLink onClick={() => savePreferences(LANGUAGE_SETTINGS.english)}>{preferences === LANGUAGE_SETTINGS.english ?
                            <> 
                                <CircleFlag countryCode={getCountry('en')} height="25" />
                                &nbsp;<b>{"EN (Default)"}</b></> :
                            <>         
                                <CircleFlag countryCode={getCountry('en')} height="25" />
                                &nbsp;{"EN (Default)"} </>}</NavLink>
                    </NavItem>
                    <NavItem key={LANGUAGE_SETTINGS.french}>
                        <NavLink onClick={() => savePreferences(LANGUAGE_SETTINGS.french)} className="">
                            {preferences === LANGUAGE_SETTINGS.french ?
                                <>    
                                    <CircleFlag countryCode={getCountry('fr')} height="25" />	&nbsp;
                                    &nbsp;<b>{"FR"}</b></> :
                                <>
                                    <CircleFlag countryCode={getCountry('fr')} height="25" />	&nbsp;
                                    &nbsp;{"FR"} </>}
                        </NavLink>
                    </NavItem>
                </ul>
            </NavItem >
        </div>
    )
}

export default UserLanguageDropDown;

function GetCusomter() {
    throw new Error("Function not implemented.");
}
