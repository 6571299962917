export const SAVE_BUTTON_TEXT_FIELD: string = "SaveButtonText";
export const CANCEL_BUTTON_TEXT_FIELD: string = "CancelButtonText";
export const LOADING_FIELD: string = "LoadingText";
export const SAVE_SUCCESSFUL_MESSAGE_FIELD: string = "SaveSuccessMessage";
export const INGESTION_PROCESS_STARTED_FIELD: string = "IngestionProcessStaredMessage";
export const SAVE_FAILED_MESSAGE_FIELD: string = "SaveFailedMessage";
export const CANCELLED_MESSAGE_FIELD: string = "CancelledMessage";
export const FILENAME_TABLE_HEADER_FIELD: string = "FilenameTableHeader";
export const SOURCE_TABLE_HEADER_FIELD: string = "SourceTableHeader";
export const DISTRIBUTION_CODE_TABLE_HEADER_FIELD: string = "DistributionCodeTableHeader";
export const POOL_CODE_TABLE_HEADER_FIELD: string = "PoolCodeTableHeader";
export const INPUT_FORMAT_TABLE_HEADER_FIELD: string = "FormatTableHeader";
export const OUTPUT_FORMAT_TABLE_HEADER_FIELD: string = "OutputFormatTableHeader";
export const BATCH_ID_TABLE_HEADER_FIELD: string = "BatchIDTableHeader";
export const DESTINATION_TABLE_HEADER_FIELD: string = "DestinationTableHeader";
export const FILE_SIZE_TABLE_HEADER_FIELD: string = "FileSizeTableHeader";
export const RIGHTHOLDER_TABLE_HEADER_FIELD: string = "RightHolderTableHeader";
export const LAST_MODIFIED_DATE_TABLE_HEADER_FIELD: string = "LastModifiedTableHeader";
export const TRACKING_INFORMATION_TABLE_HEADER_FIELD: string = "TrackingInformationTableHeader";
export const ATTACHMENT_TYPE_TABLE_HEADER_FIELD: string = "AttachmentTypeTableHeader";
export const FILES_IN_PROGRESS_MESSAGE_FIELD: string = "FilesInProgressMessage";
export const REFRESH_DATA_LABEL: string = "RefreshData";
export const DOWNLOAD_SCHEMA_LABEL: string = "DownloadSchema";
export const VALIDATION_ERROR_MESSAGE: string = "ValidationErrorMessage";
export const SAMRO_FILENAME_VALIDATION_MESSAGE: string = "SAMROFilenameValidationMessage";
export const REQUEST_SAS: string = "RequestSAS";
export const OBTAINED_SAS: string = "ObtainedSAS";
export const UPLOADING_FILE_SAS: string = "UploadingfileSAS";
export const ERROR_UPLOADING_FILE_SAS: string = "ErrorUploadingFileSAS";
export const ERROR_INVALID_FILE_NAME_OR_FOLDER: string = "ErrorInvalidFileNameOrFolder";
export const ERROR_ZIPPED_FILE: string = "ErrorZippedFile";
export const ERROR_OBTAINING_SAS: string = "ErrorObtainingSAS";
export const PATH_TO_DROP_FILE: string = "ValidLevelPathToDropFile";
export const UPLOAD_COMPLETED: string = "UploadCompleted";
export const PREVENT_REFRESH_FIELD: string = "PreventRefresh";
export const DISABLE_DRAG_AND_DROP: string = "DisableDragAndDrop"
export const DISABLE_DRAG_AND_DROP_AGREEMENTS: string = "DisableDragAndDropAgreement";
export const DISABLE_DRAG_AND_DROP_PRODUCTS: string = "DisableDragAndDropProduct";
export const DRAG_AND_DROP_INSTRUCTION: string = "DragAndDropInstruction";
export const REMOVE: string = "remove_action";
export const ADD_TO_PACKAGE_ACTION:string="addtopackage_action";
export const SHOPPINGCART_TABLE_HEADER_FIELD: string = "ShoppingCartTableHeader";
