import * as React from "react";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { setEditableFields } from "../../../../redux/reducers/MembersPortalReducer";
import { SizedDropdownDataInput } from "../../../../repertoire/components/dropdownDataInput/DropdownDataInput";
import { getLookupDefaultMultiSelect, getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { WORK_GENRE_LOOKUP, AGREEMENT_TYPE_LOOKUP, AGREEMENT_CATEGORY_LOOKUP, APPLICABLE_WORKS_LOOKUP, SET_TYPE_LOOKUP, TERRITORY_LOOKUP } from "../../../../lookup/Consts";
import TextDataInput, { SizedTextDataInput } from "../../../../repertoire/components/textDataInput/TextDataInput";
import { DRAFT_AGREEMENTS_AGREEMENT_SIGNATURE_DATE_STATE_KEY, DRAFT_AGREEMENTS_APPLICABLE_WORKS_TYPE_STATE_KEY, DRAFT_AGREEMENTS_ASSIGNED_SET_TYPES_STATE_KEY, DRAFT_AGREEMENTS_CATEGORY_STATE_KEY, DRAFT_AGREEMENTS_CREATION_DATE_STATE_KEY, DRAFT_AGREEMENTS_EFFECTIVE_FROM_STATE_KEY, DRAFT_AGREEMENTS_EFFECTIVE_TO_STATE_KEY, DRAFT_AGREEMENTS_IP_NAME_NUMBER_STATE_KEY, DRAFT_AGREEMENTS_IP_NAME_STATE_KEY, DRAFT_AGREEMENTS_PUBLISHER_AGREEMENT_NUMBER_STATE_KEY, DRAFT_AGREEMENTS_SOCIETY_AFFILIATION_STATE_KEY, DRAFT_AGREEMENTS_SOCIETY_AGREEMENT_NUMBER_STATE_KEY, DRAFT_AGREEMENTS_STATUS_STATE_KEY, DRAFT_AGREEMENTS_TERRITORY_STATE_KEY, DRAFT_AGREEMENTS_TYPE_STATE_KEY, DRAFT_WORKS_DURATION_STATE_KEY, DRAFT_WORKS_GENRE_STATE_KEY, DRAFT_WORKS_INSTRUMENTATION_STATE_KEY, DRAFT_WORKS_IS_ARRANGEMENT_STATE_KEY, DRAFT_WORKS_IS_COMMISSIONED_COMMERCIAL_STATE_KEY, DRAFT_WORKS_IS_LIBRARY_MUSIC_STATE_KEY, DRAFT_WORKS_LANGUAGE_STATE_KEY, DRAFT_WORKS_ORIGINAL_TITLE_STATE_KEY, DRAFT_WORKS_ORIGINAL_WORK_STATE_KEY, DRAFT_WORKS_TITLES_STATE_KEY, DRAFT_WORKS_WORK_PROPERTIES, EMPTY_STRING_VALUE, REPERTOIRE, USE_RELATIONSHIPS_STATE_KEY} from "../../../Consts";
import { hideModal, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { IMembersPortalField } from "../../../types/IMembersPortalField";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IAgreementState, IAgreementStateKeys } from "../../../../redux/types/IAgreementState";
import SizedDatePickerDataInput from "../../../../repertoire/components/dateDataInput/DatePickerDataInput";
import { ASSIGNED_SET_TYPES_STATE_KEY, REPERTOIRE_PAGE_FORM_ITEM_SIZE, SEARCH_VIEW, SEARCH_VIEW_CONTRIBUTORS, SEARCH_VIEW_IPS, TERRITORY_SELECTION_WITH_RADIO_BTN_VIEW, TYPE_STATE_KEY } from "../../../../repertoire/Consts";
import ShowMore from "../../../../repertoire/components/showMore/ShowMore";
import { Dropdown, IDropdownOption } from "office-ui-fabric-react";
import { IAgreementShareSet } from "../../../../repertoire/types/IAgreementShareSet";
import { FieldOptions } from "../../../../repertoire/components/fieldOptions/FieldOptions";
import CheckboxDataInput, { SizedCheckboxDataInput } from "../../../../repertoire/components/checkboxDataInput/CheckboxDataInput";

export interface IDraftAgreementsMaintenanceGeneralDataViewProps {
    draftAgreementMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    draftAgreementMaintenanceGridsViewData?: IMembersPortalComponentDataItem;
    agreements: IAgreementState;
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    editableFields: any[];
    isNewDraftAgreement?: boolean;
    updateDraftAgreementField: (value: any, fieldName: IAgreementStateKeys, index?: number) => void;
    resetMessageBanner: () => void;
    changesMade: boolean;
    updateComponentFieldsItems?: (fields: IMembersPortalField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView?: IMembersPortalComponentDataItem;
    dataGridTableEditableFieldsData?: IMembersPortalComponentDataItem;
    setEditableFields?: typeof setEditableFields;
    activeTab: number;
    tabs: ITabReduxItem[];
    showModal?: (
        modalContent: string,
        modalComponentInstance: string,
        modalProps: any,
        displayModalCloseButton: boolean,
        title: string,
        modalMessage?: string,
        isReadOnly?: boolean,
        key?: string,
        index?: number

    ) => void;
    hideModal?: typeof hideModal;
    incrementTempID?: () => void;
    activeHeaderSection?: string;
    isMandatory?: boolean;
    shareDecimalsToDisplay?: number;
    defaultTerritories?: any;
}

const DraftAgreementsMaintenanceGeneralDataView = ({
    draftAgreementMaintenanceGeneralDataViewData,
    dataGridTableData,
    draftAgreementMaintenanceGridsViewData,
    agreements,
    isReadonly,
    lookupValues,
    updateDraftAgreementField,
    showModal,
    defaultTerritories
}: IDraftAgreementsMaintenanceGeneralDataViewProps) => {
    const [loaded, setLoaded] = React.useState(false);
    const [setTypes, setSetTypes] = React.useState([] as any);
    const [viewData, setViewData] = React.useState({} as any);
    const [viewTerritories, setTerritoryData] = React.useState("" as any);

    let setTypeDropdownOptions = [];
    const lookupVals = getLookupValues(SET_TYPE_LOOKUP, lookupValues);
    lookupVals.filter(x => x.typeID < 0 && x.code.toUpperCase() !== REPERTOIRE.toUpperCase())
        .forEach(x => setTypeDropdownOptions.push({ key: x.typeID, text: x.description, data: x.code }));
    let dropdownOptions = [];
    lookupVals.filter(x => x.typeID < 0 && x.code.toUpperCase() !== REPERTOIRE.toUpperCase())
        .forEach(x => dropdownOptions.push({ key: x.typeID, text: x.description, data: x.code }));

    const showSelectedSetTypesInTheDropDown = (setTypes: any) => {
        const newSelectedOptions = [];
        setTypes.forEach((setType: IAgreementShareSet) => {
            const option = dropdownOptions.find(x => x.data === setType.setType);
            if (option) {
                newSelectedOptions.push(option.key);
            }
        });
        setSetTypes(newSelectedOptions);
    }

    React.useEffect(() => {

        if (draftAgreementMaintenanceGeneralDataViewData?.fields) {
            const visibleFields = draftAgreementMaintenanceGeneralDataViewData.fields.filter(field => !field.hidden);

            const newViewData = {};

            visibleFields.forEach(item => {
                newViewData[item.name] = item.data;
            });
            setViewData(newViewData);
            setLoaded(true);
        }

        if (agreements.agreementShareSet.length > 0 && setTypes.length === 0) {
            showSelectedSetTypesInTheDropDown(agreements.agreementShareSet);
        }
    }, [agreements, draftAgreementMaintenanceGeneralDataViewData]);

    const renderGridViewHeaderSections = (header: IMembersPortalField) => {

        return (
            <div
                className={["headerSection", "activeSection"].join(" ")}
                key={header.name}
                data-testid={header.name + "-section"}
            >
                {header.data}
            </div>
        );
    };


    const getMandatoryFlag = (fieldName: string) => {
        let filteredFields = draftAgreementMaintenanceGeneralDataViewData.fields.filter(field => field.name == fieldName);
        if (filteredFields && filteredFields.length > 0) {
            return filteredFields[0].isMandatory;
        } else {
            return false;
        }
    }

    const multiSelectChangeData = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        const optionIsNotAlreadySelected = (option: IDropdownOption, setTypes: IAgreementShareSet[]) => {
            return setTypes.length === 0 || !setTypes.find(x => x.setType === option.data);
        }

        let newSetType = agreements?.agreementShareSet ? [...agreements?.agreementShareSet] : [];
        let selectedOptionsForTheDropdownLocally = [];
        if (option.selected && optionIsNotAlreadySelected(option, agreements?.agreementShareSet)) {

            newSetType.push({
                agreementShareSetID: 0,
                agreementID: 0,
                setType: option.data,
            })

            selectedOptionsForTheDropdownLocally = [...setTypes, option.key];
        } else {

            const index = setTypes.indexOf(option.key);
            if (index > -1) {
                setTypes.splice(index, 1);
            }
            selectedOptionsForTheDropdownLocally = setTypes;
            newSetType = newSetType.filter(x => x.setType !== option.data);

        }

        updateDraftAgreementField(newSetType ? newSetType : [], "agreementShareSet");
        setSetTypes(selectedOptionsForTheDropdownLocally);
    }

    const limitedAgreementCategories = () => {
        let options: { code: string, description: string }[] = [];
        const membersPortalCodes = ['ADM', 'SPB', 'SPG']
        const categories = getLookupValues(AGREEMENT_CATEGORY_LOOKUP, lookupValues).filter(option => membersPortalCodes.includes(option.code));
        options.push(...categories)
        return options;
    }

    const {
        ipNameNumber,
        ipName,
        agreementCategory,
        specificAgreementType,
        effectiveFrom,
        effectiveTo,
        agreementRegion,
        agreementSignatureDate,
        useRelationships,
        useRelationshipsReadOnly,
        applicableWorksType,
        creationDate,
        type,
        societyAffiliation,
        agreementTerritory,
        societyAgreementNumber,
        status
    } = agreements;


    const checkStatus = (status: string) => {
        const statusLookup = lookupValues['AgreementStatus'].lookups.filter(lookup => lookup.code === status);
        status = statusLookup.length > 0 ? statusLookup[0].description : status;
        return status;
    }

    const changeData = (value: any, fieldName: string) => {
        updateDraftAgreementField(value, fieldName as IAgreementStateKeys);
    }
    const showSearchModal = (modalProps: any, searchViewKey: string) => {
        if (searchViewKey === 'territorySelectionWithRadioBtn') {
            showModal(TERRITORY_SELECTION_WITH_RADIO_BTN_VIEW, null, agreements, true, "Territory Selection", '', false, '')
        } else {
            showModal(SEARCH_VIEW, searchViewKey, modalProps, true, "Search",'',false,'draftAgreement');
        }


    };
    if (loaded) {
        let countriesOptions = [];
        let regionOptions = [];
        let updatedTerritoryCodes = [];
        let updatedTerritory = []

        if (defaultTerritories && !agreementTerritory) {

            const lookupValsTerritories = getLookupValues(TERRITORY_LOOKUP, lookupValues);
            if (lookupValsTerritories) {
                lookupValsTerritories.filter(item => !item.code.startsWith('2')).map(x => countriesOptions.push({ key: x.typeID, text: x.description, data: x.code }));
                lookupValsTerritories.filter(item => item.code.startsWith('2')).map(x => regionOptions.push({ key: x.typeID, text: x.description, data: x.code }));
            }
            if (defaultTerritories.includes && defaultTerritories.includes.length) {
                defaultTerritories.includes.map(item => updatedTerritoryCodes.push(`I: ${lookupValsTerritories.find(x => x.typeID === item).description}`))
            }
            if (defaultTerritories.excludes && defaultTerritories.excludes.length) {
                defaultTerritories.excludes.map(item => updatedTerritoryCodes.push(`E: ${lookupValsTerritories.find(x => x.typeID === item).description}`))
            }
            updatedTerritoryCodes.map(x => x.startsWith("I: ") ? updatedTerritory.push(`I: ${lookupValsTerritories.find(item => item.description === x.replace(/^(I: |E: )/, '')).code}`) : updatedTerritory.push(`E: ${lookupValsTerritories.find(item => item.description === x.replace(/^(I: |E: )/, '')).code}`));
        }

        if (agreementTerritory && agreementTerritory.length) {
            agreementTerritory.map(x => x.include ? updatedTerritory.push(`I: ${x.territory}`) : updatedTerritory.push(`E: ${x.territory}`));
        }
        const membersPortalCodes = ['ADM', 'SPB', 'SPG']
        return (
            <>
                <div className="topMargin">
                    <div className="row">
                        <div className="headerContainer">
                            <div className="headerContainer">
                                {renderGridViewHeaderSections(draftAgreementMaintenanceGridsViewData.fields.find(x => x.name.toString() === 'draftAgreementsGeneral'))}
                            </div>
                        </div>
                        <div>
                            <h5>The <span className="important-label">*</span>indicates a mandatory field.</h5>
                        </div>
                        <div className="row">

                            <div className="form-group col-md-4 col-sm-5 col-xs-12" style={{ display: 'flex', alignItems: 'center',flexDirection:'row' }}>
                                <div style={{width:'100%'}}>
                                    <TextDataInput
                                        label={viewData[DRAFT_AGREEMENTS_IP_NAME_NUMBER_STATE_KEY]}
                                        value={ipNameNumber}
                                        fieldName={DRAFT_AGREEMENTS_IP_NAME_NUMBER_STATE_KEY}
                                        changeData={changeData}
                                        readOnly={true}
                                        isHidden={false}
                                        isMandatory={true}
                                    />
                                    
                                </div>
                                <div style={{marginTop:32}}>
                                <ShowMore
                                        options={[
                                            {
                                                text: 'Select IP',
                                                onClick: () => { showSearchModal(SEARCH_VIEW, SEARCH_VIEW_IPS); },
                                                icon: "assets/external.svg"
                                            }
                                        ]}
                                    />
                                    </div>
                            </div>
                            {<SizedTextDataInput
                                fieldType={"text"}
                                label={viewData[DRAFT_AGREEMENTS_IP_NAME_STATE_KEY]}
                                fieldName={DRAFT_AGREEMENTS_IP_NAME_STATE_KEY}
                                changeData={changeData}
                                readOnly={true}
                                isHidden={false}
                                value={ipName}
                                isMandatory={false}
                            />}
                            {<SizedTextDataInput
                                fieldType={"text"}
                                label={viewData[DRAFT_AGREEMENTS_SOCIETY_AFFILIATION_STATE_KEY]}
                                fieldName={DRAFT_AGREEMENTS_SOCIETY_AFFILIATION_STATE_KEY}
                                changeData={changeData}
                                readOnly={true}
                                isHidden={false}
                                value={societyAffiliation}
                                isMandatory={false}
                            />}
                            {<SizedDropdownDataInput
                                label={viewData[DRAFT_AGREEMENTS_TYPE_STATE_KEY]}
                                fieldName={DRAFT_AGREEMENTS_ASSIGNED_SET_TYPES_STATE_KEY}
                                value={type}
                                changeData={changeData}
                                options={getLookupValues(AGREEMENT_TYPE_LOOKUP, lookupValues)}
                                readOnly={isReadonly}
                                allowNull={false}
                                isHidden={false}
                                isMandatory={true}
                            />}
                            {<SizedDropdownDataInput
                                label={viewData[DRAFT_AGREEMENTS_CATEGORY_STATE_KEY]}
                                fieldName={DRAFT_AGREEMENTS_CATEGORY_STATE_KEY}
                                value={agreementCategory}
                                changeData={changeData}
                                options={getLookupValues(AGREEMENT_CATEGORY_LOOKUP, lookupValues).filter(option => membersPortalCodes.includes(option.code))}
                                readOnly={isReadonly}
                                allowNull={false}
                                isHidden={false}
                                isMandatory={true}
                            />}

                            <div className={REPERTOIRE_PAGE_FORM_ITEM_SIZE}>
                                <Dropdown
                                    className={EMPTY_STRING_VALUE.concat(["ADM", "BO", "PUB", "SPB", "SPG"].includes(agreementCategory) ?
                                        " required " : getMandatoryFlag(DRAFT_AGREEMENTS_ASSIGNED_SET_TYPES_STATE_KEY) ?
                                            " required " : "",
                                        isReadonly ? " disabledItem " : "")}
                                    label={viewData[DRAFT_AGREEMENTS_ASSIGNED_SET_TYPES_STATE_KEY]}
                                    selectedKeys={setTypes.length > 0 ? setTypes : getLookupDefaultMultiSelect(lookupValues, SET_TYPE_LOOKUP, ASSIGNED_SET_TYPES_STATE_KEY, draftAgreementMaintenanceGeneralDataViewData)}
                                    options={dropdownOptions}
                                    multiSelect={true}
                                    onChange={multiSelectChangeData}
                                    placeholder={"No Set Types selected."}
                                    styles={
                                        {
                                            title: { height: 40, backgroundColor: "WhiteSmoke", borderWidth: 1, borderColor: "LightGray", padding: 4 }
                                        }
                                    }
                                    disabled={isReadonly}
                                    required={true}
                                />
                            </div>
                            {<SizedDatePickerDataInput
                                value={(effectiveFrom) as any}
                                fieldName={DRAFT_AGREEMENTS_EFFECTIVE_FROM_STATE_KEY}
                                changeData={changeData}
                                label={viewData[DRAFT_AGREEMENTS_EFFECTIVE_FROM_STATE_KEY]}
                                isHidden={false}
                                isMandatory={true}
                            />}
                            <div className="form-group col-md-4 col-sm-5 col-xs-12" style={{ display: 'flex', alignItems: 'center',flexDirection:'row' }}>
                                <div style={{width:'100%'}}>
                                    <TextDataInput
                                        label={viewData[DRAFT_AGREEMENTS_TERRITORY_STATE_KEY]}
                                        value={updatedTerritory.join(', ')}
                                        fieldName={DRAFT_AGREEMENTS_TERRITORY_STATE_KEY}
                                        changeData={changeData}
                                        readOnly={true}
                                        isHidden={false}
                                        isMandatory={true}
                                    />
                                    
                                </div>
                                <div style={{marginTop:32}}>
                                <ShowMore
                                        options={[
                                            {
                                                text: 'Select Territories',
                                                onClick: () => { showSearchModal(TERRITORY_SELECTION_WITH_RADIO_BTN_VIEW, TERRITORY_SELECTION_WITH_RADIO_BTN_VIEW) },
                                                icon: "assets/external.svg"
                                            }
                                        ]}
                                    />
                                    </div>
                            </div>

                            {<SizedTextDataInput
                                fieldType={"text"}
                                label={viewData[DRAFT_AGREEMENTS_STATUS_STATE_KEY]}
                                fieldName={DRAFT_AGREEMENTS_STATUS_STATE_KEY}
                                changeData={changeData}
                                readOnly={true}
                                isHidden={false}
                                value={checkStatus(status)}
                                isMandatory={false}
                            />}




                            {<SizedDatePickerDataInput
                                value={(effectiveTo) as any}
                                fieldName={DRAFT_AGREEMENTS_EFFECTIVE_TO_STATE_KEY}
                                changeData={changeData}
                                label={viewData[DRAFT_AGREEMENTS_EFFECTIVE_TO_STATE_KEY]}
                                isHidden={false}
                                isMandatory={false}
                            />}
                            {<SizedDatePickerDataInput
                                value={(agreementSignatureDate) as any}
                                fieldName={DRAFT_AGREEMENTS_AGREEMENT_SIGNATURE_DATE_STATE_KEY}
                                changeData={changeData}
                                label={viewData[DRAFT_AGREEMENTS_AGREEMENT_SIGNATURE_DATE_STATE_KEY]}
                                isHidden={false}
                                isMandatory={false}
                            />}                            
                            {<div className="row">
                                <SizedCheckboxDataInput
                                    label={viewData[USE_RELATIONSHIPS_STATE_KEY]}
                                    fieldName={USE_RELATIONSHIPS_STATE_KEY}
                                    value={useRelationships}
                                    changeData={changeData}
                                    isHidden={false}
                                    readonly={useRelationshipsReadOnly ? useRelationshipsReadOnly : isReadonly}
                                />
                                </div>
                            }
                            {type && type === '2' && <SizedDropdownDataInput
                                label={viewData[DRAFT_AGREEMENTS_APPLICABLE_WORKS_TYPE_STATE_KEY]}
                                fieldName={DRAFT_AGREEMENTS_APPLICABLE_WORKS_TYPE_STATE_KEY}
                                value={applicableWorksType}
                                changeData={changeData}
                                options={getLookupValues(APPLICABLE_WORKS_LOOKUP, lookupValues)}
                                readOnly={isReadonly}
                                allowNull={false}
                                isHidden={false}
                                isMandatory={true}
                            />}

                            {<SizedDatePickerDataInput
                                value={(creationDate) as any}
                                fieldName={DRAFT_AGREEMENTS_CREATION_DATE_STATE_KEY}
                                changeData={changeData}
                                label={viewData[DRAFT_AGREEMENTS_CREATION_DATE_STATE_KEY]}
                                isHidden={false}
                                isMandatory={false}
                                readOnly={true}
                            />}
                            {<SizedTextDataInput
                                fieldType={"text"}
                                label={viewData[DRAFT_AGREEMENTS_SOCIETY_AGREEMENT_NUMBER_STATE_KEY]}
                                fieldName={DRAFT_AGREEMENTS_SOCIETY_AGREEMENT_NUMBER_STATE_KEY}
                                changeData={changeData}
                                readOnly={true}
                                isHidden={false}
                                value={societyAgreementNumber}
                                isMandatory={false}
                            />}
                        </div>
                    </div>
                </div>
            </>
        );
    }
    return <div />;
}

export default DraftAgreementsMaintenanceGeneralDataView;