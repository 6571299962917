import * as React from "react";
import { Dictionary } from "../../../../core/types/Dictionary";
import {
    MUSIC_SOURCE_LOOKUP,
    WORK_DISTRIBUTION_CATEGORY_LOOKUP,
    WORK_FLAG_TYPE_LOOKUP,
    WORK_GENRE_LOOKUP,
    WORK_MEDLEY_TYPE_LOOKUP,
    WORK_PARTS_LOOKUP,
    WORK_SOURCE_LOOKUP,
    WORK_SOURCE_TYPE_LOOKUP,
    WORK_TYPE_LOOKUP,
    AGENCY_LOOKUP,
    WORK_STATUS_LOOKUP,
    WORK_SUB_STATUS_LOOKUP,
    WORK_GENRE_CATEGORY_LOOKUP,
    SET_TYPE_GROUP_LOOKUP,
    DOMESTIC_OR_FOREIGN,
    MUSIC_ARRANGEMENT_LOOKUP,
    LYRIC_ADAPTATION_LOOKUP,
    FIRST_PERFORMANCE_INDICATOR_LOOKUP
} from "../../../../lookup/Consts";
import { getLookupDefault, getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { ILookupInstance } from "../../../../lookup/types/ILookup";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { hideModal, showModal, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { clearModalSearchResults, matchWorkBatchRequest, postWorkBatchRequest, setEditableFields, switchSetTypeGroup, updateEditableFields, updateWorkField, updateWorkFields } from "../../../../redux/reducers/RepertoireReducer";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { IActiveAccordion } from "../../../../redux/types/IActiveAccordion";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IWorkStateKeys } from "../../../../redux/types/IWorkState";
import { IShowFieldSettingViewModalProps } from "../../../components/repertoireModal/RepertoireModal";
import {
    COMPONENT_INSTANCE_WORKS,
    COMPOUND_TYPE_STATE_KEY, DURATION_STATE_KEY, EDITABLE_FIELDS, GENERAL_DATA_EDITABLE_FIELDS, GENRE_STATE_KEY, WORK_TYPE_STATE_KEY, MAINTAINED_STATE_KEY, MATCH_WORK_BATCH_STATE_KEY, MERGE_THIS_WORK_STATE_KEY, POST_WORK_BATCH_STATE_KEY, REG_DATE_STATE_KEY, SEARCH_VIEW,
    SEARCH_VIEW_WORKSOURCE_IP, SOURCE_IP_STATE_KEY, SOURCE_SOCIETY_STATE_KEY, SOURCE_STATE_KEY, SOURCE_TYPE_STATE_KEY, STAMP_ID_STATE_KEY, TITLE_EDITABLE_FIELDS_VIEW, WORK_BATCH_ID_STATE_KEY, WORK_IS_ARRANGEMENT_STATE_KEY,
    WORK_IS_DISTRIBUTED_STATE_KEY, WORK_IS_LIBRARY_STATE_KEY, WORK_IS_ORIGINAL_WORK_STATE_KEY, WORK_MAINTENANCE_GENERAL_DATA_VIEW, IS_DISPLAY, MUSIC_SOURCE_STATE_KEY, BOOK_WORK_TYPE_CODE, CODE_CATEGORY, DATA_TYPE, IP_REPRESENTATION_WORK_GROUP_STATE_KEY, WORK_PARTS_STATE_KEY, WORK_CREATION_DATE_STATE_KEY,
    WORK_IS_COMMISIONED_FOR_COMMERCIAL_KEY,
    DISTRIBUTION_CATEGORY_STATE_KEY,
    CONFIGURE_WORKS_ROLE,
    ALL_ROLES,
    SOURCE_IPI_NUMBER_STATE_KEY,
    WORK_IS_MAINTAINED_LIBRARY_WORK_STATE_KEY,
    WORK_IS_MAINTAINED_MCPSI_STATE_KEY,
    SET_TYPE_GROUP_KEY,
    TERRITORY_SPECIFIC_INFO_STATE_KEY,
    GENRE,
    REGION_STATE_KEY,
    STATUS_STATE_KEY,
    SUB_STATUS_STATE_KEY,
    WORK_DOMESTIC_FOREIGN_STATE_KEY,
    GENRE_CATEGORY_STATE_KEY,
    WORK_VALID_FOR_USAGE_FORM_DATE_STATE_KEY,
    EMPTY_STRING_VALUE,
    WORK_VALID_FOR_USAGE_TO_DATE_STATE_KEY,
    PUBLIC_DOMAIN_STATE_KEY,
    NON_ROYALTY_BEARING_STATE_KEY,
    PD_ARRANGEMENT_STATE_KEY,
    PC_SPECIFIC_STATE_KEY,
    CONTROLLED_COMPOSITION_STATE_KEY,
    MUSIC_ARRANGEMENT_STATE_KEY,
    LYRIC_ADAPTATION_STATE_KEY,
    REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION,
    FIRST_PERFORMANCE_INDICATOR_STATE_KEY,
} from "../../../Consts";
import { DataSource } from "../../../types/DataSource";
import { IFlag } from "../../../types/IFlag";
import { IReadonlyFlagsField } from "../../../types/IReadonlyFlagsField";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { ISourceIP } from "../../../types/ISourceIP";
import { ITerritorySpecificInfo } from "../../../types/ITerritorySpecificInfo";
import WorkBatchInput from "../workBatchInput/WorkBatchInput";
import { Field, Formik, Form, FieldArrayRenderProps, FieldArray } from "formik";
import * as Yup from 'yup';
import DatePickerFormikField from "../../../../membersportal/unpaidClaims/DatePickerFormik";
import { IWorkTerritorySpecificInfo } from "../../../types/IWorkTerritorySpecificInfo";
import { SizedDropdownDataInputFormik, makeOptionsList } from "../../../components/dropdownDataInput/DropdownDataInputFormik";
import { SizedDropdownDataInput } from "../../../components/dropdownDataInput/DropdownDataInput";
import TimeDataInputWrapper from "../../../components/timeFieldDataInput/TimeDataInputWrapper";
import { InputNumber } from "antd";

export interface IWorkMaintenanceGeneralDataViewFormikProps {
    myGeneralDataFormikRef: any;
    setIsChangeGeneralForm: (isChangeMade: boolean) => void;
    workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    saveChanges: () => void;
    stampID: string;
    compoundType: string;
    isMaintained: boolean;
    registrationDate: string;
    source: string;
    sourceType: string;
    sourceSociety: string;
    genre: string;
    distributionCategory: string;
    workType: string;
    otherIndicators: IFlag[];
    readonlyIndicators: IFlag[];
    otherIndicatorsWorkFlagTypes: string[];
    readonlyIndicatorsWorkFlagTypes: string[];
    readonlyFlagsField: IReadonlyFlagsField[];
    workBatchID: number;
    territorySpecificInfo: ITerritorySpecificInfo;
    mergeThisWork: boolean;
    updateWorkField: typeof updateWorkField;
    updateWorkFields: typeof updateWorkFields;
    lookupValues: ILookupDictionary;
    genreCategories: Dictionary<Array<string>>;
    dataSource: DataSource;
    changesMade: boolean;
    postWorkBatch: typeof postWorkBatchRequest;
    matchWorkBatch: typeof matchWorkBatchRequest;
    switchSetTypeGroup: typeof switchSetTypeGroup;
    saveInProgress: boolean;
    workBatchOperationInProgress: boolean;
    isReadonly: boolean;
    isOriginalWork: boolean;
    duration: number;
    isArrangement: boolean;
    isDistributed: boolean;
    isMaintainedMCPSI: boolean;
    showModal?: typeof showModal;
    hideModal?: typeof hideModal;
    clearModalSearchResults?: typeof clearModalSearchResults;
    workSourceIP?: ISourceIP;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    refreshWorkDetails: () => void;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    setEditableFields: typeof setEditableFields;
    updateEditableField: typeof updateEditableFields;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    formatFields: FormatFields[];
    isLibraryWork?: boolean;
    isCommissionedForCommercial?: boolean;
    editableFields: IRepertoireField[];
    musicSource?: string;
    workPart?: string;
    workCreationDate?: string;
    genreCategoriesByDomOrInt: Dictionary<Array<string>>;
    openAccordion: (accordionName: string, componentName: string) => void;
    closeAccordion: (accordionName: string, componentName: string) => void;
    defaultActiveAccordions?: IActiveAccordion[];
    roles?: string[];
    isMaintainedLibraryWork?: boolean;
    excludeSourceTypePickShowIP?: string[];
    musicArrangement?: string;
    lyricAdaptation?: string;
    filterGenreCategories: boolean;
    resetMessageBanner: () => void;
    firstPerformanceIndicator: string;
}

function usePreviousSourceTypeValue(value) {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value; 
    }, [value]); 
    return ref.current; 
  };

export const formatValues = (values) => {
        const selectedInfo = values.workTerritorySpecificInfo.map(territory => {
            return {
                workTerritorySpecificInfoID: territory.workTerritorySpecificInfoID,
                setTypeGroup: territory.region.data,
                isControlledComposition: territory.isControlledComposition,
                isPCSpecific: territory.isPCSpecific,
                isPDArrangement: territory.isPDArrangement,
                isNonRoyaltyBearing: territory.isNonRoyaltyBearing,
                isOutOfCopyright: territory.isOutOfCopyright,
                workStatus: territory?.workStatus?.data ? territory.workStatus.data : null,
                workSubStatus: territory?.workSubStatus?.data ? territory.workSubStatus.data : null,
                validForUsageFrom: territory.validForUsageFrom,
                validForUsageTo: territory.validForUsageTo,
                genreCategory: territory?.genreCategory?.data ? territory.genreCategory.data : null,
                isActive: territory?.isActive ? territory.isActive : null,
                domesticOrForeign: territory?.domesticOrForeign?.data ? territory.domesticOrForeign.data : null,
            }
        });
        const updatedWork = {
            ...values,
            source: values?.source?.data ? values.source.data : null,
            sourceType: values?.sourceType?.data ? values.sourceType.data : null,
            sourceSociety: values?.sourceSociety?.data ? values.sourceSociety.data : null,
            compoundType: values?.compoundType?.data ? values.compoundType.data : null,
            genre: values?.genre?.data ? values.genre.data : null,
            distributionCategory: values?.distributionCategory?.data ? values.distributionCategory.data : null,
            workPart: values?.workPart?.data ? values.workPart.data : null,
            workStatus: values?.workStatus?.data ? values.workStatus.data : null,
            workSubStatus: values?.workSubStatus?.data ? values.workSubStatus.data : null,
            region: values?.region?.data ? values.region.data : null,
            workType: values?.workType?.data ? values.workType.data : null,
            musicSource: values?.musicSource?.data ? values.musicSource.data : null,
            musicArrangement: values?.musicArrangement?.data ? values.musicArrangement.data : null,
            lyricAdaptation: values?.lyricAdaptation?.data ? values.lyricAdaptation.data : null,
            workSourceIP: values.workSourceIP,
            duration: values.duration,
            workTerritorySpecificInfo: selectedInfo
        }

        return updatedWork;
};


const workMaintenanceGeneralSchema = (fieldData) => { 
    const schema = Yup.object().shape(
    {      
      workGroup: fieldData?.workGroup?.isMandatory ? Yup.string().nullable().required('Required!') : Yup.string().nullable().notRequired(),
      isMaintained: fieldData?.workGroup?.isMandatory ? Yup.boolean().nullable().required('Required!') : Yup.boolean().nullable().notRequired(),
      mergeThisWork: fieldData?.mergeThisWork?.isMandatory ? Yup.boolean().nullable().required('Required!') : Yup.boolean().nullable().notRequired(),
      isOriginalWork: fieldData?.isOriginalWork?.isMandatory ? Yup.boolean().nullable().required('Required!') : Yup.boolean().nullable().notRequired(),
      isMaintainedMCPSI: fieldData?.isMaintainedMCPSI?.isMandatory ? Yup.boolean().nullable().required('Required!') : Yup.boolean().nullable().notRequired(),
      isDistributed: fieldData?.isDistributed?.isMandatory ? Yup.boolean().nullable().required('Required!') : Yup.boolean().nullable().notRequired(),
      isArrangement: fieldData?.isArrangement?.isMandatory ? Yup.boolean().nullable().required('Required!') : Yup.boolean().nullable().notRequired(),
      isMaintainedLibraryWork: fieldData?.isMaintainedLibraryWork?.isMandatory ? Yup.boolean().nullable().required('Required!') : Yup.boolean().nullable().notRequired(),
      isCommissionedForCommercial: fieldData?.isCommissionedForCommercial?.isMandatory ? Yup.boolean().nullable().required('Required!') : Yup.boolean().nullable().notRequired(),
      isLibraryWork: fieldData?.isLibraryWork?.isMandatory ? Yup.boolean().nullable().required('Required!') : Yup.boolean().nullable().notRequired(),
      stampID: fieldData?.stampID?.isMandatory ? Yup.string().nullable().required('Required!') : Yup.string().nullable().notRequired(),
      
      source: fieldData?.source?.isMandatory ? Yup.object().shape({
        data: Yup.string().nullable(),
      }
      ).test(
        'empty-check',
        'Source can not be empty',
        val => !(!val.data || val.data === "" || val.data.length <= 0)) : Yup.object().shape({
            data: Yup.string().nullable(),
          }).nullable().notRequired(),
      sourceType: fieldData?.sourceType?.isMandatory ? Yup.object().shape({
        data: Yup.string().nullable(),
      }
      ).test(
        'empty-check',
        'Source type can not be empty',
        val => !(!val.data || val.data === "" || val.data.length <= 0)) : Yup.object().shape({
            data: Yup.string().nullable(),
          }).nullable().notRequired(),
      sourceSociety: fieldData?.sourceSociety?.isMandatory ? Yup.object().shape({
        data: Yup.string().nullable(),
      }
      ).test(
        'empty-check',
        'Source society can not be empty',
        val => !(!val.data || val.data === "" || val.data.length <= 0)) : Yup.object().shape({
            data: Yup.string().nullable(),
          }).nullable().notRequired(),
      sourceIPINumber: fieldData?.sourceIPINumber?.isMandatory ? Yup.string().test(
        'empty-check',
        'Source IP Number can not be empty',
        string => !(!string || string === "" || string.length <= 0)) : Yup.string().nullable().notRequired(),
        sourceIP: fieldData?.sourceIP?.isMandatory ? Yup.string().test(
            'empty-check',
            'Source IP can not be empty',
            string => !(!string || string === "" || string.length <= 0)) : Yup.string().nullable().notRequired(),
      registrationDate: fieldData?.registrationDate?.isMandatory ? Yup.string().test(
        'empty-check',
        'Registration Date can not be empty',
        string => !(!string || string === "" || string.length <= 0)) : Yup.string().nullable().notRequired(),
      compoundType: fieldData?.compoundType?.isMandatory ? Yup.object().shape({
        data: Yup.string().nullable(),
      }
      ).test(
        'empty-check',
        'Source can not be empty',
        val => !(!val.data || val.data === "" || val.data.length <= 0)) : Yup.object().shape({
            data: Yup.string().nullable(),
          }).nullable().notRequired(),
      genre: fieldData?.genre?.isMandatory ? Yup.object().shape({
        data: Yup.string().nullable(),
      }
      ).test(
        'empty-check',
        'Genre can not be empty',
        val => !(!val.data || val.data === "" || val.data.length <= 0)) : Yup.object().shape({
            data: Yup.string().nullable(),
          }).nullable().notRequired(),
      distributionCategory: fieldData?.distributionCategory?.isMandatory ? Yup.object().shape({
        data: Yup.string().nullable(),
      }
      ).test(
        'empty-check',
        'Distribution can not be empty',
        val => !(!val.data || val.data === "" || val.data.length <= 0)) : Yup.object().shape({
            data: Yup.string().nullable(),
          }).nullable().notRequired(),
      workType: fieldData?.workType?.isMandatory ? Yup.object().shape({
        data: Yup.string().nullable(),
      }
      ).test(
        'empty-check',
        'Work Type can not be empty',
        val => !(!val.data || val.data === "" || val.data.length <= 0)) : Yup.object().shape({
            data: Yup.string().nullable(),
          }).nullable().notRequired(),
      duration: Yup.string().nullable().notRequired(),
      musicSource: fieldData?.musicSource?.isMandatory ? Yup.object().shape({
        data: Yup.string().nullable(),
      }
      ).test(
        'empty-check',
        'Music source can not be empty',
        val => !(!val.data || val.data === "" || val.data.length <= 0)) : Yup.object().shape({
            data: Yup.string().nullable(),
          }).nullable().notRequired(),
      workCreationDate: fieldData?.workCreationDate?.isMandatory ? Yup.string().test(
        'empty-check',
        'Work Creation Date can not be empty',
        string => !(!string || string === "" || string.length <= 0))  : Yup.string().nullable().notRequired(),
      
       workBatchID: fieldData?.workBatchID?.isMandatory ? Yup.string().test(
            'empty-check',
            'Valid for usage from date can not be empty',
            string => !(!string || string === "" || string.length <= 0)) : Yup.string().nullable().notRequired(),
       workPart: fieldData?.workPart?.isMandatory ? Yup.object().shape({
        data: Yup.string().nullable(),
      }
      ).test(
        'empty-check',
        'Work part can not be empty',
        val => !(!val.data || val.data === "" || val.data.length <= 0)) : Yup.object().shape({
            data: Yup.string().nullable(),
          }).nullable().notRequired(),
        musicArrangement: fieldData?.musicArrangement?.isMandatory ? Yup.object().shape({
            data: Yup.string().nullable(),
          }
          ).test(
            'empty-check',
            'Music Arrangement can not be empty',
            val => !(!val.data || val.data === "" || val.data.length <= 0)) : Yup.object().shape({
                data: Yup.string().nullable(),
              }).nullable().notRequired(),
        lyricAdaptation: fieldData?.lyricAdaptation?.isMandatory ? Yup.object().shape({
                data: Yup.string().nullable(),
              }
              ).test(
                'empty-check',
                'Lyric Adaption can not be empty',
                val => !(!val.data || val.data === "" || val.data.length <= 0)) : Yup.object().shape({
                    data: Yup.string().nullable(),
                  }).nullable().notRequired(),
      workTerritorySpecificInfo: Yup.array().of(Yup.object().shape({
        isActive: Yup.bool(),
        region: fieldData?.region?.isMandatory ? Yup.object().shape({
            data: Yup.string().nullable(),
          }
          ).test(
            'empty-check',
            'Region can not be empty',
            (val, context) => {
                if (context.parent.isActive) {
                    return !(!val.data || val.data === "" || val.data.length <= 0);
                }
                return true}) : Yup.object().shape({
                data: Yup.string().nullable(),
              }).nullable().notRequired(),
        workStatus: fieldData?.workStatus?.isMandatory ? Yup.object().shape({
                data: Yup.string().nullable(),
              }
              ).test(
                'empty-check',
                'Work status can not be empty',
                (val, context) => {
                    if (context.parent.isActive) {
                        return !(!val.data || val.data === "" || val.data.length <= 0);
                    }
                    return true;
                    }) : Yup.object().shape({
                    data: Yup.string().nullable(),
                  }).nullable().notRequired(),
        domesticOrForeign: fieldData?.domesticOrForeign?.isMandatory ? Yup.object().shape({
                data: Yup.string().nullable(),
              }
              ).test(
                'empty-check',
                'Domestic or Foreign can not be empty',
                (val, context) => {
                    if (context.parent.isActive) {
                        return !(!val.data || val.data === "" || val.data.length <= 0);
                    }
                    return true;
                    }) : Yup.object().shape({
                    data: Yup.string().nullable(),
                  }).nullable().notRequired(),
        genreCategory: fieldData?.genreCategory?.isMandatory ? Yup.object().shape({
                data: Yup.string().nullable(),
              }
              ).test(
                'empty-check',
                'Genre category can not be empty',
                (val, context) => {
                    if (context.parent.isActive) {
                        return !(!val.data || val.data === "" || val.data.length <= 0);
                    }
                    return true;
                    }) : Yup.object().shape({
                    data: Yup.string().nullable(),
                  }).nullable().notRequired(),
        validForUsageFrom: fieldData?.validForUsageFrom?.isMandatory ? Yup.string().test(
                'empty-check',
                'Valid for usage from date can not be empty',
                (string, context) => { 
                    if (context.parent.isActive) {
                    return !(!string || string === "" || string.length <= 0)
                }
                return true;
            }
      ) : Yup.string().nullable().notRequired(),
        validForUsageTo: fieldData?.validForUsageTo?.isMandatory ? Yup.string().test(
                'empty-check',
                'Valid for usage from date can not be empty',
                (string, context) => { 
                    if (context.parent.isActive) {
                    return !(!string || string === "" || string.length <= 0)
                }
                return true;
            }) : Yup.string().nullable().notRequired(),
        isOutOfCopyright: fieldData?.isCommissionedForCommercial?.isMandatory ? Yup.boolean().nullable().required('Required!') : Yup.boolean().nullable().notRequired(),
        isPCSpecific: fieldData?.isPCSpecific?.isMandatory ? Yup.boolean().nullable().required('Required!') : Yup.boolean().nullable().notRequired(),
        isControlledComposition: fieldData?.isControlledComposition?.isMandatory ? Yup.boolean().nullable().required('Required!') : Yup.boolean().nullable().notRequired(),

      }))
    },
)
return schema;
}

export const CustomCheckBox = ({
    field,
    label,
    form: { errors, values },
    ...props
}) => {
    const isError = errors && !!errors[props.errorName];
    return (
        <div className={isError ? "invalidForkmikField checkboxSize" : "emptyValidationBorder checkboxSize"}>
            <div className={"checkboxContainer checkBoxContainerSize flexRow flexAlignItemsCenter"}>
                <label className="subContainer checkBoxContainerSize">
                     <input
                        type="checkbox"
                        data-testid={label}
                        checked={field && field.value ? field.value : false}
                        {...field} {...props}
                    />
                    <span className="inputCheckbox" />
                </label>
            </div>
        </div>
    )
}

export const CustomDurationPicker = ({
    field,
    label,
    form,
    onChange,
    ...props
}) => {
return <TimeDataInputWrapper
            value={field.value}
            errors={form.errors}
            changeData={durationValue => {
                field.onChange(field.name, durationValue)
                form.setFieldValue(field.name, durationValue)
            }}
            isFormik={true}
            fieldName={field.name}
            label={label}
            {...props}
    />
}

export const CustomNumberPicker = ({
    field,
    label,
    form,
    onChange,
    ...props
}) => {

    const isError = form.errors && !!form.errors[props.errorName];
    return (<InputNumber
                        size='large'
                        min={0}
                        max={10}
                        value={field.value}
                        status={isError ? 'error' : ''}
                        onChange={durationValue => {
                            field.onChange(field.name, durationValue)
                            form.setFieldValue(field.name, durationValue)
                        }}
                        {...props}
                />
    )
}

const isFieldHaveDefaultValue = (fieldName, viewData) => {
    return !!(viewData && viewData[fieldName] && viewData[fieldName].defaultValue);
}

export const newCustomDropDown = (viewData, name, lookupValues, componentInstance, isReadOnly, errors, onChange, arrayName?) => {
    let lookUpValuesLocal = getLookupValues(componentInstance, lookupValues);
    return <SizedDropdownDataInputFormik
        label={viewData[name].data}
        fieldName={arrayName ? arrayName : name}
        useComboBoxAsMenuWidth={true}
        options={lookUpValuesLocal}
        allowNull={true}
        readOnly={isReadOnly}
        isHidden={!viewData[name].data}
        errors={errors}
        onChange={onChange}
    />
}

const dropDownRegionVariation = (viewData, name, lookupValues, componentInstance, isReadOnly, showError, onChange, value, arrayName?) => {
    let lookUpValuesLocal = getLookupValues(componentInstance, lookupValues);

    return <SizedDropdownDataInput
        label={viewData[name].data}
        fieldName={arrayName ? arrayName : name}
        useComboBoxAsMenuWidth={true}
        options={lookUpValuesLocal}
        value={value}
        allowNull={false}
        readOnly={isReadOnly}
        isHidden={!viewData[name].data}
        changeData={onChange}
    />
}

export const CustomInputComponent = ({
    field,
    form: { errors },
    ...props
}) => {
    const isError = errors[field.name];
    return <div className={isError && "validationForkmikField" }>
        <input type="text" {...field} {...props} data-testid={field.name} />
    </div>
};




const WorkMaintenanceGeneralDataViewFormik = ({
    workMaintenanceGeneralDataViewData,
    saveChanges,
    stampID,
    compoundType,
    isMaintained,
    registrationDate,
    source,
    sourceType,
    sourceSociety,
    genre,
    distributionCategory,
    workType,
    otherIndicators,
    readonlyIndicators,
    otherIndicatorsWorkFlagTypes,
    readonlyIndicatorsWorkFlagTypes,
    readonlyFlagsField,
    workBatchID,
    territorySpecificInfo,
    mergeThisWork,
    updateWorkField,
    updateWorkFields,
    lookupValues,
    genreCategories,
    dataSource,
    changesMade,
    postWorkBatch,
    matchWorkBatch,
    switchSetTypeGroup,
    saveInProgress,
    workBatchOperationInProgress,
    isReadonly,
    isOriginalWork,
    duration,
    isArrangement,
    isDistributed,
    isMaintainedMCPSI,
    showModal,
    hideModal,
    clearModalSearchResults,
    workSourceIP,
    updateComponentFieldsItems,
    refreshWorkDetails,
    showUpdateFieldsModal,
    editableFieldsDataView,
    dataGridTableData,
    setEditableFields,
    updateEditableField,
    activeTab,
    tabs,
    formatFields,
    isLibraryWork,
    isCommissionedForCommercial,
    editableFields,
    musicSource,
    workPart,
    workCreationDate,
    genreCategoriesByDomOrInt, 
    openAccordion,
    closeAccordion,
    defaultActiveAccordions,
    roles,
    isMaintainedLibraryWork,
    excludeSourceTypePickShowIP,
    musicArrangement,
    lyricAdaptation,
    setIsChangeGeneralForm,
    myGeneralDataFormikRef,
    filterGenreCategories,
    resetMessageBanner,
    firstPerformanceIndicator
}) => {
    const [loaded, setLoading] = React.useState(false);
    const [isHiddenSociety, setIsHiddenSociety] = React.useState(true);
    const [isAccordionActive, setIsAccordionActive] = React.useState(false);
    const [name, setName] = React.useState("general");
    const [componentName, setComponentName] = React.useState("WorkMaintenanceGeneralDataView");
    const [viewData, setViewData]: any = React.useState({});
    const [isErrors, setIsErrors]: any = React.useState({});
    const [domesticOrForeign, setDomesticOrForeign] = React.useState('');
    const [genreCategoriesLocalLookups, setGenreCategoriesLocalLookups] = React.useState([]);
    const [sourceTypeLocal, setSourceTypeLocal] = React.useState(sourceType)
    const [regionArrayLineVisible, setRegionArrayLineVisible] = React.useState('US');
    const prevFullNameValue = usePreviousSourceTypeValue(sourceTypeLocal); 
    const changeSourceType = (value) => {
        setSourceTypeLocal(value)
    }
    const formikRef = React.useRef(null);

    const arrayHelperRef = React.useRef<FieldArrayRenderProps>();

    const changeWorkTerritorySpecificInfo = (
        value: string | string[] | boolean,
        fieldName: IRepertoireStateKeys
    ) => {

        const info: IWorkTerritorySpecificInfo[] = [];
        territorySpecificInfo.workTerritorySpecificInfo.map(wp => info.push(Object.assign({}, wp)));
        const setTypeGroup = fieldName === SET_TYPE_GROUP_KEY && value ? value.toString() : territorySpecificInfo.setTypeGroup;

        let selectedInfo: IWorkTerritorySpecificInfo = info.find(wp => wp.setTypeGroup === setTypeGroup);
        if (!selectedInfo) {
            selectedInfo = {
                workTerritorySpecificInfoID: 0,
                setTypeGroup: setTypeGroup,
                workStatus: getLookupDefault(WORK_STATUS_LOOKUP, lookupValues),
                workSubStatus: getLookupDefault(WORK_SUB_STATUS_LOOKUP, lookupValues),
                isOutOfCopyright: false,
                isControlledComposition: false,
                isPCSpecific: false,
                isPDArrangement: false,
                isNonRoyaltyBearing: false,
                domesticOrForeign: null,
                validForUsageFrom: null,
                validForUsageTo: null,
                genreCategory: getLookupDefault(WORK_GENRE_CATEGORY_LOOKUP, lookupValues),
                isActive: true,
            };
            info.push(selectedInfo);
            if (fieldName !== SET_TYPE_GROUP_KEY) {
                selectedInfo[fieldName] = value;
            }

            changeData({ workTerritorySpecificInfo: info, setTypeGroup }, TERRITORY_SPECIFIC_INFO_STATE_KEY); // the rest of the code might need to know this but we don't here.
        }
        else {
            if (fieldName === SET_TYPE_GROUP_KEY) {
                switchSetTypeGroup(setTypeGroup);
            }
            else {
                selectedInfo[fieldName] = value;
                changeData({ workTerritorySpecificInfo: info, setTypeGroup }, TERRITORY_SPECIFIC_INFO_STATE_KEY);
                if (fieldName === "domesticOrForeign") {
                    setDomesticOrForeign(selectedInfo.domesticOrForeign);
                }
            }
        }
    }

    const handleSetMergeThisWork = (
        mergeThisWork: boolean
    ) => {
        handleFormChange();
        changeData(!mergeThisWork, MERGE_THIS_WORK_STATE_KEY);
    }

    const handleFormChange = () => {
        setIsChangeGeneralForm(true)
    }

    const genreCategoryDropDownLookup = () => {
        let genreCategoriesLocal = getLookupValues(WORK_GENRE_CATEGORY_LOOKUP, lookupValues);

        if (filterGenreCategories) {
            if (genreCategories) {
                if (workType in genreCategories) {
                    genreCategoriesLocal = genreCategoriesLocal.filter(gc => genreCategories[workType].includes(gc.code));
                }
            }
            if (genre) {
                let options = genreCategoriesLocal ? genreCategoriesLocal.filter(type => type.extraFields[GENRE] === genre) : null

                if (genreCategories) {
                    if (domesticOrForeign in genreCategories) {
                        genreCategoriesLocal = genreCategoriesLocal.filter(category => genreCategories[domesticOrForeign].includes(category.code));
                        return genreCategoriesLocal.sort((a, b) => a.description.localeCompare(b.description));
                    }
                    return options;
                }
            }
            if (!genre && domesticOrForeign && domesticOrForeign === "FOR") {
                return genreCategoriesLocal.filter(x => x.code === "SZ3N");
            }
        }
        const optionsToSave = genreCategoriesLocal.sort((a, b) => a.description.localeCompare(b.description));
        setGenreCategoriesLocalLookups(optionsToSave);
    }


    React.useEffect(() => {
            if (workMaintenanceGeneralDataViewData.fields) {
                const visibleFields = workMaintenanceGeneralDataViewData.fields.filter(field => !field.hidden);
                const data = {};
                visibleFields.forEach(item => {
                    data[item.name] = item;
        
                    if (Object.keys(data).length === visibleFields.length) {
                        setViewData(data);
                        setLoading(true);
                    }
                });
            setReadOnlySourceFields();
            }

            if ((tabs[activeTab].activeAccordions && tabs[activeTab].activeAccordions.find(x => x === name) && !loaded)
            || (defaultActiveAccordions && defaultActiveAccordions.find((a) => a.fieldName === name) && defaultActiveAccordions.find((a) => a.fieldName === name).isExpanded && !loaded)) {
            openAccordion(name, componentName);
            setIsAccordionActive(true)
        }
    },
    [
        workMaintenanceGeneralDataViewData.fields,
        workMaintenanceGeneralDataViewData,
        tabs,
        activeTab,
    ]);

    React.useEffect(()=> {
        changeWorkTerritorySpecificInfo(territorySpecificInfo.setTypeGroup, SET_TYPE_GROUP_KEY);
        setRegionArrayLineVisible(territorySpecificInfo.workTerritorySpecificInfo[0].setTypeGroup);
        genreCategoryDropDownLookup();
        setEditableFields(WORK_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_WORKS); 
  }, [])


    React.useEffect(() => {
        if(prevFullNameValue !== sourceType) {
            setReadOnlySourceFields();
            changeSourceType(sourceType);
        }
    }, [sourceType])

    React.useEffect(() => {
        updateWorkSourceIP(workSourceIP);
    }, [workSourceIP])

    const updateWorkSourceIP = (workSourceIP) => {
        if (formikRef.current) {
          formikRef.current.setValues({
            ...formikRef.current.values,
            workSourceIP: workSourceIP,
            sourceIP: (workSourceIP === null) ? null : workSourceIP.fullName.value,
            sourceIPINumber: (workSourceIP === null) ? '' : workSourceIP.ipiNameNumber,
          });
        }
      };

      React.useEffect(() => {
        updateWorkBatchId(workBatchID);
    }, [workBatchID])

    const updateWorkBatchId= (workBatchID) => {
        if (formikRef.current) {
          formikRef.current.setValues({
            ...formikRef.current.values,
            workBatchID: (workBatchID === null) ? null : workBatchID,
          });
        }
      };


    const showSearchModal = (modalProps: any, searchViewKey: string) => {
        clearModalSearchResults();
        showModal(SEARCH_VIEW, searchViewKey, modalProps, true, "Search");
    };

    const isFieldPresent = (fieldName, viewData) => {
        return viewData && viewData[fieldName] && viewData[fieldName].data;
    }

    const onClick = (fieldName: IRepertoireStateKeys) => {
        handleFormChange();
        if (fieldName == SOURCE_IP_STATE_KEY) {
            showSearchModal(fieldName, SEARCH_VIEW_WORKSOURCE_IP)
        }
    }
    const setReadOnlySourceFields = () => {
        if (sourceType !== "" && sourceType === "SO") {
            setIsHiddenSociety(false);
        }
        else {
            setIsHiddenSociety(true);
        }
    }

    const onSaveEditableFields = () => {
        editableFields && editableFields.default && updateComponentFieldsItems(editableFields.default, WORK_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_WORKS, WORK_MAINTENANCE_GENERAL_DATA_VIEW, tabs, activeTab);
    }

    const showFieldsModal = () => {

        setEditableFields(WORK_MAINTENANCE_GENERAL_DATA_VIEW, COMPONENT_INSTANCE_WORKS)

        const fieldsViewData: IRepertoireComponentDataItem = { fields: editableFieldsDataView.fields.filter(x => x.componentInstance === GENERAL_DATA_EDITABLE_FIELDS) };
        const title: string = fieldsViewData.fields.find(item => item.name === TITLE_EDITABLE_FIELDS_VIEW).data;

        const fieldSetting: IShowFieldSettingViewModalProps = {
            componentInstance: EDITABLE_FIELDS,
            componentViewData: fieldsViewData,
            dataGridViewData: dataGridTableData,
            onClickSave: () => onSaveEditableFields(),
        }

        showUpdateFieldsModal(title, fieldSetting);
    }

    const toggleAccordion = () => {
        if (tabs[activeTab].activeAccordions && tabs[activeTab].activeAccordions.includes(name)) {
            closeAccordion(name, componentName);
        } else {
            openAccordion(name, componentName);
        }
        setIsAccordionActive(!isAccordionActive)
    };

        const changeData = (value: any, name: IRepertoireStateKeys) => {

            if (name == 'workType' && value == BOOK_WORK_TYPE_CODE && otherIndicators.length == 0) {
                const workFlagTypes = getLookupValues(WORK_FLAG_TYPE_LOOKUP, lookupValues);
                let flagValues = workFlagTypes ? workFlagTypes.filter(type => type.extraFields[IS_DISPLAY] === true && otherIndicatorsWorkFlagTypes.includes(type.extraFields[CODE_CATEGORY])) : null;

                const flags: IFlag[] = [];
                flagValues.map((s, i) => flags.push({
                    flagID: 0,
                    flagTypeCode: s.code,
                    createdDate: null,
                    lastModifiedBy: null,
                    value: s.extraFields[DATA_TYPE] == 'boolean' ? "0" : null,
                    dataType: s.extraFields[DATA_TYPE],
                    name: s.description,
                    workID: 0
                }));

                updateWorkField(flags, 'otherIndicators')
            }

            if (name == "workType" && readonlyIndicators.length == 0) {
                const workFlagTypes = getLookupValues(WORK_FLAG_TYPE_LOOKUP, lookupValues);
                let flagValues = workFlagTypes ? workFlagTypes.filter(type => type.extraFields[IS_DISPLAY] === true && readonlyIndicatorsWorkFlagTypes.includes(type.extraFields[CODE_CATEGORY])) : null;

                const flags: IFlag[] = [];
                flagValues.map((s, i) => flags.push({
                    flagID: 0,
                    flagTypeCode: s.code,
                    createdDate: null,
                    lastModifiedBy: null,
                    value: s.extraFields[DATA_TYPE] == 'boolean' ? "0" : null,
                    dataType: s.extraFields[DATA_TYPE],
                    name: s.description,
                    workID: 0
                }));

                updateWorkField(flags, 'readonlyIndicators')
            }

            updateWorkField(value, name as IWorkStateKeys);

        }

        const renderPostBatch = (): JSX.Element => {
            if (dataSource === DataSource.Intray && viewData && viewData[WORK_BATCH_ID_STATE_KEY] && viewData[WORK_BATCH_ID_STATE_KEY].data && viewData[POST_WORK_BATCH_STATE_KEY] && viewData[POST_WORK_BATCH_STATE_KEY].data) {
                return <WorkBatchInput
                    label={viewData[WORK_BATCH_ID_STATE_KEY].data}
                    fieldName={WORK_BATCH_ID_STATE_KEY}
                    value={workBatchID}
                    changeData={changeData}
                    postButtonLabel={viewData[POST_WORK_BATCH_STATE_KEY].data}
                    postButtonAction={() => postWorkBatch(workBatchID)}
                    matchButtonLabel={viewData[MATCH_WORK_BATCH_STATE_KEY].data}
                    matchButtonAction={() => matchWorkBatch(workBatchID)}
                    isEnabled={!changesMade && !saveInProgress && !workBatchOperationInProgress && !isReadonly}
                />
            }
            else {
                return null;
            }
        }

        const handleSubmit = (values) => {
            const updatedWork = formatValues(values);
            updateWorkFields(updatedWork);
        }

        const getConfigureRoleDisabled = (): boolean => {
            if (roles && (roles.includes(CONFIGURE_WORKS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
            return true;
        }
        
        const workTypeDropDownLookup = (): ILookupInstance[] => {
            const workTypes = getLookupValues(WORK_TYPE_LOOKUP, lookupValues);
            let options = workTypes ? workTypes.filter(type => type.extraFields[IS_DISPLAY] === true) : null
            return options;
        }
        const workTypeOptions = workTypeDropDownLookup();

        const secondsIntToTimeString =(value: number) => {
            return value && !isNaN(value) && new Date(value * 1000).toISOString().substr(11, 8)
        }
        const stringDuration = secondsIntToTimeString(duration);
        const sourceIPINumber = (workSourceIP === null) ? '' : workSourceIP.ipiNameNumber;
        const sourceIP = (workSourceIP === null) ? '' : workSourceIP.fullName.value
        const showWorkDistributionCategory = lookupValues.WorkDistributionCategory.lookups.length !== undefined && lookupValues.WorkDistributionCategory.lookups.length > 0;
        const showWorkGroup = tabs[activeTab].workMaintenanceState.workGroup !== undefined && tabs[activeTab].workMaintenanceState.workGroup.length > 0

        const selectedInfo = territorySpecificInfo.workTerritorySpecificInfo.find(wp => wp.setTypeGroup === territorySpecificInfo.setTypeGroup);
        const historyClass = isReadonly ? ' versionHistoryPointer ' : '';
        if (!selectedInfo) {
            return null;
        }

        const getDefaultValue = (fieldName, viewData) => isFieldHaveDefaultValue(fieldName, viewData) ? viewData[fieldName].defaultValue : '';
        const getInitialValueDropDown = (initialValue, lookupName, fieldName) => {
            const lookUpValuesLocal = getLookupValues(lookupName, lookupValues);
            const initialSelected = initialValue ? initialValue : getDefaultValue(fieldName, viewData);
            const sourceOptions = makeOptionsList(true, lookupName, lookUpValuesLocal, false)
            const foundOption = sourceOptions.find(option => option.text === initialSelected || option.data === initialSelected);
            return foundOption ? foundOption : {data: '', text: '', title: ''}
        }

        const handleDomesticOrForeignChange = (fieldName, value) => {
                setDomesticOrForeign(value.data);
                handleFormChange()
        }

        const findErrorsWorkTerritorySpecificInfoArray = (errorObject) => {
            return Object.keys(errorObject).some((key) => key.includes('workTerritorySpecificInfo'))
        } 
        const renderRegionRow = (index, errors, workTerritorySpecificInfo, setFieldValue) => {
            
        const handleRegionChange = (value, fieldName) => {
            let lookUpValuesLocal = getLookupValues(SET_TYPE_GROUP_LOOKUP, lookupValues);
            let indexOfNewRow;
            lookUpValuesLocal.forEach((item, i) => {
                if (item.code === value) {
                    indexOfNewRow = i;
                }})
            handleFormChange();
            setFieldValue(`workTerritorySpecificInfo[${indexOfNewRow}].isActive`, true);
            setRegionArrayLineVisible(value);
            changeWorkTerritorySpecificInfo(value, SET_TYPE_GROUP_KEY)
        }
            return regionArrayLineVisible === workTerritorySpecificInfo?.region?.data && <div key={index}><div className="pointerEnable formItemsContainer">
                {isFieldPresent(REGION_STATE_KEY, viewData) && dropDownRegionVariation(viewData, REGION_STATE_KEY, lookupValues,  SET_TYPE_GROUP_LOOKUP, isReadonly, false, handleRegionChange, workTerritorySpecificInfo.region.data, `workTerritorySpecificInfo[${index}].${REGION_STATE_KEY}`)}
                                {isFieldPresent(STATUS_STATE_KEY, viewData) && newCustomDropDown(viewData, STATUS_STATE_KEY, lookupValues,  WORK_STATUS_LOOKUP, isReadonly, errors, handleFormChange, `workTerritorySpecificInfo[${index}].${STATUS_STATE_KEY}`)} 
                                {workTerritorySpecificInfo.workStatus.data === "I" && isFieldPresent(SUB_STATUS_STATE_KEY, viewData) && newCustomDropDown(viewData, SUB_STATUS_STATE_KEY, lookupValues,  WORK_SUB_STATUS_LOOKUP, isReadonly, errors, handleFormChange, `workTerritorySpecificInfo[${index}].${SUB_STATUS_STATE_KEY}`)} 
                                {isFieldPresent(WORK_DOMESTIC_FOREIGN_STATE_KEY, viewData) && newCustomDropDown(viewData, WORK_DOMESTIC_FOREIGN_STATE_KEY, lookupValues,  DOMESTIC_OR_FOREIGN, isReadonly, errors, handleDomesticOrForeignChange, `workTerritorySpecificInfo[${index}].${WORK_DOMESTIC_FOREIGN_STATE_KEY}`)} 
                                        {isFieldPresent(GENRE_CATEGORY_STATE_KEY, viewData) &&
                                            <SizedDropdownDataInputFormik
                                            label={viewData[GENRE_CATEGORY_STATE_KEY].data}
                                            fieldName={`workTerritorySpecificInfo[${index}].${GENRE_CATEGORY_STATE_KEY}`}
                                            useComboBoxAsMenuWidth={true}
                                            options={genreCategoriesLocalLookups}
                                            allowNull={true}
                                            readOnly={isReadonly}
                                            isHidden={!viewData[GENRE_CATEGORY_STATE_KEY].data}
                                            errors={errors}
                                            onChange={handleFormChange}
                                        />}
                                    {isFieldPresent(WORK_VALID_FOR_USAGE_FORM_DATE_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem`}>
                                            <label>{viewData[WORK_VALID_FOR_USAGE_FORM_DATE_STATE_KEY].data}</label>
                                            <DatePickerFormikField label={viewData[WORK_VALID_FOR_USAGE_FORM_DATE_STATE_KEY]} error={errors && errors[`workTerritorySpecificInfo[${index}].${WORK_VALID_FOR_USAGE_FORM_DATE_STATE_KEY}`]} name={`workTerritorySpecificInfo[${index}].${WORK_VALID_FOR_USAGE_FORM_DATE_STATE_KEY}`} disabled={isReadonly} onClick={handleFormChange} size={'large'}/>
                                        </div>}
                                    {isFieldPresent(WORK_VALID_FOR_USAGE_TO_DATE_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem`}>
                                            <label>{viewData[WORK_VALID_FOR_USAGE_TO_DATE_STATE_KEY].data}</label>
                                            <DatePickerFormikField label={viewData[WORK_VALID_FOR_USAGE_TO_DATE_STATE_KEY]} error={errors && errors[`workTerritorySpecificInfo[${index}].${WORK_VALID_FOR_USAGE_TO_DATE_STATE_KEY}`]} name={`workTerritorySpecificInfo[${index}].${WORK_VALID_FOR_USAGE_TO_DATE_STATE_KEY}`} disabled={isReadonly} onClick={handleFormChange}  size={'large'}/>
                                        </div>}
                                </div>
                           
                            <div className={`${historyClass} formItemsContainer`}>
                                    {isFieldPresent(PUBLIC_DOMAIN_STATE_KEY, viewData) &&  <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem flexRow`}>
                                    <Field 
                                                label={viewData[PUBLIC_DOMAIN_STATE_KEY].data} 
                                                name={`workTerritorySpecificInfo[${index}].${PUBLIC_DOMAIN_STATE_KEY}`} 
                                                errorName={PUBLIC_DOMAIN_STATE_KEY} 
                                                component={CustomCheckBox}
                                                disabled={isReadonly}
                                                onClick={handleFormChange}
                                            />
                                        <label className="marginHorizontalVerySmall">{viewData[PUBLIC_DOMAIN_STATE_KEY].data}</label>
                                        </div>}
                                    {isFieldPresent(NON_ROYALTY_BEARING_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem flexRow flexAlignItemsCenter`}>
                                    <Field 
                                                    label={viewData[NON_ROYALTY_BEARING_STATE_KEY].data} 
                                                    name={`workTerritorySpecificInfo[${index}].${NON_ROYALTY_BEARING_STATE_KEY}`} 
                                                    errorName={NON_ROYALTY_BEARING_STATE_KEY} 
                                                    component={CustomCheckBox}
                                                    disabled={isReadonly}
                                                    onClick={handleFormChange}
                                                />
                                            <label className="marginHorizontalVerySmall">{viewData[NON_ROYALTY_BEARING_STATE_KEY].data}</label>
                                            </div>}
                                    {isFieldPresent(PD_ARRANGEMENT_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem flexRow`}>
                                    <Field 
                                                    label={viewData[PD_ARRANGEMENT_STATE_KEY].data} 
                                                    name={`workTerritorySpecificInfo[${index}].${PD_ARRANGEMENT_STATE_KEY}`} 
                                                    errorName={PD_ARRANGEMENT_STATE_KEY} 
                                                    component={CustomCheckBox}
                                                    disabled={isReadonly}
                                                    onClick={handleFormChange}
                                                />
                                            <label className="marginHorizontalVerySmall">{viewData[PD_ARRANGEMENT_STATE_KEY].data}</label>
                                            </div>}
                                    {isFieldPresent(PC_SPECIFIC_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem flexRow`}>
                                    <Field 
                                                    label={viewData[PC_SPECIFIC_STATE_KEY].data} 
                                                    name={`workTerritorySpecificInfo[${index}].${PC_SPECIFIC_STATE_KEY}`} 
                                                    errorName={PC_SPECIFIC_STATE_KEY} 
                                                    component={CustomCheckBox}
                                                    disabled={isReadonly}
                                                    onClick={handleFormChange}
                                                />
                                            <label className="marginHorizontalVerySmall">{viewData[PC_SPECIFIC_STATE_KEY].data}</label>
                                            </div>}
                                    {isFieldPresent(CONTROLLED_COMPOSITION_STATE_KEY, viewData) &&  <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem flexRow`}>
                                    <Field 
                                                    label={viewData[CONTROLLED_COMPOSITION_STATE_KEY].data} 
                                                    name={`workTerritorySpecificInfo[${index}].${CONTROLLED_COMPOSITION_STATE_KEY}`} 
                                                    errorName={CONTROLLED_COMPOSITION_STATE_KEY} 
                                                    component={CustomCheckBox}
                                                    disabled={isReadonly}
                                                    onClick={handleFormChange}
                                                />
                                            <label className="marginHorizontalVerySmall">{viewData[CONTROLLED_COMPOSITION_STATE_KEY].data}</label>
                                            </div>}
                            </div></div>
        }

        const mapRegionsSpecificInfoToInitialValues = () => {
            let regionLookUpValues = getLookupValues(SET_TYPE_GROUP_LOOKUP, lookupValues);

            return regionLookUpValues.map(region => {
                const foundTerritory = territorySpecificInfo.workTerritorySpecificInfo.find(territory => territory.setTypeGroup === region.code);
                if (foundTerritory) {
                    return {
                        domesticOrForeign:  getInitialValueDropDown(foundTerritory.domesticOrForeign, DOMESTIC_OR_FOREIGN, WORK_DOMESTIC_FOREIGN_STATE_KEY),
                        genreCategory: getInitialValueDropDown(foundTerritory.genreCategory, WORK_GENRE_CATEGORY_LOOKUP, GENRE_CATEGORY_STATE_KEY),
                        validForUsageFrom: foundTerritory.validForUsageFrom ? foundTerritory.validForUsageFrom.toString() : EMPTY_STRING_VALUE,
                        validForUsageTo: foundTerritory.validForUsageTo ? foundTerritory.validForUsageTo.toString() : EMPTY_STRING_VALUE,
                        isOutOfCopyright: foundTerritory.isOutOfCopyright ? foundTerritory.isOutOfCopyright : false,
                        isPCSpecific: foundTerritory.isPCSpecific ? foundTerritory.isPCSpecific : false,
                        isControlledComposition: foundTerritory.isControlledComposition ? foundTerritory.isControlledComposition : false,
                        isPDArrangement: foundTerritory.isPDArrangement ? foundTerritory.isPDArrangement : false,
                        isNonRoyaltyBearing: foundTerritory.isNonRoyaltyBearing ? foundTerritory.isNonRoyaltyBearing : false,
                        region: getInitialValueDropDown(foundTerritory.setTypeGroup, SET_TYPE_GROUP_LOOKUP, REGION_STATE_KEY),
                        workStatus: getInitialValueDropDown(foundTerritory.workStatus, WORK_STATUS_LOOKUP, STATUS_STATE_KEY),
                        workSubStatus: getInitialValueDropDown(foundTerritory.workSubStatus, WORK_SUB_STATUS_LOOKUP, SUB_STATUS_STATE_KEY),
                        workTerritorySpecificInfoID: foundTerritory.workTerritorySpecificInfoID,
                        isActive: true,
                    } 
                }
                return {
                    domesticOrForeign:  getInitialValueDropDown(false, DOMESTIC_OR_FOREIGN, WORK_DOMESTIC_FOREIGN_STATE_KEY),
                    genreCategory: getInitialValueDropDown(false, WORK_GENRE_CATEGORY_LOOKUP, GENRE_CATEGORY_STATE_KEY),
                    validForUsageFrom: null,
                    validForUsageTo: null,
                    isOutOfCopyright: false,
                    isPCSpecific: false,
                    isControlledComposition: false,
                    isPDArrangement:  false,
                    isNonRoyaltyBearing: false,
                    region: getInitialValueDropDown(region.code, SET_TYPE_GROUP_LOOKUP, REGION_STATE_KEY),
                    workStatus: getInitialValueDropDown(false, WORK_STATUS_LOOKUP, STATUS_STATE_KEY),
                    workSubStatus: getInitialValueDropDown(false, WORK_SUB_STATUS_LOOKUP, SUB_STATUS_STATE_KEY),
                    isActive: false,
                }
            })
        }
       
        const initialValues = () => {
            return {
                workGroup: tabs[activeTab].workMaintenanceState.workGroup ? tabs[activeTab].workMaintenanceState.workGroup : '',
                isMaintained: isMaintained ? isMaintained : false,
                mergeThisWork: mergeThisWork ? mergeThisWork : false,
                isOriginalWork: isOriginalWork ? isOriginalWork : false,
                isMaintainedMCPSI: isMaintainedMCPSI ? isMaintainedMCPSI : false,
                isDistributed: isDistributed ? isDistributed : false,
                isArrangement: isArrangement ? isArrangement : false,
                isMaintainedLibraryWork: isMaintainedLibraryWork ? isMaintainedLibraryWork : false,
                isCommissionedForCommercial: isCommissionedForCommercial ? isCommissionedForCommercial : false,
                isLibraryWork: isLibraryWork ? isLibraryWork : false,
                stampID: stampID ? stampID : '',
                source: getInitialValueDropDown(source, WORK_SOURCE_LOOKUP ,SOURCE_STATE_KEY),
                sourceType: getInitialValueDropDown(sourceType, WORK_SOURCE_TYPE_LOOKUP, SOURCE_TYPE_STATE_KEY),
                sourceSociety: getInitialValueDropDown(sourceSociety, AGENCY_LOOKUP, SOURCE_SOCIETY_STATE_KEY),
                sourceIP: sourceIP,
                sourceIPINumber: sourceIPINumber,
                registrationDate: registrationDate ? registrationDate : '',
                compoundType: getInitialValueDropDown(compoundType, WORK_MEDLEY_TYPE_LOOKUP, COMPOUND_TYPE_STATE_KEY),
                genre: getInitialValueDropDown(genre, WORK_GENRE_LOOKUP, GENRE_STATE_KEY),
                firstPerformanceIndicator: getInitialValueDropDown(firstPerformanceIndicator, FIRST_PERFORMANCE_INDICATOR_LOOKUP, FIRST_PERFORMANCE_INDICATOR_STATE_KEY),
                distributionCategory: getInitialValueDropDown(distributionCategory, WORK_DISTRIBUTION_CATEGORY_LOOKUP, DISTRIBUTION_CATEGORY_STATE_KEY),
                workType: getInitialValueDropDown(workType, WORK_TYPE_LOOKUP, WORK_TYPE_STATE_KEY),
                duration: duration ? duration : 0,
                musicSource:  getInitialValueDropDown(musicSource, MUSIC_SOURCE_LOOKUP, MUSIC_SOURCE_STATE_KEY),
                musicArrangement: getInitialValueDropDown(musicArrangement, MUSIC_ARRANGEMENT_LOOKUP, MUSIC_ARRANGEMENT_STATE_KEY),
                lyricAdaptation:  getInitialValueDropDown(lyricAdaptation, LYRIC_ADAPTATION_LOOKUP, LYRIC_ADAPTATION_STATE_KEY),
                workCreationDate: workCreationDate ? workCreationDate : '',
                workPart: getInitialValueDropDown(distributionCategory, SET_TYPE_GROUP_LOOKUP, WORK_PARTS_STATE_KEY),
                workBatchID: workBatchID ? workBatchID : '',
                workSourceIP: workSourceIP,

                workTerritorySpecificInfo: mapRegionsSpecificInfoToInitialValues(),
            }
        }

        return (
<>
    <div className={isReadonly ? 'versionHistoryPointer' : ''}>
                    <div className="row">
                        <div className="col-md-12 editableSectionHeading">
                            <div className="itemAccordian"
                                onClick={toggleAccordion}
                            >
                                <h1 className="borderBootom">
                                    <i
                                        title="Expand section"
                                        className={["icon ms-Icon ms-Icon--ChevronRight",
                                            isAccordionActive ? "expanded" : null].join(" ")}
                                    >
                                    </i>
                                    General
                                </h1>
                            </div>
                            <button className="refreshBtn" title="Refresh work details" onClick={refreshWorkDetails}>
                                <i className="ms-Icon ms-Icon--Refresh"></i>
                            </button>
                            <button className="editBtn" title="Configure data fields" onClick={showFieldsModal} disabled={getConfigureRoleDisabled()}>
                                <i className="ms-Icon ms-Icon--Settings"></i>
                            </button>
                        </div>
                    </div>
                </div>
        {viewData ? (<>
                    <Formik
                        enableReinitialize
                        validateOnChange
                        initialValues={initialValues()}
                        innerRef={formikRef}
                        validate={(values) => {
                            const schema = workMaintenanceGeneralSchema(viewData)
                            try {
                                schema.validateSync(values, { abortEarly: false });
                                return {}; 
                              } catch (errors: any) {
                                const formikErrors = {};
                                errors && errors.inner && errors.inner.forEach((error) => {
                                  formikErrors[error.path] = error.message;
                                });
                                setIsErrors(true)
                                return formikErrors;
                              }


                        }}
                        onSubmit={(values, { setSubmitting }) => {

                            handleSubmit(values);
                            setSubmitting(false);
                        }}
                > 
                {({submitForm, values, errors, validateForm, setFieldValue }) => {
                    let isHiddenIPFormik = false;
                    let isHiddenSocietyFormik = true;
                    if (values.sourceType.data === null  || values.sourceType.data === "" || excludeSourceTypePickShowIP === null || excludeSourceTypePickShowIP.includes(values.sourceType.data))
                    {
                        isHiddenIPFormik = true;
                    }
                    if(values.sourceType.data !== "" && values.sourceType.data === "SO") {
                        isHiddenSocietyFormik = false;
                    };
                     myGeneralDataFormikRef.current = {
                        validateForm,
                        submitForm,
                        errors,
                        values,
                      };
                      if (Object.keys(errors).length === 0 && isErrors) {
                        setIsErrors(false)
                        resetMessageBanner()
                      }
                    return (
                    <Form>
                        {isAccordionActive && <div>
                       <> 
                            {showWorkGroup &&  isFieldPresent(IP_REPRESENTATION_WORK_GROUP_STATE_KEY, viewData) && <div className="row">
                                <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} heightBox`}>
                                        <label>{viewData[IP_REPRESENTATION_WORK_GROUP_STATE_KEY].data}</label>
                                        <Field 
                                            label={viewData[IP_REPRESENTATION_WORK_GROUP_STATE_KEY].data}
                                            name={IP_REPRESENTATION_WORK_GROUP_STATE_KEY}
                                            component={CustomInputComponent}
                                            disabled={false}
                                        />
                                    </div>
                                </div>}

                                <div className="formItemsContainer">
                               {isFieldPresent(MAINTAINED_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem flexRow`}>
                               <Field 
                                        label={viewData[MAINTAINED_STATE_KEY].data} 
                                        name={MAINTAINED_STATE_KEY} 
                                        errorName={MAINTAINED_STATE_KEY} 
                                        component={CustomCheckBox}
                                        disabled={isReadonly}
                                        onClick={handleFormChange}
                                    />
                                    <label className="marginHorizontalVerySmall">{viewData[MAINTAINED_STATE_KEY].data}</label>
                                </div>}
                                {isFieldPresent(MERGE_THIS_WORK_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem flexRow`}>
                                <Field 
                                        label={viewData[MERGE_THIS_WORK_STATE_KEY].data} 
                                        name={MERGE_THIS_WORK_STATE_KEY} 
                                        errorName={MERGE_THIS_WORK_STATE_KEY} 
                                        component={CustomCheckBox}
                                        disabled={isReadonly}
                                        onClick={() => handleSetMergeThisWork(values.mergeThisWork)}
                                    />
                                <label className="marginHorizontalVerySmall">{viewData[MERGE_THIS_WORK_STATE_KEY].data}</label>
                                </div>}
                                {isFieldPresent(WORK_IS_ORIGINAL_WORK_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem flexRow`}>
                                <Field 
                                        label={viewData[WORK_IS_ORIGINAL_WORK_STATE_KEY].data} 
                                        name={WORK_IS_ORIGINAL_WORK_STATE_KEY} 
                                        errorName={WORK_IS_ORIGINAL_WORK_STATE_KEY} 
                                        component={CustomCheckBox}
                                        disabled={isReadonly}
                                        onClick={handleFormChange}
                                    />
                                <label className="marginHorizontalVerySmall">{viewData[WORK_IS_ORIGINAL_WORK_STATE_KEY].data}</label>
                                </div>}
                                {isFieldPresent(WORK_IS_MAINTAINED_MCPSI_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem flexRow`}>
                                <Field 
                                            label={viewData[WORK_IS_MAINTAINED_MCPSI_STATE_KEY].data} 
                                            name={WORK_IS_MAINTAINED_MCPSI_STATE_KEY} 
                                            errorName={WORK_IS_MAINTAINED_MCPSI_STATE_KEY} 
                                            component={CustomCheckBox}
                                            disabled={isReadonly}
                                            onClick={handleFormChange}
                                        />
                                <label className="marginHorizontalVerySmall">{viewData[WORK_IS_MAINTAINED_MCPSI_STATE_KEY].data}</label>
                                    </div>}
                                {isFieldPresent(WORK_IS_DISTRIBUTED_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem flexRow`}>
                                <Field 
                                        label={viewData[WORK_IS_DISTRIBUTED_STATE_KEY].data} 
                                        name={WORK_IS_DISTRIBUTED_STATE_KEY} 
                                        errorName={WORK_IS_DISTRIBUTED_STATE_KEY} 
                                        component={CustomCheckBox}
                                        disabled={isReadonly}
                                        onClick={handleFormChange}
                                    />
                                <label className="marginHorizontalVerySmall">{viewData[WORK_IS_DISTRIBUTED_STATE_KEY].data}</label>
                                </div> }
                                {isFieldPresent(WORK_IS_ARRANGEMENT_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem flexRow`}>
                                <Field 
                                        label={viewData[WORK_IS_ARRANGEMENT_STATE_KEY].data} 
                                        name={WORK_IS_ARRANGEMENT_STATE_KEY} 
                                        errorName={WORK_IS_ARRANGEMENT_STATE_KEY} 
                                        component={CustomCheckBox}
                                        disabled={isReadonly}
                                        onClick={handleFormChange}
                                    />
                                <label className="marginHorizontalVerySmall">{viewData[WORK_IS_ARRANGEMENT_STATE_KEY].data}</label>
                                </div>}
                                {isFieldPresent(WORK_IS_MAINTAINED_LIBRARY_WORK_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem flexRow`}>
                                <Field 
                                            label={viewData[WORK_IS_MAINTAINED_LIBRARY_WORK_STATE_KEY].data} 
                                            name={WORK_IS_MAINTAINED_LIBRARY_WORK_STATE_KEY} 
                                            errorName={WORK_IS_MAINTAINED_LIBRARY_WORK_STATE_KEY} 
                                            component={CustomCheckBox}
                                            disabled={isReadonly}
                                            onClick={handleFormChange}
                                        />
                                <label className="marginHorizontalVerySmall">{viewData[WORK_IS_MAINTAINED_LIBRARY_WORK_STATE_KEY].data}</label>
                                    </div>}
                                {isFieldPresent(WORK_IS_COMMISIONED_FOR_COMMERCIAL_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem flexRow`}>
                                <Field 
                                        label={viewData[WORK_IS_COMMISIONED_FOR_COMMERCIAL_KEY].data} 
                                        name={WORK_IS_COMMISIONED_FOR_COMMERCIAL_KEY} 
                                        errorName={WORK_IS_COMMISIONED_FOR_COMMERCIAL_KEY} 
                                        component={CustomCheckBox}
                                        disabled={isReadonly}
                                        onClick={handleFormChange}
                                    />
                                <label className="marginHorizontalVerySmall" >{viewData[WORK_IS_COMMISIONED_FOR_COMMERCIAL_KEY].data}</label>
                                </div> }
                                {isFieldPresent(WORK_IS_LIBRARY_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem flexRow`}>
                                <Field 
                                        label={viewData[WORK_IS_LIBRARY_STATE_KEY].data} 
                                        name={WORK_IS_LIBRARY_STATE_KEY} 
                                        errorName={WORK_IS_LIBRARY_STATE_KEY} 
                                        component={CustomCheckBox}
                                        disabled={isReadonly}
                                        onClick={handleFormChange}
                                    />
                                <label className="marginHorizontalVerySmall">{viewData[WORK_IS_LIBRARY_STATE_KEY].data}</label>
                                </div>}
                                </div>
                                <div className="formItemsContainer">
                                {isFieldPresent(STAMP_ID_STATE_KEY, viewData) &&
                                <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem `}>
                                    <label>{viewData[STAMP_ID_STATE_KEY].data}</label>
                                    <Field 
                                        label={viewData[STAMP_ID_STATE_KEY].data}
                                        name={STAMP_ID_STATE_KEY}
                                        component={CustomInputComponent}
                                        disabled={isReadonly}
                                        onClick={handleFormChange}
                                    />
                                </div>} 
                                {isFieldPresent(SOURCE_STATE_KEY, viewData) && newCustomDropDown(viewData, SOURCE_STATE_KEY, lookupValues,  WORK_SOURCE_LOOKUP, isReadonly, errors, handleFormChange, undefined)}
                                {isFieldPresent(SOURCE_TYPE_STATE_KEY, viewData) && newCustomDropDown(viewData, SOURCE_TYPE_STATE_KEY, lookupValues,  WORK_SOURCE_TYPE_LOOKUP, isReadonly, errors, handleFormChange, undefined)}
                                {!isHiddenSocietyFormik && isFieldPresent(SOURCE_SOCIETY_STATE_KEY, viewData) && newCustomDropDown(viewData, SOURCE_SOCIETY_STATE_KEY, lookupValues,  AGENCY_LOOKUP, isReadonly, errors, handleFormChange, undefined)}
                                
                                {!isHiddenIPFormik && isFieldPresent(SOURCE_IP_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem`} onClick={() => onClick(SOURCE_IP_STATE_KEY)} >
                                    <label>{viewData[SOURCE_IP_STATE_KEY].data}</label>
                                    <Field 
                                        label={viewData[SOURCE_IPI_NUMBER_STATE_KEY]}
                                        name={SOURCE_IP_STATE_KEY}
                                        component={CustomInputComponent}
                                        disabled={false}
                                        onClick={handleFormChange}
                                    />
                                </div>
                                        }
                                        {!isHiddenIPFormik && isFieldPresent(SOURCE_IPI_NUMBER_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem`}>
                                    <label>{viewData[SOURCE_IPI_NUMBER_STATE_KEY].data}</label>
                                    <Field 
                                        label={viewData[SOURCE_IPI_NUMBER_STATE_KEY]}
                                        name={SOURCE_IPI_NUMBER_STATE_KEY}
                                        component={CustomInputComponent}
                                        disabled={false}
                                        onClick={handleFormChange}
                                    />
                                    </div> }
                                    {isFieldPresent(REG_DATE_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem`}>
                                        <label>{viewData[REG_DATE_STATE_KEY].data}</label>
                                        <DatePickerFormikField label={viewData[REG_DATE_STATE_KEY].data} name={REG_DATE_STATE_KEY} isReadonly={isReadonly} onChange={handleFormChange} size={'large'}/>
                                    </div>}
                                    {isFieldPresent(COMPOUND_TYPE_STATE_KEY, viewData) && newCustomDropDown(viewData, COMPOUND_TYPE_STATE_KEY, lookupValues,  WORK_MEDLEY_TYPE_LOOKUP, isReadonly, errors, handleFormChange, undefined)}
                                    {isFieldPresent(GENRE_STATE_KEY, viewData) && newCustomDropDown(viewData, GENRE_STATE_KEY, lookupValues,  WORK_GENRE_LOOKUP, isReadonly, errors, handleFormChange, undefined)} 
                                    {isFieldPresent(FIRST_PERFORMANCE_INDICATOR_STATE_KEY, viewData) && newCustomDropDown(viewData, FIRST_PERFORMANCE_INDICATOR_STATE_KEY, lookupValues,  WORK_GENRE_LOOKUP, isReadonly, errors, handleFormChange, undefined)} 
                                    {isFieldPresent(DISTRIBUTION_CATEGORY_STATE_KEY, viewData) && viewData[DISTRIBUTION_CATEGORY_STATE_KEY].data && newCustomDropDown(viewData, DISTRIBUTION_CATEGORY_STATE_KEY, lookupValues,  WORK_DISTRIBUTION_CATEGORY_LOOKUP, isReadonly, errors, handleFormChange, undefined)} 
                                    {isFieldPresent(WORK_PARTS_STATE_KEY, viewData) && newCustomDropDown(viewData, WORK_PARTS_STATE_KEY, lookupValues,  WORK_PARTS_LOOKUP, isReadonly, errors, handleFormChange, undefined)} 
                                    {isFieldPresent(WORK_TYPE_STATE_KEY, viewData) && 
                                        <SizedDropdownDataInputFormik
                                            label={viewData[WORK_TYPE_STATE_KEY].data}
                                            fieldName={WORK_TYPE_STATE_KEY}
                                            useComboBoxAsMenuWidth={true}
                                            options={workTypeDropDownLookup()}
                                            allowNull={true}
                                            readOnly={isReadonly}
                                            isHidden={!viewData[WORK_TYPE_STATE_KEY].data}
                                            errors={errors}
                                            onChange={handleFormChange}
                                        />
                                    } 
                                    {isFieldPresent(MUSIC_SOURCE_STATE_KEY, viewData) && newCustomDropDown(viewData, MUSIC_SOURCE_STATE_KEY, lookupValues,  MUSIC_SOURCE_LOOKUP, isReadonly, errors, handleFormChange, undefined)} 
                                    {isFieldPresent(WORK_CREATION_DATE_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem`}>
                                        <label>{viewData[WORK_CREATION_DATE_STATE_KEY].data}</label>
                                        <DatePickerFormikField label={viewData[WORK_CREATION_DATE_STATE_KEY].data} name={WORK_CREATION_DATE_STATE_KEY} isReadonly={isReadonly} onChange={handleFormChange} size={'large'}/>
                                        </div>}
                                        {values.isArrangement && isFieldPresent(MUSIC_ARRANGEMENT_STATE_KEY, viewData) && newCustomDropDown(viewData, MUSIC_ARRANGEMENT_STATE_KEY, lookupValues, MUSIC_ARRANGEMENT_LOOKUP, isReadonly, errors, handleFormChange, undefined)}
                                        {values.isArrangement && isFieldPresent(LYRIC_ADAPTATION_STATE_KEY, viewData) && newCustomDropDown(viewData, LYRIC_ADAPTATION_STATE_KEY, lookupValues, LYRIC_ADAPTATION_LOOKUP, isReadonly, errors, handleFormChange, undefined)} 
                                        {isFieldPresent(DURATION_STATE_KEY, viewData) && <div className={`${REPERTOIRE_PAGE_FORM_ITEM_SIZE_WITH_ROOM_FOR_VALIDATION} formItem`}>
                                        <label>{viewData[DURATION_STATE_KEY].data}</label>
                                            <Field
                                                component={CustomDurationPicker}
                                                name={DURATION_STATE_KEY}
                                                hasFeedback
                                                onClick={handleFormChange}
                                            />
                                    </div>}
                                    {renderPostBatch()}
                                    </div>
                        </>
                 <>
                 <hr />
                 <div className={findErrorsWorkTerritorySpecificInfoArray(errors) ? 'validationForkmikField' : 'emptyValidationBorder'}>
                 {findErrorsWorkTerritorySpecificInfoArray(errors) && <div className=" alert alert-dismissible fade in alert-danger">
                            <div>
                                {'Some required fields are missing in regions'}
                            </div>
                        </div>}
                 <FieldArray
                        name="workTerritorySpecificInfo"
                        render={(arrayHelpers) => {
                            arrayHelperRef.current = arrayHelpers;
                            return values.workTerritorySpecificInfo.map((workTerritorySpecificInfo, index) =>  renderRegionRow(index, errors, workTerritorySpecificInfo, setFieldValue))
                        }}
                            />
                            </div>
                            <hr />
                    </>
                    </div>}
                </Form>)}}
                        </Formik>
                 </>
                ) : <></>}
    </>)
}

export default WorkMaintenanceGeneralDataViewFormik;
