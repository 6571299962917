// Groups
export const WORK_MAINTENANCE_GROUP: string = "WorkMaintenance";
export const PRODUCT_MAINTENANCE_GROUP: string = "ProductMaintenance";
export const AGREEMENT_MAINTENANCE_GROUP: string = "AgreementMaintenance";
export const INTERESTED_PARTY_MAINTENANCE_GROUP: string = "IPMaintenance";
export const INGESTION_GROUP: string = "Ingestion";
export const USAGE_MAINTENANCE_GROUP: string = "UsageMaintenance";
export const DISTRIBUTION_MAINTENANCE_GROUP: string = "DistributionMaintenance";
export const IPI_CONFIGURATION_GROUP: string = "IPIConfiguration"
export const MY_PACKAGE_MAINTENANCE_GROUP = "MyPackagesMaintenance";

// Keys
export const CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY: string = "FieldFormat";
export const CONFIGURATION_PARAMETER_USERELATIONSHIPS_KEY:string ="UseRelationships"

// Work Maintenance Keys
export const DISPLAY_CONTRIBUTORS_COLUMNS_KEY: string = "DisplayContributorsColumns";
export const DEFAULT_CONTRIBUTORS_TAB_TO_OPEN_KEY: string = "DefaultContributorsTabToOpen";
export const FULL_TIME_DURATION_FORMAT_KEY: string = "fullTime";
export const SECONDS_DURTION_FORMAT_KEY: string = "seconds";
export const CONFIGURATION_PARAMETER_AGREEMENT_SHARE_DISPLAY_KEY: string = "AgreementShareDisplayOptions";
export const CONFIGURATION_PARAMETER_CUSTOMER_SOCIETY_CODE: string = "CustomerSocietyCode";
export const CONFIGURATION_PARAMETER_SHARE_DECIMALS_TO_DISPLAY: string = "ShareDecimalPlacesToDisplay";
export const CONFIGURATION_PARAMETER_FIELDS_EXCLUDED_FROM_COPY_WORK = 'FieldsExcludedFromCopyWork';
export const CONFIGURATION_PARAMETER_GET_UI_CONFIGURATION = 'UIConfiguration';
export const CONFIGURATION_PARAMETER_WRITER_CONTRIBUTOR_TYPES = 'WriterContributorTypes';
export const CONFIGURATION_PARAMETER_PUBLISHER_CONTRIBUTOR_TYPES = 'PublisherContributorTypes';
export const CONFIGURATION_PARAMETER_GENRE_CATEGORY_FROM_WORK_TYPE = 'GenreCategoryFromWorkType';
export const CONFIGURATION_PARAMETER_GENRE_CATEGORY_DEFAULTS_TYPES = 'GenereCategoryDefaults';
export const CONFIGURATION_PARAMETER_FILTER_GENRE_CATEGORY = "FilterGenreCategory";
export const CONFIGURATION_PARAMETER_ENABLE_CORE_WORK_NUMBERS = 'EnableCoreWorkNumbers';
export const WORK_FLAG_KEY: string = "WorkFlag";
export const READONLY_WORK_FLAG_KEY: string = "ReadonlyWorkFlags";
export const READONLY_FLAGS_FIELD_KEY: string = "ReadonlyFlagsField";
export const SHARE_TOLERANCE_FIELD_KEY: string = "ContributorShareTolerance";
export const READONLY_FLAG_DOMESTIC_OR_FOREIGN: string = "RecalculateDomesticorForeign";

// Product Maintenance Keys
export const CONFIGURATION_PRODUCT_MAINTENANCE_SUBMISSION_KEY: string = "Submission";

// Usage Maintenance Keys
export const CONFIGURATION_PARAMETER_USAGE_GRID_SEARCH_RESULT_DISPLAY_OPTIONS: string = "UseNewUIGridSearchResults";
export const CONFIGURATION_PARAMETER_WORK_GENERAL_VIEW_DISPLAY_OPTIONS: string = "UseNewUIWorkMaintenanceGeneralView";
export const CONFIGURATION_PARAMETER_PRODUCT_GENERAL_VIEW_DISPLAY_OPTIONS: string = "UseNewUIProductMaintenanceGeneralView";
export const CONFIGURATION_PARAMETER_POOL_GENERAL_VIEW_DISPLAY_OPTIONS: string = "UseNewUIPoolMaintenanceGeneralView";
export const CONFIGURATION_PARAMETER_USAGE_MATCHING_DEFAULTS_PRODUCTS: string = "UsageMatchingSearchDefaultsProducts";
export const CONFIGURATION_PARAMETER_USAGE_MATCHING_DEFAULTS_WORKS: string = "UsageMatchingSearchDefaultsWorks";
export const CONFIGURATION_PARAMETER_USAGE_REGROUP_AND_DELETE_DISPLAY_OPTIONS: string = "EnableRegroupAndDeleteUI";

// Agreement Maintenance Keys
export const CONFIGURATION_PARAMETER_SHOW_COMMENT_SUBJECT_AS_DROPDOWN_KEY: string = "ShowCommentSubjectAsDropdown";
export const CONFIGURATION_PARAMETER_EXCLUDE_SOURCE_TYPE_PICK_SHOW_FIELDS_KEY: string = "ExcludeSourceTypePickShowIPFields";

//Distribution Maintenance Keys
export const CONFIGURATION_PARAMETER_DISTRIBUTION_SETTINGS_VIEW_DISPLAY_OPTIONS: string = "JobStatusQA";

// IP Maintenance Keys
export const CONFIGURATION_PARAMETER_IP_GRID_SEARCH_RESULT_DISPLAY_OPTIONS: string = "UseNewUIGridIPSearchResults";

export const CONFIGURATION_PARAMETER_PD_ARRANGEMENT_MUSIC_LYRIC_OPTIONS: string = "PDArrangementMusicLyric";

export const CONFIGURATION_PARAMETER_ENABLE_COMMON_CUESHEETS: string = "EnableCommonCuesheets";

export const CONFIGURATION_PARAMETER_CUESHEETS_DATASOURCES: string = "CuesheetDataSources";

//My Package Maintenance key

export const MY_PACKAGE_MAINTENANCE_KEY: string = "MyPackagesConfiguration";