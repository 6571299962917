import React, { useEffect } from "react";
import { IAgreementState } from "../../../../redux/types/IAgreementState";
import IMembersPortalComponentDataItem from "../../../../redux/types/IMembersPortalComponentDataItem";
import { DRAFT_AGREEMENTS_AGREEMENT_SIGNATURE_DATE_STATE_KEY, DRAFT_AGREEMENTS_APPLICABLE_WORKS_TYPE_STATE_KEY, DRAFT_AGREEMENTS_ASSIGNED_SET_TYPES_STATE_KEY, DRAFT_AGREEMENTS_CATEGORY_STATE_KEY, DRAFT_AGREEMENTS_CREATION_DATE_STATE_KEY, DRAFT_AGREEMENTS_EFFECTIVE_FROM_STATE_KEY, DRAFT_AGREEMENTS_EFFECTIVE_TO_STATE_KEY, DRAFT_AGREEMENTS_IP_NAME_NUMBER_STATE_KEY, DRAFT_AGREEMENTS_IP_NAME_STATE_KEY, DRAFT_AGREEMENTS_PUBLISHER_AGREEMENT_NUMBER_STATE_KEY, DRAFT_AGREEMENTS_SOCIETY_AFFILIATION_STATE_KEY, DRAFT_AGREEMENTS_SOCIETY_AGREEMENT_NUMBER_STATE_KEY, DRAFT_AGREEMENTS_STATUS_STATE_KEY, DRAFT_AGREEMENTS_TERRITORY_STATE_KEY, DRAFT_AGREEMENTS_TYPE_STATE_KEY, USE_RELATIONSHIPS_STATE_KEY } from "../../../Consts";
import DateDisplay from "../../../../repertoire/components/dateDisplay/DateDisplay";
import { AGREEMENT_CATEGORY_LOOKUP, AGREEMENT_TYPE_LOOKUP, APPLICABLE_WORKS_LOOKUP, SET_TYPE_GROUP_LOOKUP, SET_TYPE_LOOKUP, TERRITORY_LOOKUP, WORK_GENRE_LOOKUP } from "../../../../lookup/Consts";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { DataSource } from "../../../../repertoire/types/DataSource";
import { getLookupValues } from "../../../../lookup/services/LookupHelpers";
import { lookup } from "dns";
import { SizedCheckboxDataInput } from "../../../../repertoire/components/checkboxDataInput/CheckboxDataInput";
import { IRepertoireStateKeys } from "../../../../repertoire/types/IRepertoireStateKeys";

interface IDraftAgreementsGeneralReviewProps {
    agreement: IAgreementState;
    draftAgreementMaintenanceGeneralDataViewData: IMembersPortalComponentDataItem;
    lookupValues: ILookupDictionary;
    dataSource?:string;
}

const ReadOnlyLabelAndData = (label, value) => {
    return  <div className="form-group col-md-4 col-sm-6 col-xs-12">
        <div className="flexColumn">
    <label >{label}</label>
    {value}
    </div>
    </div>
}

const getReadValueFromLookup = (lookupName, lookupValues, value) => {
    let result = lookupValues[lookupName] ? lookupValues[lookupName].lookups : undefined;

    if (result) {
        let lookup = result.find(lookup => lookup.code === value);
        return lookup ? lookup.description : '';
    }

    return '';

}

const getReadValueFromLookupTerritory = (lookupName, lookupValues, values) => {
    let result = lookupValues['Territory'] ? lookupValues['Territory'].lookups : undefined;

    if (result) {
        let lookup = result.find(lookup => {
            let isFound = false;
            if (values && values.length > 0) {
             values.forEach(stringValue => {
                if (lookup.code === stringValue) {
                    isFound = true;
                }
            })
            return isFound;
        }

        });
        return lookup ? lookup.description : '';
    }

    return '';
}

const ReadOnlyLabelAndDate = (label, value, showTime) => {
    return  <div className="form-group col-md-4 col-sm-6 col-xs-12">
    <div className="flexColumn">
        <label >{label}</label>
        <DateDisplay value={value} showTime={showTime} />
        </div>
    </div>
}

const ReadOnlyLabelAndCheckbox = (label, value) => {
    return (<>
        <SizedCheckboxDataInput
            label={label}
            fieldName={USE_RELATIONSHIPS_STATE_KEY}
            value={value}
            isHidden={false}
            readonly={true} 
            changeData={null}    
             />
        </>
    );
};

const getStatusDescription = (status: string, lookupValues: ILookupDictionary) => {
    const statusCodeLookup = lookupValues['AgreementStatus'].lookups.filter(lookup => lookup.code === status);
    if (statusCodeLookup.length == 0) {
        const statusTypeIDLookup = lookupValues['AgreementStatus'].lookups.filter(lookup => lookup.typeID.toString() === status);
        if (statusTypeIDLookup.length > 0) {
            return statusTypeIDLookup[0].description;
        }
        return status;
    }
    return statusCodeLookup[0].description;
}

const DraftAgreementsGeneralReview: React.FC<IDraftAgreementsGeneralReviewProps> = ({
    agreement,
    draftAgreementMaintenanceGeneralDataViewData,
    lookupValues,
    dataSource
}) => {
    const [viewData, setViewData] = React.useState([]);


useEffect(() => {
    if (draftAgreementMaintenanceGeneralDataViewData?.fields) {
        const visibleFields = draftAgreementMaintenanceGeneralDataViewData.fields.filter(field => !field.hidden);
        let newViewData = [];
        visibleFields.forEach(item => {
            newViewData[item.name] = item.data;
        });
        setViewData(newViewData);
    }

}, [agreement, draftAgreementMaintenanceGeneralDataViewData]);
    let updatedTerritoryCodes=[];
    const lookupValsTerritories = getLookupValues(TERRITORY_LOOKUP, lookupValues);
        if(agreement.agreementTerritory && agreement.agreementTerritory.length){
            agreement.agreementTerritory.map(x => {
                updatedTerritoryCodes.push(x.include ? `I: ${lookupValsTerritories.find(item => item.code === x.territory).code}` : (`E: ${lookupValsTerritories.find(item => item.code === x.territory).code}`))
            })
        }


    return <>
        <div className="headerContainer">
            <div
                className={["headerSection", "activeSection"].join(" ")}
                key={'General'}
                data-testid={'General' + "-section"}
            >
                {'General'}
            </div>

            </div>

            <div className="row">
            {ReadOnlyLabelAndData(viewData[DRAFT_AGREEMENTS_IP_NAME_NUMBER_STATE_KEY], agreement?.ipNameNumber ? agreement.ipNameNumber : '')}
            {ReadOnlyLabelAndData(viewData[DRAFT_AGREEMENTS_IP_NAME_STATE_KEY], agreement?.ipName ? agreement.ipName : '')}
            {ReadOnlyLabelAndData(viewData[DRAFT_AGREEMENTS_SOCIETY_AFFILIATION_STATE_KEY], agreement?.societyAffiliation ? agreement.societyAffiliation : '')}
            </div>
            <div className="row">
            {ReadOnlyLabelAndData(viewData[DRAFT_AGREEMENTS_TYPE_STATE_KEY], agreement?.type ? getReadValueFromLookup(AGREEMENT_TYPE_LOOKUP, lookupValues, agreement.type) : '')}
            {ReadOnlyLabelAndData(viewData[DRAFT_AGREEMENTS_CATEGORY_STATE_KEY], agreement?.agreementCategory ? getReadValueFromLookup(AGREEMENT_CATEGORY_LOOKUP, lookupValues, agreement.agreementCategory) : '')}
            {ReadOnlyLabelAndData(viewData[DRAFT_AGREEMENTS_ASSIGNED_SET_TYPES_STATE_KEY], agreement?.agreementShareSet ? (agreement?.agreementShareSet.map(obj => getReadValueFromLookup(SET_TYPE_LOOKUP, lookupValues, obj.setType)).join(',')) : '')}
            </div>
            <div className="row">
            {ReadOnlyLabelAndData(viewData[DRAFT_AGREEMENTS_EFFECTIVE_FROM_STATE_KEY], agreement?.effectiveFrom ?((new Date(((agreement?.effectiveFrom) as unknown as string).split('T')[0])).toLocaleDateString('en-GB')) : '')}
            {ReadOnlyLabelAndData(viewData[DRAFT_AGREEMENTS_EFFECTIVE_TO_STATE_KEY], agreement?.effectiveTo ? ((new Date(((agreement?.effectiveTo) as unknown as  string).split('T')[0])).toLocaleDateString('en-GB')): '')}
            {dataSource=== DataSource.Intray && ReadOnlyLabelAndData(viewData[DRAFT_AGREEMENTS_TERRITORY_STATE_KEY], agreement?.agreementTerritory ? updatedTerritoryCodes.map(obj => obj).join(', '): '')}
            </div>
            <div className="row">
            {ReadOnlyLabelAndDate(viewData[DRAFT_AGREEMENTS_AGREEMENT_SIGNATURE_DATE_STATE_KEY], agreement?.agreementSignatureDate ? agreement?.agreementSignatureDate : '', false)}
            {ReadOnlyLabelAndData(viewData[DRAFT_AGREEMENTS_STATUS_STATE_KEY], agreement?.status ? getStatusDescription(agreement.status, lookupValues) : '')}
            {ReadOnlyLabelAndData(viewData[DRAFT_AGREEMENTS_APPLICABLE_WORKS_TYPE_STATE_KEY], agreement?.applicableWorksType ?  getReadValueFromLookup(APPLICABLE_WORKS_LOOKUP, lookupValues, agreement?.applicableWorksType) : '-' )}
            </div>
            <div className="row">
            {ReadOnlyLabelAndData(viewData[DRAFT_AGREEMENTS_SOCIETY_AGREEMENT_NUMBER_STATE_KEY], (agreement.agreementNumbers && agreement.agreementNumbers.length > 0) ? agreement.agreementNumbers.find(item => item.numberType.value === 'AgreementRef' || item.numberType.value === 'SOCAN').number.value : '-')}
            {ReadOnlyLabelAndDate(viewData[DRAFT_AGREEMENTS_CREATION_DATE_STATE_KEY], agreement?.creationDate ? agreement.creationDate : '', false)}
            {ReadOnlyLabelAndData('Region', agreement?.agreementRegion && agreement?.agreementRegion.length > 0 ? agreement?.agreementRegion.map((region, index) => `${getReadValueFromLookup(SET_TYPE_GROUP_LOOKUP, lookupValues, region.setTypeGroup)}${index +1 !== agreement?.agreementRegion.length ? ', ' : ''}`) : '')}
           </div>
           <div className="row">
            {ReadOnlyLabelAndCheckbox(viewData[USE_RELATIONSHIPS_STATE_KEY], agreement?.useRelationships ? agreement?.useRelationships : '')}
           </div>


    </>
}

export default DraftAgreementsGeneralReview;