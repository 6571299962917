import * as React from "react";
import { IArtist } from "../../types/IArtist";
import { addArtistToWork, updateAgreementIP, updateAgreementWork, addWorkToProduct, addArtistToProduct, updateRelatedWork, updateWorkSourceIP, addIPToAdjustmentDetails, addWorkToLicenseRequest, addIPToUsageDetails, updateProductSourceIP, addWorkToClaimRequest } from "../../../redux/reducers/RepertoireReducer";
import TableHeaderSection from "../tableHeaderSection/TableHeaderSection";
import { CopyTableContents } from "../../services/TableFunctions";
import IRepertoireComponentDataItem from "../../../redux/types/IRepertoireComponentDataItem";
import { IRepertoireField } from "../../types/IRepertoireField";
import ActionButton from "../actionButton/ActionButton";
import PaginationView from "../paginationView/PaginationView";
import {
    ADD_NEW_ACTION,
    UPDATE_ACTION,
    SEARCH_VIEW_CONTRIBUTORS,
    SEARCH_VIEW_ARTISTS,
    SEARCH_VIEW_AGREEMENT_IP,
    OPEN_ACTION,
    VERSION_HISTORY_VIEW,
    SEARCH_VIEW_WORKS,
    SEARCH_VIEW_PRODUCTS,
    IPI_NUMBER_STATE_KEY,
    ASSING_WORKFLOW_VIEW,
    EMPTY_STRING_VALUE,
    DISTRIBUTION_VERSION_HISTORY_VIEW,
    SEARCH_VIEW_LICENSE_USER,
    SEARCH_VIEW_WORK_LICENSES,
    SEARCH_VIEW_LICENSES,
    SEARCH_VIEW_LICENSES_FILEIMPORT,
    SEARCH_VIEW_WORK_CLAIMS,
    SEARCH_VIEW_CLAIMS,
    SEARCH_VIEW_SUBJECTS,
    SEARCH_VIEW_IPS
} from "../../Consts";
import { hideModal } from "../../../redux/reducers/ModalReducer";
import { IP } from "../../types/IP";
import { IContributorItem } from "../../types/IContributorItem";
import { IWorksSearchResult } from "../../types/IWorksSearchResult";
import { RepertoireHelper } from "../../services/RepertoireHelper";
import { IStoragePathItemData } from '../../../dataingestion/types/IStoragePathItemData';
import { ILicenseUserSearchResult } from "../../types/ILicenseUserSearchResult";
import { IProductSearchResult } from "../../types/IProductSearchResult";
import { DRAFT_AGREEMENTS_APPLICABLE_WORKS_STATE_KEY, SEARCH_VIEW_REQUEST_ACCESS, SET_LISTS_WORK_SEARCHVIEW_STATE_KEY, SET_LIST_MODAL_SEARCH_VIEW } from "../../../membersportal/Consts";
import { IShoppingCartSearchResult } from "../../types/IShoppingCartSearchResult";
import { IDraftWorkSearchResult } from "../../../membersportal/types/IDraftWorkSearchResult";
import { IDraftSetListSearchResult } from "../../../membersportal/types/IDraftSetListSearchResult";
import { IWorkMaintenanceSubject } from "../../types/IWorkMaintenanceSubjects";
import { ISubject } from "../../types/ISubject";
import DateDataInput from "../dateDataInput/DateDataInput";
import DateDisplay from "../dateDisplay/DateDisplay";
import { IAgreementStateKeys } from "../../../redux/types/IAgreementState";
import { ICwrgParameters } from "../../../redux/types/ICwrgParameters";
import { updateDraftAgreementWorkField } from "../../../redux/reducers/MembersPortalReducer";
import { InputNumber } from "antd";
import { SizedCheckboxDataInput } from "../../components/checkboxDataInput/CheckboxDataInput";

export interface ISearchResultsTableProps {
    searchResultsTableData: IRepertoireComponentDataItem;
    tableContents: any;
    componentInstance: string;
    addArtistToWork?: typeof addArtistToWork;
    addArtistToProduct?: typeof addArtistToProduct;
    addWorkToProduct?: (work: IWorksSearchResult) => void;
    updateWorkContributorIP?: (workContributor: IContributorItem, ip: IP) => void;
    updateProductContributorIP?: (productContributorID: number, ip: IP) => void;
    updateAgreementIP?: (agreementAdminIPID: number, ip: IP) => void;
    updateManualJobParams?: (params: any) => void;
    manualJobParameters?: any;
    updateAgreementWork?: (work: IWorksSearchResult) => void;
    updateAgreementCarveOutProduct?: (agreementProductWorkID: number, product: IProductSearchResult) => void;
    updateMatchingWork?: (workID: number, work: IWorksSearchResult) => void;
    updateMatchingProduct?: (productID: number, product: IProductSearchResult) => void;
    updateSubjects?: (subject: ISubject) => void;
    updateRelatedWork?: typeof updateRelatedWork;
    updateWorkSourceIP?: typeof updateWorkSourceIP;
    updateProductSourceIP?: typeof updateProductSourceIP;
    openHistoryVersion?: (entityId: number, versionNumber: number, mergedWork: boolean) => void;
    sourceItem?: any;
    hideModal: typeof hideModal;
    expandedResults?: number[];
    expandAll?: boolean;
    expandResult?: (result: number) => void;
    expandAllResults?: () => void;
    onAssingWorkflows?: (username: string) => void;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    repertoireSection?: string;
    sortSearchResults?: (name: string, results: any) => void;
    updateStoragePathItemIP?: (storagePathItemData: IStoragePathItemData, ip: IP) => void;
    addIpToAdjustmentDetail?: typeof addIPToAdjustmentDetails;
    addIPToUsageDetails?: typeof addIPToUsageDetails;
    licenseUserSearchResults?: ILicenseUserSearchResult[];
    updateLicenceUserValues?: (params: any) => void;
    addWorkToLicenseRequest?: typeof addWorkToLicenseRequest;
    requestViewAsAccess?: (requestIP: IContributorItem) => void;
    updateLicenseImportShoppingCart?: (storagePathItemData: IStoragePathItemData, shoppingCart: IShoppingCartSearchResult) => void;
    addSetListToLivePerformance?: (setList: IDraftSetListSearchResult) => void;
    addWorkToSetList?: (work: IDraftWorkSearchResult) => void;
    getProductDetails?: (dataSource: string, workID: number, openNewTab?: boolean) => void;
    addWorkToClaimRequest?: typeof addWorkToClaimRequest;
    modalIndex?: number;
    updateDraftAgreementField?: (value: any, fieldName: IAgreementStateKeys, index?: number) => void;
    updateDraftAgreementWorkField?:(value: any, fieldName: IAgreementStateKeys, index?: number)=>void;
    index?: number;
    totalWorkflows?: number;
    updateUsers?: (index: number, value: any, fieldName: string) => void;
}

interface ISearchResultsTableState {
    tableContents: any;
    activeSortHeaderSection: string;
    currentPage: number;
    resultsPerPage: number;
    indexOfFirstResult: number;
    indexOfLastResult: number;
    isFieldEnabled: any;
}

export default class SearchResultsTable extends React.PureComponent<
    ISearchResultsTableProps,
    ISearchResultsTableState
> {
    constructor(props: ISearchResultsTableProps) {
        super(props);

        this.state = {
            tableContents: props.tableContents,
            activeSortHeaderSection: undefined,
            indexOfFirstResult: this.props.indexOfFirstResult ? this.props.indexOfFirstResult : 0,
            indexOfLastResult: this.props.indexOfLastResult ? this.props.indexOfLastResult : 10,
            resultsPerPage: this.props.resultsPerPage ? this.props.resultsPerPage : 10,
            currentPage: this.props.currentPage ? this.props.currentPage : 1,
            isFieldEnabled: {},
        };
    }    

    public ascending: boolean = false;

    componentDidUpdate = (prevProps: ISearchResultsTableProps) => {
        const { tableContents } = this.props;

        if (prevProps.tableContents !== tableContents) {
            this.setState({ tableContents });
        }
    };

    sortTableByColumn = (section: string) => {
        const { componentInstance, sortSearchResults } = this.props;
        const { activeSortHeaderSection, tableContents } = this.state;
        const tableContentsCopy = CopyTableContents(tableContents);

        if (componentInstance === VERSION_HISTORY_VIEW)
            return;
        if (activeSortHeaderSection !== section) {
            this.ascending = false;
        }

        tableContentsCopy.sort((a: any, b: any) => {
            // set to emtpy string if null
            let newA = a[section] || "";
            let newB = b[section] || "";

            if (componentInstance === SEARCH_VIEW_ARTISTS) {
                newA = newA.value ? newA.value : "";
                newB = newB.value ? newB.value : "";
            }

            if (!this.ascending) {
                return newA.toString().localeCompare(newB.toString(), undefined, { 'numeric': true });
            }
            return newB.toString().localeCompare(newA.toString(), undefined, { 'numeric': true });

        });

        this.setState({
            tableContents: tableContentsCopy,
            activeSortHeaderSection: section
        });

        this.ascending = !this.ascending;

        if (sortSearchResults) {
            sortSearchResults(componentInstance, tableContentsCopy);
        }
    };

    onClickAdd = (tableContentItem: any) => {
        const { addArtistToWork, addArtistToProduct, hideModal, sourceItem, modalIndex } = this.props;
        if (addArtistToWork) {
            addArtistToWork(tableContentItem, sourceItem != null ? sourceItem.workPerformerID : -1);
            hideModal();
        }
        else {
            addArtistToProduct(tableContentItem, sourceItem ? sourceItem.productPerformerID : -1, modalIndex);
            hideModal();
        }
    };

    enableField = (index: number) => {
        const { updateUsers } = this.props;
        this.setState(prevState => {
            const updatedFieldState = {
                ...prevState.isFieldEnabled,
                [index]: !prevState.isFieldEnabled[index]
            };
            updateUsers(index, updatedFieldState[index], "assigned");
             return {isFieldEnabled: updatedFieldState}
        }, () => {
            if (!this.state.isFieldEnabled[index]) {
                this.changeData(index, "numberOfWorkflows", null)
            }
        })
    };

    changeData = (index: number, fieldName: string, newValue: string) => {
        const { updateUsers } = this.props;
        const { tableContents } = this.state;
        const updatedTableContents = [...tableContents];
        updatedTableContents[index][fieldName].value = newValue;
        updateUsers(index, newValue, fieldName);
        this.setState({ tableContents: updatedTableContents });
    };

    onClickUpdate = (tableContentItem: any, index?: any) => {
        const { sourceItem,
            componentInstance,
            updateWorkContributorIP,
            updateAgreementIP, 
            updateAgreementWork,
            updateAgreementCarveOutProduct,
            updateMatchingWork,
            updateMatchingProduct,
            updateProductContributorIP,
            addWorkToProduct,
            hideModal,
            updateRelatedWork,
            updateWorkSourceIP,
            updateProductSourceIP,
            onAssingWorkflows,
            updateStoragePathItemIP,
            addIpToAdjustmentDetail,
            addIPToUsageDetails,
            updateManualJobParams,
            manualJobParameters,
            licenseUserSearchResults,
            updateLicenceUserValues,
            addWorkToLicenseRequest,
            requestViewAsAccess,
            updateLicenseImportShoppingCart,
            addSetListToLivePerformance,
            addWorkToSetList,
            addWorkToClaimRequest,
            updateSubjects,
            getProductDetails,
            updateDraftAgreementField,
            updateDraftAgreementWorkField,
            modalIndex
        } = this.props;
        if (updateWorkContributorIP) {
            updateWorkContributorIP(sourceItem, { ...tableContentItem });
            hideModal();
        }
        else if (addIpToAdjustmentDetail) {
            addIpToAdjustmentDetail(tableContentItem, sourceItem.adjustmentDetails.adjustmentDetailFrom[0].allocationHeaderID, sourceItem.index);
            hideModal();
        }
        else if (addIPToUsageDetails) {
            addIPToUsageDetails(tableContentItem, sourceItem.usageDetailsIndex, sourceItem.usageRowIndex, sourceItem.adjustmentsIndex);
            hideModal();
        }
        else if (componentInstance === SEARCH_VIEW_AGREEMENT_IP  ) {
            if (sourceItem.agreementAdminIPID !== undefined) {
                updateAgreementIP(sourceItem.agreementAdminIPID, { ...tableContentItem });
            }
            else {
                updateAgreementIP(-99999, { ...tableContentItem });
            }
            hideModal();
        }
        else if (componentInstance === DRAFT_AGREEMENTS_APPLICABLE_WORKS_STATE_KEY) {
            updateDraftAgreementWorkField(tableContentItem,'agreementWorks',modalIndex)
            hideModal();
        }
        else if(updateDraftAgreementField){
            updateDraftAgreementField(tableContentItem,'ipName')
            hideModal();
        }
        else if (componentInstance == SEARCH_VIEW_WORK_LICENSES && addWorkToLicenseRequest) {
            addWorkToLicenseRequest({ ...tableContentItem });
            hideModal();
        }
        else if (addWorkToProduct) {
            addWorkToProduct(tableContentItem);
            hideModal();
        }
        else if (updateMatchingWork) {
            updateMatchingWork(sourceItem.workID, { ...tableContentItem });
            hideModal();
        }
        else if (updateMatchingProduct) {
            updateMatchingProduct(sourceItem.productID, { ...tableContentItem });
            hideModal();
        }
        else if (updateProductContributorIP) {
            updateProductContributorIP(sourceItem.productContributorID, { ...tableContentItem });
            hideModal();
        }
        else if (updateRelatedWork) {
            updateRelatedWork({ ...tableContentItem });
            hideModal();
        }
        else if (updateWorkSourceIP) {
            updateWorkSourceIP({ ...tableContentItem });
            hideModal();
        }
        else if (updateProductSourceIP) {
            updateProductSourceIP({ ...tableContentItem });
            hideModal();
        }
        else if (componentInstance === ASSING_WORKFLOW_VIEW && onAssingWorkflows) {
            this.enableField(index)
        }
        else if (updateStoragePathItemIP) {
            updateStoragePathItemIP(sourceItem, { ...tableContentItem });
            hideModal();
        }
        else if (requestViewAsAccess) {
            requestViewAsAccess(tableContentItem);
        }
        else if (updateLicenseImportShoppingCart) {
            updateLicenseImportShoppingCart(sourceItem, { ...tableContentItem });
            hideModal();
        }
        else if (componentInstance == SEARCH_VIEW_WORK_CLAIMS && addWorkToClaimRequest) {
            addWorkToClaimRequest({ ...tableContentItem });
            hideModal();
        }
        else if (componentInstance === SEARCH_VIEW_SUBJECTS) {
            sourceItem.subject.value = tableContentItem.code;
            updateSubjects({ ...sourceItem })
            hideModal();
        }
        else if (updateManualJobParams) {
            if (componentInstance === 'job_ip') {
                if (manualJobParameters === '') {
                    updateManualJobParams({
                        jobType: 'BEXP',
                        jobParameters: '{"ipNameNumber":"' + tableContentItem.ipiNumber + '","isbnPrefix":"","formatExport":"","format":""}'
                    });
                } else {
                    let manualJob = JSON.parse(manualJobParameters.jobParameters);
                    manualJob.ipNameNumber = tableContentItem.ipiNumber;
                    updateManualJobParams({
                        jobType: manualJobParameters.jobType,
                        jobParameters: JSON.stringify(manualJob)
                    });
                }
            }
            hideModal();
        }
        else if (componentInstance === SEARCH_VIEW_LICENSE_USER) {
            updateLicenceUserValues({
                user: tableContentItem.email,
                crmUsers: licenseUserSearchResults
            });

            hideModal();
        }
        else if (addSetListToLivePerformance) {
            addSetListToLivePerformance(tableContentItem);
            hideModal()
        }
        else if (addWorkToSetList) {
            addWorkToSetList(tableContentItem);
            hideModal()
        }
        else if (updateAgreementCarveOutProduct) {
            getProductDetails('', tableContentItem.productCoreID, true)
            updateAgreementCarveOutProduct(sourceItem.agreementProductWorkID, { ...tableContentItem });
            hideModal();
        }
       
        else {
            updateAgreementWork(tableContentItem);
        }
    };

    onClickOpen = (tableContentItem: any, mergedWork: boolean) => {
        const { openHistoryVersion, componentInstance } = this.props;
        switch (componentInstance) {
            case VERSION_HISTORY_VIEW:
                openHistoryVersion(tableContentItem.workID, tableContentItem.versionNumber, mergedWork);
                break;
            case DISTRIBUTION_VERSION_HISTORY_VIEW:
                openHistoryVersion(tableContentItem.distributionID, tableContentItem.versionNumber, mergedWork);
                break;
            default:
                break;
        }
    }

    renderTotalWorkflows = () => {
        const { componentInstance, totalWorkflows } = this.props;
        if (componentInstance == ASSING_WORKFLOW_VIEW) {
            return (
                <div><p>Workflows to assign: {totalWorkflows}</p></div>
            );
        }
        return null;
    }

    renderExpandAll = () => {
        const { expandAll, expandAllResults, expandResult } = this.props;
        return (expandResult ?
            <div className='arrowIconExpandDiv'>
                <div className={expandAll ? "arrowIconExpandAllExpanded" : "arrowIconExpandAll"}>
                    <i className="icon ms-Icon ms-Icon--DoubleChevronLeft" aria-hidden="true" id="expandResultsButton" onClick={() => expandAllResults()} title={expandAll ? "Collapse Results" : "Expand Results"}></i>
                </div>
                {expandAll ? <div className='arrowIconExpandText'> Collapse Results</div> : <div className='arrowIconExpandText'> Expand Results</div>}
            </div> : <div></div>
        );
    }

    renderPaginationView(): JSX.Element {
        const { repertoireSection } = this.props;
        const { tableContents, resultsPerPage, currentPage } = this.state;
        return <PaginationView
            contentsLength={tableContents ? tableContents.length : 0}
            resultsPerPage={resultsPerPage}
            currentPage={currentPage}
            updateContentsTable={this.updateContentsTable.bind(this)}
            repertoireSection={repertoireSection}
        />
    }

    updateContentsTable(indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string): void {
        this.setState({ indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage });
        this.props.updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
    }

    renderHeaderSections = () => {
        const { searchResultsTableData, componentInstance } = this.props;
        const { activeSortHeaderSection } = this.state;
        if (searchResultsTableData && searchResultsTableData.fields) {
            const filteredFields = searchResultsTableData.fields.filter(
                (field: IRepertoireField) =>
                    field.componentInstance === componentInstance
            );
            const isAgreementPage = this.state.tableContents.some(item => item.agreementID !== undefined);
            const displayFields = isAgreementPage
                ? filteredFields.filter(x => x.name !== 'intrayWorkID')
                : filteredFields.filter(x => x.name !== 'intrayAgreementID');
           
                return displayFields.map((section: IRepertoireField, index: number) => (
                <td key={index} className="td">
                    <TableHeaderSection
                        section={section.data}
                        fieldName={section.name}
                        isSortingActive={section.name === activeSortHeaderSection}
                        ascending={this.ascending}
                        onClickHeaderSection={this.sortTableByColumn}
                        sortable
                    />
                </td>
                ));
        }
    };

    excludeObjectKeys = (key: string) => {
        const excludedKeys = ['compoundType', 'type', 'id1', 'id2', 'source', 'workContributorIDs', 'workID', 'status', 'productType', 'musicDuration'];

        if (excludedKeys.includes(key)) {
            return false;
        } else {
            return true;
        }
    }

    renderMultipleTitlesDiv = (array: string[]) => {
        return (
            <div className="multipleTitlesDiv">
                {array.map((item, index) => (
                    <div key={index}>
                        {item}
                        {index + 1 !== array.length ? <span>,</span> : null}
                    </div>
                ))}
            </div>
        );
    };

    getRowClassName = (tableContentItem: any) => {
        if (tableContentItem['actionType'] && tableContentItem['actionType'].startsWith('INTRAY WORK')) {
            return 'trSelectable intrayRow';
        }
        else {
            return 'trSelectable';
        }
    }

    renderSearchResults = () => {
        const { tableContents, indexOfFirstResult, indexOfLastResult, isFieldEnabled } = this.state;
        const { searchResultsTableData, componentInstance, expandedResults, expandResult, expandAll } = this.props;
        const currentResults = tableContents.length === 1 ? tableContents :  tableContents.slice(
            indexOfFirstResult,
            indexOfLastResult
        );
        let filteredFields = searchResultsTableData.fields.filter(
            (field: IRepertoireField) =>
                field.componentInstance === componentInstance
        );
        return currentResults.map(
            (tableContentItem: any, index: number) => {
                const sections: JSX.Element[] = [];
                const isAgreementPage = tableContentItem.agreementID !== undefined;
                const displayFields = isAgreementPage
                    ? filteredFields.filter(x => x.name !== 'intrayWorkID')
                    : filteredFields.filter(x => x.name !== 'intrayAgreementID');

                var openText = displayFields.find(x => x.fieldType == OPEN_ACTION);

                displayFields.map((field, x: number) => { 
                    if (field.fieldType === ADD_NEW_ACTION) {
                        sections.push(
                            <td key={`${index}.${x}`} className="td" title={field.data}>
                                <ActionButton
                                    buttonText={field.data}
                                    buttonAction={() => this.onClickAdd(tableContentItem)}
                                />
                            </td>
                        );
                    }
                    else if (field.fieldType === UPDATE_ACTION && componentInstance === SEARCH_VIEW_REQUEST_ACCESS) {
                        sections.push(
                            <td key={`${index}.${x}`} className="td" title={field.data}>
                                <div className="IconTextButton" data-toggle="modal" data-target="#settingsModal" onClick={() => this.onClickUpdate(tableContentItem)}>
                                    <i className="icon ms-Icon ms-Icon--FileRequest" aria-hidden="true"></i>
                                    Request Access
                                </div>
                            </td>
                        );
                    }
                    else if (field.fieldType === UPDATE_ACTION && componentInstance === ASSING_WORKFLOW_VIEW) {
                        sections.push(
                            <td key={`${index}.${x}`} className="td" title={field.data}>
                                <ActionButton
                                    buttonText={"Assign"}
                                    buttonAction={() => this.onClickUpdate(tableContentItem, index)}
                                />
                            </td>
                        );
                    }
                    else if (field.fieldType === UPDATE_ACTION) {
                        sections.push(
                            <td key={`${index}.${x}`} className="td" title={field.data}>
                                <ActionButton
                                    buttonText={field.data}
                                    buttonAction={() => this.onClickUpdate(tableContentItem,index)}
                                />
                            </td>
                        );
                    }

                    else if (field.fieldType === OPEN_ACTION && !tableContentItem["repertoireWorkID"]) {
                        sections.push(
                            <td key={`${index}.${x}`} className="td" title={field.data}>
                                <ActionButton
                                    buttonText={field.data}
                                    buttonAction={() => this.onClickOpen(tableContentItem, false)}
                                />
                            </td>
                        );
                    }
                    else if (field.name === "dateModified") {
                        if (tableContentItem.workID || tableContentItem.intrayWorkID || tableContentItem.repertoireWorkID || tableContentItem.intrayAgreementID || tableContentItem.agreementID || tableContentItem.distributionID || tableContentItem.poolID) {
                            sections.push(
                                <td key={`${index}.${x}`} className="td" title={field.data}>
                                    <div className="tableSection">
                                    {tableContentItem[field.name]}
                                    </div>
                                </td>
                            );
                        } else {
                        sections.push(
                            <td key={`${index}.${x}`} className="td" title={field.data}>
                                <div className="tableSection">
                                    <DateDisplay value={tableContentItem[field.name]} showTime={true} currentFormat="yyyy-mm-ddTHH:mm:ss.ms"  />
                                </div>
                            </td>
                        );
                    }
                     }
                    else if (field.name === "intrayWorkID") {
                        let button;
                        if (tableContentItem["intrayWorkID"] != null) {
                            button = <button className="button btn-defaultPrimary"
                                onClick={() => this.onClickOpen(tableContentItem, true)}>
                                {openText.data}
                            </button>
                        }
                        sections.push(
                            <td key={`${index}.${x}`} className="td" title={field.data} style={{ minWidth: '200px' }}>
                                <div className="tableSection">
                                    <label>{
                                        [
                                            "contributors",
                                            "administeredBy",
                                            "works",
                                            "agreement_ip",
                                            "registeredWorks",
                                            "draftWorks",
                                            VERSION_HISTORY_VIEW,
                                            DISTRIBUTION_VERSION_HISTORY_VIEW
                                        ].includes(componentInstance) ? tableContentItem[field.name] : tableContentItem[field.name].value}</label>
                                    {button}
                                </div>
                            </td>
                        );
                    }
                    else if (field.name === "numberOfWorkflows") {
                        sections.push(
                            <td key={`${index}.${x}`} className="td" title={field.data}>
                                    <div className="tableSection">
                                        <InputNumber 
                                            value={tableContentItem[field.name].value} 
                                            readOnly={!isFieldEnabled[index]} 
                                            disabled={!isFieldEnabled[index]} type={'number'} 
                                            size='large'
                                            onChange={(newValue) => this.changeData(index, field.name, newValue)} />
                                    </div> 
                            </td>
                        );
                    }
                    else if (field.name === "assigned") {
                        sections.push(
                            <td key={`${index}.${x}`} className="td" title={field.data}>
                                <div className="tableSection">
                                    <SizedCheckboxDataInput
                                        label={null}
                                        fieldName={null}
                                        value={isFieldEnabled[index]}
                                        readonly={true}
                                        changeData={(value: string | boolean, fieldName: string): void => {}}/>
                                </div> 
                            </td>
                        );
                    }
                    else {
                        let item;
                        if (componentInstance === SEARCH_VIEW_WORKS || componentInstance === 'draftAgreementsApplicableWorks' || componentInstance === SEARCH_VIEW_PRODUCTS || componentInstance === SET_LISTS_WORK_SEARCHVIEW_STATE_KEY ||componentInstance === DRAFT_AGREEMENTS_APPLICABLE_WORKS_STATE_KEY) {
                            if (!this.excludeObjectKeys(field.name)) {
                                item = tableContentItem[field.name];
                            }
                            else {
                                item = tableContentItem[field.name] ? tableContentItem[field.name][0] : '-';
                            }

                            if (item)
                                item = item.toString();
                        }
                        sections.push(
                            (componentInstance === SEARCH_VIEW_WORKS || componentInstance === 'draftAgreementsApplicableWorks' ||componentInstance === SEARCH_VIEW_PRODUCTS || componentInstance === SET_LISTS_WORK_SEARCHVIEW_STATE_KEY) ?
                                <td key={`${index}.${x}`} className="td" title={
                                    field.name in tableContentItem ? tableContentItem[field.name][0] : field.data}>
                                    <div className="arrowIconDiv">
                                        {(field.name === 'title' || field.name === 'type') && (
                                            <img
                                                src="assets/right-arrow.svg"
                                                title="Expand title"
                                                alt="Right Arrow icon"
                                                className={(expandedResults && expandedResults.includes(index)) ? "arrowIconExpanded" : "arrowIcon"}
                                                onClick={() => componentInstance !== 'draftAgreementsApplicableWorks'?expandResult(index): undefined}
                                            />
                                        )}
                                        {(expandedResults && expandedResults.includes(index)) && (this.excludeObjectKeys(field.name) && tableContentItem[field.name]?.length > 1) ? (
                                            this.renderMultipleTitlesDiv(tableContentItem[field.name])
                                        ) : (
                                            <div className="workTitle">
                                                {item ? item.replace(/,\s*$/, "") : item}
                                            </div>
                                        )}
                                    </div>
                                </td>
                                :
                                <td key={`${index}.${x}`} className={`td ${field.name === 'versionNumber' ? 'paddedVersion' : ''}`} title={field.data} >
                                    <div className="tableSection">
                                        <label>{
                                            field.name === IPI_NUMBER_STATE_KEY ? RepertoireHelper.zeroPaddedIPINameNumber(tableContentItem[field.name]) :
                                                [
                                                    "contributors",
                                                    "administeredBy",
                                                    "works",
                                                    "agreement_ip",
                                                    "artists",
                                                    "job_ip",
                                                    "license_user",
                                                    VERSION_HISTORY_VIEW,
                                                    DISTRIBUTION_VERSION_HISTORY_VIEW,
                                                    "requestAccess",
                                                    SEARCH_VIEW_WORK_LICENSES,
                                                    SEARCH_VIEW_LICENSES_FILEIMPORT,
                                                    "registeredWorks",
                                                    "draftWorks",
                                                    SET_LIST_MODAL_SEARCH_VIEW,
                                                    "productWorkSearch", 
                                                    SEARCH_VIEW_WORK_CLAIMS,
                                                    SEARCH_VIEW_CLAIMS,
                                                    SEARCH_VIEW_SUBJECTS,
                                                    SEARCH_VIEW_IPS
                                                ].includes(componentInstance) ? tableContentItem[field.name] : tableContentItem[field.name]?.value}</label>

                                    </div>
                                </td>)
                    }
                });

                return (
                    <tr
                        key={index}
                        className={this.getRowClassName(tableContentItem)}
                        onClick={() => null}
                    >
                        {sections}
                    </tr>
                );
            }
        );
    };
    
    render() {
        return (
            <div className="selectionTable">
                {this.renderExpandAll()}
                {this.renderPaginationView()}
                {this.renderTotalWorkflows()}
                <div className="tableContainer">
                    <table className="table">
                        <thead className="thead">
                            <tr className="tr">{this.renderHeaderSections()}</tr>
                        </thead>
                        <tbody className="tbody">{this.renderSearchResults()}</tbody>
                    </table>
                </div>
                {this.renderPaginationView()}
            </div>
        );
    }
}

