import { useMsal } from "@azure/msal-react";
import { throttle } from "lodash";
import * as React from "react";
import { msalConfig } from "../../accounts/types/msalAuthConfig";
import { ComponentsHelper } from "../../core/services/ComponentHelper";
import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";
import { hideModal, openassignWorkflowTo, showModal, showUpdateWorkflow } from "../../redux/reducers/ModalReducer";
import { clearWorkflowSelection, markSelectAll, nextWorkflowInSession, selectAllWorkflow, selectSingleWorkflow, setErrorWorkflowSession, startWorkflowSession } from "../../redux/reducers/RepertoireReducer";
import { FormatFields } from "../../redux/types/FormatFields";
import { IActiveAccordion } from "../../redux/types/IActiveAccordion";
import IRepertoireComponentDataItem from "../../redux/types/IRepertoireComponentDataItem";
import { IResultsPerPage } from "../../redux/types/IResultsPerPage";
import { ITabReduxItem } from "../../redux/types/ITabReduxItem";
import SearchView from "../components/searchView/SearchView";
import { getDataAction } from "../components/toolBar/ToolbarHelper";
import { CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, PRODUCT_MAINTENANCE_GROUP, WORK_MAINTENANCE_GROUP } from "../ConfigurationConsts";
import { ALL_ROLES, ASSING_TO_SEARCH_WORKFLOW_STATE_KEY, EMPTY_STRING_VALUE, EVENT_HANDLER_THROTTLE_TIME, REFERENCE_STATE_KEY, REFERENCE_VALUE_STATE_KEY, RFERENCE_KEY_VALUE_STATE_KEY, SEARCH_VIEW_WORKFLOW, UPDATE_WORKFLOW_VIEW_STATUS_FIELD_STATE_KEY, VALUE_KEY_VALUE_STATE_KEY, VALUE_MAX_STATE_KEY, VALUE_MIN_STATE_KEY, VALUE_STATE_KEY, VIEW_WORKFLOWS_ROLE, WORK_MAINTENANCE_TOOLBAR, WORK_TYPE, WORKFLOW_ENTITY_TYPE_STATE_KEY, WORKFLOW_TYPE_MATCHING_ERROR, WORKFLOW_TYPE_VALIDATION_ERROR, WORKFLOW_WORKFLOW_TYPE_STATE_KEY } from "../Consts";
import { SearchRequests } from "../services/SearchRequests";
import { IAssignWorkflowModel } from "../types/IAssignWorkflowModel";
import { IDataActionToolbar } from "../types/IDataActionToolbar";
import { IWorkflowSearchQuery } from "../types/IWorkflowSearchQuery";
import { IWorkflowSearchResult } from "../types/IWorkflowSearchResult";
import WorkflowPageToolbar from "./components/workflowPageToolbar/WorkflowPageToolbar";
import SearchResultsTable from "../components/searchResultsTable/SearchResultsTable";
import { IRepertoireField } from "../types/IRepertoireField";
import { SizedTextDataInput } from "../components/textDataInput/TextDataInput";
import { SizedDropdownDataInput } from "../components/dropdownDataInput/DropdownDataInput";
import IconTextButton from "../components/iconTextButton/IconTextButton";
import ActionButton, { SizedActionButton } from "../components/actionButton/ActionButton";
import { IMembersPortalField } from "../../membersportal/types/IMembersPortalField";
import SelectorView from "../components/selectorView/SelectorView";
import SearchViewKeyEventListener from "../components/searchView/SearchViewKeyEventListener";
import { IRepertoireStateKeys } from "../types/IRepertoireStateKeys";
import SizedDatePickerDataInput from "../components/dateDataInput/DatePickerDataInput";
import { getLookupValues, mapUsageLookupKey } from "../../lookup/services/LookupHelpers";

export interface IWorkflowsPageProps {
    workflowPageData: IRepertoireComponentDataItem;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    workflowPageToolbarData: IRepertoireComponentDataItem;
    lookupValues: ILookupDictionary;
    searchSuccessful?: boolean;
    workflowSearchResults: IWorkflowSearchResult[];
    workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    getWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], actionList: IDataActionToolbar[], readonlyIndicatorsWorkFlagTypes: string[], workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem, formats?: FormatFields[], openEntityForWorflowSession?: boolean) => void;
    getWorkDetailsWorkflow: (
        dataSource: string,
        workID: number,
        lookups: ILookupDictionary,
        otherIndicatorsWorkFlagTypes: string[],
        dataActions: IDataActionToolbar[],
        workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem,
        formats: FormatFields[],
        readonlyIndicatorsWorkFlagTypes: string[],
        openEntityForWorflowSession?: boolean,
        activeTab?: number, currentWorkflowIndex?: number,
        workflows?: IWorkflowSearchResult[],
        workGroup?: string) => void;
    searchWorkflow: (query: IWorkflowSearchQuery, modalOpen: boolean) => void;
    selectSingleWorkflow: typeof selectSingleWorkflow;
    hideModal: typeof hideModal;
    showModal: typeof showModal;
    activeTab?: number,
    tabs?: ITabReduxItem[];
    onOpenAssignView?: typeof openassignWorkflowTo;
    assingWorkflowDataView?: IRepertoireComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    assignWorkflows?: (model: IAssignWorkflowModel, searchBody: IWorkflowSearchQuery) => void;
    latestWorkflowSearchQuery: IWorkflowSearchQuery;
    updateStatusWorkflows?: (model: IAssignWorkflowModel, searchBody: IWorkflowSearchQuery) => void;
    updateWorkflowViewData?: IRepertoireComponentDataItem;
    onOpenUpdateWorkflows: typeof showUpdateWorkflow;
    clearSelection?: typeof clearWorkflowSelection;
    users: {
        code: string;
        description: string;
    }[];
    selectAllWorkflow?: typeof selectAllWorkflow;
    isSelectedAll?: boolean;
    markSelectAll?: typeof markSelectAll;
    getUsers?: () => void;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    startWorkflowSession: typeof startWorkflowSession;
    cancelWorkflowSession: (activeTab: number) => void;
    nextWorkflowItem?: typeof nextWorkflowInSession;
    skipWorkflowItem: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]
        , lookups: ILookupDictionary
        , otherIndicatorsWorkFlagTypes: string[]
        , dataActions: IDataActionToolbar[]
        , workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
        , formats: FormatFields[]
        , readonlyIndicatorsWorkFlagTypes: string[]) => void;
    workflowSessionItems?: IWorkflowSearchResult[];
    dataActionList?: IDataActionToolbar[];
    workFormats?: FormatFields[];
    getFieldFormats?: (key: string, group: string) => void;
    isAnySelectedCompletedError?: boolean;
    setErrorWorkflowSession?: typeof setErrorWorkflowSession;
    currentWorkflowIndex?: number;
    defaultActiveAccordions?: IActiveAccordion[];
    allResultsPerPage?: IResultsPerPage[];
    updateUserPreferences: (allResultsPerPage: IResultsPerPage[], activeAccordions: IActiveAccordion[], newActiveAccordionName?: string, accordionExpanded?: boolean, componentName?: string, indexOfFirstResult?: number, indexOfLastResult?: number, resultsPerPage?: number, repertoireSection?: string) => void;
    getUsageDetails: (usageID: string, openEntityForWorflowSession?: boolean) => void;
    skipUsageWorkflowItem: (activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]) => void;
    sortSearchResults: (name: string, results: any) => void;
    roles?: string[];
    saveRoles?: (roles: string[]) => void;
    getProductDetails: (dataSource: string, productID: number,
        productMaintenanceGeneralDataView: IRepertoireComponentDataItem,
        formats?: FormatFields[],
        openEntityForWorflowSession?: boolean) => void;
    productMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    getAgreementDetails: (dataSource: string,
        agreementID: number,
        lookups: ILookupDictionary,
        dataActions: IDataActionToolbar[],
        openEntityForWorflowSession?: boolean) => void;
    skipProductWorkflowItem: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]
        , dataActions: IDataActionToolbar[]
        , formats: FormatFields[]
    ) => void;
}

const MATCH = "Match";

const WorkflowPage = (props: IWorkflowsPageProps) => {
    const [scroll, setScroll] = React.useState(0)
    const [toolbarWidth, setToolbarWidth] = React.useState(null)
    const [loaded, setLoaded] = React.useState(false)
    const { instance, accounts } = useMsal()
    const [PageOne, setPageOne] = React.useState(0)
    const [referenceKeyValueRows, setReferenceKeyValueRows] = React.useState([{ referenceKey: '', referenceValue: '', index: 0 }]);
    const initialState = {
        entityName: EMPTY_STRING_VALUE,
        entityType: EMPTY_STRING_VALUE,
        status: EMPTY_STRING_VALUE,
        workflowType: WORKFLOW_TYPE_VALIDATION_ERROR,
        assignedTo: EMPTY_STRING_VALUE,
        referenceKeyValues: EMPTY_STRING_VALUE,
        valueKey: EMPTY_STRING_VALUE,
        valuesMax: EMPTY_STRING_VALUE,
        valuesMin: EMPTY_STRING_VALUE,
        fromDateCreated: EMPTY_STRING_VALUE,
        toDateCreated: EMPTY_STRING_VALUE,
        productType: EMPTY_STRING_VALUE,
        errorMessage: EMPTY_STRING_VALUE,
    }

    const [formState, setFormState] = React.useState(initialState);

    const addNewReferenceKeyValueRow = () => {
        setReferenceKeyValueRows([...referenceKeyValueRows, { referenceKey: '', referenceValue: '', index: referenceKeyValueRows.length }]);
    }

    const changeReferenceKeyValueRowData = (value, fieldName, index) => {

        setReferenceKeyValueRows(prevRows => {
           return  prevRows.map((row, i) => {
                if (i === index) {
                    if (fieldName === 'referenceKey') {
                        return {
                            ...row,
                            referenceKey: value,
                        };
                    } else {
                        return {
                            ...row,
                            referenceValue: value,
                        };
                    }
                }
                return row; 
            })
        }
        );
    };

    const removeReferenceKeyValueRow = (index: number) => {
        setReferenceKeyValueRows(prevRows => {
            const updatedRows = prevRows.filter((_, i) => i !== index);
            
            return updatedRows.map((row, i) => ({
                ...row,
                index: i,
            }));
        });
    };

    const changeData = (value: any, fieldName: IRepertoireStateKeys, index?: number) => {
        if (fieldName === 'referenceKey') {
            changeReferenceKeyValueRowData(value, fieldName, index)
        }
        else if ((fieldName === "paymentRunDateFrom" || fieldName === "paymentRunDateTo" || fieldName === "livePerformanceSubmissionFromDate" || fieldName === "livePerformanceSubmissionToDate") && value === "") {
            let newFormState = formState;

            newFormState = formState[fieldName] = null;
            setFormState((prevState ) => ({ ...prevState, formState: newFormState}))
        }
        else {
            let newFormState = formState;
            newFormState[fieldName] = value;
            setFormState((prevState ) => ({ ...prevState, formState: newFormState}))
        }
    }

    React.useEffect(() => {
        checkIfLoaded();

        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
        getRoles();

        window.addEventListener(
            "scroll",
            throttle(handleScroll, EVENT_HANDLER_THROTTLE_TIME, {
                trailing: true
            })
        );

        return () => {
            window.removeEventListener(
                "scroll",
                throttle(handleScroll, EVENT_HANDLER_THROTTLE_TIME, {
                    leading: true
                })
            );
        }
    }, [])

    React.useEffect(() => {
        checkIfLoaded();
    }, [props, loaded])


    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    const checkIfLoaded = () => {
        const { workflowPageData, users, getUsers, workFormats, getFieldFormats } = props;

        if (workflowPageData) {
            if (workflowPageData.fields && workflowPageData.fields.length > 0 && !loaded) {
                setLoaded(true);
                const header = workflowPageData.fields.find(f => f.name === "PageTitle");
                if (header) {
                    document.title = header.data;
                }
            }

        }

        if (!users || (users && users.length == 0)) {
            getUsers();
        }
        if (!workFormats) {
            getFieldFormats(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP);
        }
    }

    const onAssignTo = (users: any) => {
        const { workflowSearchResults, assignWorkflows, latestWorkflowSearchQuery } = props;

        if (workflowSearchResults && workflowSearchResults.length > 0) {
            const selectedWorkflows = workflowSearchResults.filter(w => w.selected) as IWorkflowSearchResult[];
            let assignModel: IAssignWorkflowModel = {
                users: users,
                workflows: [],
                status: EMPTY_STRING_VALUE
            };
            if (selectedWorkflows && selectedWorkflows.length > 0) {
                selectedWorkflows.map(w => {
                    assignModel.workflows.push({ ID: w.ID.toString(), entityID: w.entityID });
                });
                assignWorkflows(assignModel, latestWorkflowSearchQuery);
            }
        }
    }

    const onUpdateStatus = (status: string) => {
        const { workflowSearchResults, updateStatusWorkflows, latestWorkflowSearchQuery } = props;

        if (workflowSearchResults && workflowSearchResults.length > 0) {
            const selectedWorkflows = workflowSearchResults.filter(w => w.selected) as IWorkflowSearchResult[];
            let assignModel: IAssignWorkflowModel = {
                users: [],
                workflows: [],
                status: status
            };
            if (selectedWorkflows && selectedWorkflows.length > 0) {
                selectedWorkflows.map(w => {
                    assignModel.workflows.push({ ID: w.ID.toString(), entityID: w.entityID });
                });
                updateStatusWorkflows(assignModel, latestWorkflowSearchQuery);
            }
        }
    }

    const getActionList = (dataSource: string) => {
        return getDataAction(dataSource, WORK_MAINTENANCE_TOOLBAR);
    }

    const getWorkFormats = () => {
        SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
    }

    const updateUserPagination = (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => {
        const { defaultActiveAccordions, allResultsPerPage, updateUserPreferences, updatePagination } = props;
        updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection);
        updateUserPreferences(allResultsPerPage, defaultActiveAccordions, undefined, undefined, undefined, indexOfFirstResult, indexOfLastResult, resultsPerPage, repertoireSection);
    }

    const getWorkflowStatusList = (): { code: string, description: string }[] => {
        const { workflowPageData } = props;

        return [
            "workflowStatusOptAssigned",
            "workflowStatusOptCancelled",
            "workflowStatusOptCompleted",
            "workflowStatusOptOpen"
        ]
            .map((v, i, a) => ({
                code: ComponentsHelper.getFieldValueByName(v, workflowPageData.fields, EMPTY_STRING_VALUE),
                description: ComponentsHelper.getFieldValueByName(v, workflowPageData.fields, EMPTY_STRING_VALUE)
            }));
    }

    const getWorkflowEntityTypeList = (): { code: string, description: string }[] => {
        const { workflowPageData } = props;

        return [
            "workflowTypeOptIntrayWork",
            "workflowTypeOptRepertoireWork",
            "workflowTypeOptUsageWorkBased",
            "workflowTypeOptUsageProductBased",
            "workflowTypeOptUsageLivePerformance",
            "workflowTypeOptMagazineApprovals",
            "workflowTypeOptIntrayProduct",
            "workflowTypeOptRepertoireProduct",
            "workflowTypeOptUsageUnidentifiedPerformance",
            "workflowTypeOptIntrayAgreement"
        ]
            .map((v, i, a) => ({
                code: ComponentsHelper.getFieldValueByName(v, workflowPageData.fields, EMPTY_STRING_VALUE),
                description: ComponentsHelper.getFieldValueByName(v, workflowPageData.fields, EMPTY_STRING_VALUE)
            }))
            .filter(item => item.code !== '' || item.description !== '');
    }

    let scope =
    {
        scopes: [msalConfig.auth.scope]
    }

    const getRoles = () => {
        const { saveRoles } = props;

        instance.acquireTokenSilent({
            ...scope,
            account: accounts[0]
        }).then((response) => {
            const decodedJwtToken = ComponentsHelper.parseJwt(response.accessToken);
            const roles = decodedJwtToken.roles;
            saveRoles(roles);
        }).catch((error) => {
            saveRoles([ALL_ROLES])
        });
    }

    const workflowStatusList = getWorkflowStatusList();

    const workflowEntityTypeList = getWorkflowEntityTypeList();

    if (loaded) {
        const {
            lookupValues,
            workMaintenanceGeneralDataViewData,
            searchViewData,
            searchResultsTableData,
            searchSuccessful,
            searchWorkflow,
            workflowSearchResults,
            selectSingleWorkflow,
            workflowPageToolbarData,
            activeTab,
            tabs,
            onOpenAssignView,
            assingWorkflowDataView,
            dataGridTableData,
            updateWorkflowViewData,
            onOpenUpdateWorkflows,
            hideModal,
            clearSelection,
            users,
            workflowPageData,
            selectAllWorkflow,
            isSelectedAll,
            markSelectAll,
            indexOfFirstResult,
            indexOfLastResult,
            resultsPerPage,
            currentPage,
            resetPagination,
            updatePagination,
            startWorkflowSession,
            nextWorkflowItem,
            cancelWorkflowSession,
            skipWorkflowItem,
            workflowSessionItems,
            dataActionList,
            workFormats,
            isAnySelectedCompletedError,
            setErrorWorkflowSession,
            currentWorkflowIndex,
            getUsageDetails,
            skipUsageWorkflowItem,
            sortSearchResults,
            productMaintenanceGeneralDataViewData,
            skipProductWorkflowItem
        } = props;

        const getWorkDetails = (dataSource: string, workID: number, openEntityForWorflowSession?: boolean) => {
            const actionList = getDataAction(dataSource, WORK_MAINTENANCE_TOOLBAR);
            const formatFields = SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
                .then(formats => {
                    props.getWorkDetails(dataSource, workID, lookupValues, tabs[activeTab].otherIndicatorWorkFlagTypes, actionList, tabs[activeTab].readonlyIndicatorWorkFlagTypes, workMaintenanceGeneralDataViewData, formats, openEntityForWorflowSession);
                });

        }

        const getAgreementDetails = (dataSource: string, agreementID: number, openEntityForWorflowSession?: boolean) => {
            const actionList = getDataAction(dataSource, WORK_MAINTENANCE_TOOLBAR);
            props.getAgreementDetails(dataSource, agreementID, lookupValues, actionList, openEntityForWorflowSession);

        }

        const getWorkDetailsWorkflow = (dataSource: string, workID: number, selectedWorkflows: IWorkflowSearchResult[], openEntityForWorkflowSession?: boolean) => {
            const actionList = getDataAction(dataSource, WORK_MAINTENANCE_TOOLBAR);
            const formatFields = SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
                .then(formats => {
                    props.getWorkDetailsWorkflow(dataSource, workID, lookupValues, tabs[activeTab].otherIndicatorWorkFlagTypes, actionList, workMaintenanceGeneralDataViewData, formats, tabs[activeTab].readonlyIndicatorWorkFlagTypes, openEntityForWorkflowSession, activeTab, currentWorkflowIndex, selectedWorkflows);
                });
        }

        const getProductDetails = (dataSource: string, productID: number, openEntityForWorflowSession?: boolean) => {
            const formatFields = SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, PRODUCT_MAINTENANCE_GROUP)
                .then(formats => {
                    props.getProductDetails(dataSource, productID, productMaintenanceGeneralDataViewData, formats, openEntityForWorflowSession);
                });
        }

        const getViewRoleDisabled = (): boolean => {
            const { roles } = props;
            if (roles && (roles.includes(VIEW_WORKFLOWS_ROLE) || roles.includes(ALL_ROLES))) {
                return false;
            }
            return true;
        }

        const onClickSearch = () => {
            setPageOne(PageOne + 1);

            let searchQuery: IWorkflowSearchQuery = {
                entityName: formState.entityName,
                entityType: formState.entityType,
                status: formState.status,
                workflowType: formState.workflowType,
                errorMessage: formState.workflowType === MATCH ? formState.errorMessage : EMPTY_STRING_VALUE,
                assignedTo: formState.assignedTo,
                referenceKeyValues: referenceKeyValueRows,
                valueKey: formState.valueKey,
                valuesMax: formState.valuesMax,
                valuesMin: formState.valuesMin,
                fromDateCreated: formState.fromDateCreated,
                toDateCreated: formState.toDateCreated,
                productType: formState.productType,
            };

            searchWorkflow(searchQuery, true);

        }
        const changeTextData = (value: any, fieldName: IRepertoireStateKeys, isOnBlur?: boolean, index?: number) => {
            if (fieldName === 'referenceValue') {
                changeReferenceKeyValueRowData(value, fieldName, index)
            }
            else {
                let newFormState = formState;
                newFormState[fieldName] = value;
                setFormState((prevState ) => ({ ...prevState, formState: newFormState}))
            }
        }

        const keyDownSubmit = (value: any, fieldName: IRepertoireStateKeys) => {
            if (value.key === 'Enter' && !getViewRoleDisabled()) {
                onClickSearch();
            }
        }


        const renderReferenceKeyValueRow = (filteredSearchFields: IRepertoireField[]) => {
            return referenceKeyValueRows.map((rkv, index) => {
                return (
                    <div key={index} className="row">
                        <SizedDropdownDataInput
                            label={filteredSearchFields.find(x => x.name === RFERENCE_KEY_VALUE_STATE_KEY).data}
                            fieldName={filteredSearchFields.find(x => x.name === RFERENCE_KEY_VALUE_STATE_KEY).name}
                            changeData={(value, fieldName) => changeData(value, fieldName, rkv.index)}
                            value={rkv.referenceKey}
                            options={[
                                { code: 'Pool', description: 'Pool' },
                                { code: 'Distribution', description: 'Distribution' },
                                { code: 'WorkBatchID', description: 'Work Batch ID' },
                                { code: 'ProductBatchID', description: 'Product Batch ID' },
                                { code: 'Category', description: 'Category' },
                                { code: 'AutomaticallyCreatedBy', description: 'Automatically`Created By' },
                                { code: 'FileName', description: 'File Name' },
                            ]}
                            allowNull={true}
                            isHidden={false}
                            componentInstance={SEARCH_VIEW_WORKFLOW}
                        />
                        <SizedTextDataInput
                            fieldType={"text"}
                            useEnterSubmit={true}
                            label={filteredSearchFields.find(x => x.name === REFERENCE_VALUE_STATE_KEY).data}
                            fieldName={filteredSearchFields.find(x => x.name === REFERENCE_VALUE_STATE_KEY).name}
                            changeData={(value, fieldName) => changeTextData(value, fieldName, undefined, rkv.index)}
                            handleKeyDown={keyDownSubmit}
                            value={rkv.referenceValue}
                            readOnly={false}
                            isHidden={false}
                        />

                        {index !== 0 &&
                            <div style={{ paddingTop: '30px' }}>
                                <IconTextButton onClick={() => removeReferenceKeyValueRow(rkv.index)} text={"Remove"} icon={"icon ms-Icon ms-Icon--Delete"} />
                            </div>}
                    </div>
                )
            });
        }

        const renderKeyAndValueInputsWorkflowSearch = () => {
            if (searchViewData && searchViewData.fields) {

                let filteredSearchFields = workflowPageData.fields.filter(
                    (searchField: IRepertoireField) =>
                        searchField.componentInstance === SEARCH_VIEW_WORKFLOW
                );

                return (
                    <>
                        <div key='workflow1' className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <label>{workflowPageData.fields.find(x => x.name === REFERENCE_STATE_KEY).data}</label>
                        </div>
                        <div key='content1' className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            {renderReferenceKeyValueRow(filteredSearchFields)}

                        </div>
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <SizedActionButton buttonAction={addNewReferenceKeyValueRow} buttonText={'Add New'} />
                        </div>
                        <div key='workflow2' className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <label>{workflowPageData.fields.find(x => x.name === VALUE_STATE_KEY).data}</label>
                        </div>
                        <div key='content2' className="">
                            <SizedDropdownDataInput
                                label={filteredSearchFields.find(x => x.name === VALUE_KEY_VALUE_STATE_KEY).data}
                                fieldName={filteredSearchFields.find(x => x.name === VALUE_KEY_VALUE_STATE_KEY).name}
                                changeData={changeData}
                                value={formState[filteredSearchFields.find(x => x.name === VALUE_KEY_VALUE_STATE_KEY).name]}
                                options={[
                                    { code: 'UsageWeight', description: 'Usage Group Weight' },
                                ]}
                                allowNull={true}
                                isHidden={false}
                                componentInstance={SEARCH_VIEW_WORKFLOW}
                            />
                            <SizedTextDataInput
                                fieldType={"integer"}
                                useEnterSubmit={true}
                                label={filteredSearchFields.find(x => x.name === VALUE_MIN_STATE_KEY).data}
                                fieldName={filteredSearchFields.find(x => x.name === VALUE_MIN_STATE_KEY).name}
                                changeData={(value, fieldName) => changeTextData(value, fieldName)}
                                handleKeyDown={keyDownSubmit}
                                value={filteredSearchFields.find(x => x.name === VALUE_MIN_STATE_KEY).name === 'values' ? EMPTY_STRING_VALUE : formState[filteredSearchFields.find(x => x.name === VALUE_MIN_STATE_KEY).name]}
                                readOnly={false}
                                isHidden={false}
                            />
                            <SizedTextDataInput
                                fieldType={"integer"}
                                useEnterSubmit={true}
                                label={filteredSearchFields.find(x => x.name === VALUE_MAX_STATE_KEY).data}
                                fieldName={filteredSearchFields.find(x => x.name === VALUE_MAX_STATE_KEY).name}
                                changeData={(value, fieldName) => changeTextData(value, fieldName)}
                                handleKeyDown={keyDownSubmit}
                                value={filteredSearchFields.find(x => x.name === VALUE_MAX_STATE_KEY).name === 'values' ? EMPTY_STRING_VALUE : formState[filteredSearchFields.find(x => x.name === VALUE_MAX_STATE_KEY).name]}
                                readOnly={false}
                                isHidden={false}
                            />
                        </div>
                    </>
                )
            }

        }
        const setWorkflowType = (type: string) => {
            if (type.length > 0)
                setFormState({ ...formState, workflowType: type })
        }

        const setStateInitial = () => { }

        const renderSearchFields = () => {


            let filteredSearchFields = searchViewData.fields.filter(
                (searchField: IRepertoireField | IMembersPortalField) =>
                    searchField.componentInstance === SEARCH_VIEW_WORKFLOW
            );

            return <>
                <SearchViewKeyEventListener setStateInitial={setStateInitial} />
                {filteredSearchFields.map((searchField: IRepertoireField | IMembersPortalField, index: number) => {

                    
                    let isProductTypeField = searchField.name === 'productType';
                    const isCorrectTypeSelected = formState.entityType === "Intray Product" || formState.entityType === "RepertoireProduct" || formState.entityType === "Usage (Product Based)";

                    if (isProductTypeField && !isCorrectTypeSelected) {
                        return <></>;
                    }

                    let isMatchingErrorTypeSelected = formState.workflowType === WORKFLOW_TYPE_MATCHING_ERROR;

                    if (!isMatchingErrorTypeSelected && searchField.name === 'errorMessage') {
                        return <></>;
                     }


                    if ((searchField.fieldType === 'text' || searchField.fieldType === 'integer' || searchField.fieldType === 'decimal') && searchField.name !== WORKFLOW_WORKFLOW_TYPE_STATE_KEY) {

                        return <SizedTextDataInput
                                fieldType={searchField.fieldType}
                                useEnterSubmit={true}
                                label={searchField.data}
                                fieldName={searchField.name}
                                changeData={(value, fieldName) => changeTextData(value, fieldName)}
                                handleKeyDown={keyDownSubmit}
                                value={searchField.name === 'values' ? EMPTY_STRING_VALUE : formState[searchField.name]}
                                readOnly={false}
                                isHidden={searchField.hidden}
                                key={`${index}`}
                            />
                    }
                    if (searchField.name === WORKFLOW_WORKFLOW_TYPE_STATE_KEY)

                    return <SelectorView
                                selection={formState[searchField.name]}
                                setSelection={setWorkflowType}
                                option1={WORKFLOW_TYPE_VALIDATION_ERROR}
                                option2={WORKFLOW_TYPE_MATCHING_ERROR}
                                selectorTitle={searchField.data}
                                customStyle={'noTopMargin'}
                                key={`${index}`}
                            />

                            if (searchField.fieldType === 'date')
                                return   <SizedDatePickerDataInput
                                            value={formState[searchField.name]}
                                            fieldName={searchField.name}
                                            changeData={changeData}
                                            label={searchField.data}
                                            isHidden={searchField.hidden}
                                            key={`${index}`}
                                        />
                            if (searchField.fieldType === 'dropdown')  {
                                let options: { code: string, description: string }[] = [];
                                if (searchField.name === UPDATE_WORKFLOW_VIEW_STATUS_FIELD_STATE_KEY) {
                                    options = workflowStatusList;
                                }
                                else if (searchField.name === WORKFLOW_ENTITY_TYPE_STATE_KEY) {
                                    options = workflowEntityTypeList;
                                }
                                else if (searchField.name === ASSING_TO_SEARCH_WORKFLOW_STATE_KEY) {
                                    options = users;
                                }
                                else if (searchField.name === 'errorMessage') { 
                                    options = [
                                        { code: 'Multiple matches found', description: 'Multiple matches found' },
                                        { code: 'No matches found', description: 'No matches found' }
                                    ]
                                } else if (lookupValues && getLookupValues(mapUsageLookupKey(searchField.name, ''), lookupValues)) {
                                    isProductTypeField = false;
                                    options = getLookupValues(mapUsageLookupKey(searchField.name, ''), lookupValues);
                                }

                                return <SizedDropdownDataInput
                                label={searchField.data}
                                fieldName={searchField.name}
                                changeData={changeData}
                                value={formState[searchField.name]}
                                options={options}
                                allowNull={true}
                                isHidden={searchField.hidden}
                                componentInstance={SEARCH_VIEW_WORKFLOW}
                                readOnly={false}
                                key={`${index}`}
                            />     
                            
                            }

                            return <></>;
                })}
            </>

        }

        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <span className="title">Find Workflow</span>
                    </div>
                </div>

                <div className="searchView">
                    <div key='searchFields' className="row flexWrapRow paddingVeryLarge">
                        {renderSearchFields()}
                        {renderKeyAndValueInputsWorkflowSearch()}
                    </div>
                    <div key='action' className="row">
                        <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                            <ActionButton buttonText="Search" buttonAction={onClickSearch} disabled={getViewRoleDisabled()} />
                        </div>
                    </div>
                    <div key='results'>
                        <>
                            {workflowSearchResults && workflowSearchResults.length >= 1 && workflowSearchResults.filter(x => x.selected).length > 0 && (
                                <WorkflowPageToolbar
                                    activeTab={activeTab}
                                    hideModal={hideModal}
                                    showModal={showModal}
                                    scroll={scroll}
                                    toolbarWidth={toolbarWidth}
                                    workflowPageToolbarData={workflowPageToolbarData}
                                    tabs={tabs}
                                    workflowSearchResultsCount={workflowSearchResults.filter(x => x.selected).length}
                                    onOpenAssignView={onOpenAssignView}
                                    assingWorkflowDataView={assingWorkflowDataView}
                                    dataGridTableData={dataGridTableData}
                                    onAssignWorkflow={onAssignTo}
                                    onUpdateStatusWorkflow={onUpdateStatus}
                                    updateWorkflowViewData={updateWorkflowViewData}
                                    onOpenUpdateWorkflowsView={onOpenUpdateWorkflows}
                                    clearSelection={clearSelection}
                                    users={users}
                                    markSelectAll={markSelectAll}
                                    updatePagination={updatePagination}
                                    startWorkflowSession={startWorkflowSession}
                                    getWorkDetails={getWorkDetails}
                                    getWorkDetailsWorkflow={getWorkDetailsWorkflow}
                                    getProductDetails={getProductDetails}
                                    nextWorkflowItem={nextWorkflowItem}
                                    cancelWorkflowSession={cancelWorkflowSession}
                                    skipWorkflowItem={skipWorkflowItem}
                                    workflowSessionItems={workflowSessionItems}
                                    dataActionList={dataActionList}
                                    workFormats={workFormats}
                                    workMaintenanceGeneralDataViewData={workMaintenanceGeneralDataViewData}
                                    lookupValues={lookupValues}
                                    isAnySelectedCompletedError={isAnySelectedCompletedError}
                                    setErrorWorkflowSession={setErrorWorkflowSession}
                                    openEntityForWorkflowSession={true}
                                    currentWorkflowIndex={currentWorkflowIndex}
                                    getUsageDetails={getUsageDetails}
                                    skipUsageWorkflowItem={skipUsageWorkflowItem}
                                    getAgreementDetails={getAgreementDetails}
                                    skipProductWorkflowItem={skipProductWorkflowItem}
                                />)}

                        {workflowSearchResults && workflowSearchResults.length >= 1 && (
                            <div className="row">
                                <div className="col-md-12 searchResultsDiv">
                                    <SearchResultsTable
                                        key={PageOne}
                                        searchResultsTableData={searchResultsTableData}
                                        tableContents={workflowSearchResults}
                                        componentInstance={SEARCH_VIEW_WORKFLOW}
                                        getWorkDetails={getWorkDetails}
                                        getProductDetails={getProductDetails}
                                        selectWorkflow={selectSingleWorkflow}
                                        selectAll={true}
                                        selectAllWorkflow={selectAllWorkflow}
                                        isSelectedAll={isSelectedAll}
                                        markSelectAll={markSelectAll}
                                        indexOfFirstResult={indexOfFirstResult}
                                        indexOfLastResult={indexOfLastResult}
                                        resultsPerPage={resultsPerPage}
                                        currentPage={currentPage}
                                        updatePagination={updatePagination}
                                        repertoireSection={SEARCH_VIEW_WORKFLOW}
                                        getUsageDetails={getUsageDetails}
                                        sortSearchResults={sortSearchResults}
                                        getAgreementDetails={getAgreementDetails}
                                    />
                                </div>
                            </div>)}
                        </>

                    </div>
                </div>
            </div>
        )
    }
    else {
        return null;
    }
}

export default WorkflowPage;