import axios from "axios";
import { IStoragePath } from "../../dataingestion/types/IStoragePath";
import { IDataSourceBase } from "../../home/types/JobParameterControls/IDataSource";
import { IDistribution } from "../../repertoire/types/usageTypes/IDistribution";
import { IContributorMassUpdateParameters } from "../types/IContributorMassUpdateParameters";
import { IScheduledJobState } from "../types/IScheduledJobState";
import { ITriggerManualJob } from "../types/ITriggerManualJob";
import { ICwrgParameters } from "../types/ICwrgParameters";

export class JobService {

    static postWorkBatch(workBatchID: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post("job/postWorkBatch/" + workBatchID)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static matchWorkBatch(workBatchID: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post("job/matchWorkBatch/" + workBatchID)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static postProductBatch(productBatchID: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post("job/postProductBatch/" + productBatchID)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static matchProductBatch(productBatchID: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post("job/matchProductBatch/" + productBatchID)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static postAgreementBatch(agreementBatchID: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post("job/postAgreementBatch/" + agreementBatchID)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static postAdjustmentBatch(adjustmentBatchID: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post("job/postAdjustmentBatch/" + adjustmentBatchID)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static matchIngestionFile(storagePath: IStoragePath): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            axios.post(encodeURI(`job/matchIngestionFile`), storagePath)
                .then(res => {
                    resolve(res.data);
                });
        });
    }

    static triggerContributorMassUpdate(parameters: IContributorMassUpdateParameters): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            axios.post(encodeURI(`job/contributorMassUpdate`), parameters)
                .then(res => {
                    resolve(res.data);
                });
        });
    }

    static getJobParameters(key: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("job/jobParameter/" + key)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err.response.status);
                });
        });
    }

    static allocateDistributionPools(distribution: IDistribution, undoAllocation: boolean): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post('job/allocateDistributionPools/', { distribution, undoAllocation })
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static runQAOnDistributionPools(distribution: IDistribution, undoQA: boolean): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post('job/runQAOnDistributionPools/', { distribution, undoQA })
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }
    
    static triggerManualJob(manualJob: ITriggerManualJob): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post('job/triggerManualJob/', manualJob)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static getUsageFilenames(poolValue:string,distributionValue:string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("job/usageFileNames/"+distributionValue+"/"+poolValue)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static getAllUsageFilenamesFromDistribution(distributionValue:string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("job/usageFileNames/"+distributionValue)
                .then(response => {
                    resolve(response);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static getCWRAckHWM(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("job/cwrAckHWM/")
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err.response.status);
                });
        });
    }

    static getAutomaticAgreementGHWM(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("job/aagHwm/")
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err.response.status);
                });
        });
    }
    static getIceATF(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("job/iceATF/")
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err.response.status);
                });
        });
    }

    static getEmuwHwm(distributionId: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("job/emuwHwm?distributionId=" + distributionId)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err.response.status);
                });
        });
    }

    static getManualJobDataSources(): Promise<{[key: string]: IDataSourceBase}> {
        return axios.get<{[key: string]: IDataSourceBase}>('job/getManualJobDataSources/')
            .then(response => {
                return response.data;
            })
            .catch(error => {
                let tst = error;
                return null;
            });
    }

    public static getScheduledJob(jobType: string, jobParameters: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("job/scheduledJob?jobType=" + jobType + "&jobParameters=" + jobParameters)
                .then(response => {
                        const result = response.data;
                        resolve(result);
                })
                .catch(err => {
                    reject(err);
                })
        })
    }

    static upsertScheduledJob(schedule: IScheduledJobState): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post('job/upsertScheduledJob/', schedule)
                .then(response => {
                    const result = response.data;
                    resolve(result);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }

    static getCWRGHighWaterMark(cwrgParams: ICwrgParameters): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post("job/cwrgHighWaterMark/", cwrgParams)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        if (result === '') {
                            resolve(null);
                        }
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err.response.status);
                });
        });
    }

    static getDaspHighWaterMark(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .get("job/daspHighWaterMark/")
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        if (result === '') {
                            resolve(null);
                        }
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err.response.status);
                });
        });
    }

}
