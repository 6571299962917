import * as React from "react";
import { SizedTextDataInput } from "../../textDataInput/TextDataInput";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IMembersPortalField } from "../../../../membersportal/types/IMembersPortalField";
import {  EMPTY_STRING_VALUE, SEARCH_VIEW_WORKS} from "../../../Consts";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { ComponentsHelper } from "../../../../core/services/ComponentHelper";
import ActionButton from "../../actionButton/ActionButton";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { useMsal } from "@azure/msal-react";
import { IWorksSearchQuery } from "../../../types/IWorksSearchQuery";
import { DataSource } from "../../../types/DataSource";
import { IWorksSearchResult } from "../../../types/IWorksSearchResult";
import SearchResultsTable from "../../selectionTable/SelectionTable";
import { hideModal } from "../../../../redux/reducers/ModalReducer";

export interface AgreementApplicableWorkSearchViewProps {
    searchViewData: IRepertoireComponentDataItem;
    searchSuccessful: boolean;
    searchResultsTableData: IRepertoireComponentDataItem;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    currentPage?: number;
    resetPagination: (repertoireSection: string) => void;
    updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    hideModal: typeof hideModal;
    modalTitle: string;
    handleAddNewWork: () => void;
    searchWorks: (query: IWorksSearchQuery, modalOpen: boolean) => void;
    updateAgreementWork?: (work: IWorksSearchResult) => void;
    worksSearchResults?: IWorksSearchResult[];
    modalProps: any;
    expandedWorkResults?: number[];
    expandAll?: boolean;
    expandWorkResult?: (index: number) => void;
    expandAllResults?: () => void;
}
const AgreementApplicableWorkSearchView: React.FC<AgreementApplicableWorkSearchViewProps> = ({
    searchViewData,
    searchSuccessful,
    searchResultsTableData,
    indexOfFirstResult,
    indexOfLastResult,
    resultsPerPage,
    currentPage,
    resetPagination,
    updatePagination,
    hideModal,
    modalTitle,
    searchWorks,
    updateAgreementWork,
    worksSearchResults,
    modalProps,
    expandAll,
    expandedWorkResults,
    expandWorkResult,
    expandAllResults
}) => {
    const [loaded, setLoaded] = React.useState(false);
    const [filteredFields, setFilteredFields] = React.useState<IRepertoireField[] | IMembersPortalField[]>([]);
    const { instance, accounts } = useMsal();


    const [formData, setFormData] = React.useState({
        writers: '',
        societyAccountNumber: modalProps?.ipBaseNumber ? modalProps.ipBaseNumber : '',
        dataSource: DataSource.Repertoire,
        workBatchID: '',
        hasOpenWorkflow: false,
        workType: '',
        title: '',
        productType: '',
        number: '',
        productContributor: '',
        artist: '',
        productBatchID: undefined,
    });

    React.useEffect(() => {
        setLoaded(true);
        const account = accounts[0]
        ComponentsHelper.createBearerHeaderFromAADLogin(instance, account);
        resetPagination(SEARCH_VIEW_WORKS);
    }, [])

    React.useEffect(() => {

        if (searchViewData && searchViewData.fields) {
            let filteredSearchFields = searchViewData.fields.filter(
                (searchField: IRepertoireField | IMembersPortalField) =>
                    searchField.componentInstance === SEARCH_VIEW_WORKS
            );

            setFilteredFields(filteredSearchFields);
        }

    }, [searchViewData])

    const changeData = (value: any, fieldName: IRepertoireStateKeys) => {
        setFormData(
            { ...formData, [fieldName]: value }
        )
    }

    const keyDownSubmit = (value: any) => {
        if (value.key === 'Enter') {
            onClickSearch();
        }
    }

    const onClickSearch = () => {
        let searchQuery: IWorksSearchQuery = {
            title: formData.title,
            writers: formData.writers,
            number: formData.number,
            artist: formData.artist,
            societyAccountNumber: formData.societyAccountNumber,
            dataSource: formData.dataSource,
            workBatchID: formData.workBatchID,
            hasOpenWorkflow: formData.hasOpenWorkflow,
            workType: formData.workType,
        };
            searchWorks(searchQuery, false);
        }


    const renderSearchFields = () => {
        let filteredSearchFieldsMapper = (searchField: IRepertoireField | IMembersPortalField, index: number): React.ReactElement => {
            let formField: React.ReactElement = <></>;

            if ((searchField.fieldType === 'text' || searchField.fieldType === 'integer' || searchField.fieldType === 'decimal')) {
                formField =
                    <div key={index}>
                        <SizedTextDataInput
                            fieldType={searchField.fieldType}
                            useEnterSubmit={true}
                            label={searchField.data}
                            fieldName={searchField.name}
                            changeData={(value, fieldName) => changeData(value, fieldName)}
                            handleKeyDown={keyDownSubmit}
                            value={searchField.name === 'values' ? EMPTY_STRING_VALUE : formData[searchField.name]}
                            isHidden={false}
                        />
                    </div>
            }

            return formField;

        };
        return filteredFields.map(filteredSearchFieldsMapper);
    };

    if (loaded && filteredFields && filteredFields.length > 0 && searchSuccessful) {
        return (
            <div className="repertoireModal">
                <div className={"modal"}>
                    <div>
                        <div className="closeButton" onClick={hideModal}>
                            <img src={"assets/close.svg"} className="closeIcon" />
                        </div>
                    </div>
                    <div className="fixedPart" />
                    <div className="dynamicPart">
                        <div className="title">{modalTitle}</div>
                        <div className="modalBody">
                            <div className="searchView">
                                <div key='searchFields' className="row">
                                    {renderSearchFields()}
                                </div>
                                <div key='action' className="row">
                                    <div className="form-group col-lg-4 col-lg-push-8 col-md-4 col-md-push-8 col-sm-6 col-sm-push-6 col-xs-12 col-xs-push-0">
                                        <ActionButton buttonText="Search" buttonAction={onClickSearch} />
                                    </div>
                                </div>
                                {searchSuccessful && worksSearchResults && worksSearchResults.length >= 1 && (<div className="row" key={'applicable works'}>
                                    <SearchResultsTable
                                        key={0}
                                        searchResultsTableData={searchResultsTableData}
                                        tableContents={worksSearchResults}
                                        componentInstance={SEARCH_VIEW_WORKS}
                                        sourceItem={modalProps}
                                        updateAgreementWork={updateAgreementWork}
                                        hideModal={hideModal}
                                        expandedResults={expandedWorkResults}
                                        expandAll={expandAll}
                                        expandResult={expandWorkResult}
                                        expandAllResults={expandAllResults}
                                        indexOfFirstResult={indexOfFirstResult}
                                        indexOfLastResult={indexOfLastResult}
                                        resultsPerPage={resultsPerPage}
                                        currentPage={currentPage}
                                        updatePagination={updatePagination}
                                        repertoireSection={SEARCH_VIEW_WORKS}
                                        />
                                    </div>)
                                }
                            </div>
                        </div>
                    </div>
                    <div className="fixedPart" />
                </div>
            </div>
        );
    } else if (searchSuccessful == false) {
            return (
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 centerTextNotFound" >
                        Error while searching, please contact customer support if issue persists.
                    </div>
                </div>
            )
    }
    return <div>Loading ...</div>
}

export default AgreementApplicableWorkSearchView;