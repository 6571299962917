import * as React from "react";
import { Dictionary } from "../../../../core/types/Dictionary";
import { IComponentDataItem } from "../../../../core/types/IComponentDataItem";
import { ContainerDetailsWindow } from "../../../../dataingestion/components/containerDetailsWindow/ContainerDetailsWindow";
import { IFileSystem } from "../../../../dataingestion/types/IFileSystem";
import { IStoragePathItemData } from "../../../../dataingestion/types/IStoragePathItemData";
import { ILookupInstance } from "../../../../lookup/types/ILookup";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { addFileToUpload, CleanUploadFilesState, fileMetadataChanged, resetPagination, setInDropZone, setProgressUploadFileProccess, sortColumn, updatePagination } from "../../../../redux/reducers/DataIngestionReducer";
import { hideModal, showModal, showUpdateFieldsModal, showWorkSubmission } from "../../../../redux/reducers/ModalReducer";
import {
    addDroppedFile, addTab, cleanDroppedFilesRepertoireState, clearModalSearchResults,
    enableCustomField, setEditableFields, setProgressUploadFileRepertoireProccess, sortWorkFieldData,
    updateContributorSetType, updateUserPreference, updateWorkField
} from "../../../../redux/reducers/RepertoireReducer";
import { RepertoireService } from "../../../../redux/services/RepertoireService";
import { IAddWorkAttachmentModel } from "../../../../redux/types/IAddAttchmentWorkModel";
import { IAttachedFile } from "../../../../redux/types/IAttachedFile";
import IDroppedFiles from "../../../../redux/types/IDroppedFiles";
import IFile from "../../../../redux/types/IFiles";
import { IPaginationDetail } from "../../../../redux/types/IPaginationDetail";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IWorkState } from "../../../../redux/types/IWorkState";
import { WORKS_PAGE_VIEW } from "../../../Consts";
import { DataSource } from "../../../types/DataSource";
import { IAgreementShareDisplayConfiguration } from "../../../types/IAgreementShareDisplayConfiguration";
import { IArtist } from "../../../types/IArtist";
import { IArticle } from "../../../types/IArticle";
import { IComment } from "../../../types/IComment";
import { IContainedWork } from "../../../types/IContainedWork";
import { IContributorItem } from "../../../types/IContributorItem";
import { IContributorSiteConfiguration } from "../../../types/IContributorSiteConfiguration";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { IInstrumentation } from "../../../types/IInstrumentation";
import { IMatchingWork } from "../../../types/IMatchingWork";
import { IIntrayMatchingWork } from "../../../types/IIntrayMatchingWork";
import { INumber } from "../../../types/INumber";
import { IRemoveWorkBlobAttachment } from "../../../types/IRemoveWorkBlobAttachment";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IReplacedWork } from "../../../types/IReplacedWork";
import { IRepresentation } from "../../../types/IRepresentation";
import { ITitle } from "../../../types/ITitle";
import { IWorkContributorSetType } from "../../../types/IWorkContributorSetType";
import { IWorkProductDataItems } from "../../../types/IWorkProductDataItems";
import { IWorksSubmission } from "../../../types/IWorkSubmission";
import { ManualMergeOptions } from "../../../types/ManualMergeOptions";
import { IDistribution } from "../../../types/usageTypes/IDistribution";
import { WorkSubmissionID } from "../../../types/WorkSubmissionDBID";
import WorkMaintenanceAccordionList from "../workMaintenanceAccordionList/WorkMaintenanceAccordionList";
import { ISheetMusic } from "../../../types/ISheetMusic";
import { IBook } from "../../../types/IBook";
import { IChapter } from "../../../types/IChapter";
import { IMagazine } from "../../../types/IMagazine";
import { IFlag } from "../../../types/IFlag";
import { IReadonlyFlagsField } from "../../../types/IReadonlyFlagsField";
import { IWorkPaymentRunDataItems } from "../../../types/IWorkPaymentRunDataItems";
import { IWorkPaymentRun } from "../../../types/IWorkPaymentRun";
import { IWorkMaintenanceSubject } from "../../../types/IWorkMaintenanceSubjects";
import { IWorkPrice } from "../../../types/IWorkPrice";
import { INumberAdditional } from "../../../types/INumberAdditional";
import { ISubject } from "../../../types/ISubject";
import { IWorkSubmissionStatus } from "../../../types/IWorkSubmissionStatus";
import { IEdition } from "../../../types/IEdition";
import { IWorkEditionDataItems } from "../../../types/IWorkEditionDataItems";

export interface IWorkMaintenanceGridsViewProps {
    updateWorkField: typeof updateWorkField;
    sortWorkFieldData: typeof sortWorkFieldData;
    updateContributorSetType: typeof updateContributorSetType;
    workMaintenanceGridsViewData: IRepertoireComponentDataItem;
    workMaintenanceAccordionViewData?: IRepertoireComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    contributorsDataViewData?: IRepertoireComponentDataItem;
    workMetadataAccordionViewData?: IRepertoireComponentDataItem;
    workMaintenanceSaveResultData?: IRepertoireComponentDataItem;
    showModal: typeof showModal;
    clearModalSearchResults: typeof clearModalSearchResults;
    lookupValues: ILookupDictionary;
    activeAccordian: string[];
    openWorkAccordian: (accordionName: string, componentName: string) => void;
    closeWorkAccordian: (accordionName: string, componentName: string) => void;
    dataSource: DataSource;
    workNames: ITitle[];
    workNumbers: INumber[];
    subjects: ISubject[];
    workNumbersAdditional: INumberAdditional[];
    workArtists: IArtist[];
    workArticles: IArticle;
    workSheetMusic: ISheetMusic;
    workBook: IBook;
    workChapter: IChapter;
    workMagazine: IMagazine;
    workFlags: IFlag[];
    workPrice: IWorkPrice[];
    readonlyIndicators: IFlag[]
    workContributorSetTypes: IWorkContributorSetType[];
    workContributors: Dictionary<IContributorItem[]>;
    workNotes: IComment[];
    workProduct: IWorkProductDataItems[];
    workPaymentRun: IWorkPaymentRunDataItems[];
    matchingWorks: IMatchingWork[];
    matchingIntrayWorks: IIntrayMatchingWork[];
    addTab: typeof addTab;
    tabs: ITabReduxItem[];
    activeTab: number;
    getAgreementDetails: (dataSource: string, agreementID: number, lookups: ILookupDictionary, dataAction?: IDataActionToolbar[], agreementGeneralDataView?: IRepertoireComponentDataItem) => void;
    isReadonly: boolean;
    getWorkDetails: (dataSource: string, workID: number) => void;
    getProductDetails: (dataSource: string, productID: number) => void;
    getIPDetails: (ipBaseNumber: string, accountNumber: string) => void;
    iswcSubmissions?: IWorksSubmission[];
    cisNetSubmissions?: IWorksSubmission[];
    widSubmissions?: IWorksSubmission[];
    showWorkSubmissionDetails?: typeof showWorkSubmission;
    hideModal?: typeof hideModal;
    workSubmissionDetails?: IRepertoireComponentDataItem;
    work: IWorkState;
    workSubmissions?: IWorksSubmission[];
    workSubmissionStatuses?: IWorkSubmissionStatus[];
    currentUser?: string;
    manualMergeWork: (matchingWorkId: number, manualMergeOption: ManualMergeOptions) => void;
    relatedWorks: IContainedWork[];
    contributorDisplaySettings: IContributorSiteConfiguration[];
    agreementMaintenanceGeneralDataView: IRepertoireComponentDataItem;
    workEdition: IWorkEditionDataItems[];
    workInstrumentation: IInstrumentation[];
    customFields?: number[];
    enableCustomField?: typeof enableCustomField;
    containerDetailsWindowComponentData: IComponentDataItem;
    distributions: IDistribution[];
    destinations: ILookupInstance[];
    selectedFileSystem: IFileSystem;
    droppedFiles: IDroppedFiles;
    setInDropZone: typeof setInDropZone;
    addFileToUpload: typeof addFileToUpload;
    sortColumn: typeof sortColumn;
    isDropZone: boolean;
    setProgressUploadFileProccess: typeof setProgressUploadFileProccess;
    cleanUploadFilesState: typeof CleanUploadFilesState;
    storagePaths: IStoragePathItemData[];
    storagePathItemData: IComponentDataItem;
    refreshDirectory: (filesystem: IFileSystem) => void;
    addRepertoireAttachmentToState: (attachedFile: IAttachedFile) => void;
    attachedFiles: IStoragePathItemData[];
    removeWorkAttachmentIfExists: (attachedFile: IAttachedFile, source: DataSource) => void;
    workAttachmentsDropped?: IDroppedFiles;
    addDroppedFileRepertoire?: typeof addDroppedFile;
    setProgressUploadFileRepertoire: typeof setProgressUploadFileRepertoireProccess;
    cleanDroppedFilesRepertoire: typeof cleanDroppedFilesRepertoireState;
    updateSetTypeOnBreak: (setType: string) => void;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    defaultContributorsTabToOpen: string;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    editableContributorFieldGridDataView: IRepertoireComponentDataItem;
    contributorGridEditableFields: IRepertoireField[];
    readonlyFlagsField: IReadonlyFlagsField[];
    setEditableFields: typeof setEditableFields;
    updateContributorFields?: (fields: IRepertoireField[], componentName: string, componentInstance?: string) => void;
    replacedWorks?: IReplacedWork[];
    agreementShareDisplayOptions: IAgreementShareDisplayConfiguration;
    shareDecimalsToDisplay?: number;
    resetMessageBanner?: () => void;
    refreshWorkDetails: () => void;
    containerWorks?: IContainedWork[];
    editableFields: IRepertoireField[];
    addNewAccount: () => void;
    paginationDetail: IPaginationDetail[];
    updatePagination: typeof updatePagination;
    representations: IRepresentation[];
    expandedWorkResults?: number[];
    expandMatchingWork?: (index: number) => void;
    incrementTempID?: () => void;
    commentCategoryFlag?: boolean;
    saveAdditionalWorkNumbers?: (pageNumber: number, additionalNumbers: INumberAdditional[]) => void;
    getCurrentPageDetails?: (pageNumber: number) => void;
    loadWorkIceDistCacheAdditionalInfo: (workID: number, activeTabItem: ITabReduxItem) => void;
    isFromUsage?:boolean;
}

interface IWorkMaintenanceGridsViewState {
    activeHeaderSection: string;
}

export default class WorkMaintenanceGridsView extends React.PureComponent<
    IWorkMaintenanceGridsViewProps,
    IWorkMaintenanceGridsViewState
> {
    constructor(props: IWorkMaintenanceGridsViewProps) {
        super(props);

        this.state = {
            activeHeaderSection: "core"
        };
    }

    onClickHeaderSection = (activeHeaderSection: string) => {
        this.setState({
            activeHeaderSection
        });
    };

    renderGridViewHeaderSections = () => {
        const { activeHeaderSection } = this.state;
        const { workMaintenanceGridsViewData, dataSource } = this.props;

        if (workMaintenanceGridsViewData && workMaintenanceGridsViewData.fields) {
            return workMaintenanceGridsViewData.fields.filter(f => f.fieldType === null || f.fieldType === dataSource.toString()).map((section, index) => (
                <div
                    className={[
                        "headerSection", activeHeaderSection === section.name ? "activeSection" : null
                    ].join(" ")}
                    key={index}
                    onClick={() => this.onClickHeaderSection(section.name)}
                >
                    {section.data}
                </div>
            ));
        }
    };
    public ascending: boolean = false;

    sortTableByColumn = (section: string, ascending: boolean) => {
        this.props.sortColumn(section, ascending);

    };


    removeAttachmentFromMaintenanceScreen(fullName: string, workId: number, dataSource: DataSource, guid: string, index: number) {
        const { refreshWorkDetails } = this.props;
        const attachedFile: IAttachedFile = {
            fileName: fullName,
            workId: workId
        }
        const model: IRemoveWorkBlobAttachment = {
            Guid: guid,
            Source: dataSource,
            workID: workId
        }

        RepertoireService.removeWorkBlobAttachment(model)
            .then(res => {
                if (res) {
                    RepertoireService.removeWorkAttachment({ attachedFile: attachedFile, dataSource: dataSource })
                        .then((res: boolean) => {
                            if (res) {
                                refreshWorkDetails();
                            }
                        })
                }
            })
    }

    uploadWorkAttachment(fileName: string, workId: number, dataSource: DataSource) {
        const model: IAddWorkAttachmentModel = {
            attachedFile: {
                fileName: fileName,
                workId: workId
            },
            dataSource: dataSource
        }
        return Promise.resolve(
            RepertoireService.addAttachmentRepertoire(model)
                .then((res: IAttachedFile) => {
                    return res;
                })
                .catch((err: any) => {
                    const newFile: IFile = {
                        isInProgress: true,
                        message: err,
                        name: fileName,
                        size: 0
                    }
                    setProgressUploadFileProccess(err, newFile, true);
                    return false;
                })
        )
    }

    renderAccordions() {
        const {
            updateWorkField,
            sortWorkFieldData,
            workMaintenanceAccordionViewData,
            dataGridTableData,
            contributorsDataViewData,
            workMetadataAccordionViewData,
            showModal,
            clearModalSearchResults,
            lookupValues,
            activeAccordian,
            openWorkAccordian,
            closeWorkAccordian,
            dataSource,
            workNames,
            workNumbers,
            subjects,
            workNumbersAdditional,
            workContributorSetTypes,
            workContributors,
            workArtists,
            workArticles,
            workSheetMusic,
            workBook,
            workChapter,
            workMagazine,
            workFlags,
            workPrice,
            readonlyIndicators,
            readonlyFlagsField,
            workNotes,
            workProduct,
            workPaymentRun,
            matchingWorks,
            matchingIntrayWorks,
            workEdition,
            workInstrumentation,
            addTab,
            tabs,
            activeTab,
            getAgreementDetails,
            updateContributorSetType,
            isReadonly,
            getWorkDetails,
            getProductDetails,
            getIPDetails,
            showWorkSubmissionDetails,
            hideModal,
            workSubmissionDetails,
            work,
            workSubmissions,
            workSubmissionStatuses,
            currentUser,
            manualMergeWork,
            relatedWorks,
            contributorDisplaySettings,
            agreementMaintenanceGeneralDataView,
            workMaintenanceGridsViewData,
            distributions,
            selectedFileSystem,
            droppedFiles,
            setInDropZone,
            sortColumn,
            addFileToUpload,
            isDropZone,
            setProgressUploadFileProccess,
            cleanUploadFilesState,
            storagePaths,
            storagePathItemData,
            containerDetailsWindowComponentData,
            destinations,
            refreshDirectory,
            attachedFiles,
            enableCustomField,
            customFields,
            addRepertoireAttachmentToState,
            removeWorkAttachmentIfExists,
            addDroppedFileRepertoire,
            workAttachmentsDropped,
            cleanDroppedFilesRepertoire,
            setProgressUploadFileRepertoire,
            updateSetTypeOnBreak,
            editableContributorFieldGridDataView,
            contributorGridEditableFields,
            setEditableFields,
            updateContributorFields,
            replacedWorks,
            agreementShareDisplayOptions,
            shareDecimalsToDisplay,
            resetMessageBanner,
            containerWorks,
            showUpdateFieldsModal,
            updateComponentFieldsItems,
            editableFields,
            addNewAccount,
            paginationDetail,
            updatePagination,
            representations,
            expandedWorkResults,
            expandMatchingWork,
            workMaintenanceSaveResultData,
            incrementTempID,
            commentCategoryFlag,
            saveAdditionalWorkNumbers,
            getCurrentPageDetails,
            loadWorkIceDistCacheAdditionalInfo,
            defaultContributorsTabToOpen,
            isFromUsage
        } = this.props;
        const { activeHeaderSection } = this.state;

        let iswc: IWorksSubmission[];
        let ice: IWorksSubmission[];
        let cisNet: IWorksSubmission[];
        let wid: IWorksSubmission[];
        
        if (work.workSubmissions) {
            iswc = work.workSubmissions.filter(sub => sub.submissionDB === WorkSubmissionID.ISWC);
            cisNet = work.workSubmissions.filter(sub => sub.submissionDB === WorkSubmissionID.CisNet);
            ice = work.workSubmissions.filter(sub => sub.submissionDB === WorkSubmissionID.ICE);
            wid = work.workSubmissions.filter(sub => sub.submissionDB === WorkSubmissionID.WID || sub.submissionDB === WorkSubmissionID.WIDACK);
        }

        if (activeHeaderSection === 'attachment') {
            return (
                <div key={activeHeaderSection} className={'topMarginRepertoireAttachment'}>
                    <ContainerDetailsWindow
                        addFileToUpload={addFileToUpload}
                        cancelChanges={undefined}
                        cleanUploadFilesState={cleanUploadFilesState}
                        containerDetailsWindowComponentData={containerDetailsWindowComponentData}
                        currentDirectory={"work"}
                        destinations={destinations}
                        editingChanged={undefined}
                        fileMetadataChanged={fileMetadataChanged}
                        jobs={[]}
                        matchNow={undefined}
                        matchNowSubmit={undefined}
                        openDirectory={undefined}
                        pageContentIsActive={true}
                        distributions={distributions}
                        droppedFiles={droppedFiles}
                        isDropZone={isDropZone}
                        selectedFileSystem={selectedFileSystem}
                        setInDropZone={setInDropZone}
                        setProgressUploadFileProccess={setProgressUploadFileProccess}
                        sortColumn={sortColumn}
                        storagePathItemData={storagePathItemData}
                        storagePaths={attachedFiles}
                        refreshDirectory={refreshDirectory}
                        saveChanges={undefined}
                        saveState={undefined}
                        isRepertoireModule={true}
                        toggleSidebarCollapsed={undefined}
                        validationMessageChanged={undefined}
                        addRepertoireAttachmentToState={addRepertoireAttachmentToState}
                        entityID={work.workID}
                        entitySource={work.dataSource}
                        uploadAttachment={this.uploadWorkAttachment.bind(this)}
                        removeAttachmentIfExists={removeWorkAttachmentIfExists}
                        addDroppedFileRepertoire={addDroppedFileRepertoire}
                        attachmentsDropped={workAttachmentsDropped}
                        cleanDroppedFilesRepertoire={cleanDroppedFilesRepertoire}
                        setProgressUploadFileRepertoire={setProgressUploadFileRepertoire}
                        component={WORKS_PAGE_VIEW}
                        removeAttachmentMaintenanceScreen={this.removeAttachmentFromMaintenanceScreen.bind(this)}
                        displayRemove={true}
                        paginationDetails={paginationDetail}
                        updatePagination={updatePagination}
                        lookupValues={lookupValues}
                        tabs={tabs}
                        activeTab={activeTab}
                        isWorkAttachmentTypeNotRequired={work.isWorkAttachmentTypeNotRequired}
                        isWorkAttachmentTypeOptional={work.isWorkAttachmentTypeOptional}
                        isProductAttachmentTypeNotRequired={false}
                        isProductAttachmentTypeOptional={false}
                    />
                </div>
            )
        } else {
            return (

                <div key={activeHeaderSection}>
                    <WorkMaintenanceAccordionList
                        updateWorkField={updateWorkField}
                        sortWorkFieldData={sortWorkFieldData}
                        workMaintenanceAccordionViewData={workMaintenanceAccordionViewData}
                        componentInstance={activeHeaderSection}
                        dataGridTableData={dataGridTableData}
                        contributorsDataViewData={contributorsDataViewData}
                        workMetadataAccordionViewData={workMetadataAccordionViewData}
                        showModal={showModal}
                        clearModalSearchResults={clearModalSearchResults}
                        lookupValues={lookupValues}
                        activeAccordian={activeAccordian}
                        openWorkAccordian={openWorkAccordian}
                        closeWorkAccordian={closeWorkAccordian}
                        dataSource={dataSource}
                        workNames={workNames}
                        workNumbers={workNumbers}
                        subjects={subjects}
                        workNumbersAdditional={workNumbersAdditional}
                        workContributorSetTypes={workContributorSetTypes}
                        workContributors={workContributors}
                        workArtists={workArtists}
                        workArticles={workArticles}
                        workSheetMusic={workSheetMusic}
                        workBook={workBook}
                        workChapter={workChapter}
                        workMagazine={workMagazine}
                        workFlags={workFlags}
                        workPrice={workPrice}
                        readonlyIndicators={readonlyIndicators}
                        readonlyFlagsField={readonlyFlagsField}
                        workNotes={workNotes}
                        workEdition={workEdition}
                        workInstrumentation={workInstrumentation}
                        workProduct={workProduct}
                        workPaymentRun={workPaymentRun}
                        matchingWorks={matchingWorks}
                        matchingIntrayWorks={matchingIntrayWorks}
                        addTab={addTab}
                        tabs={tabs}
                        activeTab={activeTab}
                        getAgreementDetails={getAgreementDetails}
                        updateContributorSetType={updateContributorSetType}
                        isReadonly={isReadonly}
                        getWorkDetails={getWorkDetails}
                        getProductDetails={getProductDetails}
                        getIPDetails={getIPDetails}
                        iswcSubmissions={iswc}
                        cisNetSubmissions={cisNet}
                        widSubmissions={wid}
                        iceSubmissions={ice}
                        showWorkSubmissionDetail={showWorkSubmissionDetails}
                        hideModal={hideModal}
                        workSubmissionDetails={workSubmissionDetails}
                        work={work}
                        currentUser={currentUser}
                        manualMergeWork={manualMergeWork}
                        relatedWorks={relatedWorks}
                        contributorDisplaySettings={contributorDisplaySettings}
                        agreementMaintenanceGeneralDataView={agreementMaintenanceGeneralDataView}
                        customFields={customFields}
                        enableCustomField={enableCustomField}
                        addFileToUpload={addFileToUpload}
                        cleanUploadFilesState={cleanUploadFilesState}
                        containerDetailsWindowComponentData={containerDetailsWindowComponentData}
                        destinations={destinations}
                        distributions={distributions}
                        droppedFiles={droppedFiles}
                        isDropZone={isDropZone}
                        selectedFileSystem={selectedFileSystem}
                        setInDropZone={setInDropZone}
                        setProgressUploadFileProccess={setProgressUploadFileProccess}
                        sortColumn={sortColumn}
                        storagePathItemData={storagePathItemData}
                        storagePaths={storagePaths}
                        refreshDirectory={refreshDirectory}
                        updateSetTypeOnBreak={updateSetTypeOnBreak}
                        editableContributorFieldGridDataView={editableContributorFieldGridDataView}
                        showUpdateFieldsModal={showUpdateFieldsModal}
                        contributorGridEditableFields={contributorGridEditableFields}
                        setEditableFields={setEditableFields}
                        updateContributorFields={updateContributorFields}
                        replacedWorks={replacedWorks}
                        agreementShareDisplayOptions={agreementShareDisplayOptions}
                        shareDecimalsToDisplay={shareDecimalsToDisplay}
                        resetMessageBanner={resetMessageBanner}
                        containerWorks={containerWorks}
                        editableFields={editableFields}
                        updateComponentFieldsItems={updateComponentFieldsItems}
                        addNewAccount={addNewAccount}
                        representations={representations}
                        expandedWorkResults={expandedWorkResults}
                        expandMatchingWork={expandMatchingWork}
                        saveResultData={workMaintenanceSaveResultData}
                        incrementTempID={incrementTempID}
                        commentCategoryFlag={commentCategoryFlag}
                        saveAdditionalWorkNumbers={saveAdditionalWorkNumbers}
                        getCurrentPageDetails={getCurrentPageDetails}
                        loadWorkIceDistCacheAdditionalInfo={loadWorkIceDistCacheAdditionalInfo}
                        defaultContributorsTabToOpen={defaultContributorsTabToOpen}
                        isFromUsage={isFromUsage}
                    />
                </div>
            )
        }
    }

    render() {
        const {
            isReadonly,
            work
        } = this.props;

        return (
            <>
                <div className="GridsView">
                    <div className={["headerContainer", isReadonly ? "pointerEnable" : ""].join(" ")}>
                        {this.renderGridViewHeaderSections()}
                    </div>

                    {this.renderAccordions()}
                </div>
            </>
        );
    }
}
