import { ILookupDictionary } from "../../lookup/types/ILookupDictionary";

export function PushDistributionRestrictedPools(formItem: any, options: any, distributionValue: string) {
    let poolOptions: { code: string; description: string; pools?: [] }[];
    let distributionOptions: { code: string; description: string; pools?: [] }[];
    distributionOptions = formItem.DataSourceRepo['openDistributions'].Read();
    let poolsArray = [];
    distributionOptions.map(element => {
        if (element.code === distributionValue) {
            poolsArray.push(element.pools);
        }
    })
    poolOptions = formItem.DataSourceRepo['pools'].Read();
    poolOptions.map(element => {
        poolsArray.map(ele => {
            if (ele.includes(element.code)) {
                options.push(element);
            }
        })
    })

    if (options.length > 0) {
        options.push(poolOptions.find(e => e.code == 'AllPools'))
    }
}

export function getOptions(formItem: any, distributionValue: string) {

    let options = [];

    if (typeof formItem.Options == "string") {
        if (formItem.Options === 'pools' && distributionValue !== '') {
            PushDistributionRestrictedPools(formItem, options, distributionValue)
        }
        else {
            options = formItem.DataSourceRepo[formItem.Options].Read();
        }
    } else {
        options = formItem.Options;
    }

    options.sort((a, b) => a.description.localeCompare(b.description));

    return options.map((v, i, a) => ({
        key: v.code,
        text: v.description,
    })).filter(x => !formItem.FilteredValues || formItem.FilteredValues.includes(x.key))
}

export function getLookupValues(lookup: string, lookupValues: ILookupDictionary, jobType: string) {
    let result = lookupValues[lookup] ? lookupValues[lookup].lookups : undefined;
    return result.sort((a, b) => a.code.localeCompare(b.code)).filter(e => e.code == jobType)[0];;
}
