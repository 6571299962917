import throttle from "lodash.throttle";
import * as React from "react";
import { Dictionary } from "../../../../core/types/Dictionary";
import { SET_TYPE_LOOKUP } from "../../../../lookup/Consts";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { hideModal, showModal, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { addTab, clearModalSearchResults, setEditableFields, updateContributorSetType, updateWorkField } from "../../../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IWorkStateKeys } from "../../../../redux/types/IWorkState";
import CarouselScrollButton from "../../../components/carouselScrollButton/CarouselScrollButton";
import DataGridTable from "../../../components/dataGridTable/DataGridTable";
import { SizedDropdownDataInput } from "../../../components/dropdownDataInput/DropdownDataInput";
import { MessageBanner, MessageType } from "../../../components/messageBanner/MessageBanner";
import { IShowFieldSettingViewModalProps } from "../../../components/repertoireModal/RepertoireModal";
import { getDataAction } from "../../../components/toolBar/ToolbarHelper";
import {
    ADD_NEW_ACTION, AGREEMENT_MAINTENANCE_TOOLBAR, CONTRIBUTORS_DATA_VIEW_BREAK_INHERITANCE, CONTRIBUTORS_DATA_VIEW_CANNOT_SET_INHERITANCE_MESSAGE, CONTRIBUTORS_DATA_VIEW_DO_NOT_INHERIT, CONTRIBUTORS_DATA_VIEW_ICED, CONTRIBUTORS_DATA_VIEW_INHERITANCE_ADDED_MESSAGE, CONTRIBUTORS_DATA_VIEW_INHERITANCE_BROKEN_MESSAGE, CONTRIBUTORS_DATA_VIEW_INHERIT_FROM, CONTRIBUTORS_KEY, DATA_GRID_TABLE, DEPENDENT_ROW_ACTION, EDITABLE_FIELDS_GRID, ICE_NUMBER_TYPE_CODE, OTHER_KEY, SCREEN_WIDTH_CHANGE, SET_TYPE_KEY, TITLE_EDITABLE_FIELDS_VIEW, WORK_OTHERCONTRIBUTOR_SOURCEIP_STATE_KEY,
    WORK_OTHERCONTRIBUTOR_SOURCESOCIETY_STATE_KEY, WORK_OTHERCONTRIBUTOR_SOURCETYPE_STATE_KEY
} from "../../../Consts";
import { DataSource } from "../../../types/DataSource";
import { IAgreementLink } from "../../../types/IAgreementLink";
import { IAgreementShareDisplayConfiguration } from "../../../types/IAgreementShareDisplayConfiguration";
import { IContributorDataFilter } from "../../../types/IContributorDataFilter";
import { IContributorItem } from "../../../types/IContributorItem";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { isTableAction, ITableAction } from "../../../types/ITableAction";
import { IWorkContributorSetType } from "../../../types/IWorkContributorSetType";
import ContributorsDataViewButton from "../contributorsDataViewButton/ContributorsDataViewButton";

export interface IContributorsDataViewProps {
    updateWorkField: (value: any, fieldName: IWorkStateKeys, index?: number) => void;
    showModal: typeof showModal;
    clearModalSearchResults: typeof clearModalSearchResults;
    addTab: typeof addTab;
    updateContributorSetType: typeof updateContributorSetType;
    tableContents: Dictionary<IContributorItem[]>;
    contributorsDataViewData?: IRepertoireComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    sortData: (value: any, name: IRepertoireStateKeys) => void;
    lookupValues: ILookupDictionary;
    tableActions?: ITableAction[];
    setTypeGroup: string;
    dataSource: DataSource;
    getAgreementDetails: (dataSource: string, agreementID: number, lookups: ILookupDictionary, dataAction?: IDataActionToolbar[], agreementGeneralDataView?: IRepertoireComponentDataItem) => void;
    workContributorSetTypes: IWorkContributorSetType[];
    contributorInheritanceChanged: boolean;
    isReadonly: boolean;
    resetMessageBanner: () => void;
    openInNewTab(openItem: any): void;
    tabs?: ITabReduxItem[];
    activeTab?: number;
    currentUser?: string;
    contributorConfiguration?: string;
    agreementMaintenanceGeneralDataView: IRepertoireComponentDataItem;
    updateSetTypeOnBreak: (setType: string) => void;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFields: IRepertoireField[];
    editableContributorFieldGridDataView: IRepertoireComponentDataItem;
    contributorGridEditableFields: IRepertoireField[];
    setEditableFields: typeof setEditableFields;
    updateContributorFields?: (fields: IRepertoireField[], componentName: string, componentInstance?: string) => void;
    hideModal?: typeof hideModal;
    agreementShareDisplayOptions?: IAgreementShareDisplayConfiguration;
    shareDecimalsToDisplay?: number;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    addNewAccount: () => void;
    incrementTempID?: () => void;
    showContributorTabsAsDropdown?: boolean;
    loadWorkIceDistCacheAdditionalInfo: (workID: number, activeTabItem: ITabReduxItem) => void;
    defaultContributorsTabToOpen: string;
    isFromUsage?:boolean;
}

interface IContributorsDataViewState {
    activeSetType: string;
    loaded: boolean;
    otherSourceType: string;
    otherSourceIp: string;
    otherSourceSociety: string;
}

const REPERTOIRE = "repertoire";
const DISTRIBUTIONS = "distribution";

export default class ContributorsDataView extends React.PureComponent<
    IContributorsDataViewProps,
    IContributorsDataViewState
> {
    viewData;
    constructor(props: IContributorsDataViewProps) {
        super(props);

        this.state = {
            activeSetType: this.getInitialTabToOpen(props),
            loaded: false,
            otherSourceType: "All",
            otherSourceIp: "All",
            otherSourceSociety: "All"
        };
        this.viewData = {};
    }

    componentDidMount = () => {
        const { contributorsDataViewData: { fields },workContributorSetTypes,tableContents,updateWorkField,isFromUsage} = this.props;
        fields.forEach(item => {
            this.viewData[item.name] = item.data;

            if (Object.keys(this.viewData).length === fields.length) {
                this.setState({ loaded: true });
            }
        });
        const { activeSetType } = this.state;
        if(workContributorSetTypes && tableContents && activeSetType && isFromUsage){
            const tableContentsCopy: Dictionary<IContributorItem[]> = JSON.parse(JSON.stringify(tableContents));
            workContributorSetTypes.filter(s => s.inheritFromSetType === activeSetType).map((setType, i) => {
                const contributors: IContributorItem[] = JSON.parse(JSON.stringify(tableContentsCopy[activeSetType]));
                tableContentsCopy[setType.setType] = contributors;
                Object.keys(tableContentsCopy).forEach(key => {
                    if (tableContentsCopy[key] && tableContentsCopy[key].length > 0) {
                        tableContentsCopy[key].forEach(x => {
                            if (x.mechanicalShareToDisplay != null && x.mechPercentage != null)
                                x.mechanicalShareToDisplay.value = x.mechPercentage.value.toString();
                            if (x.performingShareToDisplay != null && x.perfPercentage != null)
                                x.performingShareToDisplay.value = x.perfPercentage.value.toString();
                        });
                    }
                })
                updateWorkField(tableContentsCopy, CONTRIBUTORS_KEY);
            });
        }
        
    };

    getInitialTabToOpen = (props: IContributorsDataViewProps) => {
        if (props.defaultContributorsTabToOpen === CONTRIBUTORS_DATA_VIEW_ICED){
            if (this.iceNumberExistsInWork()){
                this.onLoadWorkIceDistCacheAdditionalInfo(CONTRIBUTORS_DATA_VIEW_ICED);
                return props.defaultContributorsTabToOpen;
            }
            return REPERTOIRE;
        }
        return props.defaultContributorsTabToOpen ?? REPERTOIRE;
    }

    onLoadWorkIceDistCacheAdditionalInfo = (activeSetType: IRepertoireStateKeys) => {
        const { loadWorkIceDistCacheAdditionalInfo, tabs, activeTab } = this.props;
        if(activeSetType === CONTRIBUTORS_DATA_VIEW_ICED){
            loadWorkIceDistCacheAdditionalInfo(tabs[activeTab].workMaintenanceState.work.workID, tabs[activeTab]);
        }
    }

    iceNumberExistsInWork = () => {
        const { tabs, activeTab } = this.props;
        return tabs[activeTab].workMaintenanceState.work.numbers.some( x => x.type.value === ICE_NUMBER_TYPE_CODE);
    }


    setButtonActive = (name: IRepertoireStateKeys) => {
        this.setState({
            activeSetType: name
        });  
        this.onLoadWorkIceDistCacheAdditionalInfo(name);
    };

    handleSelect = event => {
        let value = event.target.value;
        this.setButtonActive(value);
    };

    renderTabs = () => {
        const { contributorsDataViewData, loadWorkIceDistCacheAdditionalInfo, tabs, activeTab } = this.props;
        const { activeSetType } = this.state;

        return contributorsDataViewData.fields.filter(f => f.fieldType === SET_TYPE_KEY && f.hidden === false).map((field, index) => (
            <ContributorsDataViewButton key={index}
                text={field.data}
                active={field.name === activeSetType}
                onClick={() => this.setButtonActive(field.name)}
            />
        ));
    };


    filterTableContents = () => {
        const { tableContents, contributorsDataViewData, setTypeGroup } = this.props;
        const { activeSetType, otherSourceIp, otherSourceSociety, otherSourceType } = this.state;
        let filteredTableContents: IContributorItem[] = [];

        if (activeSetType === OTHER_KEY) {
            const setTypeTabs: string[] = [] //
            contributorsDataViewData.fields
                .filter(f => f.fieldType === SET_TYPE_KEY)
                .filter(f => f.name !== OTHER_KEY)
                .map(f => setTypeTabs.push(f.name));
        }

        if (activeSetType === OTHER_KEY) {

            if (tableContents[activeSetType] !== undefined) {
                let flitered = tableContents[activeSetType].filter(f => f.setTypeGroup === setTypeGroup)

                if (otherSourceIp != null && otherSourceIp !== "" && otherSourceIp != "All") {
                    flitered = flitered.filter(f => (f.sourceIP != null ? f.sourceIP.toString() === otherSourceIp : false));
                }

                if (otherSourceSociety != null && otherSourceSociety !== "" && otherSourceSociety != "All") {
                    flitered = flitered.filter(f => f.sourceSociety === otherSourceSociety);
                }

                if (otherSourceType != null && otherSourceType !== "" && otherSourceType != "All") {
                    flitered = flitered.filter(f => f.sourceType == otherSourceType);
                }

                filteredTableContents = JSON.parse(
                    JSON.stringify(flitered.filter(f => f.setTypeGroup === setTypeGroup))
                );
            }

        }
        else if (tableContents[activeSetType]) {
            filteredTableContents = JSON.parse(
                JSON.stringify(tableContents[activeSetType].filter(f => f.setTypeGroup === setTypeGroup))
            );
        } else if(tableContents[activeSetType] === undefined && activeSetType === DISTRIBUTIONS && tableContents[REPERTOIRE]) {
            filteredTableContents = JSON.parse(
                JSON.stringify(tableContents[REPERTOIRE].filter(f => f.setTypeGroup === setTypeGroup))
            );
        }

        return filteredTableContents;
    };

    // replace tabs with dropdown on mobile
    renderDropdown = () => {
        const { contributorsDataViewData } = this.props;
        return (
            <select className="dropdown" onChange={this.handleSelect}>
                {contributorsDataViewData.fields.filter(f => f.fieldType === SET_TYPE_KEY).map((field, index) => (
                    <option key={index} value={field.name}>
                        {field.data}
                    </option>
                ))}
            </select>
        );
    };


    changeData(value: IContributorItem[], name: IRepertoireStateKeys) {
        const { updateWorkField, tableContents, setTypeGroup, workContributorSetTypes, isReadonly, updateSetTypeOnBreak } = this.props;
        const { activeSetType } = this.state;
        if (isReadonly)
            return;

        const tableContentsCopy: Dictionary<IContributorItem[]> = JSON.parse(JSON.stringify(tableContents));
        const distributionContentCopy: IContributorItem[] = tableContents["distribution"] ? tableContents["distribution"] : [];


        let exisitingData: IContributorDataFilter[] = [];

        distributionContentCopy.forEach(x => {
            exisitingData.push({
                setTypeGroup: x.setTypeGroup,
                ipBaseNumber: x.ipBaseNumber,
                ipiNumber: x.ipiNumber.value,
                mechSocietyAffiliation: x.mechSocietyAffiliation,
                perfSocietyAffiliation: x.perfSocietyAffiliation,
                relationships: x.relationships
            });
        });

        const otherSetTypeGroups: IContributorItem[] = tableContentsCopy[activeSetType] ?
            tableContentsCopy[activeSetType].filter(c => c.setTypeGroup !== setTypeGroup && c.setType == activeSetType)
            : [];
        tableContentsCopy[activeSetType] = [...value, ...otherSetTypeGroups];

        workContributorSetTypes.filter(s => s.inheritFromSetType === activeSetType).map((setType, i) => {
            const contributors: IContributorItem[] = JSON.parse(JSON.stringify(tableContentsCopy[activeSetType]));
            contributors.forEach((c: IContributorItem, index: number) => {
                const data: IContributorDataFilter = exisitingData.find(x => x.setTypeGroup === c.setTypeGroup && x.ipBaseNumber === c.ipBaseNumber && x.ipiNumber === c.ipiNumber.value);
                if (data) {
                    c.mechSocietyAffiliation = data.mechSocietyAffiliation;
                    c.perfSocietyAffiliation = data.perfSocietyAffiliation;
                }
                c.setType = setType.setType;
                c.workContributorID = - Math.abs(c.workContributorID) * (i + 1);
                c.agreementChain = []
            });
            tableContentsCopy[setType.setType] = contributors;
        });

        Object.keys(tableContentsCopy).forEach(key => {
            if (tableContentsCopy[key] && tableContentsCopy[key].length > 0) {
                tableContentsCopy[key].forEach(x => {
                    if (x.mechanicalShareToDisplay != null && x.mechPercentage != null)
                        x.mechanicalShareToDisplay.value = x.mechPercentage.value.toString();
                    if (x.performingShareToDisplay != null && x.perfPercentage != null)
                        x.performingShareToDisplay.value = x.perfPercentage.value.toString();
                });
            }
        })

        updateWorkField(tableContentsCopy, CONTRIBUTORS_KEY);
    }

    updateContributorRelationships(value: string, contributors: IContributorItem[], rowIndex: number): void {
        const contributor = contributors[rowIndex];
        contributor.relationships = value;
        contributor.relationshipIDs = [];

        const str = !!value ? value.trim() : '';
        // if the string is empty, remove all relationships from this contributor to others 
        // and from other contributors to this one
        if (str == '') {
            contributor.relationshipIDs = [];
            contributor.isEditingRelationships = false;

            for (let i = 0; i < contributors.length; i++) {
                if (contributors[i].relationshipIDs) {
                    const k = contributors[i].relationshipIDs.indexOf(contributor.workContributorID); // if contributor has relationship
                    if (k > -1) {
                        contributors[i].relationshipIDs.splice(k, 1); // remove the relationship
                    }
                }
            }
        }
        else if (str.endsWith(';')) {
            contributor.isEditingRelationships = false;
            const relsTo = str.split(';').filter(r => r.startsWith('T'));

            // add relationships to other contributors
            relsTo.map(r => {
                let index: number = parseInt(r.trim().substring(1))
                if (!isNaN(index)) {
                    const contributorIndex: number = index - 1;
                    if (
                        contributorIndex >= 0
                        && contributorIndex <= contributors.length - 1 // index is within array bounds
                        && contributorIndex !== rowIndex) { // does not refer to the contributor getting edited

                        const id: number = contributors[contributorIndex].workContributorID;
                        if (!contributor.relationshipIDs.find(x => x === id)) {
                            contributor.relationshipIDs.push(id);
                        }
                    }
                }
            });

            // get all relationships from other contributors to this one.
            // remove them if they don't have a corresponding F relationship here.
            const relsFrom = str.split(';').filter(r => r.startsWith('F')); // remove rel from if it has been deleted
            const indexesOfContributorsWithRelations: number[] = [];
            relsFrom.map(r => {
                let index: number = parseInt(r.trim().substring(1))
                if (!isNaN(index)) {
                    const contributorIndex: number = index - 1;
                    if (
                        contributorIndex >= 0
                        && contributorIndex <= contributors.length - 1 // index is within array bounds
                        && contributorIndex !== rowIndex) { // does not refer to the contributor getting edited
                        indexesOfContributorsWithRelations.push(contributorIndex);
                    }
                }
            });
            for (let i = 0; i < contributors.length; i++) {
                if (contributors[i].relationshipIDs) {
                    let k = contributors[i].relationshipIDs.indexOf(contributor.workContributorID); // if contributor has relationship
                    if (k === -1) {
                        k = contributors[i].relationshipIDs.indexOf(-contributor.workContributorID);
                    }
                    if (k > -1 && !str.includes('F' + (i + 1) + ';')) {
                        contributors[i].relationshipIDs.splice(k, 1); // remove the relationship
                    }
                }

            }
        }
        else {
            contributor.isEditingRelationships = true; // suppress recalculating while the user is enter text. Wait for ';'
        }
        this.changeData(contributors, CONTRIBUTORS_KEY);
    }

    calculateContributorRelationships = (contributors: IContributorItem[]) => {
        if (contributors) {
            contributors.map(c => {
                if (!c.isEditingRelationships) { // suppress recalculating while the user is enter text. Wait for ';'
                    let relationships: string = '';
                    if (c.relationshipIDs) {
                        c.relationshipIDs.map(r => {
                            const index: number = contributors.findIndex(c => Math.abs(c.workContributorID) === Math.abs(r));
                            if (index !== -1) {
                                relationships = relationships + 'T' + (index + 1) + ';'
                            }
                        });
                    }
                    c.relationships = relationships;
                }
            });
            contributors.map(c => {
                if (!c.isEditingRelationships) {
                    const otherContributors = contributors.filter(oc => Math.abs(oc.workContributorID) !== Math.abs(c.workContributorID));
                    otherContributors.map(oc => {
                        if (oc.relationshipIDs && oc.relationshipIDs.findIndex(x => Math.abs(x) == Math.abs(c.workContributorID)) > -1) {
                            c.relationships = c.relationships + 'F' + (contributors.findIndex(x => Math.abs(x.workContributorID) == Math.abs(oc.workContributorID)) + 1) + ';'
                        }
                    })
                }
            });
        }
    }

    getAgreementDetails(agreementLink: IAgreementLink): void {
        const { getAgreementDetails, lookupValues, dataSource, tabs, activeTab, agreementMaintenanceGeneralDataView } = this.props;
        const actionList = getDataAction(dataSource, AGREEMENT_MAINTENANCE_TOOLBAR);
        getAgreementDetails(dataSource.toString(), agreementLink.agreementID, lookupValues, actionList, agreementMaintenanceGeneralDataView)
    }

    toggleContributorInheritance(option: string): void {
        const { updateContributorSetType, workContributorSetTypes, isReadonly, updateSetTypeOnBreak } = this.props;
        const { activeSetType } = this.state;
        if (isReadonly)
            return;

        const copy: IWorkContributorSetType[] = JSON.parse(JSON.stringify(workContributorSetTypes));
        const current = copy.find(s => s.setType === activeSetType);
        if (current) {
            if (option === null || option === CONTRIBUTORS_DATA_VIEW_DO_NOT_INHERIT) {
                current.inheritFromSetType = null;
                updateSetTypeOnBreak(current.setType);
            }
            else {
                current.inheritFromSetType = option as string;
            }

            updateContributorSetType(current.setType, current.inheritFromSetType);
        }
    }

    inherits(): boolean {
        const { activeSetType } = this.state;
        const { workContributorSetTypes } = this.props;
        const current = workContributorSetTypes.find(s => s.setType === activeSetType);
        const parentSetType = current && current.inheritFromSetType;
        return !!parentSetType;
    }

    changeFilter = (value: any, name: IRepertoireStateKeys) => {

        if (name == WORK_OTHERCONTRIBUTOR_SOURCETYPE_STATE_KEY) {
            this.setState({
                otherSourceType: value
            });
        }
        else if (name == WORK_OTHERCONTRIBUTOR_SOURCEIP_STATE_KEY) {
            this.setState({
                otherSourceIp: value
            });
        }
        else if (name == WORK_OTHERCONTRIBUTOR_SOURCESOCIETY_STATE_KEY) {
            this.setState({
                otherSourceSociety: value
            });
        }

        this.render();
    }

    renderOtherFilters() {
        const { activeSetType, otherSourceIp, otherSourceSociety, otherSourceType } = this.state;
        const { isReadonly, tableContents } = this.props;

        if (activeSetType !== OTHER_KEY)
            return null;

        let sourceType = null;
        let sourceIp = null;
        let sourceSociety = null;

        if (tableContents[activeSetType] != undefined) {
            sourceType = tableContents[activeSetType].filter(x => x.sourceType !== "" && x.sourceType !== null).map(x => ({ code: x.sourceType, description: x.sourceType })).filter((v, i, a) => a.findIndex(t => (t.code === v.code)) === i);
            sourceIp = tableContents[activeSetType].filter(x => x.sourceIP !== null).map(x => ({ code: x.sourceIP.toString(), description: x.sourceIP.toString() })).filter((v, i, a) => a.findIndex(t => (t.code === v.code)) === i);
            sourceSociety = tableContents[activeSetType].filter(x => x.sourceSociety !== "" && x.sourceSociety !== null).map(x => ({ code: x.sourceSociety, description: x.sourceSociety })).filter((v, i, a) => a.findIndex(t => (t.code === v.code)) === i);

            sourceType.unshift({ code: "All", description: "All" });
            sourceIp.unshift({ code: "All", description: "All" });
            sourceSociety.unshift({ code: "All", description: "All" });

        }


        return (<div className="otherContributorFilters">
            <SizedDropdownDataInput
                label={this.viewData[WORK_OTHERCONTRIBUTOR_SOURCETYPE_STATE_KEY]}
                fieldName={WORK_OTHERCONTRIBUTOR_SOURCETYPE_STATE_KEY}
                value={otherSourceType}
                changeData={this.changeFilter}
                options={sourceType}
                allowNull={true}
                readOnly={isReadonly}
                isHidden={false}
            />
            
            <SizedDropdownDataInput
                label={this.viewData[WORK_OTHERCONTRIBUTOR_SOURCEIP_STATE_KEY]}
                fieldName={WORK_OTHERCONTRIBUTOR_SOURCEIP_STATE_KEY}
                value={otherSourceIp}
                changeData={this.changeFilter}
                options={sourceIp}
                allowNull={true}
                readOnly={isReadonly}
                isHidden={false}
            /><SizedDropdownDataInput
                label={this.viewData[WORK_OTHERCONTRIBUTOR_SOURCESOCIETY_STATE_KEY]}
                fieldName={WORK_OTHERCONTRIBUTOR_SOURCESOCIETY_STATE_KEY}
                value={otherSourceSociety}
                changeData={this.changeFilter}
                options={sourceSociety}
                allowNull={true}
                readOnly={isReadonly}
                isHidden={false}
            /></div>)
    }

    renderSetTypeInheritanceOptions() {
        const { activeSetType } = this.state;
        const { workContributorSetTypes, lookupValues, contributorInheritanceChanged, isReadonly } = this.props;
        const inherits: boolean = this.inherits();
        if (activeSetType === OTHER_KEY)
            return null;
        const renderOptions = () => {
            var options: JSX.Element[] = [];
            options.push(<option key={-1} value={CONTRIBUTORS_DATA_VIEW_DO_NOT_INHERIT} title={this.viewData[CONTRIBUTORS_DATA_VIEW_DO_NOT_INHERIT]}>
                {this.viewData[CONTRIBUTORS_DATA_VIEW_DO_NOT_INHERIT]}
            </option>)

            const filteredSetTypes = workContributorSetTypes.filter(s => s.inheritFromSetType === null && s.setType !== activeSetType);

            filteredSetTypes.map((setType, i) => {
                const lookup = lookupValues[SET_TYPE_LOOKUP];
                if (lookup) {
                    const description = lookupValues[SET_TYPE_LOOKUP].lookups.find(l => l.code === setType.setType).description;
                    options.push(<option key={i} value={setType.setType} title={description}>
                        {description}
                    </option>)
                }
            })

            return options;
        }

        const renderBanner = () => {
            const { resetMessageBanner } = this.props;
            if (!contributorInheritanceChanged)
                return null;
            else if (this.inherits())
                return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Information} messages={[this.viewData[CONTRIBUTORS_DATA_VIEW_INHERITANCE_ADDED_MESSAGE]]} />;
            else
                return <MessageBanner resetMessageBanner={resetMessageBanner} messageType={MessageType.Information} messages={[this.viewData[CONTRIBUTORS_DATA_VIEW_INHERITANCE_BROKEN_MESSAGE]]} />;
        }
        const value: string = inherits ? workContributorSetTypes.find(s => s.setType === activeSetType).inheritFromSetType : CONTRIBUTORS_DATA_VIEW_DO_NOT_INHERIT;
        const disabled: boolean = !!workContributorSetTypes.find(s => s.inheritFromSetType === activeSetType) || isReadonly;

        return <>
            <div className="form-group col-md-3 col-sm-6 col-xs-12 flexContent">
                <label>{this.viewData[CONTRIBUTORS_DATA_VIEW_INHERIT_FROM]}</label>
                <select
                    onChange={(event) => this.toggleContributorInheritance(event.target.value)}
                    value={value}
                    disabled={disabled}
                    title={disabled ? this.viewData[CONTRIBUTORS_DATA_VIEW_CANNOT_SET_INHERITANCE_MESSAGE] : null}
                >
                    {renderOptions()}
                </select>
            </div>
            {
                inherits ?
                    <div className="form-group col-md-3 col-sm-6 col-xs-12 margin-topSmall flexContent">
                        <div className="verticalDivider"></div>
                        <div className="IconTextButton" onClick={(e) => this.toggleContributorInheritance(null)}>
                            <i className="icon ms-Icon ms-Icon--RemoveLink" aria-hidden={true}></i>
                            {this.viewData[CONTRIBUTORS_DATA_VIEW_BREAK_INHERITANCE]}
                        </div>
                    </div> : null
            }
            {renderBanner()}
        </>
    }

    showFieldsModal = () => {
        const {
            showUpdateFieldsModal,
            editableContributorFieldGridDataView,
            dataGridTableData,
            setEditableFields,
        } = this.props;

        setEditableFields(DATA_GRID_TABLE, CONTRIBUTORS_KEY)

        const fieldsViewData: IRepertoireComponentDataItem = { fields: editableContributorFieldGridDataView.fields.filter(x => x.componentInstance === EDITABLE_FIELDS_GRID) };
        const title: string = fieldsViewData.fields.find(item => item.name === TITLE_EDITABLE_FIELDS_VIEW).data;

        const fieldSetting: IShowFieldSettingViewModalProps = {
            componentInstance: EDITABLE_FIELDS_GRID,
            componentViewData: fieldsViewData,
            dataGridViewData: dataGridTableData,
            onClickSave: this.onSaveEditableFields.bind(this)
        }

        showUpdateFieldsModal(title, fieldSetting);
    }

    onSaveEditableFields() {
        const { updateComponentFieldsItems, editableFields, tabs, activeTab } = this.props;
        updateComponentFieldsItems(editableFields["default"], DATA_GRID_TABLE, CONTRIBUTORS_KEY, DATA_GRID_TABLE, tabs, activeTab);
    }


    renderTable = () => {
        const {
            dataGridTableData,
            sortData,
            showModal,
            clearModalSearchResults,
            lookupValues,
            addTab,
            setTypeGroup,
            dataSource,
            isReadonly,
            openInNewTab,
            tabs,
            activeTab,
            currentUser,
            contributorConfiguration,
            agreementShareDisplayOptions,
            shareDecimalsToDisplay,
            addNewAccount,
            incrementTempID
        } = this.props;
        const { activeSetType } = this.state;
        const inherits: boolean = this.inherits();
        const tableActions: ITableAction[] = [{ name: ADD_NEW_ACTION, disabled: inherits }];

        dataGridTableData.fields.forEach(f => f.disabled = null);
        if (inherits) {
            dataGridTableData.fields.forEach(f => {
                if (isTableAction(f.fieldType) && f.fieldType !== DEPENDENT_ROW_ACTION)
                    f.disabled = true;
            })
        }

        const filteredContributors = activeSetType === CONTRIBUTORS_DATA_VIEW_ICED ? 
            tabs[activeTab].iceDistCacheAdditionalInfoState?.workContributors : this.filterTableContents();
        this.calculateContributorRelationships(filteredContributors);

        return (
            <>


                <div className="contributorsDataView">

                    <DataGridTable
                        tableContents={filteredContributors}
                        tableActions={tableActions}
                        stateKey={CONTRIBUTORS_KEY}
                        dataGridTableData={dataGridTableData}
                        componentInstance={CONTRIBUTORS_KEY}
                        changeData={this.changeData.bind(this)}
                        sortData={sortData}
                        showModal={showModal}
                        clearModalSearchResults={clearModalSearchResults}
                        lookupValues={lookupValues}
                        addTab={addTab}
                        showDropdownsAsCodes={true}
                        showRowNumber={true}
                        setTypeGroup={setTypeGroup}
                        activeSetType={activeSetType}
                        dataSource={dataSource}
                        dependentRowAction={this.getAgreementDetails.bind(this)}
                        isReadOnly={inherits || isReadonly}
                        openInNewTab={openInNewTab}
                        tabs={tabs}
                        activeTab={activeTab}
                        currentUser={currentUser}
                        contributorConfiguration={contributorConfiguration}
                        updateContributorRelationships={this.updateContributorRelationships.bind(this)}
                        showFieldsModal={this.showFieldsModal.bind(this)}
                        agreementShareDisplayOptions={agreementShareDisplayOptions}
                        shareDecimalsToDisplay={shareDecimalsToDisplay}
                        addNewAccount={addNewAccount}
                        incrementTempID={incrementTempID}
                    />
                </div>
            </>
        );
    };

    render() {
        const { showContributorTabsAsDropdown, contributorsDataViewData } = this.props;
        const { loaded } = this.state;

        if (!loaded &&  contributorsDataViewData?.fields?.length < 0)
            return null;

        return (
            <div className="buttonsCarouselView">
                <div className="form-group col-md-6 col-sm-6 col-xs-12 flexContent">
                    <CarouselScrollButton
                        content={showContributorTabsAsDropdown ? this.renderDropdown() : this.renderTabs()}
                        distanceToScroll={100}
                    />
                </div>
                {this.renderSetTypeInheritanceOptions()}
                {this.renderOtherFilters()}
                <div className="form-group col-md-3 col-sm-6 col-xs-12 flex-end-Content">
                    <button className="contributorGearBtn" title="Configure data fields" onClick={this.showFieldsModal.bind(this)}>
                        <i className="ms-Icon ms-Icon--Settings"></i>
                    </button>
                </div>
                {this.renderTable()}
            </div>
        );
    }
}
