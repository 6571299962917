import * as React from "react";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { showModal } from "../../../../redux/reducers/ModalReducer";
import { addUsageDetailsShareIPRow } from "../../../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { USAGE_ROWS_SECTION_KEY, USAGE_ROW_DROPDOWN_KEY } from "../../../Consts";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { IUsageRowData } from "../../../types/usageTypes/IUsageRowData";
import { UsageRowDropdown } from "../usageRowDropdown/UsageRowDropdown";
import TextDataInput from "../../../components/textDataInput/TextDataInput";
import DropdownDataInput from "../../../components/dropdownDataInput/DropdownDataInput";
import DateDisplay from "../../../components/dateDisplay/DateDisplay";
import CheckboxDataInput from "../../../components/checkboxDataInput/CheckboxDataInput";

export interface IUsageRowViewProps {
    usage: IUsageRowData;
    index: number;
    dataGridTableData: IRepertoireComponentDataItem;
    lookupValues?: ILookupDictionary;
    changeData?: (value: any, name: IRepertoireStateKeys, objectKey?: string, ipRepresentationsIdsToRemove?: number[], index?: number) => void;
    showModal:(
        modalContent: string,
        modalComponentInstance: string,
        modalProps: any,
        displayModalCloseButton: boolean,
        title: string,
        modalMessage?: string,
        isReadOnly?: boolean,
        key?: string,
        index?: number

    )=>void;
    addUsageDetailsShareIPRow?: typeof addUsageDetailsShareIPRow;
    tableContents: any;
    resetMessageBanner: () => void;
    onRowClick: () => void;
    focusedRowNumber: number;
    expandedUsageDetailsRow?:number[];
    enableRegroupAndDeleteUI?:boolean;
}

interface IUsageRowViewState {
    rowClicked: boolean
}

export class UsageRowView extends React.PureComponent<
    IUsageRowViewProps,
    IUsageRowViewState
> {

    constructor(props: IUsageRowViewProps) {
        super(props);
        this.state = {
            rowClicked: false
        };
    }

    onClickRow = () => {
        this.setState({ rowClicked: !this.state.rowClicked })
    }

    changeUsageData = (value: any, name: string) => {
        const { changeData, index, usage } = this.props;
        let usageCopy = usage;

        usageCopy[name].value = value
        changeData(usageCopy, name);
    }
    getMaxWidth = (fieldName: string) => {
        switch (fieldName) {
            case "dimension1":
            case "dimension2":
            case "dimension3":
            case "dimension4":
            case "dimension5": {
                return 120;
            }
            default: {
                return undefined;
            }
        };
    }

    getRowColumns = (usage: IUsageRowData) => {
        const { dataGridTableData, lookupValues,enableRegroupAndDeleteUI } = this.props;
        let gridData = dataGridTableData.fields.filter(x => !x.hidden && x.componentInstance === USAGE_ROWS_SECTION_KEY);
        let updatedGridData
        if(!enableRegroupAndDeleteUI){
            updatedGridData = gridData.filter(item=>item.name!=='isSelected')
        }else{
            updatedGridData = gridData
        }
        const rowColumns: JSX.Element[] = [];
        for (let [key, dataItem] of Object.entries(usage)) {
            if (updatedGridData.find(x => x.name === key))
                switch (dataItem.inputType) {
                    case 'row': 
                        if (key === 'usageDate' || key === 'dateLoaded' || key === 'programStartDate' || key === 'programEndDate') {
                            rowColumns.push(
                                <td key={key} className="td">
                                    <div className="tableCell">
                                        <DateDisplay value={dataItem.value} showTime={true}/>
                                    </div>
                                </td>
                            );
                        } else {
                        rowColumns.push(
                            <td key={key} className="td">
                                <div className="tableCell">{dataItem.value}</div>
                            </td>
                        );
                    }
                    break;
                    case 'text':
                        rowColumns.push(
                            <td key={key} className="td">
                                <TextDataInput changeData={this.changeUsageData} fieldName={key} value={dataItem.value} isHidden={false} Width={this.getMaxWidth(key)} />
                            </td>
                        );
                        break;
                    case 'dropdown':
                        let countryLookup = lookupValues['Territory'].lookups.filter(l => l.code.length === 2).map(l => ({ code: l.code, description: l.description }))
                        countryLookup.sort(function(a, b) {
                            return a.code.localeCompare(b.code);
                        })
                        rowColumns.push(
                            <td key={key} className="td">
                                <DropdownDataInput changeData={this.changeUsageData} fieldName={key} value={dataItem.value} options={countryLookup} allowNull={true} isHidden={false} useComboBoxAsMenuWidth={false} showDropdownsAsCodes={true} />
                            </td>
                        );
                        break;
                    case 'checkbox':
                        rowColumns.push(
                            <td key={key} className="td">                                    
                                <CheckboxDataInput
                                    label=""
                                    fieldName={key}
                                    value={dataItem.value}
                                    changeData={this.changeUsageData}
                                    readonly={false}
                                    isHidden={null}
                                />
                                
                            </td>
                            );
                        break;
                }
        }
        rowColumns.sort((r1, r2) => {
            let index1 = dataGridTableData.fields.find(field => field.name === r1.key).order;
            let index2 = dataGridTableData.fields.find(field => field.name === r2.key).order;

            return index1 - index2
        });

        return rowColumns;
    }

    render() {
        const {
            usage,
            index,
            dataGridTableData,
            lookupValues,
            changeData,
            showModal,
            addUsageDetailsShareIPRow,
            tableContents,
            resetMessageBanner,
            onRowClick,
            focusedRowNumber,
            expandedUsageDetailsRow
        } = this.props;

        const {
            rowClicked
        } = this.state

        const columns: JSX.Element[] = this.getRowColumns(usage);
        return (
            <>
                <tr key={index} className={`tr ${focusedRowNumber === index && 'focusedTableRow'}`}>
                    <td className="td">
                        <i title="Expand row" className={["icon ms-Icon ms-Icon--Chevron", (rowClicked || (expandedUsageDetailsRow && expandedUsageDetailsRow.includes(index)) ) ? "Down" : "Right"].join("")} onClick={() => this.onClickRow()}/>
                    </td>
                    {columns}
                </tr>
                {(rowClicked || (expandedUsageDetailsRow && expandedUsageDetailsRow.includes(index)) ) &&
                    <UsageRowDropdown
                        usage={usage}
                        usageDataGridTableData={dataGridTableData}
                        componentInstance={USAGE_ROW_DROPDOWN_KEY}
                        lookupValues={lookupValues}
                        changeData={changeData}
                        showModal={showModal}
                        addUsageDetailsShareIPRow={addUsageDetailsShareIPRow}
                        usageRowViewIndex={index}
                        tableContents={tableContents}
                        resetMessageBanner={resetMessageBanner}
                    />
                }
            </>
        );

    }
}