import * as React from "react";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import DataGridTable from "../../../components/dataGridTable/DataGridTable";
import { ADD_NEW_ACTION, PAYMENTRUN_DISTRIBUTIONS_KEY, EMPTY_STRING_VALUE } from "../../../Consts";
import { showModal, hideModal } from "../../../../redux/reducers/ModalReducer";
import { updatePaymentRunField } from "../../../../redux/reducers/RepertoireReducer";
import { IRepertoireStateKeys } from "../../../types/IRepertoireStateKeys";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IPaymentRunStateKeys } from "../../../../redux/types/IPaymentRunState";

import { IDistributionSearchQuery } from "../../../types/usageTypes/IDistributionSearchQuery";
import { IPaymentRunDistributionState } from "../../../../redux/types/IPaymentRunDistributionState";
import DistributionsPage from "../../../distributions/DistributionsPage";
import { IDistribution } from "../../../types/usageTypes/IDistribution";

export interface IPaymentRunMaintenanceGridsViewProps {
    paymentRunMaintenanceGridsViewData: IRepertoireComponentDataItem,
    dataGridTableData?: IRepertoireComponentDataItem;
    isReadonly: boolean;
    lookupValues: ILookupDictionary;
    updatePaymentRunField: typeof updatePaymentRunField;
    showModal: typeof showModal;
    paymentRunDistributions: IPaymentRunDistributionState[];
    searchDistributions: (query: IDistributionSearchQuery) => void;
    distributions: IDistribution[];
    isNewPaymentRun?: boolean;
    isBulkCheckboxActive?:boolean;
    changeBulkCheckbox?: (value: boolean) => void;
    activeTab?: number;
    tabs?: ITabReduxItem[];
    resetMessageBanner?: () => void;
    incrementTempID?: () => void;
    onBackendSearch?: (value: string) => void;
    customer: string;
    searchDistributionByCode?: (search: IDistributionSearchQuery) => void;
    distributionCodeToSearch?: string;
    updateDistributionCodeToSearch?: (value: string) => void;
}

interface IPaymentRunMaintenanceGridsViewState {
    activeHeaderSection: string;
    loaded: boolean;
    distributionCodeToSearch: string;
}

export class PaymentRunMaintenanceGridsView extends React.PureComponent<
    IPaymentRunMaintenanceGridsViewProps,
    IPaymentRunMaintenanceGridsViewState
    > {
    viewData;
    constructor(props: IPaymentRunMaintenanceGridsViewProps) {
        super(props);
        this.state = {
            activeHeaderSection: PAYMENTRUN_DISTRIBUTIONS_KEY,
            loaded: false,
            distributionCodeToSearch: EMPTY_STRING_VALUE
        };
        this.viewData = {};
    }

    componentDidUpdate() {
        this.loadData();
    }

    componentDidMount() {
        this.loadData();
        const {
            searchDistributions,
            dataGridTableData: { fields }
        } = this.props;
        const query: IDistributionSearchQuery = {
            year: "",
            distributionType: "",
            distributionStatus: "",
            distributionCode: "",
            cat1: "",
            cat2: "",
            description: ""
        }
        searchDistributions(query);

        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);
            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;

                if (Object.keys(this.viewData).length === visibleFields.length) {
                    this.setState({ loaded: true });
                }
            });
        }
    }

    loadData() {
        const { loaded } = this.state;
        if (!loaded) {
            if (!this.props.paymentRunMaintenanceGridsViewData) {
                return;
            }
            else {
                this.setState({ loaded: true });
            }
        }
    }

    onClickHeaderSection = (activeHeaderSection: string) => {
        this.setState({
            activeHeaderSection
        });
    };

    renderGridViewHeaderSections = () => {
        const { activeHeaderSection } = this.state;
        const { paymentRunMaintenanceGridsViewData } = this.props;

        if (paymentRunMaintenanceGridsViewData && paymentRunMaintenanceGridsViewData.fields) {
            return paymentRunMaintenanceGridsViewData.fields.filter(f => f.fieldType === null).map((section, index) => (
                <div
                    className={[
                        "headerSection", activeHeaderSection === section.name ? "activeSection" : null
                    ].join(" ")}
                    key={index}
                    onClick={() => this.onClickHeaderSection(section.name)}
                >
                    {section.data}
                </div>
            ));
        }
    }

    changeData = (value: any, name: IRepertoireStateKeys, objectKey?: string, event?: any) => {
        const { lookupValues, updatePaymentRunField, distributions, searchDistributionByCode } = this.props;

        value = value.map(v => {
            v.distributionID = !!v.distributionCode.value
                ? distributions
                    .filter(d => d.distributionCode === v.distributionCode.value)
                    .map(d => d.distributionID)[0]
                : "";

            v.cat1.value = !!v.distributionCode.value
                ? distributions
                    .filter(d => d.distributionCode === v.distributionCode.value)
                    .map(d => d.cat1)[0]
                : "";

            v.cat2.value = !!v.distributionCode.value
                ? distributions
                    .filter(d => d.distributionCode === v.distributionCode.value)
                    .map(d => d.cat2)[0]
                : "";

            v.description.value = !!v.distributionCode.value
                ? distributions
                    .filter(d => d.distributionCode === v.distributionCode.value)
                    .map(d => d.description)[0]
                : "";

            return v;
        });

        updatePaymentRunField(value, name as IPaymentRunStateKeys, lookupValues, objectKey);
    }

    onSearchDistributions = (distributionCodeToSearch: string) => {
        const { searchDistributionByCode, updateDistributionCodeToSearch } = this.props;

        updateDistributionCodeToSearch(distributionCodeToSearch)

        if (distributionCodeToSearch && distributionCodeToSearch.length >= 5) {
            const searchQuery: IDistributionSearchQuery = {
                year: EMPTY_STRING_VALUE,
                distributionCode: distributionCodeToSearch,
                distributionType: EMPTY_STRING_VALUE,
                distributionStatus: EMPTY_STRING_VALUE,
                cat1: EMPTY_STRING_VALUE,
                cat2: EMPTY_STRING_VALUE,
                description: EMPTY_STRING_VALUE,
                pagination: {
                    count: 150,
                    skip: 0,
                    orderBy: "distributionCode",
                },
            };

            searchDistributionByCode(searchQuery);
        }
    }

    render() {
        const {
            isReadonly,
            lookupValues,
            dataGridTableData,
            paymentRunDistributions,
            showModal,
            distributions,
            isNewPaymentRun,
            isBulkCheckboxActive,
            changeBulkCheckbox,
            tabs,
            activeTab,
            resetMessageBanner,
            incrementTempID,
            customer,
            onBackendSearch,
        } = this.props;

        const {
            activeHeaderSection,
            loaded
        } = this.state;

        if (!loaded)
            return <div className="loading" />

        return (
            <div className="GridsView">
                <div className="headerContainer">
                    {this.renderGridViewHeaderSections()}
                </div>
                {activeHeaderSection === PAYMENTRUN_DISTRIBUTIONS_KEY ?
                    <DataGridTable
                        dataGridTableData={dataGridTableData}
                        componentInstance={PAYMENTRUN_DISTRIBUTIONS_KEY}
                        tableContents={paymentRunDistributions}
                        showRowNumber={false}
                        isReadOnly={isReadonly}
                        isExpandable={false}
                        showModal={showModal}
                        lookupValues={lookupValues}
                        tableActions={[{ name: ADD_NEW_ACTION }]}
                        distributionCodes={!!distributions ? distributions.map(d => d.distributionCode) : null}
                        stateKey={PAYMENTRUN_DISTRIBUTIONS_KEY}
                        changeData={this.changeData.bind(this)}
                        sortData={undefined}
                        tabs={tabs}
                        activeTab={activeTab}
                        incrementTempID={incrementTempID}
                        customer={customer }
                        isBackendSearchEnabled={customer === 'CEDRO' ? true : false}
                        onBackendSearch={this.onSearchDistributions.bind(this) }
                    /> : <div> </div>}
            </div>

        );
    }
}