import React from "react";
import { connect } from "react-redux";
import IAppState from "../../../redux/types/IAppState";
import IShortCutKeys from "../../../redux/types/IShortCutKeys";

export interface ISearchViewKeyEventListenerComponentProps {
    setStateInitial: () => void;
};
export interface IStateProps {shortCutKeys: IShortCutKeys[]}

type ICombinedToolbarConnectProps = ISearchViewKeyEventListenerComponentProps & IStateProps;

const SearchViewKeyEventListener: React.FC<ICombinedToolbarConnectProps> = ({
    setStateInitial,
    shortCutKeys,
}) => {
    const [keysPressed, setKeysPressed] = React.useState([]);
    const areCorrectKeysPressed = (actionCode, keysPressed) => {
        const shortCutKeysCommaSeparatedString = shortCutKeys && shortCutKeys.find(key => key.actionCode === actionCode)?.key
        const shortCutKeysArray = shortCutKeysCommaSeparatedString ? shortCutKeysCommaSeparatedString.split(','): [];
        let filteredRequiredPressedKeys = shortCutKeysArray && shortCutKeysArray.filter(keyString => keysPressed[keyString]);
        return filteredRequiredPressedKeys?.length === shortCutKeysArray?.length;
    }
    const emptySearchFields  = (e, pressedKeys) => {     

    if (areCorrectKeysPressed("EMPTY_FIELD_ON_SEARCH_GRID", pressedKeys)){
        setStateInitial()
        setKeysPressed(([]))
      }
    }

    const setKeyState = (event, state) => {
        setKeysPressed((prevState) => {
            const newState = {...prevState};
            newState[event.key] = state;
            emptySearchFields(event, newState);
            return newState});
     }

     const setTheKeyPressFalse = (event) => {
        setKeysPressed((prevState) => {
            const newState = {...prevState};
            newState[event.key] = false;
            return newState});
     }
     const handleBlur = () => {
        setKeysPressed((prevState) => ([]))
    };

 
    React.useEffect(() => {
 
           window.addEventListener('keydown', (event) => {
            setKeyState(event, true)
           })
           window.addEventListener('blur', handleBlur);
 
           window.addEventListener('keyup', (event) => {
            setTheKeyPressFalse(event)
          });

          return () => {
            
           window.addEventListener('keydown', (event) => {
            setKeyState(event, true)
           })
           window.addEventListener('blur', handleBlur);
            window.removeEventListener('keyup', (event) => {
                setTheKeyPressFalse(event)
             });
          }


    }, [])

return <></>;

}

export default connect((state: IAppState, props: ISearchViewKeyEventListenerComponentProps) => ({
    shortCutKeys: state.repertoire.shortCutKeys,
}), null)(SearchViewKeyEventListener);
