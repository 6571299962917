import throttle from "lodash.throttle";
import * as React from "react";
import { If } from "../../../../core/components/If";
import { IDistribution } from "../../../types/usageTypes/IDistribution";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import {
    hideModal,
    showModal,
    showYesNoPrompt
} from "../../../../redux/reducers/ModalReducer";
import {
    clearModalSearchResults, copyExistingPaymentRun, updatePaymentRunField
} from "../../../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import {
    ALL_ROLES,
    EVENT_HANDLER_THROTTLE_TIME, UPDATE_PAYMENT_RUNS_ROLE, EMPTY_STRING_VALUE
} from "../../../Consts";
import { ITreeData } from "../../../types/ITreeData";
import PaymentRunMaintenanceToolbar from "../paymentRunMaintenanceToolbar/PaymentRunMaintenanceToolbar";
import { IPaymentRunState } from "../../../../redux/types/IPaymentRunState";
import { ILastPercentageSearchQuery } from "../../../types/usageTypes/ILastPercentageSearchQuery";
import PaymentRunMaintenanceGeneralDataView from "../paymentRunMaintenanceGeneralDataView/PaymentRunMaintenaceGeneralDataView";
import { PaymentRunMaintenanceGridsView } from "../paymentRunMaintenanceGridsView/PaymentRunMaintenanceGridsView";
import { IDistributionSearchQuery } from "../../../types/usageTypes/IDistributionSearchQuery";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { FormatFields } from "../../../../redux/types/FormatFields";

export interface IPaymentRunMaintenancePageProps {
    paymentRunMaintenanceGeneralDataViewData: IRepertoireComponentDataItem;
    paymentRunMaintenanceToolbarData: IRepertoireComponentDataItem;
    paymentRunMaintenanceSaveResultData: IRepertoireComponentDataItem;
    paymentRunMaintenanceGridsViewData: IRepertoireComponentDataItem;
    dataGridTableData?: IRepertoireComponentDataItem;
    paymentRun: IPaymentRunState | undefined;
    activeTab: number;
    tabs: ITabReduxItem[];
    lookupValues: ILookupDictionary;
    hideModal: typeof hideModal;
    showYesNoPrompt: typeof showYesNoPrompt;
    resetMessageBanner: () => void;
    addNewPaymentRun: (lookupValues: ILookupDictionary, isNew?: boolean, paymentRunMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem) => void;
    updatePaymentRunField: typeof updatePaymentRunField;
    saveChanges: (paymentRun: IPaymentRunState, paymentRunMaintenanceGeneralDataViewData: IRepertoireComponentDataItem, lookupValues: ILookupDictionary) => void;
    undoPaymentRun: (paymentRunID: number, lookupValues: ILookupDictionary) => void;
    deletePaymentRun: (paymentRunId: number, activeTab: number, sources: ITreeData[]) => void;
    searchDistributions: (query: IDistributionSearchQuery) => void;
    distributions: IDistribution[];
    showModal: typeof showModal;
    clearModalSearchResults: typeof clearModalSearchResults;
    searchPaymentRunVersionHistory: (paymentRunId: number, lookups: ILookupDictionary, actionList: IDataActionToolbar[], formats: FormatFields[]) => void;
    copyPaymentRun: typeof copyExistingPaymentRun
    getLastPercentageValues: (lastPercentageSearchQuery: ILastPercentageSearchQuery, lookups: ILookupDictionary) => void;
    sources: ITreeData[];
    roles: string[];
    incrementTempID?: () => void;
    updatedScroll?: number;
    searchDistributionByCode: (query: IDistributionSearchQuery) => void;
    distributionCodeToSearch: string;
    updateDistributionCodeToSearch: (value: string) => void;
    customer: string;
}


interface IPaymentRunMaintenancePageState {
    toolbarWidth: number;
    isBulkCheckboxActive?: boolean;
}

export class PaymentRunMaintenancePage extends React.PureComponent<
    IPaymentRunMaintenancePageProps,
    IPaymentRunMaintenancePageState
> {
    toolbarParentRef;

    constructor(props: IPaymentRunMaintenancePageProps) {
        super(props);
        this.state = {
            toolbarWidth: null,
            isBulkCheckboxActive: false
        };

        this.toolbarParentRef = React.createRef();
    }

    savePaymentRun() {
        const { saveChanges, paymentRun, paymentRunMaintenanceGeneralDataViewData, lookupValues } = this.props;
        saveChanges(paymentRun, paymentRunMaintenanceGeneralDataViewData, lookupValues);
        this.setState({ isBulkCheckboxActive: false });
    }

    deletePaymentRun() {
        const { activeTab, paymentRun, sources } = this.props;
        this.props.deletePaymentRun(paymentRun.paymentRunID, activeTab, sources);
    }

    undoChanges = () => {
        const { paymentRun, undoPaymentRun, lookupValues } = this.props;
        undoPaymentRun(paymentRun.paymentRunID, lookupValues);
    }

    resetMessageBanner() {
        const { resetMessageBanner, updatePaymentRunField } = this.props;
        updatePaymentRunField(false, 'showStatusChangeWarning');
        resetMessageBanner();
    }

    changeBulkCheckbox = (value: boolean) => {
        this.setState({ isBulkCheckboxActive: value });
    }

    render() {
        if (this.props.paymentRun) {
            const {
                tabs,
                activeTab,
                paymentRun,
                paymentRunMaintenanceGeneralDataViewData,
                hideModal,
                showYesNoPrompt,
                paymentRunMaintenanceToolbarData,
                paymentRunMaintenanceSaveResultData,
                addNewPaymentRun,
                updatePaymentRunField,
                dataGridTableData,
                paymentRunMaintenanceGridsViewData,
                searchDistributions,
                distributions,
                showModal,
                searchPaymentRunVersionHistory,
                clearModalSearchResults,
                copyPaymentRun,
                roles,
                incrementTempID,
                updatedScroll,
                searchDistributionByCode,
                distributionCodeToSearch,
                updateDistributionCodeToSearch,
                customer,
            } = this.props;

            // Only Closed distributions can be attached to Payment Runs
            let filteredDistributions: IDistribution[] = distributions ? distributions.filter(distribution => distribution.distributionStatus === "Closed") : null;

            let isNewPaymentRun: boolean = this.props.paymentRun.paymentRunID < 1 ? true : false;
            let { lookupValues } = this.props;
            const title = paymentRun ? paymentRun.code : null;

            const changesMade = tabs[activeTab].changesMade || false;
            const isReadonly: boolean = tabs[activeTab].isReadonly;

            const {
                paymentRunDistributions
            } = this.props.paymentRun;

            const {
                toolbarWidth,
                isBulkCheckboxActive
            } = this.state;

            return (
                <div className="">
                    <div><span className="title">{title}</span></div>
                    <If condition={tabs[activeTab].versionNumber != null}>
                        <div><span>Version history number: {tabs[activeTab].versionNumber}</span></div>
                    </If>

                    <button type="button" className="toolbar-toggle" data-toggle="collapse" data-target="#toolbarContainer">
                        <i className="icon ms-Icon ms-Icon--MoreVertical" aria-hidden="true"></i>
                    </button>

                    <div id="toolbarContainer" className="WorkMaintenanceToolbar collapse" ref={this.toolbarParentRef}>
                        <PaymentRunMaintenanceToolbar
                            changesMade={changesMade}
                            showYesNoPrompt={showYesNoPrompt}
                            showModal={showModal}
                            hideModal={hideModal}
                            resetMessageBanner={this.resetMessageBanner.bind(this)}
                            scroll={updatedScroll}
                            toolbarWidth={toolbarWidth}
                            toolbarData={paymentRunMaintenanceToolbarData}
                            saveResultData={paymentRunMaintenanceSaveResultData}
                            paymentRun={paymentRun}
                            activeTab={activeTab}
                            tabs={tabs}
                            saveChanges={this.savePaymentRun.bind(this)}
                            deletePaymentRun={this.deletePaymentRun.bind(this)}
                            clearModalSearchResults={clearModalSearchResults}
                            isReadonly={isReadonly}
                            undoPaymentRunChanges={this.undoChanges.bind(this)}
                            searchVersionHistory={searchPaymentRunVersionHistory}
                            addNewPaymentRun={() => addNewPaymentRun(lookupValues, true, paymentRunMaintenanceGeneralDataViewData)}
                            isNew={tabs[activeTab].paymentRunMaintenanceState?.isNew}
                            lookupValues={lookupValues}
                            copyPaymentRun={copyPaymentRun}
                            roles={roles} />
                    </div>

                    <PaymentRunMaintenanceGeneralDataView
                        paymentRunMaintenanceGeneralDataViewData={paymentRunMaintenanceGeneralDataViewData}
                        paymentRun={paymentRun}
                        isReadonly={isReadonly}
                        lookupValues={lookupValues}
                        updatePaymentRunField={updatePaymentRunField}
                    />
                    <PaymentRunMaintenanceGridsView
                        dataGridTableData={dataGridTableData}
                        paymentRunMaintenanceGridsViewData={paymentRunMaintenanceGridsViewData}
                        updatePaymentRunField={updatePaymentRunField}
                        lookupValues={lookupValues}
                        isReadonly={paymentRun.status === 'Closed'}
                        showModal={showModal}
                        isNewPaymentRun={isNewPaymentRun}
                        paymentRunDistributions={paymentRunDistributions}
                        searchDistributions={searchDistributions}
                        distributions={filteredDistributions}
                        isBulkCheckboxActive={isBulkCheckboxActive}
                        changeBulkCheckbox={this.changeBulkCheckbox}
                        tabs={tabs}
                        activeTab={activeTab}
                        resetMessageBanner={this.resetMessageBanner.bind(this)}
                        incrementTempID={incrementTempID}
                        searchDistributionByCode={searchDistributionByCode}
                        distributionCodeToSearch={distributionCodeToSearch}
                        updateDistributionCodeToSearch={updateDistributionCodeToSearch}
                        customer={customer }
                    />

                </div>
            );
        }
        else
            return null;
    }
}
