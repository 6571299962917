import React, { useEffect } from "react";
import { IAgreementState } from "../../../../redux/types/IAgreementState";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";

interface IDraftAgreementsAssigneeReviewProps {
    agreement: IAgreementState;

}

const DraftAgreementsAssigneeReview: React.FC<IDraftAgreementsAssigneeReviewProps> = ({
    agreement,
}) => {
  const [agreementData, setAgreementData] = React.useState([]);

  useEffect(() => {

    if (agreement?.agreementAdminIP) {

    const dataSource = agreement.agreementAdminIP.map((assignee, index) => ({
      key: index,
      name: assignee.administeredName.value,
      assigneeNameNumber: assignee.administeredIP.value,
      mechanicalShare: (Number((assignee.mechanicalShare.value)).toFixed(2)),
      creatorRetainedMechShare: (Number((assignee.creatorRetainedMechShare.value)).toFixed(2)),
      publisherRetainedMechShare: (Number((assignee.publisherRetainedMechShare.value)).toFixed(2)),
      performingShare:(Number((assignee.performingShare.value)).toFixed(2)),
      creatorRetainedPerShare: (Number((assignee.creatorRetainedPerShare.value)).toFixed(2)),
      publisherRetainedPerShare: (Number((assignee.publisherRetainedPerShare.value)).toFixed(2)),
      societyAffiliation: assignee.societyAffiliation?.value ? assignee.societyAffiliation.value : "",
  }));
  setAgreementData(dataSource)
  }




  }, [agreement]);



    const columns: ColumnsType<any>  = [
        {
            title: 'Assignee Name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'Assignee Name Number',
            dataIndex: 'assigneeNameNumber',
            key: 'assigneeNameNumber',
          },
          {
            title: 'Mech Share Assigned',
            dataIndex: 'mechanicalShare',
            key: 'mechanicalShare',
          },
          {
            title: 'Creator Retained Mech Share',
            dataIndex: 'creatorRetainedMechShare',
            key: 'creatorRetainedMechShare',
          },
          {
            title: 'Publisher Retained Mech Share',
            dataIndex: 'publisherRetainedMechShare',
            key: 'publisherRetainedMechShare',
          },
          {
            title: 'Perf Share Assigned',
            dataIndex: 'performingShare',
            key: 'performingShare',
          },
          {
            title: 'Creator Retained Perf Share',
            dataIndex: 'creatorRetainedPerShare',
            key: 'creatorRetainedPerShare',
          },
          {
            title: 'Publisher Retained Perf Share',
            dataIndex: 'publisherRetainedPerShare',
            key: 'publisherRetainedPerShare',
          },
          {
            title: 'Society Affiliation',
            dataIndex: 'societyAffiliation',
            key: 'societyAffiliation',
          },
]


    return <div className={``}>
                    <div className="headerContainer">
            <div
                className={["headerSection", "activeSection"].join(" ")}
                key={'Assignee'}
                data-testid={'Assignee' + "-section"}
            >
                {'Assignee'}
            </div>
        </div>
        <div className='assigneeTable'>
        <Table dataSource={agreementData} columns={columns}  />
        </div>
    </div>
}

export default DraftAgreementsAssigneeReview;