import { Input, Space, Table } from 'antd';
import crypto from 'crypto';
import React from 'react';
import { hideModal, showModal } from '../../../../redux/reducers/ModalReducer';
import { addMatchWorkToUsage, searchDataChanged, updateAdjustmentField } from '../../../../redux/reducers/RepertoireReducer';
import { IAdjustmentDetails } from '../../../../redux/types/IAdjustmentDetails';
import { IAdjustmentState, IAdjustmentStateKeys } from '../../../../redux/types/IAdjustmentState';
import IRepertoireComponentDataItem from '../../../../redux/types/IRepertoireComponentDataItem';
import ActionButton, { SizedActionButton } from '../../../components/actionButton/ActionButton';
import IconTextButton from '../../../components/iconTextButton/IconTextButton';
import ShowMore from '../../../components/showMore/ShowMore';
import { ALLOCATION_ACCOUNT_NUMBER, ALLOCATION_ADJUSTMENT_IP_KEY, ALLOCATION_AMOUNT_ADJUSTED, ALLOCATION_DISTRIBUTION, ALLOCATION_HEADER_ID, ALLOCATION_IPI_NUMBER, ALLOCATION_IP_NAME, ALLOCATION_POINT_ADJUSTED, ALLOCATION_POOL, ALLOCATION_SEGMENT, ALLOCATION_SHARE_ADJUSTED, ALLOCATION_WORK_NAME, ALLOCATION_WORK_NUMBER, REMOVE_ACTION, REPERTOIRE, SEARCH_VIEW, USAGE_MATCHING_WORKS_SECTION_KEY, WORK_MAINTENANCE_TOOLBAR } from '../../../Consts';
import { AllocationHeaderRowType } from '../allocationSelectionView/AllocationSelectionView';
import UsageSearchView from '../../../components/usageComponents/searchView/UsageSearchView';
import { IUsagesSearchQuery } from '../../../types/usageTypes/IUsagesSearchQuery';
import { ITreeData } from '../../../types/ITreeData';
import { IWorksSearchResult } from '../../../types/IWorksSearchResult';
import { IWorksSearchQuery } from '../../../types/IWorksSearchQuery';
import { IMatchWorksSearchQuery } from '../../../types/usageTypes/IMatchWorksSearchQuery';
import { IMatchProductsSearchQuery } from '../../../types/usageTypes/IMatchProductsSearchQuery';
import { IMatchWorksSearchResult } from '../../../types/usageTypes/IMatchWorksSearchResult';
import { IDistributionType } from '../../../types/usageTypes/IDistibutionType';
import { ILookupDictionary } from '../../../../lookup/types/ILookupDictionary';
import { IDataActionToolbar } from '../../../types/IDataActionToolbar';
import { FormatFields } from '../../../../redux/types/FormatFields';
import { IUsageGroupState } from '../../../../redux/types/IUsageGroupState';
import { IAdjustmentMaintenanceStateKeys } from '../../../../redux/types/IAdjustmentMaintenanceState';
import { IMatchingWorksRowData } from '../../../types/usageTypes/IMatchingWorksRowData';
import { SearchRequests } from '../../../services/SearchRequests';
import { CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP } from '../../../ConfigurationConsts';
import { getDataAction } from '../../../components/toolBar/ToolbarHelper';
import { ITabReduxItem } from '../../../../redux/types/ITabReduxItem';
import { SCREEN_SIZE_BIG_ENOUGH_FOR_TWO_SCREENS } from '../../../usage/components/usageMaintenancePage/UsageMaintenancePage';
import { DataSource } from '../../../types/DataSource';

interface IAllocationAdjustmentDetailsViewProps {
    allocationAdjustmentDetailsViewData: IRepertoireComponentDataItem;
    adjustment: IAdjustmentState;
    updateAdjustmentField: (value: any, fieldName: IAdjustmentStateKeys) => void;
    showModal: typeof showModal;
    allocationRecords: AllocationHeaderRowType[];
    searchUsages?: (query: IUsagesSearchQuery, modalOpen: boolean, usageType: string, lookupSources: ITreeData[], continuationToken?: string) => void;
    resetPagination?: (repertoireSection: string) => void;
    updatePagination?: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    sortSearchResults?: (name: string, results: any) => void;
    searchWorks?: (query: IWorksSearchQuery, modalOpen: boolean) => void;
    worksSearchResults?: IWorksSearchResult[];
    expandedWorkResults?: number[];
    searchSuccessful?: boolean;
    expandAll?: boolean;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    expandWorkResult?: (workResult: number) => void;
    expandAllResults?: () => void;
    currentPage?: number;
    username?: string;
    searchMatchWorks?: (query: IMatchWorksSearchQuery, modalOpen: boolean) => void;
    searchMatchProducts?: (query: IMatchProductsSearchQuery, modalOpen: boolean) => void;
    matchWorkSearchResult?: IMatchWorksSearchResult[];
    expandedMatchWorkResults?: number[];
    distributionTypes?: IDistributionType[];
    lookupSources?: ITreeData[];
    usageMatchingDefaultsWorks?: string[];
    addMatchworkToUsage?: typeof addMatchWorkToUsage;
    usageExpandAllResults?: () => void;
    expandMatchWorkResult?: (matchWorkResults: number) => void;
    paneIndex?: number;
    formatFields?: FormatFields[];
    updateUsagesAdvanced?: boolean;
    lookupValues?: ILookupDictionary;
    searchResultsTableData?: IRepertoireComponentDataItem;
    searchViewData?: IRepertoireComponentDataItem;
    getWorkDetails?: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], readonlyIndicatorsWorkFlagTypes: string[], dataActions?: IDataActionToolbar[], workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem, formats?: FormatFields[], openEntityForWorflowSession?: boolean, isOpenedInSecondTab?: boolean) => void;
    isAllocationSelected?: boolean;
    isWorkAdjust?: boolean;
    updateAllocationRecordField: (value: any, fieldName: IAdjustmentMaintenanceStateKeys) => void;
    saveSuccessful?:boolean;
    workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem;
    activeTab: number;
    tabs: ITabReduxItem[];
    isReadonly?:boolean;
}

interface AllocationAdjustmentDetailsRowType {
    key: React.Key;
    direction: string;
    allocationHeaderID?: number;
    adjustmentDetailID: number;
    [adjustmentDetailID: number]: number;
    distribution: string;
    pool: string;
    segment: string;
    workNumber: string;
    workName: string;
    accountNumber: string;
    name: string;
    ipiNumber: number;
    sharePctAdj: string;
    pointsAdj?: string;
    amountAdj: string;
}

interface IAllocationAdjustmentDetailsViewState {
    loaded: boolean;
    searchCriteriaVisible: boolean;
    workAdjustmentIndex: number;
    isWorkLevelAdjustment?: boolean;
    setMatchWorkSearchResult?: any
}

export class AllocationAdjustmentDetailsView extends React.Component<
    IAllocationAdjustmentDetailsViewProps,
    IAllocationAdjustmentDetailsViewState> {

    viewData;
    constructor(props: IAllocationAdjustmentDetailsViewProps) {
        super(props);
        this.state = {
            loaded: false,
            searchCriteriaVisible: false,
            workAdjustmentIndex: undefined,
            isWorkLevelAdjustment: false,
            setMatchWorkSearchResult:[]
        };
        this.viewData = {};
    }

    componentDidMount() {
        const {
            allocationAdjustmentDetailsViewData: { fields },matchWorkSearchResult
        } = this.props
        if (fields) {
            const visibleFields = fields.filter(field => !field.hidden);
            visibleFields.forEach(item => {
                this.viewData[item.name] = item.data;
            });

            if (Object.keys(this.viewData).length === visibleFields.length) {
                this.setState({ loaded: true, setMatchWorkSearchResult:matchWorkSearchResult});
            }
        }
    }

    componentDidUpdate(prevProps: IAllocationAdjustmentDetailsViewProps, prevState: IAllocationAdjustmentDetailsViewState) {
        const { setMatchWorkSearchResult } = this.state;
        const { matchWorkSearchResult,saveSuccessful } = this.props;
        if (prevProps.matchWorkSearchResult !== matchWorkSearchResult) {
            this.setState({ setMatchWorkSearchResult: matchWorkSearchResult });
        }
        if(prevProps.saveSuccessful!==saveSuccessful){
            this.setState({ workAdjustmentIndex:undefined });
        }
    }

    onInputChange = (key, index, id) => (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const { adjustment: { adjustmentDetails }, updateAdjustmentField } = this.props;
        const adjustmentDetailsUpdate = adjustmentDetails;
        if (key == 'ipNumber')
            adjustmentDetailsUpdate[index].adjustmentDetailTo.find(x => x.adjustmentDetailID === id)[key] = +e.target.value;
        else
            adjustmentDetailsUpdate[index].adjustmentDetailTo.find(x => x.adjustmentDetailID === id)[key] = e.target.value;
        updateAdjustmentField(adjustmentDetailsUpdate, 'adjustmentDetails');
    };

    showSearchModal = (modalProps: any) => {
        const { showModal } = this.props;
        showModal(SEARCH_VIEW, ALLOCATION_ADJUSTMENT_IP_KEY, modalProps, true, "Search");
    };

    onClickRemove = (direction: string, index: number, detailsIndex?: number) => {
        const { updateAdjustmentField, adjustment: { adjustmentDetails }, allocationRecords, updateAllocationRecordField } = this.props;
        let updatedDetails = adjustmentDetails;
        let updatedAllocatedRecords = allocationRecords;

        if (adjustmentDetails[index]) {
            if (direction === 'From') {
                if (updatedDetails[index].adjustmentDetailFrom.length === 1)
                    updatedDetails.splice(index, 1);
                else
                    updatedDetails[index].adjustmentDetailFrom.splice(detailsIndex, 1);
            }
            else if (direction === 'To') {
                updatedDetails[index].adjustmentDetailTo.splice(detailsIndex, 1)
            }            
        }
        if (updatedDetails && updatedDetails.length > 0) {
            let adjustmentDetailsIds = []
           
            updatedDetails.map(item => adjustmentDetailsIds.push(item.id))
            let updatedRecords = updatedAllocatedRecords.map(x => {
                const isSelected = adjustmentDetailsIds.includes(x.AllocationUsageHeaderId);
                return { ...x, IsSelected: isSelected };
            });
            updateAllocationRecordField(updatedRecords, 'allocationHeaderDetails')
        } else {
            updatedAllocatedRecords = updatedAllocatedRecords.map(item => ({
                ...item,
                IsSelected: false 
            }));
            updateAllocationRecordField(updatedAllocatedRecords, 'allocationHeaderDetails')
        }
        updateAdjustmentField(updatedDetails, 'adjustmentDetails');
    };

    addRowToAdjustmentDetail = (index) => {
        const { adjustment, adjustment: { adjustmentDetails }, updateAdjustmentField, isWorkAdjust } = this.props;
        let updatedAdjustmentDetails = adjustmentDetails;
        let updatedAdjustmentDetail = updatedAdjustmentDetails[index];
        updatedAdjustmentDetails[index].adjustmentDetailTo.push({
            adjustmentDetailID: index,
            adjustmentId: adjustment.id,
            allocationHeaderID: updatedAdjustmentDetail.id,
            distribution: adjustment.adjustmentDistribution,
            pool: adjustment.adjustmentPool,
            segment: adjustment.adjustmentSegment,
            workNumber: isWorkAdjust ? updatedAdjustmentDetail.adjustmentDetailTo[0].workNumber : updatedAdjustmentDetail.adjustmentDetailFrom[0].workNumber,
            workName: isWorkAdjust ? updatedAdjustmentDetail.adjustmentDetailTo[0].workName : updatedAdjustmentDetail.adjustmentDetailFrom[0].workName,
            accountNumber: '',
            name: '',
            ipNumber: undefined,
            sharePercentAdjusted: '',
            pointsAdjusted: '',
            amountAdjusted: ''
        });
        updateAdjustmentField(updatedAdjustmentDetails, 'adjustmentDetails');
    }

    applyAdjustmentToAllSelectedAllocationRecords = (adjustmentDetail: IAdjustmentDetails) => {
        let { adjustment, adjustment: { adjustmentDetails }, allocationRecords, updateAdjustmentField,isReadonly } = this.props;
        adjustmentDetails = [];
        allocationRecords?.filter(x => x.IsSelected).forEach((value) => {
            if (this.state.isWorkLevelAdjustment) {
                const newAllocationDetail: IAdjustmentDetails = {
                    id: value.AllocationUsageHeaderId,
                    adjustmentDetailFrom: adjustmentDetail.adjustmentDetailFrom.map((item, index) => ({
                        
                        adjustmentId: adjustment.id,
                        distribution: adjustment.sourceDistribution,
                        segment: adjustment.sourceSegment,
                        allocationHeaderID: value.AllocationUsageHeaderId,
                        ipNumber: item.ipNumber,
                        accountNumber: item.accountNumber,
                        name: item.name,
                        amountAdjusted: item.amountAdjusted,
                        pointsAdjusted: item.pointsAdjusted,
                        sharePercentAdjusted: item.sharePercentAdjusted,
                        pool: adjustmentDetail.adjustmentDetailFrom[0]['pool'],
                        workNumber: value.WorkNumber,
                        workName: value.WorkName
                    })),
                    adjustmentDetailTo: adjustmentDetail.adjustmentDetailTo.map((obj) => ({ ...obj }))
                }
    
                newAllocationDetail.adjustmentDetailTo.forEach(x => {
                    const adj = newAllocationDetail.adjustmentDetailFrom[0];
                    const amount = newAllocationDetail.adjustmentDetailFrom.reduce((a, b) => { return Number(a) + Number(b.amountAdjusted) }, 0);
                    const newDetailShares = newAllocationDetail.adjustmentDetailFrom.reduce((a, b) => { return Number(a) + Number(b.sharePercentAdjusted) }, 0);
                    const existingDetailShares = adjustmentDetail.adjustmentDetailFrom.reduce((a, b) => { return Number(a) + Number(b.sharePercentAdjusted) }, 0);
    
                    const newDetailPointsAdjusted = newAllocationDetail.adjustmentDetailFrom.reduce((a, b) => { return Number(a) + Number(b.pointsAdjusted) }, 0);
                    const existingDetailPointsAdjusted = adjustmentDetail.adjustmentDetailFrom.reduce((a, b) => { return Number(a) + Number(b.pointsAdjusted) }, 0);
    
    
    
                    x.allocationHeaderID = adj.allocationHeaderID;
                    x.distribution = adjustment.adjustmentDistribution;
                    x.segment = adj.segment;
                    x.pool = adjustment.adjustmentPool;
                    x.workNumber = x.workNumber;
                    x.workName = x.workName;
                    x.sharePercentAdjusted = ((Number(x.sharePercentAdjusted) / existingDetailShares) * newDetailShares).toString();
                    x.pointsAdjusted = ((Number(x.pointsAdjusted) / existingDetailPointsAdjusted) * newDetailPointsAdjusted).toString();
                    x.amountAdjusted = (amount * (Number(x.sharePercentAdjusted) / newDetailShares)).toString()
                })
                adjustmentDetails.push(newAllocationDetail);
                updateAdjustmentField(adjustmentDetails, 'adjustmentDetails');
            } else {
                const validateThatAdjustmentDetailIsAlreadyInAllocatedDetails = () => {
                    return adjustmentDetails.some(x => x.id === value.AllocationUsageHeaderId);
                }
    
                const validateThatAdjustmentDetailsAreSimilarEnoughToBeCopied = (comparisonAdjDet: AllocationHeaderRowType, existingAdjDet: IAdjustmentDetails) => {
                    const existingFromIps = existingAdjDet.adjustmentDetailFrom.map(x => x.ipNumber);
                    const comparisonFromIps = comparisonAdjDet.UsageDetails.map(x => x[1]);
    
                    return existingFromIps.every(r => comparisonFromIps.includes(r));
                }
    
                if (validateThatAdjustmentDetailIsAlreadyInAllocatedDetails()) return;
                if (!validateThatAdjustmentDetailsAreSimilarEnoughToBeCopied(value, adjustmentDetail)) return;
    
                const fromIps = adjustmentDetail.adjustmentDetailFrom.map(x => x.ipNumber);
                const usageDetails = value.UsageDetails.filter(x => fromIps.includes(x[1]));
                const newAllocationDetail: IAdjustmentDetails = {
                    id: value.AllocationUsageHeaderId,
                    adjustmentDetailFrom: usageDetails.map((item, index) => ({
                        adjustmentId: adjustment.id,
                        distribution: adjustment.sourceDistribution,
                        segment: adjustment.sourceSegment,
                        allocationHeaderID: item[0],
                        ipNumber: item[1],
                        accountNumber: item[3],
                        name: item[4],
                        amountAdjusted: (item[5] * 1).toString(),
                        pointsAdjusted: (item[6] * 1).toString(),
                        sharePercentAdjusted: (item[7] * 1).toString(),
                        pool: adjustmentDetail.adjustmentDetailFrom[0]['pool'],
                        workNumber: value.WorkNumber,
                        workName: value.WorkName
                    })),
                    adjustmentDetailTo: adjustmentDetail.adjustmentDetailTo.map((obj) => ({ ...obj }))
                }

                newAllocationDetail.adjustmentDetailTo.forEach(x => {
                    const adj = newAllocationDetail.adjustmentDetailFrom[0];
                    const amount = newAllocationDetail.adjustmentDetailFrom.reduce((a, b) => { return Number(a) + Number(b.amountAdjusted) }, 0);
                    const newDetailShares = newAllocationDetail.adjustmentDetailFrom.reduce((a, b) => { return Number(a) + Number(b.sharePercentAdjusted) }, 0);
                    const existingDetailShares = adjustmentDetail.adjustmentDetailFrom.reduce((a, b) => { return Number(a) + Number(b.sharePercentAdjusted) }, 0);

                    const newDetailPointsAdjusted = newAllocationDetail.adjustmentDetailFrom.reduce((a, b) => { return Number(a) + Number(b.pointsAdjusted) }, 0);
                    const existingDetailPointsAdjusted = adjustmentDetail.adjustmentDetailFrom.reduce((a, b) => { return Number(a) + Number(b.pointsAdjusted) }, 0);



                    x.allocationHeaderID = adj.allocationHeaderID;
                    x.distribution = adjustment.adjustmentDistribution;
                    x.segment = adj.segment;
                    x.pool = adjustment.adjustmentPool;
                    x.workNumber = adj.workNumber;
                    x.workName = adj.workName;
                    x.sharePercentAdjusted = ((Number(x.sharePercentAdjusted) / existingDetailShares) * newDetailShares).toString();
                    x.pointsAdjusted = ((Number(x.pointsAdjusted) / existingDetailPointsAdjusted) * newDetailPointsAdjusted).toString();
                    x.amountAdjusted = (amount * (Number(x.sharePercentAdjusted) / newDetailShares)).toString()
                })

                adjustmentDetails.push(newAllocationDetail);

                updateAdjustmentField(adjustmentDetails, 'adjustmentDetails');
            }

        })
    }
    searchWorkForAdjustment = (value, index?: number, adjustmentDetail?: IAdjustmentDetails) => {
        const { adjustment: { adjustmentDetails } } = this.props;
        this.setState({ searchCriteriaVisible: !value, workAdjustmentIndex: this.state.workAdjustmentIndex===index? null:index, isWorkLevelAdjustment: true,setMatchWorkSearchResult:[] })
    }
    updateAdjustmentDetails = (adjustmentDetails: any) => {
        const { updateAdjustmentField } = this.props;
        if (adjustmentDetails) {
            updateAdjustmentField(adjustmentDetails, 'adjustmentDetails');
        }


    }
    openWork(openItem: IMatchingWorksRowData) {        
        const isScreenWidthBigEnoughForTwoScreens = window.innerWidth > SCREEN_SIZE_BIG_ENOUGH_FOR_TWO_SCREENS;
             const { lookupValues, workMaintenanceGeneralDataViewData, tabs, activeTab } = this.props;
             const dataActionList = getDataAction(DataSource.Repertoire, WORK_MAINTENANCE_TOOLBAR);
             const formatFields = SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
                 .then(formats => {
                     this.props.getWorkDetails(DataSource.Repertoire, openItem.workID, lookupValues, tabs[activeTab].otherIndicatorWorkFlagTypes, tabs[activeTab].readonlyIndicatorWorkFlagTypes, dataActionList, workMaintenanceGeneralDataViewData, formats, undefined,  false);
                 });
    }
    render() {
        const { adjustment, adjustment: { adjustmentDetails }, searchUsages, searchSuccessful, indexOfFirstResult, resultsPerPage,
            resetPagination, updatePagination, searchMatchWorks, searchMatchProducts, matchWorkSearchResult, expandMatchWorkResult,
            expandedMatchWorkResults, addMatchworkToUsage, lookupValues, lookupSources, distributionTypes, usageMatchingDefaultsWorks,
            usageExpandAllResults, searchResultsTableData, indexOfLastResult, currentPage, searchViewData, getWorkDetails, isReadonly,isAllocationSelected } = this.props;
        const { loaded } = this.state;
        let filteredSearchViewData = { fields: [] };
        let searchTypeField;
        let isSearchTypeHidden;
        if (searchViewData) {
            const filtered = searchViewData.fields.filter(f => f.componentInstance === USAGE_MATCHING_WORKS_SECTION_KEY);
            searchTypeField = filtered && filtered.find(x => x.name === 'searchType');
            isSearchTypeHidden = searchTypeField && searchTypeField.hidden
            filteredSearchViewData = { fields: searchViewData.fields.filter(f => !['usageType', 'searchType'].includes(f.name)) };
        }
        let tableList = [];
        if (loaded) {
            if (adjustmentDetails && adjustmentDetails.length > 0) {

                let totalAmount = 0;
                let totalPoints = 0;
                let totalSharePercentage = 0;

                adjustmentDetails.forEach((adjustmentDetail, index) => {
                    let data: AllocationAdjustmentDetailsRowType[] = [];

                    let adjustmentDetailIdOrigin = null;
                    if ((adjustmentDetail.adjustmentDetailFrom != null) && (adjustmentDetail.adjustmentDetailFrom.length > 0))
                        adjustmentDetailIdOrigin = adjustmentDetail.adjustmentDetailFrom[0];
                    if ((adjustmentDetailIdOrigin == null) && (adjustmentDetail.adjustmentDetailTo != null) && (adjustmentDetail.adjustmentDetailTo.length > 0))
                        adjustmentDetailIdOrigin = adjustmentDetail.adjustmentDetailTo[0];
                    
                    adjustmentDetail.id = adjustmentDetailIdOrigin ? adjustmentDetailIdOrigin.allocationHeaderID : null;
                    adjustmentDetail.adjustmentDetailFrom.forEach((adjustmentDetailFrom, i) => {
                        data.push({
                            key: i === 0 ? `${adjustmentDetailFrom.allocationHeaderID}_999_From` : `${adjustmentDetailFrom.allocationHeaderID}_${(i * -1)}_From`,
                            direction: 'From',
                            allocationHeaderID: adjustmentDetailFrom.allocationHeaderID,
                            distribution: adjustmentDetailFrom.distribution,
                            pool: adjustmentDetailFrom.pool,
                            segment: adjustmentDetailFrom.segment,
                            workNumber: adjustmentDetailFrom.workNumber,
                            workName: adjustmentDetailFrom.workName,
                            accountNumber: adjustmentDetailFrom.accountNumber,
                            name: adjustmentDetailFrom.name,
                            ipiNumber: adjustmentDetailFrom.ipNumber,
                            sharePctAdj: adjustmentDetailFrom.sharePercentAdjusted,
                            pointsAdj: adjustmentDetailFrom.pointsAdjusted,
                            amountAdj: adjustmentDetailFrom.amountAdjusted,
                            adjustmentDetailID: i
                        });
                        totalAmount += parseFloat(adjustmentDetailFrom.amountAdjusted)
                        totalPoints += parseFloat(adjustmentDetailFrom.pointsAdjusted)
                        totalSharePercentage += parseFloat(adjustmentDetailFrom.sharePercentAdjusted)
                    });

                    adjustmentDetail.adjustmentDetailTo.forEach((adjustmentDetailTo, i) => {

                        let pointsAdjusted =
                            parseFloat(adjustmentDetailTo.sharePercentAdjusted) <= 100 && parseFloat(adjustmentDetailTo.sharePercentAdjusted) >= 0 ?
                                ((parseFloat(adjustmentDetailTo.sharePercentAdjusted) / totalSharePercentage) * totalPoints).toString() : "0"
                        let amountAdjusted =
                            parseFloat(adjustmentDetailTo.sharePercentAdjusted) <= 100 && parseFloat(adjustmentDetailTo.sharePercentAdjusted) >= 0 ?
                                ((parseFloat(adjustmentDetailTo.sharePercentAdjusted) / totalSharePercentage) * totalAmount).toString() : "0"

                        data.push({
                            key: i === 0 ? `${adjustmentDetailTo.allocationHeaderID}_999_To` : `${adjustmentDetailTo.allocationHeaderID}_${(i * -1)}_To`,
                            direction: 'To',
                            allocationHeaderID: adjustmentDetailTo.allocationHeaderID,
                            distribution: adjustmentDetailTo.distribution,
                            pool: adjustmentDetailTo.pool?adjustmentDetailTo.pool:adjustmentDetail.adjustmentDetailFrom[0].pool,
                            segment: adjustmentDetailTo.segment,
                            workNumber: adjustmentDetailTo.workNumber,
                            workName: adjustmentDetailTo.workName,
                            accountNumber: adjustmentDetailTo.accountNumber,
                            name: adjustmentDetailTo.name,
                            ipiNumber: adjustmentDetailTo.ipNumber,
                            sharePctAdj: adjustmentDetailTo.sharePercentAdjusted,
                            pointsAdj: pointsAdjusted,
                            amountAdj: amountAdjusted,
                            adjustmentDetailID: i
                        });
                        adjustmentDetailTo.adjustmentDetailID = i;
                    });

                    let columns = [
                        {
                            title: '', dataIndex: 'direction', key: 'direction', render: (_, value, index) => {
                                const text = value.direction;
                                return <><Space size="middle"><strong>{text}</strong></Space></>
                            },
                        },
                        { title: this.viewData[ALLOCATION_HEADER_ID], dataIndex: ALLOCATION_HEADER_ID, key: ALLOCATION_HEADER_ID },
                        { title: this.viewData[ALLOCATION_DISTRIBUTION], dataIndex: ALLOCATION_DISTRIBUTION, key: ALLOCATION_DISTRIBUTION },
                        { title: this.viewData[ALLOCATION_POOL], dataIndex: ALLOCATION_POOL, key: ALLOCATION_POOL },
                        { title: this.viewData[ALLOCATION_SEGMENT], dataIndex: ALLOCATION_SEGMENT, key: ALLOCATION_SEGMENT },
                        {
                            title: this.viewData[ALLOCATION_WORK_NUMBER], dataIndex: ALLOCATION_WORK_NUMBER, key: ALLOCATION_WORK_NUMBER, render: (text, record, index) => {
                                return <><div className={"widerFieldNumber"}><span>{record.workNumber}</span></div></>
                            }
                        },
                        {
                            title: this.viewData[ALLOCATION_WORK_NAME], dataIndex: ALLOCATION_WORK_NAME, key: ALLOCATION_WORK_NAME, render: (text, record, index) => {
                                return <><div className={"widerField"}><span>{record.workName}</span></div></>
                            }
                        },
                        {
                            title: this.viewData[ALLOCATION_ACCOUNT_NUMBER], dataIndex: ALLOCATION_ACCOUNT_NUMBER, key: ALLOCATION_ACCOUNT_NUMBER, render: (text, record, index) => {
                                return <Input title={this.viewData[ALLOCATION_ACCOUNT_NUMBER]} className={"widerFieldNumber"} value={record.accountNumber} readOnly={true} onChange={this.onInputChange("accountNumber", index, record.adjustmentDetailID)} />
                            }
                        },
                        {
                            title: this.viewData[ALLOCATION_IP_NAME], dataIndex: ALLOCATION_IP_NAME, key: ALLOCATION_IP_NAME, render: (text, record, index) => {
                                return <Input title={this.viewData[ALLOCATION_IP_NAME]} className={"widerField"} value={record.name} readOnly={true} onChange={this.onInputChange("name", index, record.adjustmentDetailID)} />
                            }
                        },
                        {
                            title: this.viewData[ALLOCATION_IPI_NUMBER], dataIndex: ALLOCATION_IPI_NUMBER, key: ALLOCATION_IPI_NUMBER, render: (text, record) => (
                                <div title={this.viewData[ALLOCATION_IPI_NUMBER]} className='hasIcon'>
                                    <Input className={"widerFieldNumber"} value={record.ipiNumber} readOnly={record.direction === 'From'?true:isReadonly}  type={'number'} onChange={this.onInputChange("ipNumber", index, record.adjustmentDetailID)} />
                                    {(record.direction === 'To' && adjustment.dataSource !== 'Posted') &&
                                        <ShowMore
                                            options={[
                                                {
                                                    text: 'Select IP',
                                                    onClick: () => { this.showSearchModal({ adjustmentDetails: adjustmentDetails[index], index: record.adjustmentDetailID }); },
                                                    icon: "assets/external.svg"
                                                }
                                            ]}
                                        />}
                                </div>
                            )
                        },
                        {
                            title: this.viewData[ALLOCATION_SHARE_ADJUSTED], dataIndex: ALLOCATION_SHARE_ADJUSTED, key: ALLOCATION_SHARE_ADJUSTED, render: (text, record) => (
                                <Input title={this.viewData[ALLOCATION_SHARE_ADJUSTED]} className={"percentageField"} value={record.sharePctAdj} readOnly={record.direction === 'From'?true:isReadonly} type={'number'} onChange={this.onInputChange("sharePercentAdjusted", index, record.adjustmentDetailID)} />
                            )
                        },
                        {
                            title: this.viewData[ALLOCATION_POINT_ADJUSTED], dataIndex: ALLOCATION_POINT_ADJUSTED, key: ALLOCATION_POINT_ADJUSTED, render: (text, record) => (
                                <Input title={this.viewData[ALLOCATION_POINT_ADJUSTED]} value={record.pointsAdj} readOnly={true} type={'number'} onChange={this.onInputChange("pointsAdjusted", index, record.adjustmentDetailID)} />
                            )
                        }, {
                            title: this.viewData[ALLOCATION_AMOUNT_ADJUSTED], dataIndex: ALLOCATION_AMOUNT_ADJUSTED, key: ALLOCATION_AMOUNT_ADJUSTED, render: (text, record) => (
                                <Input title={this.viewData[ALLOCATION_AMOUNT_ADJUSTED]} className={"widerFieldNumber"} value={record.amountAdj} readOnly={true} type={'number'} onChange={this.onInputChange("amountAdjusted", index, record.adjustmentDetailID)} />
                            )
                        },
                        {
                            title: '', dataIndex: REMOVE_ACTION, key: REMOVE_ACTION, render: (text, record) => (
                                record.direction === 'From' ?
                                    <div className="tableCell">
                                        <IconTextButton onClick={() => this.onClickRemove('From', index, record.adjustmentDetailID)} text={'Remove'} icon={"icon ms-Icon ms-Icon--Delete"} disabled={adjustment.dataSource === 'Posted'} />
                                    </div>
                                    :
                                    <div>
                                        <IconTextButton onClick={() => this.onClickRemove('To', index, record.adjustmentDetailID)} text={'Remove'} icon={"icon ms-Icon ms-Icon--Delete"} disabled={adjustment.dataSource === 'Posted'} />
                                    </div>
                            )
                        }
                    ]
                    tableList.push(
                        <div key={index}>
                            <div className="row">
                                <div className="col-md-6">
                                    {index === 0 &&
                                        <span className="title">Adjustment Details for Selected Allocation Records</span>
                                    }

                                </div>
                                {isAllocationSelected && <div className="col-md-3">
                                    <ActionButton
                                        buttonAction={() => this.searchWorkForAdjustment(this.state.searchCriteriaVisible, index, adjustmentDetail)}
                                        buttonText={(index === this.state.workAdjustmentIndex) ? 'Close Search Work for Adjustment' : 'Search Work for Adjustment'}
                                        disabled={adjustment.dataSource === 'Posted'}
                                    />
                                </div>}
                                <div className="col-md-3" style={{ float: 'inline-end' }}>
                                    <ActionButton
                                        buttonAction={() => this.applyAdjustmentToAllSelectedAllocationRecords(adjustmentDetail)}
                                        buttonText={'Apply Adjustment to All Selected Allocation Records'}
                                        disabled={adjustment.dataSource === 'Posted'}
                                    />
                                </div>
                            </div>
                            {(index === this.state.workAdjustmentIndex) && <div className="row" style={{ padding: '12px' }}>
                                <UsageSearchView
                                    searchViewData={filteredSearchViewData}
                                    searchResultsTableData={searchResultsTableData}
                                    componentInstance={USAGE_MATCHING_WORKS_SECTION_KEY}
                                    modalProps={undefined}
                                    searchUsages={searchUsages}
                                    searchSuccessful={searchSuccessful}
                                    indexOfFirstResult={indexOfFirstResult}
                                    indexOfLastResult={indexOfLastResult}
                                    resultsPerPage={resultsPerPage}
                                    currentPage={currentPage}
                                    resetPagination={resetPagination}
                                    updatePagination={updatePagination}
                                    hideModal={hideModal}
                                    searchMatchWorks={searchMatchWorks}
                                    searchMatchProducts={searchMatchProducts}
                                    matchWorkSearchResult={this.state.setMatchWorkSearchResult}
                                    getUsageDetails={undefined}
                                    expandMatchWorkResult={expandMatchWorkResult}
                                    expandedMatchWorkResults={expandedMatchWorkResults}
                                    addMatchworkToUsage={undefined}
                                    searchDataChanged={searchDataChanged}
                                    lookupValues={lookupValues}
                                    lookupSources={lookupSources}
                                    distributionTypes={distributionTypes}
                                    usageMatchingDefaultsWorks={usageMatchingDefaultsWorks}
                                    usageExpandAllResults={usageExpandAllResults}
                                    updateAdjustmentDetail={this.updateAdjustmentDetails}
                                    getWorkDetails={this.openWork.bind(this)}
                                    adjustmentDetails={adjustmentDetails}
                                    adjustment={adjustment}
                                    workAdjustmentIndex={this.state.workAdjustmentIndex}
                                />
                            </div>}
                            <div className='tableContainer'>
                                <Table
                                    rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'}
                                    columns={columns}
                                    dataSource={data}
                                    pagination={false}
                                />
                                <div className='row'>
                                    <SizedActionButton buttonAction={() => this.addRowToAdjustmentDetail(index)} buttonText={'Add New'} disabled={adjustment.dataSource === 'Posted'} />
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            return <div>{tableList}</div>
        }
        else return (<div></div>)
    }
}

export default (AllocationAdjustmentDetailsView);