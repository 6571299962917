import React from "react";

import IShortCutKeys from "../../../redux/types/IShortCutKeys";
import { SaveOption } from "../../types/SaveOption";
import { ADJUSTMENT_MAINTENANCE_TOOLBAR, PRODUCT_MAINTENANCE_TOOLBAR, WORK_MAINTENANCE_TOOLBAR } from "../../Consts";
import { DataSource } from "../../types/DataSource";
import { debounce } from "lodash";

interface IToolbarComponentEventListenerProps {
    saveMethod: (saveOption: SaveOption) => void;
    component: string;
    dataSource: string;
    changesMade: boolean; 
    shortCutKeys: IShortCutKeys[];
}


const ToolbarEventListener: React.FC<IToolbarComponentEventListenerProps> = ({
    saveMethod,
    component,
    dataSource,
    changesMade,
    shortCutKeys,
}) => {
    const [keysPressed, setKeysPressed] = React.useState({});
    const handleBlur = () => {
        setKeysPressed((prevState) => ({}))
    };

    const handleFocus = () => {
        setKeysPressed((prevState) => ({}))
    };

    const debounceHandleFocus = debounce(() => {
        handleFocus()}, 3000);

    const areCorrectKeysPressed = (actionCode, pressedKeys) => {
            if (!shortCutKeys || !pressedKeys) return false;
        
          
            const shortCutKeysString = shortCutKeys.find((key) => key.actionCode === actionCode)?.key;
        
            if (!shortCutKeysString) return false;
        
            const shortCutKeysArray = shortCutKeysString.split(',');
            const filteredPressedKeys = shortCutKeysArray.filter((key) => pressedKeys[key]);
        
            return filteredPressedKeys.length === shortCutKeysArray.length;
    };

    const setKeyState = (event, state) => {
        setKeysPressed((prevState) => {
            const newState = {...prevState};
            newState[event.key] = state;
            saveKeyListener(event, newState);
            return newState});
     }

    React.useEffect(() => {
        window.addEventListener('keydown', (event) => {
            setKeyState(event, true)
         });
           window.addEventListener('keyup', (event) => {
            setKeyState(event, false)
          });
          document.addEventListener('visibilitychange', handleBlur);
          window.addEventListener('blur', handleBlur);
          window.addEventListener('focus', debounceHandleFocus);


          return () => {
            setKeysPressed({});
            window.addEventListener('keydown', (event) => {
                setKeyState(event, true)
             });
            window.removeEventListener('keyup', (event) => {
                setKeyState(event, false)
             });
             document.addEventListener('visibilitychange', handleBlur);
             window.addEventListener('blur', handleBlur);
             window.addEventListener('focus', debounceHandleFocus);
          }
    }, []);
    const saveKeyListener = (e, pressedKeys) => {

        if (areCorrectKeysPressed("SAVE_DATA", pressedKeys)) {
            e.preventDefault();
            if (changesMade) {
                saveMethod(SaveOption.Save)
            }
        }
        if (areCorrectKeysPressed("SAVE_AND_MATCH", pressedKeys) && changesMade) {
             saveMethod(SaveOption.Save)
        }

        if ((component === WORK_MAINTENANCE_TOOLBAR || component === PRODUCT_MAINTENANCE_TOOLBAR) && dataSource === DataSource.Intray) {
                if ( areCorrectKeysPressed("SAVE_MATCH_POST_NEW", pressedKeys) && changesMade) {
                    saveMethod(SaveOption.SaveMatchPostNew)
                }
                if (areCorrectKeysPressed("SAVE_MATCH_AUTO_MERGE", pressedKeys) && changesMade) {
                    saveMethod(SaveOption.SaveMatchAutoMerge)
                }
        }
        if (component === ADJUSTMENT_MAINTENANCE_TOOLBAR) {
            if (areCorrectKeysPressed("SAVE_MATCH_POST_NEW", pressedKeys) && changesMade) {
                saveMethod(SaveOption.SavePost)
            }
        }
        }

    return <></>
    
}
export default ToolbarEventListener;