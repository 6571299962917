import { connect } from "react-redux";
import IAppState from "../../../../redux/types/IAppState";
import { AdjustmentMaintenancePage } from "./AdjustmentMaintenancePage";
import { showModal, showYesNoPrompt, hideModal                                               } from "../../../../redux/reducers/ModalReducer";
import { addTab, setChangesMade, updateAdjustmentField, createNewAdjustment, copyAdjustment, undoAdjustmentChanges, resetMessageBanner, expandWorkResult, expandAllResults, sortSearchResults, expandMatchWorkResult, addMatchWorkToUsage, usageExpandAllResults, resetPagination, updatePagination, updateAllocationField } from "../../../../redux/reducers/RepertoireReducer";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IYesNoPromptViewModalProps } from "../../../components/modalViews/yesNoPromptView/YesNoPromptView";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { IRepertoireField } from "../../../types/IRepertoireField";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IAdjustmentState, IAdjustmentStateKeys } from "../../../../redux/types/IAdjustmentState";
import { searchDatabricksAllocationsThunk, saveAdjustmentThunk, searchAdjustmentVersionHistoryThunk, undoAdjustmentChangesThunk, updateComponentFieldItemsThunk, searchWorksThunk, searchMatchWorksThunk, searchMatchProductsThunk, getUsageMatchingDefaultsWorks, getWorkDetailsThunk } from "../../../../redux/thunks/RepertoireThunks";
import { IAdjustment } from "../../../types/usageTypes/IAdjustment";
import { postAdjustmentBatchThunk } from "../../../../redux/thunks/JobThunks";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { clearModalSearchResultsThunk } from "../../../../redux/thunks/ModalThunks";
import { IAllocationSearchModel } from "../../../../redux/types/IAllocationSearchModel";
import { ITabProps } from "../../../../redux/types/ITabProps";
import { IWorksSearchQuery } from "../../../types/IWorksSearchQuery";
import { IMatchWorksSearchResult } from "../../../types/usageTypes/IMatchWorksSearchResult";
import { IMatchWorksSearchQuery } from "../../../types/usageTypes/IMatchWorksSearchQuery";
import { IMatchProductsSearchQuery } from "../../../types/usageTypes/IMatchProductsSearchQuery";
import { IAdjustmentMaintenanceStateKeys } from "../../../../redux/types/IAdjustmentMaintenanceState";

export default connect(
    (state: IAppState, props: ITabProps) => ({
        adjustmentMaintenanceGeneralDataViewData: state.repertoire.componentData.AdjustmentMaintenanceGeneralDataView,
        adjustmentMaintenanceToolbarData: state.repertoire.componentData.AdjustmentMaintenanceToolbarData,
        adjustmentMaintenanceGridsViewData: state.repertoire.componentData.AdjustmentMaintenanceGridsView,
        allocationAdjustmentDetailsViewData: state.repertoire.componentData.AllocationAdjustmentDetailsViewData,
        adjustmentMaintenanceSaveResultData: state.repertoire.componentData.SaveResult,
        dataGridTableData: state.repertoire.componentData.DataGridTable,
        activeTab: state.repertoire.panes[props.paneIndex].activeTab,
        tabs: state.repertoire.panes[props.paneIndex].tabs,
        loaded: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].adjustmentMaintenanceState && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].adjustmentMaintenanceState.loaded,
        adjustment: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].adjustmentMaintenanceState && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].adjustmentMaintenanceState.adjustment,
        editableFieldsDataView: state.repertoire.componentData.EditableFieldsDataView,
        lookupValues: state.lookupEntities,
        editableFields: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].editableFields && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].editableFields,
        distributions: state.dataIngestion.distributions,
        allocationRecords: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].adjustmentMaintenanceState && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].adjustmentMaintenanceState.allocationHeaderDetails,
        saveInProgress: state.repertoire.saveInProgress,
        adjustmentBatchOperationInProgress: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].adjustmentMaintenanceState && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].adjustmentMaintenanceState.adjustmentBatchOperationInProgress,
        roles: state.account.roles,
        worksSearchResults: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] === undefined ? undefined : state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].worksSearchResult,
        expandedWorkResults: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].expandedWorkResults,
        expandAll: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].expandAll,
        indexOfFirstResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'usages')[0].indexOfFirstResult,
        indexOfLastResult: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'usages')[0].indexOfLastResult,
        resultsPerPage: state.repertoire.resultsPerPage.filter(x => x.repertoireSection === 'usages')[0].resultsPerSection,
        currentPage: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].currentPage ? state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].currentPage : undefined,
        username:state.account.currentUser,
        matchWorkSearchResult: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] === undefined ? undefined : state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].matchWorkSearchResult,
        expandedMatchWorkResults: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].expandedMatchWorkResults,
        distributionTypes: state.repertoire.distributionTypes,
        usageMatchingDefaultsWorks: state.repertoire.usageMatchingDefaultsWorks,
        usagePoolsSearchResults: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].usagePoolSearchResult && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].usagePoolSearchResult,
        pool: state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].usagePoolMaintenanceState && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].usagePoolMaintenanceState.usagePool,
        expandedUsageDetailsRow:state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab] && state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].expandedUsageDetails,
        enableCuesheet:state.repertoire.enableCuesheet,
        cuesheetDatasource:state.repertoire.cuesheetDatasource,
        paneIndex:props.paneIndex,
        searchViewData: state.repertoire.componentData.UsageSearchView,
        searchResultsTableData: state.repertoire.componentData.ResultsTable,
        saveSuccessful:state.repertoire.panes[props.paneIndex].tabs[state.repertoire.panes[props.paneIndex].activeTab].saveSuccessful,
        searchSuccessful: state.repertoire.searchSuccessful,

    }),
    dispatch => ({
        showModal: (
            modalContent: string,
            modalComponentInstance: string,
            modalProps: any,
            displayModalCloseButton: boolean,
            title: string
        ) =>
            dispatch(
                showModal(modalContent, modalComponentInstance, modalProps, displayModalCloseButton, title)
            ),
        hideModal: () => dispatch(hideModal()),
        addTab: (tab: ITabReduxItem) => dispatch(addTab(tab)),
        setChangesMade: (tabIndex: number | null, changesMade: boolean) => dispatch(setChangesMade(tabIndex, changesMade)),
        clearModalSearchResults: () => dispatch<any>(clearModalSearchResultsThunk()),
        updateAdjustmentField: (value: any, fieldName: IAdjustmentStateKeys) => dispatch(updateAdjustmentField(value, fieldName)),
        updateAllocationRecordField: (value: any, fieldName: IAdjustmentMaintenanceStateKeys) => dispatch(updateAllocationField(value, fieldName)),
        saveChanges: (adjustment: IAdjustmentState, saveOption?: string) => dispatch<any>(saveAdjustmentThunk(adjustment, saveOption)),
        addNewAdjustment: (lookupValues: ILookupDictionary, isNew?: boolean, adjustmentMaintenanceGeneralDataView?: IRepertoireComponentDataItem) => dispatch<any>(createNewAdjustment(lookupValues, isNew, adjustmentMaintenanceGeneralDataView)),
        updateComponentFieldsItems: (
            fields: IRepertoireField[],
            componentName: string,
            componentInstance: string,
            componentDataFieldName: string,
            tabs: ITabReduxItem[],
            activeTab: number) => dispatch<any>(updateComponentFieldItemsThunk(fields, componentName, componentInstance, componentDataFieldName, tabs, activeTab)),
        copyAdjustment: () => dispatch<any>(copyAdjustment()),
        searchAllocation: (searchParams: IAllocationSearchModel) => dispatch<any>(searchDatabricksAllocationsThunk(searchParams)),
        postAdjustments: (batchId: number) => dispatch<any>(postAdjustmentBatchThunk(batchId)),
        undoAdjustmentChanges: (adjustmentId: string) => dispatch<any>(undoAdjustmentChangesThunk(adjustmentId)),
        searchAdjustmentVersionHistory: (adjustmentId: string, lookups: ILookupDictionary, actionList: IDataActionToolbar[], formats: FormatFields[]) =>
            dispatch<any>(searchAdjustmentVersionHistoryThunk(adjustmentId, lookups, actionList, formats)),
        showYesNoPrompt: (title: string, props: IYesNoPromptViewModalProps) =>
            dispatch(showYesNoPrompt(title, props)),
        resetMessageBanner: () => dispatch(resetMessageBanner()),
        searchWorks: (query: IWorksSearchQuery, modalOpen: boolean) =>
            dispatch<any>(searchWorksThunk(query, false)),
        expandWorkResult: (workResult: number) => dispatch<any>(expandWorkResult(workResult)),
        expandAllResults: () => dispatch<any>(expandAllResults()),
        sortSearchResults: (name: string, results: any) => dispatch(sortSearchResults(name, results)),
        searchMatchWorks: (query: IMatchWorksSearchQuery, modalOpen: boolean) => dispatch<any>(searchMatchWorksThunk(query, true)),
        expandMatchWorkResult: (matchWorkResults: number) => dispatch<any>(expandMatchWorkResult(matchWorkResults)),
        getUsageMatchingDefaultsWorks: () => dispatch<any>(getUsageMatchingDefaultsWorks()),
        addMatchworkToUsage: (work: IMatchWorksSearchResult) => dispatch(addMatchWorkToUsage(work)),
        usageExpandAllResults: () => dispatch<any>(usageExpandAllResults()),
        resetPagination: (repertoireSection: string) => dispatch(resetPagination(repertoireSection)),
        updatePagination: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) =>
            dispatch<any>(updatePagination(indexOfFirstResult, indexOfLastResult, resultsPerPage, currentPage, repertoireSection)),
        getWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], readonlyIndicatorsWorkFlagTypes: string[], dataActions?: IDataActionToolbar[], workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem, formats?: FormatFields[], openEntityForWorflowSession?: boolean, isOpenedInSecondTab?: boolean) =>
                    dispatch<any>(getWorkDetailsThunk(dataSource, workID, lookups, otherIndicatorsWorkFlagTypes, dataActions, workMaintenanceGeneralDataViewData, formats, readonlyIndicatorsWorkFlagTypes, openEntityForWorflowSession, undefined, isOpenedInSecondTab)),
    })
)(AdjustmentMaintenancePage);