import * as React from "react";
import { IDistribution } from "../../../../repertoire/types/usageTypes/IDistribution";
import { hideModal, showModal, showYesNoPrompt, showUpdateFieldsModal } from "../../../../redux/reducers/ModalReducer";
import { clearModalSearchResults, updateUsageField, setEditableFields, startWorkflowSession, nextWorkflowInSession, addUsageDetailsShareIPRow, addMatchWorkToUsage } from "../../../../redux/reducers/RepertoireReducer";
import IRepertoireComponentDataItem from "../../../../redux/types/IRepertoireComponentDataItem";
import { ITabReduxItem } from "../../../../redux/types/ITabReduxItem";
import { IUsageGroupState } from "../../../../redux/types/IUsageGroupState";
import { getDataAction } from "../../../components/toolBar/ToolbarHelper";
import { getUsageDataAction } from "../../../components/usageComponents/toolBar/ToolbarHelper";
import { ALL_ROLES, SAVE_CHANGES_STATE_KEY, SAVE_METHOD_TOOLBAR, UPDATE_USAGES_ROLE, UPDATE_USAGES_ADVANCED_ROLE, USAGE_MAINTENANCE_TOOLBAR, WORK_MAINTENANCE_TOOLBAR } from "../../../Consts";
import UsageMaintenanceGeneralDataView from "../usageMaintenanceGeneralDataView/UsageMaintenaceGeneralDataView";
import { UsageMaintenanceGridsView } from "../usageMaintenanceGridsView/UsageMaintenanceGridsView";
import UsageMaintenanceToolbar from "../usageMaintenanceToolbar/UsageMaintenanceToolbar";
import { IRepertoireField } from "../../../types/IRepertoireField";
import {
    CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY,
    PRODUCT_MAINTENANCE_GROUP,
    WORK_MAINTENANCE_GROUP,
} from "../../../ConfigurationConsts";
import { SearchRequests } from "../../../services/SearchRequests";
import { ILookupDictionary } from "../../../../lookup/types/ILookupDictionary";
import { IDataActionToolbar } from "../../../types/IDataActionToolbar";
import { FormatFields } from "../../../../redux/types/FormatFields";
import { ITreeData } from "../../../types/ITreeData";
import { IWorkflowSearchResult } from "../../../types/IWorkflowSearchResult";
import { IWorkflowParams } from "../../../types/IWorkFlowParams";
import { IComponentDataItem } from "../../../../core/types/IComponentDataItem";
import { CleanUploadFilesState } from "../../../../redux/reducers/DataIngestionReducer";
import { IUsageGroupLocation } from "../../../types/usageTypes/IUsageGroupLocation";
import { IUsageGroupSetListAdditional } from "../../../types/usageTypes/IUsageGroupSetListAdditional";
import { IUsageGroupVenueDetails } from "../../../types/usageTypes/IUsageGroupVenueDetails";
import { IUsagePool } from "../../../types/usageTypes/IUsagePool";
import { IUsagePoolSearchQuery } from "../../../types/usageTypes/IUsagePoolSearchQuery";
import { IUsagePoolState } from "../../../../redux/types/IUsagePoolState";
import { IWorksSearchQuery } from "../../../types/IWorksSearchQuery";
import { IWorksSearchResult } from "../../../types/IWorksSearchResult";
import { IMatchProductsSearchQuery } from "../../../types/usageTypes/IMatchProductsSearchQuery";
import { IMatchWorksSearchQuery } from "../../../types/usageTypes/IMatchWorksSearchQuery";
import { IUsagesSearchQuery } from "../../../types/usageTypes/IUsagesSearchQuery";
import { IMatchWorksSearchResult } from "../../../types/usageTypes/IMatchWorksSearchResult";
import { IDistributionType } from "../../../types/usageTypes/IDistibutionType";
import { IMatchingProductsRowData } from "../../../types/usageTypes/IMatchingProductsRowData";

export const SCREEN_SIZE_BIG_ENOUGH_FOR_TWO_SCREENS = 1100;
export interface IUsageMaintenancePageProps {
    usageGroup?: IUsageGroupState;
    usageMaintenanceGeneralViewData: IRepertoireComponentDataItem;
    usageMaintenanceGridsViewData: IRepertoireComponentDataItem;
    usageDataGridTableData: IRepertoireComponentDataItem;
    activeTab: number;
    tabs: ITabReduxItem[];
    showModal: typeof showModal;
    hideModal: typeof hideModal;
    clearModalSearchResults: typeof clearModalSearchResults;
    searchViewData: IRepertoireComponentDataItem;
    searchResultsTableData: IRepertoireComponentDataItem;
    searchSuccessful?: boolean;
    usageMaintenanceToolbarData?: IRepertoireComponentDataItem;
    usageMaintenanceMatchingWorksToolbar?: IRepertoireComponentDataItem;
    usageMaintenanceSaveResultData?: IRepertoireComponentDataItem;
    resetMessageBanner: () => void;
    showYesNoPrompt: typeof showYesNoPrompt;
    currentUser?: string;
    saveChanges: (updatedUsage: IUsageGroupState,
        isWorkflowSession: boolean,
        workflowsSessionItems?: IWorkflowSearchResult[],
        currentWorkflowIndex?: number,
        activeTab?: number,
        isOpenForWorkflowSession?: boolean) => void;
    updateUsageField: typeof updateUsageField;
    showUpdateFieldsModal?: typeof showUpdateFieldsModal;
    editableFieldsDataView?: IRepertoireComponentDataItem;
    setEditableFields: typeof setEditableFields;
    updateComponentFieldsItems: (fields: IRepertoireField[],
        componentName: string,
        componentInstance: string,
        componentDataFieldName: string,
        tabs: ITabReduxItem[],
        activeTab: number) => void;
    updateFields?: (fields: IRepertoireField[], componentName: string) => void;
    editableFields?: IRepertoireField[];
    distributions: IDistribution[];
    dataGridTableData?: IRepertoireComponentDataItem;
    lookupValues: ILookupDictionary;
    workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem;
    productMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem;
    getWorkDetails: (dataSource: string, workID: number, lookups: ILookupDictionary, otherIndicatorsWorkFlagTypes: string[], readonlyIndicatorsWorkFlagTypes: string[], dataActions?: IDataActionToolbar[], workMaintenanceGeneralDataViewData?: IRepertoireComponentDataItem, formats?: FormatFields[], openEntityForWorflowSession?: boolean, isOpenedInSecondTab?: boolean) => void;
    getProductDetails: (
        dataSource: string,
        productCoreID: number,
        productMaintenanceGeneralDataView: IRepertoireComponentDataItem,
        formats: FormatFields[],
        openEntityForWorflowSession?: boolean,
        openNewTab?: boolean,
        id1?: string,
        id2?: string,
        productType?: string,
        originOfRevision?: string,
        enableCuesheet?: boolean,
        cuesheetDatasource?: any,
        productionType?: string,
        musicDuration?: number[],
        isOpenedInSecondTab?: boolean,
    ) => void;
    getSources: () => void;
    lookupSources?: ITreeData[];
    workflowFieldsData?: IRepertoireComponentDataItem;
    isWorkflowSessionStarted?: boolean;
    startWorkflowSession?: typeof startWorkflowSession;
    cancelWorkflowSession?: (activeTab: number) => void;
    nextWorkflowItem?: typeof nextWorkflowInSession;
    skipWorkflowItem?: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]) => void;
    workflowSessionItems?: IWorkflowSearchResult[];
    currentWorkflowIndex?: number;
    isOpenForWorkflowSession?: boolean;
    skipWorkWorkflowItem: (
        activeTab: number
        , currentWorkflowIndex: number
        , workflows: IWorkflowSearchResult[]
        , lookups: ILookupDictionary
        , otherIndicatorsWorkFlagTypes: string[]
        , dataActions: IDataActionToolbar[]
        , workMaintenanceGeneralDataViewData: IRepertoireComponentDataItem
        , formats: FormatFields[]
        , readonlyIndicatorsWorkFlagTypes: string[]) => void;
    formatFields?: FormatFields[];
    refreshUsageGroupDetails?: (usageID: string, activeTabItem: ITabReduxItem) => void;
    saveWorkflow?: (workflowparams: IWorkflowParams) => void;
    roles?: string[];
    matchingSearchViewDefaults?: {
        usageMatchingSearchDefaultsProducts?: string[],
        usageMatchingSearchDefaultsWorks?: string[]
    }
    addUsageDetailsShareIPRow?: typeof addUsageDetailsShareIPRow;
    containerDetailsWindowComponentData: IComponentDataItem;
    getFilesystems: (isRepertoireModule?: boolean) => void;
    getDataIngestionComponentData: () => void;
    cleanUploadFilesState: typeof CleanUploadFilesState;
    updateLocation: (newLocation: IUsageGroupLocation) => void,
    updateSetListAdditional: (newSetListAdditional: IUsageGroupSetListAdditional) => void,
    updateUsageGroupVenueDetails: (newVenueDetails: IUsageGroupVenueDetails) => void,
    updateWorkflowAndEntityStatus: (workflowparams: IWorkflowParams, approvalStatus?: boolean) => void;
    updateClaimWorkflowAndEntityStatus?: (workflowparams: IWorkflowParams, callFrom: string, refreshDetails: () => void) => void;
    searchUsagePools: (query: IUsagePoolSearchQuery) => void;
    sources?: ITreeData[];
    getUsagePool: (poolId: number, matchingSources: ITreeData[]) => void;
    pool?: IUsagePoolState;
    updatedScroll?: number;
    expandedUsageDetailsRow?: number[];
    expandUsageDetail?: (index: number) => void;
    dataSource?: string;
    searchWorks?: (query: IWorksSearchQuery, modalOpen: boolean) => void;
    worksSearchResults?: IWorksSearchResult[];
    expandedWorkResults: number[];
    expandAll: boolean;
    indexOfFirstResult?: number;
    indexOfLastResult?: number;
    resultsPerPage?: number;
    expandWorkResult?: (workResult: number) => void;
    expandAllResults?: () => void;
    currentPage?: number;
    resetPagination?: (repertoireSection: string) => void;
    updatePagination?: (indexOfFirstResult: number, indexOfLastResult: number, resultsPerPage: number, currentPage: number, repertoireSection: string) => void;
    sortSearchResults?: (name: string, results: any) => void;
    username?: string;
    searchUsages?: (query: IUsagesSearchQuery, modalOpen: boolean, usageType: string, lookupSources: ITreeData[], continuationToken?: string) => void;
    searchMatchWorks?: (query: IMatchWorksSearchQuery, modalOpen: boolean) => void;
    searchMatchProducts?: (query: IMatchProductsSearchQuery, modalOpen: boolean) => void;
    matchWorkSearchResult?: IMatchWorksSearchResult[];
    expandedMatchWorkResults?: number[];
    distributionTypes?: IDistributionType[];
    usageMatchingDefaultsWorks?: string[];
    getUsageMatchingDefaultsWorks?: () => void;
    addMatchworkToUsage?: typeof addMatchWorkToUsage;
    usageExpandAllResults?: () => void;
    expandMatchWorkResult?: (matchWorkResults: number) => void;
    enableCuesheet?:boolean;
    cuesheetDatasource?:any;
    addNewWork: (lookups: ILookupDictionary, actionList?: IDataActionToolbar[], isNew?: boolean, workMaintenanceGeneralDataView?: IRepertoireComponentDataItem, formats?: FormatFields[], workGroupId?: string, workGroup?: string, paneIndex?: number,isFromUsage?:boolean,usage?:IUsageGroupState,isOpenedInSecondTab?: boolean) =>void;
    paneIndex?:number;
    getUsageDetails?: (usageID: string, openEntityForWorflowSession?: boolean,reGroupIds?:Number[]) => void;
    saveRegroupedUsageGroup?:(reGroupedUsage:IUsageGroupState,currentPane?:number) =>void;
    enableRegroupAndDeleteUI?:boolean;

}

interface IUsageMaintenancePageState {
    toolbarWidth: number;
    isCompleteWorkflowOnSave: boolean;
    dataAction: IDataActionToolbar[];
}

export class UsageMaintenancePage extends React.PureComponent<
    IUsageMaintenancePageProps,
    IUsageMaintenancePageState
> {
    toolbarParentRef;
    constructor(props: IUsageMaintenancePageProps) {
        super(props);
        this.state = {
            toolbarWidth: null,
            isCompleteWorkflowOnSave: true,
            dataAction: this.getDataAction(),
        };
        this.toolbarParentRef = React.createRef();
        const { searchUsagePools } = this.props;
        const query: IUsagePoolSearchQuery = {
            poolCode: "",
            cat1: "",
            cat2: "",
            allocationRule: ""
        }
        searchUsagePools(query);
    }
    getUpdateRoleDisabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(UPDATE_USAGES_ROLE) || roles.includes(ALL_ROLES))) {
            return false;
        }
        return true;
    }

    getUpdateAdvancedRoleEnabled = (): boolean => {
        const { roles } = this.props;
        if (roles && (roles.includes(UPDATE_USAGES_ADVANCED_ROLE) || roles.includes(ALL_ROLES))) {
            return true;
        }
        return false;
    }

    getDataAction() {
        const {
            usageMaintenanceToolbarData: { fields },
        } = this.props;

        const actionList: IDataActionToolbar[] = [];

        const save: IDataActionToolbar = {
            action: this.saveUsage.bind(this),
            component: USAGE_MAINTENANCE_TOOLBAR,
            method: SAVE_METHOD_TOOLBAR,
            name: fields.find(save => save.name === SAVE_CHANGES_STATE_KEY).data,
            icon: '',
            isReadOnly: this.getUpdateRoleDisabled()
        };
        actionList.push(save);

        return actionList;
    }

    componentDidMount() {

        const { lookupSources, getSources, containerDetailsWindowComponentData, searchUsagePools, getUsageMatchingDefaultsWorks, getDataIngestionComponentData, cleanUploadFilesState, getFilesystems } = this.props;

        if (!lookupSources) {
            getSources();
        }

        if (!containerDetailsWindowComponentData || !containerDetailsWindowComponentData.fields || containerDetailsWindowComponentData.fields.length === 0) {
            getDataIngestionComponentData();
        }


        getFilesystems(true);
        cleanUploadFilesState();
        getUsageMatchingDefaultsWorks && getUsageMatchingDefaultsWorks();

    }

    calculateWeightCategory(weight: number): string {
        let low: number = 1000;
        let med: number = 5000;

        if (weight) {
            if (weight < low)
                return "Low";
            else if (weight >= low && weight < med)
                return "Med";
            else if (weight >= med)
                return "High";
        }
        else
            return "";
    }

    saveUsage() {
        const { saveChanges, usageGroup, tabs, activeTab, isWorkflowSessionStarted, workflowSessionItems, currentWorkflowIndex, isOpenForWorkflowSession } = this.props;
        const dataActions = getUsageDataAction(USAGE_MAINTENANCE_TOOLBAR);
        const { isCompleteWorkflowOnSave } = this.state;
        if (isCompleteWorkflowOnSave) {
            if (usageGroup.workflows && usageGroup.workflows[0] && usageGroup.workflows[0].status) {
                usageGroup.workflows[0].status = "Completed";
            }
        }
        saveChanges(usageGroup, isWorkflowSessionStarted, workflowSessionItems, currentWorkflowIndex + 1, activeTab, isOpenForWorkflowSession);
    }

    undoChanges() {
        return null;
    }

    resetMessageBanner() {
        const { resetMessageBanner } = this.props;
        resetMessageBanner();
    }

    expandUsageDetailRow(index: number) {
        const { expandUsageDetail } = this.props;
        expandUsageDetail(index);
    }
    expandMatchWorkResult(index: number) {
        const { expandMatchWorkResult } = this.props;        
        expandMatchWorkResult(index);
    }

    getWorkDetails(dataSource: string, workID: number, isOpenedInSecondTab?: boolean) {
        const isScreenWidthBigEnoughForTwoScreens = window.innerWidth > SCREEN_SIZE_BIG_ENOUGH_FOR_TWO_SCREENS;
        const { lookupValues, workMaintenanceGeneralDataViewData, tabs, activeTab } = this.props;
        const dataActionList = getDataAction(dataSource, WORK_MAINTENANCE_TOOLBAR);
        const formatFields = SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, WORK_MAINTENANCE_GROUP)
            .then(formats => {
                this.props.getWorkDetails(dataSource, workID, lookupValues, tabs[activeTab].otherIndicatorWorkFlagTypes, tabs[activeTab].readonlyIndicatorWorkFlagTypes, dataActionList, workMaintenanceGeneralDataViewData, formats, undefined,  isScreenWidthBigEnoughForTwoScreens && isOpenedInSecondTab);
            });
    }

    getProductDetails = (dataSource: string, tableItem: IMatchingProductsRowData, isOpenedInSecondTab?: boolean) => {
        const isScreenWidthBigEnoughForTwoScreens = window.innerWidth > SCREEN_SIZE_BIG_ENOUGH_FOR_TWO_SCREENS;
        const { productMaintenanceGeneralDataViewData, enableCuesheet, cuesheetDatasource, usageGroup } = this.props;
        const formatFields = SearchRequests.getConfigurationParameter(CONFIGURATION_PARAMETER_FIELD_FORMAT_KEY, PRODUCT_MAINTENANCE_GROUP)
            .then(formats => {
                this.props.getProductDetails(dataSource, tableItem.productCoreID, productMaintenanceGeneralDataViewData, formats, undefined, undefined, tableItem.id1, tableItem.id2, tableItem.type, tableItem.originOfRevision, enableCuesheet, cuesheetDatasource, undefined, undefined, isScreenWidthBigEnoughForTwoScreens && isOpenedInSecondTab);
            });
    }
    refreshUsageGroupDetails = () => {
        const { tabs, activeTab } = this.props;
        let activeTabItem = tabs[activeTab];

        this.props.refreshUsageGroupDetails(activeTabItem.usageMaintenanceState.usageGroup.id, activeTabItem)
    }

    completeWorkflowOnSave(value: boolean) {
        const { isCompleteWorkflowOnSave } = this.state;
        this.setState({
            isCompleteWorkflowOnSave: value
        });
    }


    render() {
        const {
            usageMaintenanceGeneralViewData,
            usageMaintenanceGridsViewData,
            usageDataGridTableData,
            tabs,
            activeTab,
            showModal,
            hideModal,
            clearModalSearchResults,
            usageMaintenanceToolbarData,
            usageMaintenanceSaveResultData,
            currentUser,
            usageGroup,
            showYesNoPrompt,
            updateUsageField,
            editableFieldsDataView,
            showUpdateFieldsModal,
            setEditableFields,
            editableFields,
            updateComponentFieldsItems,
            distributions,
            lookupSources,
            workflowFieldsData,
            isWorkflowSessionStarted,
            startWorkflowSession,
            cancelWorkflowSession,
            nextWorkflowItem,
            skipWorkflowItem,
            workflowSessionItems,
            currentWorkflowIndex,
            isOpenForWorkflowSession,
            lookupValues,
            formatFields,
            skipWorkWorkflowItem,
            saveWorkflow,
            roles,
            matchingSearchViewDefaults,
            addUsageDetailsShareIPRow,
            resetMessageBanner,
            updateLocation,
            updateSetListAdditional,
            updateUsageGroupVenueDetails,
            updateWorkflowAndEntityStatus,
            updateClaimWorkflowAndEntityStatus,
            sources,
            getUsagePool,
            pool,
            updatedScroll,
            expandUsageDetail,
            usageMaintenanceMatchingWorksToolbar,
            searchViewData,
            searchResultsTableData,
            dataSource,
            searchWorks,
            worksSearchResults,
            indexOfFirstResult,
            searchUsages,
            searchMatchProducts,
            searchMatchWorks,
            indexOfLastResult,
            resultsPerPage,
            expandWorkResult,
            expandAllResults,
            currentPage,
            resetPagination,
            updatePagination,
            sortSearchResults,
            username,
            matchWorkSearchResult,
            expandedMatchWorkResults,
            expandedWorkResults,
            expandAll,
            searchSuccessful,
            usageMatchingDefaultsWorks,
            distributionTypes,
            addMatchworkToUsage,
            usageExpandAllResults,
            addNewWork,
            paneIndex,
            getUsageDetails,
            saveRegroupedUsageGroup,
            enableRegroupAndDeleteUI
        } = this.props;
        const isWorkFlowShown = usageGroup != null && usageGroup.workflows != null && usageGroup.workflows.length > 0;
        const changesMade = tabs[activeTab].changesMade;
        const {
            toolbarWidth,
            dataAction,
        } = this.state;

        const isReadonly: boolean = tabs[activeTab].isReadonly;

        if (usageGroup) {
            const {
                id,
                fullTitle,
                fullContributor,
                fullPerformer,
                sourceMajor,
                sourceMinor,
                usageType,
                matchStatus,
                allocationStatus,
                allocations,
                remittingSocietyWorkCode,
                distributionCode,
                totalWeight,
                productType,
                location,
                setListAdditional,
                venueDetails,
                rejectReason,
                poolCode
            } = usageGroup;
            if(distributions && distributions.length){
                var distributionStatus: string = distributions.find((d) => d.distributionCode === distributionCode)?.distributionStatus;
            }
           
            return (
                <div className="usageMaintenancePage">
                    <div><span className="title">{fullTitle}</span></div>
                    <div id="toolbarContainer" className="WorkMaintenanceToolbar collapse" ref={this.toolbarParentRef}>
                        <UsageMaintenanceToolbar
                            scroll={updatedScroll}
                            toolbarWidth={toolbarWidth}
                            toolbarData={usageMaintenanceToolbarData}
                            saveResultData={usageMaintenanceSaveResultData}
                            saveChanges={this.saveUsage.bind(this)}
                            resetMessageBanner={this.resetMessageBanner.bind(this)}
                            changesMade={changesMade}
                            usageGroup={usageGroup}
                            activeTab={activeTab}
                            tabs={tabs}
                            showModal={showModal}
                            showYesNoPrompt={showYesNoPrompt}
                            hideModal={hideModal}
                            clearModalSearchResults={clearModalSearchResults}
                            currentUser={currentUser}
                            usageMaintenanceGeneralDataViewData={usageMaintenanceGeneralViewData}
                            workflowFieldsData={workflowFieldsData}
                            isWorkflowSessionStarted={isWorkflowSessionStarted}
                            startWorkflowSession={startWorkflowSession}
                            cancelWorkflowSession={cancelWorkflowSession}
                            nextWorkflowItem={nextWorkflowItem}
                            skipWorkflowItem={skipWorkflowItem}
                            workflowSessionItems={workflowSessionItems}
                            currentWorkflowIndex={currentWorkflowIndex}
                            isOpenForWorkflowSession={isOpenForWorkflowSession}
                            lookupValues={lookupValues}
                            formatFields={formatFields}
                            skipWorkWorkflowItem={skipWorkWorkflowItem}
                            refreshWorkDetails={this.refreshUsageGroupDetails}
                            saveWorkflow={saveWorkflow}
                            roles={roles}
                            updateWorkflowAndEntityStatus={updateWorkflowAndEntityStatus}
                            updateClaimWorkflowAndEntityStatus={updateClaimWorkflowAndEntityStatus}
                            completeWorkflowOnSave={this.completeWorkflowOnSave.bind(this)}
                            getUsageDetails={getUsageDetails}
                            updateUsageField={updateUsageField}
                            updateUsagesAdvanced={this.getUpdateAdvancedRoleEnabled()}
                            paneIndex={paneIndex}
                            saveRegroupedUsageGroup={saveRegroupedUsageGroup}
                            distributionStatus={distributionStatus}
                            enableRegroupAndDeleteUI={enableRegroupAndDeleteUI}
                        />
                    </div>
                    <button type="button" className="toolbar-toggle" data-toggle="collapse" data-target="#toolbarContainer">
                        <i className="icon ms-Icon ms-Icon--MoreVertical" aria-hidden="true"></i>
                    </button>

                    <UsageMaintenanceGeneralDataView
                        key={id}
                        usageMaintenanceGeneralDataViewData={usageMaintenanceGeneralViewData}
                        title={fullTitle}
                        composer={fullContributor}
                        artist={fullPerformer}
                        editableFieldsDataView={editableFieldsDataView}
                        showUpdateFieldsModal={showUpdateFieldsModal}
                        hideModal={hideModal}
                        editableFields={editableFields}
                        setEditableFields={setEditableFields}
                        dataGridTableData={usageDataGridTableData}
                        distributionCode={distributionCode}
                        matchStatus={matchStatus}
                        allocations={allocations}
                        allocationStatus={allocationStatus}
                        sourceMajor={sourceMajor}
                        sourceMinor={sourceMinor}
                        usageType={usageType}
                        distributions={distributions}
                        licenseesWorkNumber={remittingSocietyWorkCode}
                        usageWeight={totalWeight}
                        weightCategory={this.calculateWeightCategory(totalWeight)}
                        isReadonly={isReadonly}
                        tabs={tabs}
                        activeTab={activeTab}
                        updateComponentFieldsItems={updateComponentFieldsItems}
                        lookupSources={lookupSources}
                        updateUsageField={updateUsageField}
                        lookupValues={lookupValues}
                        roles={roles}
                        productType={productType}
                        showModal={showModal}
                        location={location}
                        setListAdditional={setListAdditional}
                        updateLocation={updateLocation}
                        updateSetListAdditional={updateSetListAdditional}
                        approvalStatus={this.props.usageGroup.approvalStatus}
                        enableApprovalStatusEdit={this.props.usageGroup.enableApprovalStatusEdit}
                        venueDetails={venueDetails}
                        updateUsageGroupVenueDetails={updateUsageGroupVenueDetails}
                        qaStatus={this.props.usageGroup.qaStatus}
                        rejectReason={rejectReason}
                        poolCode={poolCode}
                    />
                    <UsageMaintenanceGridsView
                        usageMaintenanceGridsViewData={usageMaintenanceGridsViewData}
                        usageDataGridTableData={usageDataGridTableData}
                        usageGroup={this.props.usageGroup}
                        showModal={showModal}
                        updateUsageField={updateUsageField}
                        hideModal={hideModal}
                        clearModalSearchResults={clearModalSearchResults}
                        getWorkDetails={this.getWorkDetails.bind(this)}
                        getProductDetails={this.getProductDetails.bind(this)}
                        lookupValues={lookupValues}
                        addUsageDetailsShareIPRow={addUsageDetailsShareIPRow}
                        resetMessageBanner={this.resetMessageBanner.bind(this)}
                        showUpdateFieldsModal={showUpdateFieldsModal}
                        editableFields={editableFields}
                        setEditableFields={setEditableFields}
                        editableFieldsDataView={editableFieldsDataView}
                        updateComponentFieldsItems={updateComponentFieldsItems}
                        tabs={tabs}
                        activeTab={activeTab}
                        sources={sources}
                        getUsagePool={getUsagePool}
                        pool={pool}
                        expandedUsageDetailsRow={this.props.expandedUsageDetailsRow}
                        expandUsageDetail={this.expandUsageDetailRow.bind(this)}
                        updatedScroll={updatedScroll}
                        usageMaintenanceMatchingWorksToolbar={usageMaintenanceMatchingWorksToolbar}
                        searchViewData={searchViewData}
                        searchResultsTableData={searchResultsTableData}
                        dataSource={dataSource}
                        searchWorks={searchWorks}
                        worksSearchResults={worksSearchResults}
                        expandedWorkResults={expandedWorkResults}
                        expandAll={expandAll}
                        searchSuccessful={searchSuccessful}
                        indexOfFirstResult={indexOfFirstResult}
                        indexOfLastResult={indexOfLastResult}
                        resultsPerPage={resultsPerPage}
                        expandWorkResult={expandWorkResult}
                        expandAllResults={expandAllResults}
                        currentPage={currentPage}
                        resetPagination={resetPagination}
                        updatePagination={updatePagination}
                        sortSearchResults={sortSearchResults}
                        username={username}
                        searchUsages={searchUsages}
                        searchMatchWorks={searchMatchWorks}
                        searchMatchProducts={searchMatchProducts}
                        matchWorkSearchResult={matchWorkSearchResult}
                        expandedMatchWorkResults={expandedMatchWorkResults}
                        distributionTypes={distributionTypes}
                        lookupSources={lookupSources}
                        usageMatchingDefaultsWorks={usageMatchingDefaultsWorks}
                        addMatchworkToUsage={addMatchworkToUsage}
                        usageExpandAllResults={usageExpandAllResults}
                        expandMatchWorkResult={this.expandMatchWorkResult.bind(this)}
                        addNewWork={addNewWork}
                        paneIndex={paneIndex}
                        formatFields={formatFields}
                        updateUsagesAdvanced={this.getUpdateAdvancedRoleEnabled()}
                        enableRegroupAndDeleteUI={enableRegroupAndDeleteUI}
                    />

                </div>
            );
        }
        else return <div />;
    }
}
