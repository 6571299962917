import axios from "axios";
import { IWorkflow } from "../../types/IWorkflow";
import { IUsage } from "../../types/usageTypes/IUsage";
import { IUsageGroupLocation } from "../../types/usageTypes/IUsageGroupLocation";
import { IUsageGroupSetListAdditional } from "../../types/usageTypes/IUsageGroupSetListAdditional";
import { IUsageMatch } from "../../types/usageTypes/IUsageMatch";
import { IUsageGroupVenueDetails } from "../../types/usageTypes/IUsageGroupVenueDetails";
import { IUsageGroup } from "../../types/usageTypes/IUsageGroup";
import { IDistributionState } from "../../../redux/types/IDistributionState";
import { IUsagePoolState } from "../../../redux/types/IUsagePoolState";

export class UsageService {
    
    public static getUsage(usageID: string): Promise<IUsageGroup> {
        return new Promise<IUsageGroup>((resolve, reject) => {
            axios
                .get("/usage/id/" + usageID)
                .then(response => {
                    if (response.status === 200) {
                        const result = response.data;
                        resolve(result);
                    }
                    else {
                        throw response;
                    }
                })
                .catch(err => {
                    reject(err.response.status);
                });
        });
    }

    public static getDistributions(): Promise<IDistributionState[]> {
        return new Promise<IDistributionState[]>((resolve, reject) => {
            axios.get("Distribution/Distributions/")
                .then(res => {
                    resolve(res.data);
                })
        });
    }

    public static getPools(): Promise<IUsagePoolState[]> {
        return new Promise<IUsagePoolState[]>((resolve, reject) => {
            axios.get("usagePool/Pools/")
                .then(res => {
                    resolve(res.data);
                })
        });
    }
    public static getSources(matchType: string, level: number): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
            axios.get("Sources/GetSourcesByMatchType/" + matchType + "/" + level)
                .then(res => {
                    resolve(res.data);
                })
        });
    }

    static saveUsageGroup(
        matchingWorks: IUsageMatch[], 
        workflows: IWorkflow[], 
        id: string, 
        matchStatus: string, 
        usages?: IUsage[],
        location?: IUsageGroupLocation,
        setListAdditional?: IUsageGroupSetListAdditional,
        approvalStatus?: string,
        venueDetails?: IUsageGroupVenueDetails,
        isFromSearchResults?: boolean,
        qaStatus?: string
    ): Promise<any> {
        const url: string = "usage/save/" + id;
        let usageRowData: IUsage[] = [];
        if(usages === undefined || usages === null) {
            usages = usageRowData;
        }
        var data = {
            usageMatches: matchingWorks,
            workflows: workflows,
            matchStatus: matchStatus,
            usages: usages,
            location: { ... location },
            setListAdditional: {...setListAdditional },
            approvalStatus,
            venueDetails: { ...venueDetails },
            isFromSearchResults: isFromSearchResults,
            qaStatus: qaStatus || null
        };
        return new Promise<any>((resolve, reject) => {
            axios
                .post(url, data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static async updateUsageApprovalStatus(
        usageGroupId: string,
        approved: boolean
    ): Promise<any> {
        
        const data = {
            approved: approved
        }

        return new Promise<any>((resolve, reject) => {
            axios
                .put(`usage/approval/${usageGroupId}`, data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    static undoUsageIngestion(fullFilePath: string): Promise<any> {
        const url: string = encodeURI("storage/undo/" + fullFilePath);
        return new Promise<any>((resolve, reject) => {
            axios.delete(url).then(res => resolve(res)).catch(err => reject(err));
        });
    }

    public static getPaymentRunIDs(): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
            axios.get("usage/getPaymentRunIDs")
                .then(res => {
                    resolve(res.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }
    static saveRegroupedUsage(regroupedUsage: IUsageGroup,id?:string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            axios
                .post(`usage/saveRegroupedUsageGroup/${id}`, regroupedUsage)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                })
        })
    }
}

